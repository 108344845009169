import { useEffect } from "react";
import produce from "immer";
import { genTempId } from "@helpers/utils";
import {
  getThreadDetailsByDescription,
  getThreadDetailsByOuterDiameter,
} from "@helpers/thread";
import { InputNumber } from "@components/InputNumber";
import { AddNextBtn } from "../../AddNextBtn";
import { Diameter } from "./Diameter";
import commonCs from "../AdditionalProcessing.module.scss";
import cs from "./Threads.module.scss";

export const Threads = ({ holes = [], readOnly, onChange }) => {
  holes = addTempId(holes);

  useEffect(() => {
    if (holes.length === 0) {
      setTimeout(() => {
        handleDiameterAdded();
      });
    }
  }, [holes]);

  return (
    <div>
      <div className="h5 mb16">Gwintowanie:</div>

      <div className={commonCs.body}>
        <div className={commonCs.amount}>
          <div className="color-dark-gray">Ilość średnic gwintów</div>
          <div>
            <InputNumber
              value={holes.length}
              readOnly
              style={{ width: "63px" }}
              size="small"
            />
          </div>
        </div>

        <div className={cs.main}>
          {holes.map((diameterGroup, index) => (
            <Diameter
              group={diameterGroup}
              index={index}
              readOnly={readOnly}
              onDiameterChange={handleDiameterChanged}
              onDiameterRemoved={handleDiameterRemoved}
              onDepthAdded={handleDepthAdded}
              onDepthChange={handleDepthChanged}
              onDepthRemoved={handleDepthRemoved}
            />
          ))}

          {!readOnly && (
            <AddNextBtn className="mt4" onClick={handleDiameterAdded} />
          )}
        </div>
      </div>
    </div>
  );

  function handleDepthAdded(diameterTempId) {
    const updatedHoles = produce(holes, (draft) => {
      const diameterGroup = draft.find(
        (group) => group.tempId === diameterTempId
      );

      diameterGroup.depths.push({
        count: null,
        thread_depth: null,
        hole_depth: null,
        through_hole: null,
      });
    });

    onChange("holes.threadHoles", updatedHoles);
  }

  function handleDiameterAdded() {
    onChange("holes.threadHoles", [
      ...holes,
      {
        description: null,
        standard: null,
        hole_diameter: null,
        outer_diameter: null,
        depths: [
          {
            count: null,
            thread_depth: null,
            hole_depth: null,
            through_hole: null,
          },
        ],
      },
    ]);
  }

  function handleDepthChanged(diameterTempId, depthTempId, property, value) {
    const updatedHoles = produce(holes, (draft) => {
      const diameterGroup = draft.find(
        ({ tempId }) => tempId === diameterTempId
      );
      const updatedDepth = diameterGroup.depths.find(
        (depth) => depth.tempId === depthTempId
      );
      updatedDepth[property] = value;
    });

    onChange("holes.threadHoles", updatedHoles);
  }

  function handleDiameterChanged(diameterTempId, property, value) {
    const updatedHoles = produce(holes, (draft) => {
      const diameterGroup = draft.find(
        ({ tempId }) => tempId === diameterTempId
      );

      diameterGroup[property] = value;

      if (property === "outer_diameter") {
        const thread = getThreadDetailsByOuterDiameter(
          diameterGroup.standard,
          value
        );

        diameterGroup.description = thread?.desc;
        diameterGroup.hole_diameter = thread?.hole_diameter;
      } else if (property === "description") {
        const thread = getThreadDetailsByDescription(
          diameterGroup.standard,
          value
        );

        diameterGroup.outer_diameter = thread?.outer_diameter;
        diameterGroup.hole_diameter = thread?.hole_diameter;
      } else if (property === "standard") {
        diameterGroup.description = null;
        diameterGroup.outer_diameter = null;
        diameterGroup.hole_diameter = null;
      }
    });

    onChange("holes.threadHoles", updatedHoles);
  }

  function handleDiameterRemoved(diameterTempId) {
    onChange(
      "holes.threadHoles",
      holes.filter((hole) => hole.tempId !== diameterTempId)
    );
  }

  function handleDepthRemoved(diameterTempId, depthTempId) {
    const updatedHoles = produce(holes, (draft) => {
      const diameterGroup = draft.find(
        ({ tempId }) => tempId === diameterTempId
      );
      diameterGroup.depths = diameterGroup.depths.filter(
        (depth) => depth.tempId !== depthTempId
      );
    });

    onChange("holes.threadHoles", updatedHoles);
  }
};

function addTempId(holes: any): any {
  return produce(holes, (draft) => {
    draft.forEach((diameterGroup) => {
      diameterGroup.tempId = genTempId();

      diameterGroup.depths.forEach((depth) => {
        depth.tempId = genTempId();
      });
    });
  });
}
