import { useState, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useImmer } from "use-immer";
import { Spin } from "antd";
import { useSearchOrders } from "../../services/order.hooks";
import { store } from "@store";
import { isSameOrBefore } from "@helpers/dateUtils";
import { Table } from "@components/Table";
import { ActivateLicenseWarning } from "../../components/ActivateLicenseWarning";
import { Filters } from "./Filters/Filters";
import { ListItem } from "./ListItem";
import cs from "./SearchOrdersPage.module.scss";

const Page = ({ children }: { children: ReactNode }) => (
  <main className={cs.SearchOrdersPage}>
    <header className="mb32">
      <h1 className="h3">Szukaj zleceń</h1>
    </header>

    {children}
  </main>
);

export const SearchOrdersPage = () => {
  const [sort, setSort] = useState("created_at");
  const [filters, setFilters] = useImmer({});

  const [orders, isLoading] = useSearchOrders();
  const user = store.hooks.useUser();

  let mainList = getFilteredResults(orders, filters, sort);
  let secondaryList;

  if (filters.compatible && user.subscription) {
    [mainList, secondaryList] = getResultDividedByCompatibility(mainList);
  }

  if (isLoading) {
    return (
      <Spin
        spinning
        size="large"
        tip="Ładujemy zlecenia. To może potrwać kilka sekund..."
        className={cs.loader}
      />
    );
  }

  return (
    <Page>
      <ActivateLicenseWarning className="mb30" />

      <Filters
        filters={filters}
        sort={sort}
        onFilterChange={handleFilterChanged}
        onSortChange={setSort}
      />

      <section>
        {mainList.length === 0 && (
          <Table
            data={[]}
            className={cs.noResults}
            empty={{
              title: "Lista jest pusta",
              sub: "Spróbuj użyć innych kryteriów wyszukiwania",
            }}
          />
        )}

        {mainList.map((order) => (
          <ListItem key={order._id} order={order} />
        ))}
      </section>

      {secondaryList?.length > 0 && (
        <section className="mt40">
          <div className="mb26">
            <div className="h5 mb4">
              Te zlecenia mogą nie pasować do Twojego Parku Maszyn (PM)
            </div>

            <div className="caption hide-mobile">
              <span>
                Jeżeli posiadasz maszynę, która będzie w stanie obsłużyć
                poniższe zlecenia,{" "}
              </span>
              <Link to="/account/machines" className={cs.link}>
                dodaj ją do parku maszyn
              </Link>
            </div>
          </div>
          {secondaryList.map((order) => (
            <ListItem key={order._id} order={order} />
          ))}
        </section>
      )}
    </Page>
  );

  function handleFilterChanged(field: string, value) {
    setFilters((prev) => {
      if (value === null || value === undefined) {
        delete prev[field];
      } else {
        prev[field] = value;
      }
    });
  }

  function getFilteredResults(orders: any[], filters, sort) {
    let filtered = orders;

    if (filters.services?.length > 0) {
      filtered = orders.filter((order) => {
        return order.elements.some((element) => {
          return element.services.some((service) => {
            return filters.services.includes(service._id);
          });
        });
      });
    }
    if (filters.bidDeadline) {
      filtered = filtered.filter((order) => {
        return isSameOrBefore(filters.bidDeadline, order.bid_deadline);
      });
    }
    if (filters.doneDeadline) {
      filtered = filtered.filter((order) => {
        return isSameOrBefore(filters.doneDeadline, order.done_deadline);
      });
    }
    if (filters.region) {
      filtered = filtered.filter((order) => {
        return (
          order.owner_company?.region === filters.region ||
          order.region === filters.region
        );
      });
    }

    if (sort) {
      filtered = getSorted(filtered, sort);
    }

    return filtered;
  }
};

function getResultDividedByCompatibility(list) {
  const compatible = list.filter(({ isCompatible }) => isCompatible === true);
  const incompatible = list.filter(({ isCompatible }) => isCompatible !== true);

  return [compatible, incompatible];
}

function getSorted(list, sort) {
  switch (sort) {
    case "created_at":
      list = list.sort((a, b) => {
        return (
          new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        );
      });
      break;
    case "total_amount":
      list = list.sort((a, b) => {
        return b.total_amount - a.total_amount;
      });
      break;
    case "-total_amount":
      list = list.sort((a, b) => {
        return a.total_amount - b.total_amount;
      });
      break;
    case "offers_count":
      list = list.sort((a, b) => {
        return b.offers_count - a.offers_count;
      });
      break;
    case "-offers_count":
      list = list.sort((a, b) => {
        return a.offers_count - b.offers_count;
      });
      break;
  }

  return list;
}
