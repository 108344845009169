import { useEffect, useState } from "react";
import classNames from "classnames";
import { InputNumber } from "@components/InputNumber";
import { AddNextBtn } from "../../AddNextBtn";
import { LineLength } from "./LineLength";
import deleteImg from "../../img/delete.svg";
import cs from "./Bending.module.scss";

export const Radius = ({ radiusGroup, readOnly, onChange, onRemove }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (radiusGroup.lineLengths.length === 0) {
      setTimeout(() => {
        handleLineLengthGroupAdded();
      });
    }
  }, [radiusGroup.lineLengths]);

  const rootCs = classNames(cs.Radius, {
    [cs.hovered]: isHovered,
  });

  return (
    <div className={rootCs}>
      <div className={cs.withBtn}>
        <div className={cs.input}>
          <span>Promień gięcia</span>
          <InputNumber
            size="small"
            value={radiusGroup.radius}
            min={0.01}
            readOnly={readOnly}
            required
            onChange={handleRadiusChanged}
          />
          <span>mm</span>
        </div>

        {!readOnly && (
          <img
            src={deleteImg}
            alt=""
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => onRemove(radiusGroup.tempId)}
          />
        )}
      </div>

      <div>
        {radiusGroup.lineLengths.map((lineLengthGroup) => (
          <LineLength
            lineLengthGroup={lineLengthGroup}
            readOnly={readOnly}
            onChange={handleLineLengthGroupChanged}
            onRemove={handleLineLengthGroupRemoved}
          />
        ))}

        {!readOnly && (
          <AddNextBtn className="mt4" onClick={handleLineLengthGroupAdded} />
        )}
      </div>
    </div>
  );

  function handleRadiusChanged(newValue) {
    onChange({ ...radiusGroup, radius: newValue });
  }

  function handleLineLengthGroupAdded() {
    onChange({
      ...radiusGroup,
      lineLengths: [
        ...radiusGroup.lineLengths,
        {
          length: null,
          angles: [
            {
              angle: null,
              count: null,
            },
          ],
        },
      ],
    });
  }

  function handleLineLengthGroupChanged(updatedLineLengthGroup) {
    onChange({
      ...radiusGroup,
      lineLengths: radiusGroup.lineLengths.map((lineLengthGroup) => {
        if (lineLengthGroup.tempId === updatedLineLengthGroup.tempId) {
          return updatedLineLengthGroup;
        }

        return lineLengthGroup;
      }),
    });
  }

  function handleLineLengthGroupRemoved(removedTempId) {
    onChange({
      ...radiusGroup,
      lineLengths: radiusGroup.lineLengths.filter(
        (lineLengthGroup) => lineLengthGroup.tempId !== removedTempId
      ),
    });
  }
};
