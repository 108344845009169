import axios from "axios";
import { groupByService } from "@services/pricing.helpers";

const apiUrl = "/api/pricing";

export async function getPriceListApi() {
  try {
    const response = await axios.get(apiUrl);

    return {
      isSuccess: response.status === 200,
      data: groupByService(response.data),
      rawData: response.data, // TODO: Probably not best approach
    };
  } catch (errorWrapper: any) {
    console.error(errorWrapper);

    return {
      isSuccess: false,
    };
  }
}

export async function savePricingApi(pricing) {
  try {
    const response = await axios.post(apiUrl, pricing);

    return {
      isSuccess: response.status === 201,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
    };
  }
}

export async function deletePricingApi(pricingIds: number | number[]) {
  pricingIds = Array.isArray(pricingIds) ? pricingIds : [pricingIds];

  try {
    const response = await axios.delete(apiUrl + `/${pricingIds}`);

    return {
      isSuccess: response.status === 204,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
    };
  }
}
