import { useEffect, useRef, useState } from "react";
import produce from "immer";
import { useImmer } from "use-immer";
import { store, useStore } from "@store";
import { saveOfferedServices } from "@services/account.api";
import {
  addMachineApi,
  createMachineApi,
  deleteMachineApi,
  updateMachineApi,
  updateMachinelessServicesApi,
} from "../../services/machine.api";
import { genTempId } from "@helpers/utils";
import { Confirmation } from "@components/Confirmation";
import { useSelectMachineModal } from "./SelectMachineModal";
import { MachineParkPure } from "./MachineParkPure";
import { getParamDetails } from "./temp";

export const MachinePark = () => {
  const [machines, setMachines] = useImmer<any[]>([]);
  const [machinelessServices, setMachinelessServices] = useImmer([]);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);

  const confirmMachineRemoval = useRef(null);
  const updateServicesTimer = useRef<any>(null);

  const machinesStore = useStore((state) => state.machinePark);
  const machinelessStore = useStore((state) => state.machinelessServices);
  const services = useStore((state) => state.services);

  const [SelectMachineModal, openSelectMachineModal] = useSelectMachineModal();

  useEffect(() => {
    setMachines(
      machinesStore.map((machine) => ({
        ...machine,
        parameters: getParamDetails(machine),
        technologies: machine.technology_ids,
      }))
    );
  }, []);

  useEffect(() => {
    setMachinelessServices(machinelessStore);
  }, []);

  useEffect(() => {
    setSelectedServices(services);
  }, []);

  return (
    <>
      <MachineParkPure
        selectedServices={selectedServices}
        machines={machines}
        machinelessServices={machinelessServices}
        onAddMachine={handleAddMachine}
        onRemoveMachine={handleDeleteClicked}
        onParameterChange={handleParameterChanged}
        onTechnologiesChange={handleTechnologiesChanged}
        onMachinelessSave={handleMachinelessSaved}
        onServicesChange={handleServicesChange}
      />

      {SelectMachineModal}

      <Confirmation
        ref={confirmMachineRemoval}
        title="Potwierdź"
        text="Czy na pewno chcesz usunąć tę maszynę?"
        cancelText="Anuluj"
        okText="Potwierdź i usuń"
      />
    </>
  );

  async function handleAddMachine() {
    let newMachine = await openSelectMachineModal();

    if (newMachine) {
      newMachine = await produce(newMachine, async (newMachine: any) => {
        // NOTE: Custom machine
        if (!newMachine.model._id) {
          const newMachineId = await createNewMachineInDb(newMachine);

          newMachine.model._id = newMachineId;
        }

        newMachine.model_name = newMachine.model.name;
        newMachine.brand_name = newMachine.brand.name;
        newMachine.type_name = newMachine.type.name;
        newMachine.parameters = assignParametersBasedOnTechnologies(newMachine);
      });

      const tempId = genTempId();

      addMachineToMachineParkInDb(newMachine).then((newMachineId) => {
        setMachines((prev) => {
          const machine = prev.find(({ _id }) => _id === tempId);

          machine._id = newMachineId;
        });
      });

      setMachines((prev) => {
        prev.unshift({ ...newMachine, _id: tempId });
      });
    }
  }

  function handleParameterChanged(machineId, updatedParameters) {
    setMachines((prev) => {
      const machine = prev.find(({ _id }) => _id === machineId);
      machine.parameters = updatedParameters;

      saveMachineChangesToDb(machine);
    });
  }

  function handleMachinelessSaved(updatedMachinelessServices) {
    setMachinelessServices(updatedMachinelessServices);

    saveMachinelessChangesToDb(updatedMachinelessServices);
  }

  async function handleDeleteClicked(deletedMachineId) {
    const isConfirmed = await confirmMachineRemoval.current.open();

    if (!isConfirmed) return;

    const { isSuccess } = await deleteMachineApi(deletedMachineId);

    if (isSuccess) {
      setMachines((prev) => {
        return prev.filter(({ _id }) => _id !== deletedMachineId);
      });

      // NOTE: Refresh store
      await useStore.getState().fetchMachinePark();
      await useStore.getState().fetchPriceLists();
    }
  }

  function handleTechnologiesChanged(machineId, updatedTechnologyIds) {
    const requiredParams = calculateRequiredParameters(updatedTechnologyIds);

    setMachines((prev) => {
      const machine = prev.find(({ _id }) => _id === machineId);

      machine.parameters = requiredParams.map((requiredParam) => {
        const existingParam = machine.parameters.find(
          ({ _id }) => _id === requiredParam._id
        );

        if (existingParam) {
          return existingParam;
        }

        return requiredParam;
      });

      machine.technologies = updatedTechnologyIds;

      saveMachineChangesToDb(machine);
    });
  }

  async function handleServicesChange(serviceIds: number[]) {
    setSelectedServices(serviceIds);

    if (updateServicesTimer.current) {
      clearTimeout(updateServicesTimer.current);
    }

    updateServicesTimer.current = setTimeout(async () => {
      const { isSuccess } = await saveOfferedServices(serviceIds);

      if (isSuccess) {
        await useStore.getState().fetchOfferedServices();
      }
    }, 1000);
  }
};

async function createNewMachineInDb(machine: any) {
  const { isSuccess, newMachineId } = await createMachineApi({
    modelName: machine.model.name,
    brandName: machine.brand.name,
    typeId: machine.type._id,
  });

  if (isSuccess) {
    return newMachineId;
  }
}

async function addMachineToMachineParkInDb(machine: any) {
  const { isSuccess, newMachineId } = await addMachineApi(
    machine.model._id,
    machine.technologies,
    machine.parameters
  );

  if (isSuccess) {
    // NOTE: Refresh store
    await useStore.getState().fetchMachinePark();

    return newMachineId;
  }
}

async function saveMachineChangesToDb(machine: any) {
  const { isSuccess } = await updateMachineApi(
    machine._id,
    machine.technologies,
    machine.parameters
  );

  if (isSuccess) {
    // NOTE: Refresh store
    await useStore.getState().fetchMachinePark();
  }
}

async function saveMachinelessChangesToDb(machinelessServices: any) {
  const { isSuccess } = await updateMachinelessServicesApi(machinelessServices);

  if (isSuccess) {
    // NOTE: Refresh store
    await useStore.getState().fetchMachinelessServices();
  }
}

function assignParametersBasedOnTechnologies(machine: any) {
  const requiredParameters = calculateRequiredParameters(machine.technologies);

  return requiredParameters.map((requiredParameter) => {
    const defaultModelValue = machine.model.default_parameter_values?.find(
      ({ parameter_id }) => parameter_id === requiredParameter._id
    );

    if (defaultModelValue) {
      return {
        ...requiredParameter,
        value: defaultModelValue.value,
      };
    }

    return requiredParameter;
  });
}

function calculateRequiredParameters(technologyIds: number[]) {
  if (!technologyIds) return [];

  const parameters = store.selectors.getParameters(useStore.getState());

  return parameters.filter(({ technology_ids }) =>
    technology_ids.some((techId) => technologyIds.includes(techId))
  );
}
