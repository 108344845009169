import * as Sentry from "@sentry/react";
import { getMaterialsApi } from "@services/materials.api";
import { StoreState, useStore } from "@store";

export type MaterialsSlice = {
  materials: any[];
  types: any[];
  fetchMaterials: () => Promise<void>;
};

export const materialsSlice = (set: any): MaterialsSlice => ({
  materials: [],
  types: [],
  fetchMaterials: async () => {
    const { data, isSuccess } = await getMaterialsApi();

    if (isSuccess) {
      const types = attachMaterialCategory(null, data.types);
      const materials = attachMaterialCategory(data.materials, data.types);

      set({ materials, types }, false, "technology/fetchMaterials");
    }
  },
});

export const selectors = {
  getMaterials: (state: StoreState) => state.materials,
  getTypes: (state: StoreState) => state.types,
};

export const hooks = {
  useMaterials() {
    const materials = useStore(selectors.getMaterials);

    return materials;
  },
  useMaterial(materialId: number) {
    const materials = useStore(selectors.getMaterials);

    return materials.find(({ _id }) => _id === materialId);
  },
  useMaterialTypes() {
    const types = useStore(selectors.getTypes);

    return types;
  },
  useMaterialTypesByService(serviceId: number) {
    const types = useStore(selectors.getTypes);

    return getMaterialTypesByServiceId(types, serviceId);
  },
  useMaterialType(typeId) {
    const types = useStore(selectors.getTypes);

    return types.find(({ _id }) => _id === typeId);
  },
};

function attachMaterialCategory(materials, types) {
  if (!materials) {
    return types.map((type) => {
      let categoryName = "";

      if (isMetal(type.category)) {
        categoryName = "metal";
      } else if (isPlastic(type.category)) {
        categoryName = "plastic";
      } else if (isWood(type.category)) {
        categoryName = "wood";
      }

      return { ...type, categoryName };
    });
  }

  return materials.map((material) => {
    const type = types.find((type) => {
      return type._id === material.type;
    });

    let categoryName = "";

    if (isMetal(type.category)) {
      categoryName = "metal";
    } else if (isPlastic(type.category)) {
      categoryName = "plastic";
    } else if (isWood(type.category)) {
      categoryName = "wood";
    }

    return { ...material, categoryName };
  });
}

function isMetal(category: number) {
  return [1, 2, 3, 4].includes(category);
}

function isPlastic(category: number) {
  return [5, 6, 7, 8].includes(category);
}

function isWood(category: number) {
  return [9, 10, 11, 12, 13, 14, 15, 18].includes(category);
}

// TODO: Doesn't seem like the best solution
function getMaterialTypesByServiceId(materialTypes, serviceId) {
  switch (serviceId) {
    case 1:
    case 2:
    case 19:
      return materialTypes.filter((type) => type.categoryName === "metal");
    case 3:
      return materialTypes.filter((type) => type.categoryName === "wood");
    case 4:
      return materialTypes.filter((type) => type.categoryName === "plastic");
    default:
      const error = `Service with id ${serviceId} unrecognized`;

      console.error(error);
      Sentry.captureException(error);

      return materialTypes;
  }
}
