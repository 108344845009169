import axios from "axios";
import { prepareOrder } from "./order.helpers";

const apiUrl = "";

export async function getOrderApi(orderId: string) {
  const response = await axios.get(apiUrl + "/api/offers/order/" + orderId);

  return prepareOrder(response.data);
}

export async function searchOrdersApi() {
  const response = await axios.get(apiUrl + "/api/orders/search");

  return response.data;
}
