const TEMP_MATERIAL_DENSITY = 0.00786; // TODO: Density should come from material

export function calculateMassForMaterial(
  { sheet_metal_length, sheet_metal_width, thickness },
  material
) {
  console.groupCollapsed(`Obliczanie masy`);

  console.log("Długość materiału: %s mm", sheet_metal_length);
  console.log("Szerokość materiału: %s mm", sheet_metal_width);
  console.log("Grubość materiału: %s mm", thickness);
  console.log("Gęstość materiału: %s g/mm3", TEMP_MATERIAL_DENSITY);

  const mass =
    (sheet_metal_length *
      sheet_metal_width *
      thickness *
      TEMP_MATERIAL_DENSITY) /
    1000;

  console.log("Masa: %s kg", mass);

  console.groupEnd();

  return mass;
}
