import { toLocaleDate } from "@helpers/dateUtils";
import { Chip } from "@components/Chip";

export const columns = [
  {
    title: "Numer",
    field: "invoice_number",
  },
  {
    title: "Okres",
    field: "period",
    render(value, cell, row) {
      return (
        <span>
          <span>{toLocaleDate(row.period_start)}</span>
          <span> - </span>
          <span>{toLocaleDate(row.period_end)}</span>
        </span>
      );
    },
  },
  {
    title: "Data płatności",
    field: "created_at",
    render(value) {
      return <span>{toLocaleDate(value)}</span>;
    },
  },
  {
    title: "Status",
    render() {
      return (
        <Chip size="small" color="green">
          Opłacona
        </Chip>
      );
    },
  },
  {
    title: "Faktura",
    field: "pdf_link",
    width: 150,
    render(value) {
      return (
        <a
          href={value}
          className="Button_small Button_secondary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pobierz
        </a>
      );
    },
  },
];
