import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { store } from "@store";
import { getDaysFromToday, toLocaleDate } from "@helpers/dateUtils";
import { getShortUsername } from "@helpers/utils";
import { Chip } from "@components/Chip";
import { OrderStatus } from "@components/OrderStatus";
import { Card } from "@components/Card";
import alarmImg from "./alarm.svg";
import cs from "./OrderCard.module.scss";

export const OrderCard = ({ order }) => {
  const navigate = useNavigate();
  const rootCs = classNames("OrderCard", cs.OrderCard);

  const user = store.hooks.useUser();

  const elements = order.elements.filter((el) => el.thumbnail);

  const elementsCountAfterFour = elements.length - 4;
  const firstFourElements: any[] = elements.slice(0, 4);

  return (
    <Card className={rootCs} onClick={() => navigate(`/order/${order._id}`)}>
      <Card.Header className={cs.header}>
        <div className="h5">Zapytanie ofertowe {order.seq_no}</div>
        <div className={cs.desc}>{order.desc}</div>
      </Card.Header>

      <Card.Body className={cs.main}>
        <div className={cs.tags}>
          <OrderStatus status={1} size="caption" />

          <Chip color="gray" size="caption">
            <img src={alarmImg} />
            <span>Do końca: </span>&nbsp;
            <strong> {getDaysFromToday(order.done_deadline)}dni</strong>
          </Chip>

          {/*<Chip color="blue" size="caption">*/}
          {/*  <img src={bellImg} />*/}
          {/*  <span>2 nowe oferty</span>*/}
          {/*</Chip>*/}
        </div>

        <div className={cs.info}>
          <div className={cs.lowestOffer}>
            <div>Najniższa oferta:</div>
            {order.lowest_offer ? (
              <div className="h5">{order.lowest_offer} PLN</div>
            ) : (
              <div className="h5">brak</div>
            )}
          </div>

          <div className={cs.details}>
            <div>
              <span>Termin realizacji: </span>
              <strong>{toLocaleDate(order.done_deadline)}</strong>
            </div>

            <div>
              <span>Termin składania ofert: </span>
              <strong>{toLocaleDate(order.bid_deadline)}</strong>
            </div>

            {user.is_admin && (
              <div>
                <span>Zlecający: </span>
                <strong>{getShortUsername(order.owner_name)}</strong>
              </div>
            )}

            <div>
              <span>Ilość ofert: </span>
              <strong>{order.offers.length}</strong>
            </div>
          </div>
        </div>

        <div className={cs.elements}>
          <div>Ilość kompletów: {order.total_amount}</div>
          <div className={cs.thumbnails}>
            {firstFourElements.map((element) => (
              <img key={element._id} src={element.thumbnail} />
            ))}

            {elementsCountAfterFour > 0 && (
              <span className={cs.moreElements}>
                + {elementsCountAfterFour} elem.
              </span>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
