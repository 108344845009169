import { OrderLink } from "@components/DownloadOrderDocs";

type Props = {
  order: any;
  files: any[]; // TODO:
};

export const OrderElementFiles = ({ order, files }: Props) => (
  <>
    {files.map((file, idx) => {
      const isLast = idx === files.length - 1;
      const comma = isLast ? " " : ", ";

      if (file.aws_key) {
        return (
          <div key={file.aws_key}>
            <OrderLink order={order} file={file} />
            {comma}
          </div>
        );
      }

      return (
        <div key={file.name}>
          {file.name}
          {comma}
        </div>
      );
    })}
  </>
);
