import produce from "immer";
import axios from "axios";
import { CadAnalyser } from "./CadAnalyser";

/**
 * @public
 */
export async function analyzeCADFileApi(cadFile: File) {
  const data = new FormData();
  data.append("cadFile", cadFile);

  try {
    const response = await axios.post("/api/cad", data);

    return {
      isSuccess: true,
      data: _enrichElementInformation(response.data),
    };
  } catch {
    return {
      isSuccess: false,
    };
  }
}

///

/**
 * @public
 */
export function _enrichElementInformation(element: any) {
  return produce(element, (elementDraft: any) => {
    new CadAnalyser(elementDraft).analyse();
  });
}
