import classNames from "classnames";
import { Counter } from "@components/Counter";
import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { Btn } from "./Element";
import { MaterialName } from "./MaterialName";
import cs from "./Element.module.scss";

export const ElementMin = ({
  element,
  noOfSets,
  isSelected,
  onShowDetails,
  onShowNoOfElementCopies,
  onChange,
  onSelectionChange,
  onShowMaterial,
}) => {
  const { amount, total_amount } = element;

  const rootCs = classNames("Element__min", cs.Element__min);

  return (
    <div className={rootCs}>
      <div className="mr12">
        <Checkbox
          checked={isSelected}
          onChange={(e) =>
            onSelectionChange({
              selected: e.target.checked,
              _id: element._id,
            })
          }
        />
      </div>

      <div className="pr8">
        <div className="body bold mb4">{element.name}</div>
        <div className="caption" style={{ color: "#2D3436" }}>
          {element.desc}
        </div>
      </div>

      <div>
        <div className="caption mb4">Materiał:</div>
        <div className="caption bold color-black">
          {element.material_id || element.material_type_id ? (
            <MaterialName
              materialId={element.material_id}
              typeId={element.material_type_id}
            />
          ) : (
            <>
              <div className={`${cs.error} Element_error`}>Brak materiału</div>
              <Btn onClick={onShowMaterial}>Dodaj</Btn>
            </>
          )}
        </div>
      </div>

      <div>
        <div className="caption mb4">Ilość szt. w komplecie:</div>
        <Counter
          value={amount}
          onChange={(newValue: number) => {
            onChange("amount", newValue);
          }}
        />
      </div>

      <div>
        <div className="caption mb4">Łączna ilość sztuk:</div>
        <div className="caption">
          <span>
            {noOfSets} x {amount} ={" "}
          </span>
          <strong>{total_amount} szt. </strong>
          <span className="color-primary">
            <Btn onClick={onShowNoOfElementCopies}>Zmień</Btn>
          </span>
        </div>
      </div>

      <div>
        <Button type="link" size="medium" onClick={onShowDetails}>
          Szczegóły elementu
        </Button>
      </div>
    </div>
  );
};
