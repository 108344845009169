import { Navigate } from "react-router-dom";
import { Card } from "@components/Card";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Baner } from "../../components/Baner";
import { Button } from "@components/Button";
import { isAuthenticated, logOutApi } from "@services/auth.api";
import cs from "./PendingVerificationPage.module.scss";

export const PendingVerificationPage = () => {
  const isUserAuthenticated = isAuthenticated();

  if (!isUserAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <main className={`PendingVerificationPage ${cs.PendingVerificationPage}`}>
      <Baner type="invited" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <Card>
          <Card.Header className="h5">
            Twoje konto wymaga dodatkowej weryfikacji
          </Card.Header>

          <Card.Body>
            <div className="body">
              Prawdopodobnie ktoś z naszego działu obsługi będzie próbował się z
              Tobą skontaktować.
            </div>
          </Card.Body>
          <Card.Footer className="text-center">
            <Button
              type="primary"
              size="medium"
              className={cs.logOutBtn}
              onClick={logOutApi}
            >
              <span>Wyloguj się</span>
            </Button>
          </Card.Footer>
        </Card>

        <PrivacyPolicy />
      </section>
    </main>
  );
};
