// @ts-nocheck

import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import { useOrder } from "../../services/order.hooks";
import { OfferingAnalyser } from "../../services/OfferingAnalyser";
import { store } from "@store";
import { toLocaleDate } from "@helpers/dateUtils";
import { ORDER_STATUSES } from "../../../constants/ORDER_STATUSES";
import { Button } from "@components/Button";
import { DownloadOrderDocsBtn } from "@components/DownloadOrderDocs";
import { CustomerDetails } from "../../components/CustomerDetails";
import { OrderDescription } from "../../components/OrderDescription";
import { ActivateLicenseWarning } from "../../components/ActivateLicenseWarning";
import { Chat } from "../../components/Chat/Chat";
import { StatusInfoPanel } from "./StatusInfoPanel";
import { AdditionalFiles } from "./AdditionalFiles";
import { Elements } from "./Elements";
import cs from "./OrderPage.module.scss";

export const OrderPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [order] = useOrder(id);

  const user = store.hooks.useUser();

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <main className={cs.OrderPage}>
      <header className={cs.header}>
        <div>
          <h1 className="h3 mb8">Zapytanie ofertowe {order.seq_no}</h1>
          <div>Opublikowano: {toLocaleDate(order.created_at)}</div>
        </div>

        <div className={cs.headerActions}>
          <DownloadOrderDocsBtn
            order={order}
            size="medium"
            disabled={!user.subscription}
            title={!user.subscription && "Aktywuj subskrypcję"}
          />

          {order.status === ORDER_STATUSES.ONGOING && (
            <Button
              type="primary"
              size="medium"
              title={!user.subscription && "Aktywuj subskrypcję"}
              onClick={makeAnOffer}
            >
              Utwórz ofertę do tego zapytania
            </Button>
          )}
        </div>
      </header>

      <ActivateLicenseWarning className="mt15" />

      <StatusInfoPanel order={order} />

      <section className={cs.descCustomerSection}>
        <OrderDescription desc={order.desc} />

        {order.owner && (
          <CustomerDetails
            ownerCompany={order.owner_company}
            owner={order.owner}
          />
        )}
      </section>

      <Elements
        order={order}
        elements={order.elements}
        noOfSets={order.total_amount}
        isCompatible={order.isCompatible}
      />

      {order.order_files.length > 0 && (
        <AdditionalFiles order={order} files={order.order_files} />
      )}

      <Chat orderId={order._id} />

      <ActivateLicenseWarning className="mt20" />

      <section className={cs.actions}>
        <DownloadOrderDocsBtn
          order={order}
          size="large"
          disabled={!user.subscription}
          title={!user.subscription && "Aktywuj subskrypcję"}
        />

        {order.status === ORDER_STATUSES.ONGOING && (
          <Button
            type="primary"
            size="large"
            className="ml16"
            title={!user.subscription && "Aktywuj subskrypcję"}
            onClick={makeAnOffer}
          >
            Utwórz ofertę do tego zapytania
          </Button>
        )}
      </section>
    </main>
  );

  function makeAnOffer() {
    OfferingAnalyser.createOfferClicked();

    if (user.subscription) {
      navigate(`/offer/order/${id}/new`);
    } else {
      notification.warning({
        message: "Aktywuj subskrypcję",
        description: (
          <span>
            Aby zobaczyć dane kontaktowe zleceniodawcy oraz uzyskać możliwość
            pobrania dokumentacji{" "}
            <a href="/account/users" className="color-primary semi-bold">
              kliknij tutaj!
            </a>
          </span>
        ),
        placement: "topRight",
        duration: 10,
      });
    }
  }
};
