import axios from "axios";
import {
  groupTechnologies,
  sortTechnologiesByName,
} from "./technology.helpers";

const apiUrl = "";

export async function getTechnologies() {
  try {
    const response = await axios.get(apiUrl + "/api/technology");

    const { technologies, groups } = response.data;

    const groupedTechnologies = groupTechnologies(groups, technologies);

    return {
      isSuccess: response.status === 200,
      technologies: technologies,
      groupedTechnologies: groupedTechnologies,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
    };
  }
}

export async function getTechnologies2() {
  try {
    const response = await axios.get(apiUrl + "/api/technology/2");

    const technologies = sortTechnologiesByName(response.data);

    return {
      isSuccess: response.status === 200,
      technologies: technologies,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
    };
  }
}

export async function getParameters() {
  try {
    const response = await axios.get(apiUrl + "/api/technology/parameters");

    return {
      isSuccess: response.status === 200,
      parameters: response.data,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
    };
  }
}
