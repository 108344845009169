import { GTMAnalyser, SmartlookAnalyser } from "@services/analytics.helpers";

export class OfferingAnalyser {
  public static createOfferClicked() {
    SmartlookAnalyser.trigger("offering_start");
  }

  public static generatePrices() {
    GTMAnalyser.trigger("offer_generate_prices");
  }

  public static offerCreated() {
    GTMAnalyser.trigger("offer_created");
  }
}
