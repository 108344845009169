import create from "zustand";
import { devtools } from "zustand/middleware";
import {
  hooks as userHooks,
  selectors as userSelectors,
  UserSlice,
  userSlice,
} from "./user.slice";
import {
  hooks as technologyHooks,
  selectors as technologySelectors,
  TechnologiesSlice,
  technologiesSlice,
} from "./technologies.slice";
import {
  hooks as materialHooks,
  MaterialsSlice,
  materialsSlice,
} from "./materials.slice";
import {
  MachineParkSlice,
  machineParkSlice,
  selectors as machineParkSelectors,
} from "./machine_park.slice";
import {
  hooks as priceListHooks,
  priceListSlice,
  PriceListSlice,
} from "./price_list.slice";
import {
  hooks as companyHooks,
  CompanySlice,
  companySlice,
} from "./company.slice";

export type StoreState = TechnologiesSlice &
  UserSlice &
  MaterialsSlice &
  MachineParkSlice &
  CompanySlice &
  PriceListSlice;

export const useStore = create<StoreState>(
  devtools(
    (set) => ({
      ...technologiesSlice(set),
      ...userSlice(set),
      ...materialsSlice(set),
      ...machineParkSlice(set),
      ...priceListSlice(set),
      ...companySlice(set),
    }),
    { name: "4dustry" }
  )
);

export const selectors = {
  ...userSelectors,
  ...technologySelectors,
};

export const store = {
  hooks: {
    ...technologyHooks,
    ...materialHooks,
    ...priceListHooks,
    ...userHooks,
    ...companyHooks,
  },
  selectors: {
    ...userSelectors,
    ...technologySelectors,
    ...machineParkSelectors,
  },
};
