import { Button } from "@components/Button";
import { Select } from "@components/Select";
import chevronRightImg from "./img/chevron_right.svg";
import cs from "./Pagination.module.scss";

type Props = {
  current: number;
  perPage: number;
  total: number;
  onPageChange: (newPage: number) => void;
  onPerPageChange: (newPerPage: number) => void;
};

const perPages = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

export const Pagination = ({
  current = 1,
  perPage = 5,
  total,
  onPageChange,
  onPerPageChange,
}: Props) => {
  const noOfPages = Math.ceil(total / perPage);

  const pages = Array(noOfPages)
    .fill(1)
    .map((_, index) => index + 1);

  const lastPage = pages[pages.length - 1];

  return (
    <div className={cs.Pagination}>
      <div className={cs.perPage}>
        <span className="mr8">Ilość wierszy:</span>

        <Select
          value={perPage}
          options={perPages}
          onChange={(newPerPage) => {
            onPerPageChange(+newPerPage);
          }}
        />
      </div>

      <Pages pages={pages} current={current} onPageChange={onPageChange} />

      {current !== lastPage && (
        <Button type="outline" size="medium" className="ml8" onClick={nextPage}>
          <img src={chevronRightImg} alt="" />
        </Button>
      )}
    </div>
  );

  function nextPage() {
    onPageChange(current + 1);
  }
};

const Pages = ({ current, pages, onPageChange }) => {
  const lastPage = pages[pages.length - 1];

  return (
    <div className="d-flex">
      {current !== 1 && (
        <div className={cs.cell} onClick={() => onPageChange(1)}>
          {pages[0]}
        </div>
      )}

      <CellsBeforeCurrent
        pages={pages}
        current={current}
        onChange={onPageChange}
      />

      <div className={`${cs.cell} bold`}>{current}</div>

      <CellsAfterCurrent
        pages={pages}
        current={current}
        onChange={onPageChange}
      />

      {current !== lastPage && (
        <div className={cs.cell} onClick={() => onPageChange(lastPage)}>
          {lastPage}
        </div>
      )}
    </div>
  );
};

const CellsBeforeCurrent = ({ pages, current, onChange }) => {
  const cellsBeforeCurrent = pages
    .slice(current - 3, current - 1)
    .filter((page) => page !== 1);

  return (
    <>
      {cellsBeforeCurrent.length > 0 && !cellsBeforeCurrent.includes(2) && (
        <div className={cs.cell}>...</div>
      )}

      {cellsBeforeCurrent.map((page) => (
        <div key={page} className={cs.cell} onClick={() => onChange(page)}>
          {page}
        </div>
      ))}
    </>
  );
};

const CellsAfterCurrent = ({ pages, current, onChange }) => {
  const lastPage = pages[pages.length - 1];

  const cellsAfterCurrent = pages
    .slice(current, current + 2)
    .filter((page) => page !== lastPage);

  return (
    <>
      {cellsAfterCurrent.map((page) => (
        <div key={page} className={cs.cell} onClick={() => onChange(page)}>
          {page}
        </div>
      ))}

      {cellsAfterCurrent.length > 0 &&
        !cellsAfterCurrent.includes(pages[pages.length - 2]) && (
          <div className={cs.cell}>...</div>
        )}
    </>
  );
};
