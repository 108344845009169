import { Popover } from "antd";
import {
  markNotificationsAsSeenApi,
  useNotifications,
} from "@services/notifications";
import { Notification } from "./Notification";
import bellImg from "./img/bell.svg";
import cs from "./Notifications.module.scss";

export const Notifications = () => {
  const [notifications, setNotifications] = useNotifications();

  const notSeenCount = notifications.reduce((sum, curr) => {
    return curr.seen ? sum : sum + 1;
  }, 0);

  const popoverContent = (
    <div onClick={simulateOutsideClick}>
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <Notification notification={notification} key={notification._id} />
        ))
      ) : (
        <div className="body pl24">Brak powiadomień.</div>
      )}
    </div>
  );

  const popoverTrigger = (
    <span>
      <img src={bellImg} alt="" />

      {notSeenCount > 0 && (
        <span className={cs.countBadge}>{notSeenCount}</span>
      )}
    </span>
  );

  return (
    <div className={cs.Notifications}>
      <Popover
        placement="bottomLeft"
        content={popoverContent}
        trigger="click"
        overlayClassName={cs.popover}
        arrowContent={null}
        onVisibleChange={async (opened) => {
          if (!opened) {
            await markNotificationsAsSeen();
          }
        }}
      >
        {popoverTrigger}
      </Popover>
    </div>
  );

  async function markNotificationsAsSeen() {
    const { isSuccess } = await markNotificationsAsSeenApi();

    if (isSuccess) {
      setNotifications((prev) =>
        prev.map((notification) => ({ ...notification, seen: true }))
      );
    }
  }
};

// NOTE: Close popover when notification is clicked
function simulateOutsideClick() {
  const clickEvent = new MouseEvent("mousedown", { bubbles: true });

  document.querySelector(".AppBar")?.dispatchEvent(clickEvent);
}
