import { Rate } from "antd";
import { useAccount } from "@services/account.hooks";
import { calcAvgRating } from "@services/review.helpers";
import { store } from "@store";
import { getAddress } from "@helpers/getAddress";
import { getNoOfVotesText } from "@helpers/getNoOfVotesText";
import { Modal } from "@components/Modal";
import { Review } from "./Review";
import cs from "./CompanyModal.module.scss";

export const CompanyModal = ({ isOpen, companyId, onClose }) => {
  const company = useAccount(companyId);
  const user = store.hooks.useUser();

  if (!company) {
    return null;
  }

  const avgRating = calcAvgRating(company.reviews);

  const currUserReview = company.reviews.find(
    (review) => review.reviewer_id === user._id
  );
  const otherUsersReviews = company.reviews
    .filter((review) => review !== currUserReview)
    .sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

  return (
    <Modal
      title="Szczegóły firmy"
      width={560}
      cancelText="Zamknij"
      cancelButtonProps={{ className: "Button_secondary Button_medium" }}
      visible={isOpen}
      onClose={onClose}
    >
      <div className="mb20">
        <div className="body">
          <div className={cs.name}>{company.name}</div>

          <div className="mb2">
            <span className="color-dark-gray semi-bold">Adres: </span>
            <span>{getAddress(company)}</span>
          </div>

          <div className="mb2">
            <span className="color-dark-gray semi-bold">NIP: </span>
            <span>{company.tax_number || "-"}</span>
          </div>

          <div className="mb2">
            <span className="color-dark-gray semi-bold">
              Strona internetowa:{" "}
            </span>
            {company.website ? (
              <a href={company.website} className="link">
                {company.website}
              </a>
            ) : (
              "-"
            )}
          </div>

          <div className="mb2">
            <span className="color-dark-gray semi-bold">REGON: </span>
            <span>{company.regon_number || "-"}</span>
          </div>

          <div>
            <span className="color-dark-gray semi-bold">Kontakt: </span>
            <a href={`tel:${company.phone}`} className="link">
              {company.phone}
            </a>
            <span> | </span>
            <a href={`mailto:${company.email}`} className="link">
              {company.email}
            </a>
          </div>

          <div>
            <span className="color-dark-gray semi-bold">
              Pozostałe informacje:{" "}
            </span>
            <span>{company.additional_info || "-"}</span>
          </div>
        </div>
      </div>

      <div>
        <div className={cs.sectionTitle}>Opinie ({company.reviews.length})</div>
        <div className={cs.rate}>
          <Rate
            disabled
            allowHalf
            defaultValue={avgRating}
            style={{ fontSize: "24px" }}
          />

          <span className="ml8">{getNoOfVotesText(company.rate)}</span>
        </div>

        <div
          style={{
            borderTop: "1px solid #dfe6e9",
            marginTop: 16,
            marginBottom: "16px",
          }}
        />

        <div>
          {company.reviews.length === 0 && (
            <div>Żadna opinia nie została jeszcze dodana dla tej firmy.</div>
          )}

          {currUserReview && <Review review={currUserReview} />}

          {otherUsersReviews.map((review) => (
            <Review review={review} />
          ))}
        </div>
      </div>
    </Modal>
  );
};
