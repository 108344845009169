import { Table } from "@components/Table";
import { columns } from "./tableColumns";

export const DownloadRequests = ({ downloadRequests, onPermissionChoice }) => (
  <section className="mt24">
    <Table
      data={downloadRequests}
      columns={columns({ onPermissionChoice })}
      title="Prośby o dostęp do dokumentacji"
    />
  </section>
);
