import { useEffect, useState } from "react";
import { Textarea } from "@components/Textarea";
import { Modal } from "@components/Modal";

export const RemarksModal = ({ isOpen, remarks, onClose, onConfirm }) => {
  const [remarksCopy, setRemarksCopy] = useState();

  useEffect(() => {
    if (isOpen) {
      setRemarksCopy(remarks);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Uwagi do elementu"
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okText="Zapisz zmiany"
      width={480}
      onOk={() => {
        onConfirm("remarks", remarksCopy);
        onClose();
      }}
      onClose={onClose}
    >
      <Textarea
        value={remarksCopy}
        style={{ width: "100%" }}
        rows={4}
        onChange={setRemarksCopy}
      />
    </Modal>
  );
};
