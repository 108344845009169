import { useState } from "react";
import { trim } from "@helpers/utils";
import { Btn } from "../../Customer/views/NewOrderPage/Elements/Element/Element";
import { RemarksModal } from "../../Customer/views/NewOrderPage/Elements/Element/RemarksModal";

export const Remarks = ({ remarks, isEditable, onChange }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const remarksTrimmed = trim(remarks, 220);

  if (!isEditable) {
    return <p className="caption color-black">{remarksTrimmed || "-"}</p>;
  }

  return (
    <>
      {remarks ? (
        <>
          <p className="caption color-black mb2">{remarksTrimmed}</p>
          <Btn onClick={openModal}>Edytuj</Btn>
        </>
      ) : (
        <Btn onClick={openModal}>Dodaj uwagi</Btn>
      )}

      <RemarksModal
        isOpen={isModalOpened}
        remarks={remarks}
        onConfirm={onChange}
        onClose={() => setIsModalOpened(false)}
      />
    </>
  );

  function openModal() {
    setIsModalOpened(true);
  }
};
