import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { notification } from "antd";
import { getTechnologyIdsByService } from "@services/technology.helpers";
import { analyzeCADFileApi } from "../../../services/cad/cad.api";
import { handleNewMaterialSet } from "../../../services/element.helpers";
import { useFirstFileUploaded } from "../../../services/OrderingAnalyser";
import { analyzeCADFilePublicApi } from "../../../../public/services/public_order.api";
import { getNoOfSetsText } from "@helpers/getNoOfSetsText";
import { dataUrlToFile, renameFile } from "@helpers/utils";
import { CAD_FILE_EXTENSIONS } from "../../../constants/CAD_FILE_EXTENSIONS";
import { ACCEPTED_ELEMENT_EXTENSIONS } from "../../../constants/ACCEPTED_ELEMENT_EXTENSIONS";
import { Button } from "@components/Button";
import { Chip } from "@components/Chip";
import { InfoModal } from "@components/InfoModal";
import { Checkbox } from "@components/Checkbox";
import { ElementDetailsModal } from "@components/ElementDetailsModal";
import { Card } from "@components/Card";
import { useListTypeCmp } from "../../../components/ListType";
import { Element } from "./Element";
import { LoadingElement } from "./LoadingElement";
import { EditMultipleModal } from "./EditMultipleModal";
import { NoOfSetsModal } from "./NoOfSetsModal";
import { Uploader } from "./Uploader";
import chevronDownImg from "./img/chevron_down.svg";
import questionImg from "./img/question.svg";
import closeImg from "./img/close.svg";
import trashImg from "./img/trash.svg";
import pencilImg from "./img/pencil.svg";
import arrowDownImg from "./img/arrow_90_down.svg";
import exclamationImg from "./img/exclamation.svg";
import cs from "./Elements.module.scss";

export const Elements = ({
  order,
  elements,
  selectedElements,
  noOfSets,
  isHowToClosed,
  mode,
  onNoOfSetsChange,
  onElementsChange,
  onSelectionChange,
  onCloseHowToCard,
}) => {
  const [isSetNoModalOpened, setIsSetNoModalOpened] = useState(false);
  const [isEditMultiOpened, setIsEditMultiOpened] = useState(false);
  const [loadingElements, setLoadingElements] = useImmer<Set<number>>(
    new Set()
  );
  const [isHowToAddFilesModalVisible, setIsHowToAddFilesModalVisible] =
    useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [viewedElement, setViewedElement] = useState(null);

  const [listType, ListTypeBtn] = useListTypeCmp("full");
  const setFirstUploaded = useFirstFileUploaded(elements.length);

  useEffect(() => {
    elements.forEach((element) => {
      setLoadingElements((prev) => {
        prev.delete(element._id);
      });
    });
  }, [elements]);

  return (
    <section className={`Elements ${cs.Elements}`}>
      <Card>
        <Card.Header>
          <div className={cs.title}>
            <span className="h5">Elementy</span>

            {isHowToClosed && (
              <button
                className={cs.howToAddFiles}
                type="button"
                onClick={() => setIsHowToAddFilesModalVisible(true)}
              >
                <img src={questionImg} className="mr8" />
                <span>Jak dodawać pliki?</span>
              </button>
            )}
          </div>

          <div className="caption">
            W tym miejscu możesz dodać dokumentacje na podstawie, której
            utworzysz listę detali które chcesz zamówić (BOM). Obsługiwane
            formaty (.ipt; .sldprt; .step; .stp; .prt; .dwg; .dxf; .jpg; .png;
            .pdf)
          </div>
        </Card.Header>

        <Card.Body>
          <div className={cs.messages}>
            {messages.map((message) => (
              <Message
                key={message.text}
                type={message.type}
                onClose={() => removeMessage(message)}
              >
                {message.text}
              </Message>
            ))}
          </div>

          {elements.length > 0 && (
            <>
              <div className={cs.selectionActions}>
                <img src={arrowDownImg} alt="" />

                <div className="ml12">
                  <Button
                    type="secondary"
                    size="small"
                    disabled={selectedElements.size === 0}
                    onClick={() => setIsEditMultiOpened(true)}
                  >
                    <img src={pencilImg} alt="" />
                    <span className="ml8">Edytuj zaznaczone</span>
                  </Button>

                  <Button
                    type="secondary"
                    size="small"
                    className="ml8"
                    disabled={selectedElements.size === 0}
                    onClick={removeSelectedElements}
                  >
                    <img src={trashImg} alt="" />
                    <span className="ml8">Usuń zaznaczone</span>
                  </Button>
                </div>

                <ListTypeBtn className="ml-auto" />
              </div>

              <div>
                <Checkbox
                  checked={elements.length === selectedElements.size}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                />
              </div>
            </>
          )}

          {elements.length > 0 && (
            <div className={cs.noOfSets}>
              <span className="mr8">Ilość: </span>

              <Chip
                color="orange"
                size="body"
                onClick={() => setIsSetNoModalOpened(true)}
              >
                <span className="mr4">{getNoOfSetsText(noOfSets)}</span>
                <img src={chevronDownImg} />
              </Chip>
            </div>
          )}

          <div>
            {elements.map((element) => (
              <Element
                key={element._id}
                order={order}
                element={element}
                noOfSets={noOfSets}
                isSelected={selectedElements.has(element._id)}
                listType={listType}
                onPropertyChanged={handleElementPropertyChanged}
                onReplaceElement={handleReplaceElement}
                onSelectionChange={onSelectionChange}
                onShowDetails={setViewedElement}
              />
            ))}
          </div>

          {Array.from(loadingElements).map((key) => (
            <LoadingElement key={key} listType={listType} />
          ))}

          <Uploader
            compact={elements.length > 0}
            onFileUpload={handleFilesUploaded}
          />
        </Card.Body>
      </Card>

      <ElementDetailsModal
        element={viewedElement}
        noOfSets={noOfSets}
        visible={viewedElement}
        onClose={(updatedElement: any) => {
          handleReplaceElement(updatedElement);
          setViewedElement(null);
        }}
        onConfirm={(updatedElement: any) => {
          handleReplaceElement(updatedElement);

          const currIndex = elements.findIndex((el) => el === viewedElement);
          const nextElement = elements[currIndex + 1];

          if (nextElement) {
            setViewedElement(nextElement);
            setTimeout(() => {
              document.querySelector(".ant-modal-header")?.scrollIntoView();
            });
          } else {
            setViewedElement(null);
          }
        }}
      />

      <NoOfSetsModal
        isOpen={isSetNoModalOpened}
        noOfSets={noOfSets}
        onConfirm={onNoOfSetsChange}
        onClose={() => setIsSetNoModalOpened(false)}
      />

      <InfoModal
        title="Jak dodawać elementy?"
        visible={isHowToAddFilesModalVisible}
        onClose={() => {
          setIsHowToAddFilesModalVisible(false);
        }}
      >
        <ul className={cs.addFilesInstruction}>
          <li className={cs.addFilesInstruction_li}>
            Dodaj pliki w formatach typowych dla CAD (.ipt, .sldprt, .step,
            .stp, .prt). Każdy z tych formatów zostanie przekonwertowany na
            .step, który będzie mógł pobrać Twój potencjalny usługodawca.
          </li>

          <li className={cs.addFilesInstruction_li}>
            Pliki w formatach (.ipt) zostaną automatycznie rozczytane i zostaną
            z nich pobrane takie informacje jak materiał. Dodatkowo, o ile to
            możliwe, zostaną pobrane parametry dla proponowanych technologii
            produkcji. Jeżeli element będzie konstrukcją blaszaną, zostanie
            automatycznie wygenerowane rozwinięcie oraz plik .dxf
          </li>

          <li className={cs.addFilesInstruction_li}>
            Dodając kilka plików o tej samej nazwie zostaną one automatycznie
            złączone i przydzielone do jednego elementu.
          </li>
        </ul>
      </InfoModal>

      <EditMultipleModal
        isOpen={isEditMultiOpened}
        noOfSelected={selectedElements.size}
        onClose={() => setIsEditMultiOpened(false)}
        onConfirm={handleMultipleElementsChanged}
      />
    </section>
  );

  function addMessage(type, text) {
    setMessages((prev) => {
      const messageExists = prev.find(
        (msg) => msg.text === text && msg.type === type
      );

      if (messageExists) {
        return prev;
      }

      return [{ type, text }, ...prev];
    });
  }

  function removeMessage(removedMessage) {
    setMessages((prev) => {
      return prev.filter((message) => message !== removedMessage);
    });
  }

  async function handleFilesUploaded(files: File[]) {
    // NOTE: 1) Filter out formats that are not supported
    const notSupported: any[] = [];

    files.forEach((file) => {
      const fileExtension = file.name.split(".").pop() as string;

      if (!ACCEPTED_ELEMENT_EXTENSIONS.includes(fileExtension.toLowerCase())) {
        notSupported.push(file);

        addMessage(
          "error",
          `Plik: ${file.name} nie został dodany z powodu niewspieranego rozszerzenia (.${fileExtension})`
        );
      }
    });

    files = files.filter((file) => !notSupported.includes(file));

    // NOTE: 2) Check if existing element with the name not uploaded already
    const existing: any[] = [];

    files.forEach((file) => {
      const fileName = file.name.replace(/\.[^/.]+$/, "");

      const existingElement = elements.find(
        (element: any) => element.name === fileName
      );

      if (existingElement) {
        existing.push(file);

        // NOTE: Element with the same name already exists - add as related file
        handleElementPropertyChanged(existingElement._id, "relatedFiles", [
          ...existingElement.relatedFiles,
          file,
        ]);
      }
    });

    files = files.filter((file) => !existing.includes(file));

    if (mode.isPublic && elements.length >= 15 && files.length > 0) {
      notification.warn({ message: "Maksymalna ilość elementów wynosi 15." });

      return;
    }

    // NOTE: 3) Group files by name
    const groupedByNames: any = [];

    files.forEach((file) => {
      const fileName = file.name.replace(/\.[^/.]+$/, "");

      const existingGroup = groupedByNames.find(
        (grouped) => grouped.name === fileName
      );

      if (existingGroup) {
        existingGroup.files.push(file);
      } else {
        groupedByNames.push({
          name: fileName,
          files: [file],
        });
      }
    });

    // NOTE: 4) Find main file and add the rest as related files
    groupedByNames.forEach((group) => {
      const iptFile = group.files.find((file) => {
        const fileExtension = file.name.split(".").pop();

        return fileExtension.toLowerCase() === "ipt";
      });

      const stepFile = group.files.find((file) => {
        const fileExtension = file.name.split(".").pop();

        return fileExtension.toLowerCase() === "step";
      });

      const otherCADFile = group.files.find((file) => {
        const fileExtension = file.name.split(".").pop();

        return CAD_FILE_EXTENSIONS.includes(fileExtension.toLowerCase());
      });

      if (iptFile) {
        group.mainFile = iptFile;
      } else if (stepFile) {
        group.mainFile = stepFile;
      } else if (otherCADFile) {
        group.mainFile = otherCADFile;
      } else {
        group.mainFile = group.files[0];
      }

      group.files = group.files.filter((file) => file !== group.mainFile);
    });

    // NOTE: 5) Add element and if main file is a CAD file then analyze
    for (const group of groupedByNames) {
      const _id = Math.ceil(Math.random() * 100000000);

      // NOTE: Add loading placeholder for the element
      startLoadingElement(_id);

      const mainFileExt = group.mainFile.name.split(".").pop();
      const mainFileName = group.mainFile.name.replace(/\.[^/.]+$/, "");

      if (CAD_FILE_EXTENSIONS.includes(mainFileExt.toLowerCase())) {
        const result = await analyze(group);

        if (result.isSuccess) {
          if (result.data.dxfFile) {
            group.files.push(result.data.dxfFile);
          }

          addElement({
            _id,
            file: result.data.mainFileAsStep,
            relatedFiles: group.files,
            name: mainFileName,
            ...result.data.details,
          });
        } else {
          setLoadingElements((prev) => {
            prev.delete(_id);
          });

          addMessage("error", `Plik: ${mainFileName} nie został dodany`);

          if (result.error === "LIMIT_FILE_SIZE") {
            notification.error({ message: "Maksymalny rozmiar pliku to 15mb" });
          } else if (result.error === "CAD_FILES_LIMIT_REACHED") {
            notification.error({
              message: "Wykorzystałeś limit liczby analizowanych plików.",
            });
          }
        }
      } else {
        addElement({
          _id,
          file: group.mainFile,
          relatedFiles: group.files,
          name: mainFileName,
          parameters: {},
          services: [],
          amount: 1,
          total_amount: noOfSets,
        });
      }

      setLoadingElements((prev) => {
        prev.delete(_id);
      });
    }
  }

  function analyze(group: any) {
    const analyzeApi = mode.isPublic
      ? analyzeCADFilePublicApi
      : analyzeCADFileApi;

    return analyzeApi(group.mainFile).then(async (response) => {
      if (response.isSuccess) {
        const details: any = { ...response.data };

        details.amount = 1;
        details.total_amount = noOfSets;

        const fileName = group.mainFile.name.replace(/\.[^/.]+$/, "");
        const fileExtension = group.mainFile.name.split(".").pop();

        let mainFileAsStep;
        let dxfFile;

        if (fileExtension.toUpperCase() !== "STEP") {
          const stepAsDataUrl =
            "data:application/step;base64," + details.step_file;

          mainFileAsStep = await dataUrlToFile(
            stepAsDataUrl,
            fileName + ".step",
            "application/step"
          );
        } else {
          // NOTE: For step files only make sure extension is lowercased (.STEP > .step)
          mainFileAsStep = renameFile(
            group.mainFile,
            fileName + "." + fileExtension.toLowerCase()
          );
        }

        if (details.dxf_file) {
          const dxfAsDataUrl =
            "data:application/dxf;base64," + details.dxf_file;

          dxfFile = await dataUrlToFile(
            dxfAsDataUrl,
            fileName + ".dxf",
            "application/dxf"
          );
        }

        return {
          isSuccess: true,
          data: {
            mainFileAsStep,
            dxfFile,
            details,
          },
        };
      } else {
        return {
          isSuccess: false,
          error: response.error,
          data: {},
        };
      }
    });
  }

  function startLoadingElement(newElementId) {
    onCloseHowToCard();

    setFirstUploaded(true);
    setLoadingElements((prev) => void prev.add(newElementId));
  }

  function addElement(element) {
    onElementsChange((elements) => {
      return [...elements, element];
    });
  }

  function removeSelectedElements() {
    onElementsChange((elements) => {
      return elements.filter((el) => ![...selectedElements].includes(el._id));
    });

    selectedElements.forEach((s) => {
      onSelectionChange({ selected: false, _id: s });
    });
  }

  function handleElementPropertyChanged(elementId, property, newValue) {
    onElementsChange((elements) => {
      return elements.map((element: any) => {
        if (element._id !== elementId) return element;

        const updatedEl = {
          ...element,
          [property]: newValue,
        };

        if (property === "amount") {
          updatedEl.total_amount = noOfSets * newValue;
        }

        return updatedEl;
      });
    });
  }

  function handleReplaceElement(updatedElement) {
    onElementsChange((elements) => {
      return elements.map((element: any) => {
        if (element._id !== updatedElement._id) return element;

        return updatedElement;
      });
    });
  }

  function handleMultipleElementsChanged(commonValues) {
    onElementsChange((elements) => {
      return elements.map((element: any) => {
        if (!selectedElements.has(element._id)) return element;

        let updatedElement = { ...element };

        if (commonValues.material) {
          updatedElement = handleNewMaterialSet(element, commonValues.material);
        }

        if (commonValues.services?.length > 0) {
          updatedElement.services = commonValues.services.map(
            (serviceId: number) => ({
              _id: serviceId,
              technologyIds: getTechnologyIdsByService(serviceId),
            })
          );
        }

        if (commonValues.remarks) {
          updatedElement.remarks = commonValues.remarks;
        }

        return updatedElement;
      });
    });
  }

  function handleSelectAll(selected) {
    elements.forEach(({ _id }) => {
      onSelectionChange({ selected, _id });
    });
  }
};

const Message = ({ type, children, onClose }) => {
  if (type === "error") {
    return (
      <div className={cs.Message__error}>
        <img src={exclamationImg} alt="" className="mr8" />

        <span>{children}</span>

        <img src={closeImg} alt="" className={cs.closeBtn} onClick={onClose} />
      </div>
    );
  }

  return (
    <div className={cs.Message__success}>
      <span>{children}</span>

      <img src={closeImg} alt="" className={cs.closeBtn} onClick={onClose} />
    </div>
  );
};
