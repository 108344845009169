import { flattenDeepUnique } from "@helpers/utils";
import THREADS from "../constants/THREADS.json";

type ThreadType =
  | "ANSI Gwinty cal zunifikowane"
  | "ANSI Profil metryczny M"
  | "ISO Profil metryczny"
  | "Trapezowe gwinty metryczne ISO";

export const typesAsSelectOptions = THREADS.map((threadType) => ({
  value: threadType.name,
  label: threadType.name,
}));

export const outerDiametersAsSelectOptions = (typeName: ThreadType) => {
  if (!typeName) return [];

  const type = getTypeDetailsByName(typeName);

  if (!type) return [];

  return flattenDeepUnique(
    type.options.map(({ outer_diameter }) => outer_diameter)
  ).map((outer_diameter) => ({
    value: outer_diameter,
    label: outer_diameter,
  }));
};

export const descsAsSelectOptions = (typeName: ThreadType) => {
  if (!typeName) return [];

  const type = getTypeDetailsByName(typeName);

  if (!type) return [];

  return type.options.map((option) => ({
    value: option.desc,
    label: option.desc,
  }));
};

export function getThreadDetailsByDescription(
  typeName: ThreadType,
  desc: string
) {
  const type = getTypeDetailsByName(typeName);

  if (!type) return null;

  const option = type.options.find((option) => option.desc === desc);

  return option;
}

export function getThreadDetailsByOuterDiameter(
  typeName: ThreadType,
  outerDiameter: number
) {
  const type = getTypeDetailsByName(typeName);

  if (!type) return null;

  const option = type.options.find(
    (option) => option.outer_diameter === outerDiameter
  );

  return option;
}

///

function getTypeDetailsByName(typeName: ThreadType) {
  const type = THREADS.find(({ name }) => name === typeName);

  if (!type) {
    console.warn(`Thread type (${typeName}) not found.`);

    return null;
  }

  return type;
}
