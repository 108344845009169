import { useEffect, useState } from "react";
import { Chip } from "@components/Chip";
import { Btn } from "../../Customer/views/NewOrderPage/Elements/Element/Element";
import uploadImg from "./upload.svg";
import deleteImg from "./delete.svg";
import cs from "./Files.module.scss";

export const Files = ({ main, additional, isEditable, onChange }) => {
  const [additionalDraft, setAdditionalDraft] = useState([]);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setAdditionalDraft(additional || []);
  }, [additional]);

  return (
    <div className={cs.Files}>
      <Chip color="gray" size="caption">
        {main.name}
      </Chip>

      {additionalDraft?.map(({ name }) => (
        <Chip color="gray" size="caption" className={cs.addFile} key={name}>
          <span>{name}</span>

          {isEdited && (
            <img src={deleteImg} onClick={() => handleFileRemoved(name)} />
          )}
        </Chip>
      ))}

      {isEdited ? (
        <>
          <Chip color="blue" size="caption" className={cs.uploadBtn}>
            <span className="mr4">Dodaj plik</span>
            <img src={uploadImg} />

            <label htmlFor="Files_upload" />
          </Chip>

          <Btn onClick={handleSave}>
            <span className="bold">Zapisz</span>
          </Btn>
        </>
      ) : (
        isEditable && (
          <Btn onClick={() => setIsEdited(true)}>
            <span className="bold">Edytuj</span>
          </Btn>
        )
      )}

      <input
        type="file"
        id="Files_upload"
        hidden
        multiple
        onChange={handleFilesUploaded}
      />
    </div>
  );

  function handleFilesUploaded(e) {
    const uploadedFiles = Array.from(e.target.files);

    setAdditionalDraft([...additionalDraft, ...uploadedFiles]);

    e.target.value = "";
  }

  function handleFileRemoved(fileName) {
    setAdditionalDraft((prev) => prev.filter(({ name }) => name !== fileName));
  }

  function handleSave() {
    onChange("relatedFiles", additionalDraft);

    setIsEdited(false);
  }
};
