import { CSSProperties } from "react";
import classNames from "classnames";
import cs from "./Textarea.module.scss";

type Props = {
  value: string;
  label?: string;
  className?: string;
  placeholder?: string;
  name?: string;
  rows?: number;
  readOnly?: boolean;
  style?: CSSProperties;
  maxLength?: number;
  size?: "default" | "small" | "large";
  onChange: (value: string, name: string) => void;
};

export const Textarea = ({
  value,
  rows,
  label,
  className,
  placeholder = "",
  name = "",
  maxLength,
  size = "default",
  readOnly = false,
  style = {},
  onChange,
}: Props) => {
  const csRoot = classNames("Textarea", cs.root, className, {
    [cs.default]: size === "default",
    [cs.small]: size === "small",
    [cs.large]: size === "large",
  });

  return (
    <div className={csRoot} style={style}>
      {label && <label className={cs.label}>{label}</label>}

      <textarea
        value={value}
        rows={rows}
        maxLength={maxLength}
        placeholder={placeholder}
        name={name}
        readOnly={readOnly}
        onChange={(e) => onChange(e.target.value, name)}
      />
    </div>
  );
};
