import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { notification } from "antd";

export function useHandleSubscribed() {
  const [justSubscribed, setJustSubscribed] = useState(false);

  const [searchParam, setSearchParam] = useSearchParams();

  useEffect(() => {
    if (searchParam.get("subscribed") === "true") {
      setJustSubscribed(true);

      notification.success({
        message: "Subskrypcja została zakupiona.",
        description:
          "Jesteśmy w trakcie przetwarzania Twojej płatności. Gdy ten proces się zakończy w panelu poniżej będziesz mógł przydzielić zakupione licencje odpowiednim użytkownikom.",
        placement: "topRight",
        duration: 15,
      });
    }

    setSearchParam("", {});
  }, [searchParam, setJustSubscribed]);

  return justSubscribed;
}
