import { useEffect } from "react";
import { useImmer } from "use-immer";
import { Metal } from "./Metal";
import { Plastic } from "./Plastic";
import { Wood } from "./Wood";
import exclamationImg from "./img/exclamanation.svg";
import cs from "./AdditionalProcessings.module.scss";

export const AdditionalProcessings = ({ machinelessServices, onSave }) => {
  const [machinelessDraft, setMachinelessDraft] = useImmer(machinelessServices);

  useEffect(() => {
    setMachinelessDraft(machinelessServices);
  }, [machinelessServices]);

  const noneSelected = machinelessServices.length === 0;

  return (
    <section className={cs.AdditionalProcessings}>
      {noneSelected && <Info />}

      <div>
        <Metal
          machinelessServices={machinelessDraft}
          onChange={handleChanged}
          onSave={handleSave}
        />

        <Plastic
          machinelessServices={machinelessDraft}
          onChange={handleChanged}
          onSave={handleSave}
        />

        <Wood
          machinelessServices={machinelessDraft}
          onChange={handleChanged}
          onSave={handleSave}
        />
      </div>
    </section>
  );

  function handleChanged(serviceId, isChecked) {
    setMachinelessDraft((prev) => {
      if (isChecked) {
        prev.push(serviceId);

        // NOTE: If any hole-related service is checked than
        //       drilling must be checked too
        if ([126, 127, 128].includes(serviceId)) {
          // NOTE: Metal
          if (!prev.includes(125)) {
            prev.push(125);
          }
        } else if ([135, 136].includes(serviceId)) {
          // NOTE: Wood
          if (!prev.includes(133)) {
            prev.push(133);
          }
        } else if ([131, 132].includes(serviceId)) {
          // NOTE: Plastic
          if (!prev.includes(129)) {
            prev.push(129);
          }
        }
      } else {
        // NOTE: If drilling is unchecked then all hole-related
        //       services must be unchecked too.
        if (serviceId === 125) {
          // NOTE: Metal
          return prev.filter((id) => ![125, 126, 127, 128].includes(id));
        } else if (serviceId === 133) {
          // NOTE: Wood
          return prev.filter((id) => ![133, 135, 136].includes(id));
        } else if (serviceId === 129) {
          // NOTE: Plastic
          return prev.filter((id) => ![129, 131, 132].includes(id));
        } else {
          return prev.filter((id) => id !== serviceId);
        }
      }
    });
  }

  function handleSave() {
    onSave(machinelessDraft);
  }
};

const Info = () => (
  <div className={cs.Info}>
    <img src={exclamationImg} alt="" />

    <div>
      Wybierz dodatkowe usługi obsługiwane przez Twój Park Maszyn, aby zwiększyć
      szansę na znalezienie odpowiedniego zlecenia.{" "}
    </div>
  </div>
);
