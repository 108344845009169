import { useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { Btn } from "../../../../../Customer/views/NewOrderPage/Elements/Element/Element";
import { ServiceContext } from "./WithMachinesAndMaterials";
import { getTableByService } from "./tables/getTableByService";
import deleteImg from "./img/delete.svg";
import cs from "./MaterialsLevel.module.scss";

export const MaterialsLevel = ({
  materialsGroupPricing,
  onChange,
  onEdit,
  onDelete,
}) => {
  const [draft, setDraft] = useImmer(materialsGroupPricing);
  const [isEdited, setIsEdited] = useState(false);

  const serviceId = useContext(ServiceContext);

  useEffect(() => {
    setDraft(materialsGroupPricing);
  }, [materialsGroupPricing]);

  const Table = getTableByService(serviceId);

  return (
    <div className={`MaterialsLevel ${cs.MaterialsLevel}`}>
      <div className={cs.header}>
        <div>
          <div>
            {materialsGroupPricing.materialTypes
              .map(({ name }) => name)
              .join(", ")}
            {":"}
          </div>

          <div>
            <Btn onClick={onEdit}>Edytuj materiały</Btn>
          </div>
        </div>

        <div style={{ height: 20 }}>
          <img src={deleteImg} alt="" onClick={onDelete} />
        </div>
      </div>

      <div>
        <Table
          rows={draft.prices.table}
          readOnly={!isEdited}
          onChange={handleTableChanged}
        />

        <div className="mt4">
          {isEdited ? (
            <Btn onClick={handleSave}>Zapisz zmiany</Btn>
          ) : (
            <Btn onClick={() => setIsEdited(true)}>Edytuj ceny</Btn>
          )}
        </div>
      </div>
    </div>
  );

  function handleTableChanged(updatedTable) {
    setDraft((prev) => {
      prev.prices.table = updatedTable;
    });
  }

  function handleSave() {
    setIsEdited(false);

    onChange(draft);
  }
};
