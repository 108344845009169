import { MinusSvg } from "@components/Counter/MinusSvg";
import { InputNumber } from "@components/InputNumber";
import plusImg from "./plus.svg";
import cs from "./Counter.module.scss";

export const Counter = ({ value = 1, onChange }) => {
  return (
    <div className={cs.Counter}>
      <button type="button" disabled={value === 1} onClick={decrement}>
        <MinusSvg isDisabled={value === 1} />
      </button>

      <InputNumber value={value} min={1} step={1} onChange={handleChange} />

      <button type="button" onClick={increment}>
        <img src={plusImg} alt="" />
      </button>
    </div>
  );

  function increment() {
    onChange(value + 1);
  }

  function decrement() {
    onChange(value - 1);
  }

  function handleChange(value: string) {
    onChange(Number.parseInt(value, 10) || 1);
  }
};
