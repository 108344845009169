import { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { ReactComponent as PlusImg } from "./img/plus.svg";
import { ReactComponent as FileUpImg } from "./img/file_up.svg";
import { ReactComponent as SearchImg } from "./img/search.svg";
import { ReactComponent as FileDownImg } from "./img/file_down.svg";
import { ReactComponent as GearImg } from "./img/gear.svg";
import { ReactComponent as LayersImg } from "./img/layers.svg";
import chevronDoubleLeftImg from "./img/chevron_double_left.svg";
import chevronDoubleRightImg from "./img/chevron_double_right.svg";
import cs from "./Menu.module.scss";

export const Menu = ({ onLinkClicked = () => {} }) => {
  const [isExpanded, setIsExpanded] = useState(() => {
    return !window.sessionStorage.getItem("4d_menu_collapsed");
  });

  const rootCs = classNames("Menu", {
    [cs.Menu]: isExpanded,
    [cs.Menu__closed]: !isExpanded,
  });

  return (
    <nav className={rootCs}>
      <div>
        <div className={cs.menuItem}>
          <NavLink to="/" onClick={onLinkClicked}>
            <LayersImg />

            <span>Pulpit</span>
          </NavLink>
        </div>
      </div>

      <div>
        <div className={cs.subTitle}>Zleceniodawca</div>

        <div>
          <div className={`${cs.menuItem} hide-phone`}>
            <NavLink to="/order">
              <PlusImg />
              <span>Utwórz zapytanie</span>
            </NavLink>
          </div>
          <div className={cs.menuItem}>
            <NavLink to="/orders" onClick={onLinkClicked}>
              <FileUpImg />
              <span>Moje zapytania</span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="mb30">
        <div className={cs.subTitle}>Usługodawca</div>

        <div>
          <div className={cs.menuItem}>
            <NavLink to="/search" onClick={onLinkClicked}>
              <SearchImg />
              <span>Szukaj zleceń</span>
            </NavLink>
          </div>

          <div className={cs.menuItem}>
            <NavLink to="/offers" onClick={onLinkClicked}>
              <FileDownImg />
              <span>Moje oferty</span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className={cs.itemBtm}>
        <NavLink to="/account" onClick={onLinkClicked}>
          <GearImg />

          <span>Ustawienia</span>
        </NavLink>

        <img
          src={chevronDoubleLeftImg}
          alt=""
          className="hide-mobile"
          onClick={handleToggle}
        />
      </div>

      <div className={cs.expandMenu}>
        <img src={chevronDoubleRightImg} alt="" onClick={handleToggle} />
      </div>
    </nav>
  );

  function handleToggle() {
    if (isExpanded) {
      window.sessionStorage.setItem("4d_menu_collapsed", "true");
    } else {
      window.sessionStorage.removeItem("4d_menu_collapsed");
    }

    setIsExpanded(!isExpanded);
  }
};
