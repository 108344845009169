import { useEffect, useState } from "react";
import { Modal } from "@components/Modal";
import { InputNumber } from "@components/InputNumber";
import { toFixedNumber } from "@helpers/utils";

export const TotalPriceModal = ({ isOpen, price, onClose, onConfirm }) => {
  const [selectedPrice, setSelectedPrice] = useState(price);

  useEffect(() => {
    if (isOpen) {
      setSelectedPrice(price);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Zmień cenę sumaryczną"
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okText="Zapisz zmiany"
      width={480}
      onOk={() => {
        handleConfirm();
        onClose();
      }}
      onClose={onClose}
    >
      <div>
        <span className="mr8 body">Cena sumaryczna:</span>
        <InputNumber
          size="small"
          value={selectedPrice || ""}
          step={1}
          min={0}
          placeholder="-"
          onChange={(newValue: number) => {
            setSelectedPrice(toFixedNumber(newValue, 2));
          }}
        />
        <span className="ml12 body">zł</span>
      </div>
    </Modal>
  );

  function handleConfirm() {
    onConfirm(selectedPrice);
  }
};
