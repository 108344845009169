import { useStore } from "@store";

export function groupTechnologies(groups: any[], technologies: any[]) {
  groups.forEach((group) => {
    group.items = technologies.filter((technology) => {
      return technology.group_id === group._id;
    });
  });

  return groups;
}

export function sortTechnologiesByName(technologies: any[]) {
  return technologies.sort((a, b) => {
    if (a.name > b.name) return 1;

    return -1;
  });
}

/**
 * Groups technologies by service and returns map
 *
 * @param technologyIds
 */
// TODO: Name
export function groupTechnologiesByServiceRetMap(technologyIds) {
  const availableTechnologies = useStore.getState().technologies2;

  const techs = technologyIds.map((techId) =>
    availableTechnologies.find(({ _id }) => _id === techId)
  );

  const grouped = new Map();

  techs.forEach((tech) => {
    if (tech.parent_id) {
      grouped.set(tech.parent_id, [
        ...(grouped.get(tech.parent_id) || []),
        tech,
      ]);
    } else {
      // NOTE: Services without any chilren like 'Czernienie'
      grouped.set(tech._id, [tech]);
    }
  });

  return grouped;
}

export function groupTechnologiesByService2(technologyIds) {
  const availableTechnologies = useStore.getState().technologies2;

  const technologiesWithDetails = technologyIds.map((techId) =>
    availableTechnologies.find(({ _id }) => _id === techId)
  );

  const grouped = [];

  technologiesWithDetails.forEach((tech) => {
    if (tech.parent_id) {
      const service = grouped.find(({ _id }) => _id === tech.parent_id);

      if (service) {
        service.technologyIds.push(tech._id);
      } else {
        grouped.push({
          _id: tech.parent_id,
          technologyIds: [tech._id],
        });
      }
    } else {
      // NOTE: Services without any children like 'Czernienie'
      grouped.push({
        _id: tech._id,
        technologyIds: [],
      });
    }
  });

  return grouped;
}

export function getTechnologyIdsByService(serviceId: number) {
  const availableTechnologies = useStore.getState().technologies2;

  return availableTechnologies
    .filter(({ parent_id }) => parent_id === serviceId)
    .map(({ _id }) => _id);
}

export function getServiceNameById(serviceId: number) {
  const availableServices = useStore.getState().technologies2;

  const service = availableServices.find((service) => {
    return service._id === serviceId;
  });

  return service.name;
}
