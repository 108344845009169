export const PROVINCES = [
  { label: "Dolnośląskie", value: "dolnośląskie" },
  { label: "Kujawsko-pomorskie", value: "kujawsko-pomorskie" },
  { label: "Lubelskie", value: "lubelskie" },
  { label: "Lubuskie", value: "lubuskie" },
  { label: "Łódzkie", value: "łódzkie" },
  { label: "Małopolskie", value: "małopolskie" },
  { label: "Mazowieckie", value: "mazowieckie" },
  { label: "Opolskie", value: "opolskie" },
  { label: "Podkarpackie", value: "podkarpackie" },
  { label: "Podlaskie", value: "podlaskie" },
  { label: "Pomorskie", value: "pomorskie" },
  { label: "Śląskie", value: "śląskie" },
  { label: "Świętokrzyskie", value: "świętokrzyskie" },
  { label: "Warmińsko-mazurskie", value: "warmińsko-mazurskie" },
  { label: "Wielkopolskie", value: "wielkopolskie" },
  { label: "Zachodniopomorskie", value: "zachodniopomorskie" },
];
