import { useEffect, useState } from "react";
import { enableMapSet } from "immer";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { Spin } from "antd";
import "antd/dist/antd.css";
import { checkIfUserAuthenticated } from "@services/auth.api";
import { store, useStore } from "@store";
import { ApplicationRoutes } from "./routes/ApplicationRoutes";
import "./App.css";
import "./styles.scss";

enableMapSet();

initSentry();
initGTM();

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const fetchTechnologies = useStore((state) => state.fetchTechnologies);
  const fetchTechnologies2 = useStore((state) => state.fetchTechnologies2);
  const fetchCurrentUser = useStore((state) => state.fetchCurrentUser);
  const fetchMaterials = useStore((state) => state.fetchMaterials);
  const fetchParameters = useStore((state) => state.fetchParameters);
  const fetchMachinePark = useStore((state) => state.fetchMachinePark);
  const fetchOfferedServices = useStore((state) => state.fetchOfferedServices);
  const fetchMachinelessServices = useStore(
    (state) => state.fetchMachinelessServices
  );
  const fetchPriceLists = useStore((state) => state.fetchPriceLists);
  const fetchSubscriptionInfo = useStore(
    (state) => state.fetchSubscriptionInfo
  );
  const user = store.hooks.useUser();

  useEffect(() => {
    (async () => {
      const isAuthenticated = await checkIfUserAuthenticated();

      fetchMaterials();
      fetchTechnologies2();

      if (isAuthenticated) {
        await Promise.all([
          fetchTechnologies(),
          fetchCurrentUser(),
          fetchMachinePark(),
          fetchMachinelessServices(),
          fetchOfferedServices(),
          fetchSubscriptionInfo(),
          fetchParameters(), // TODO: Nie tutaj - dopiero jak ktoś wejdzie na park maszyn
        ]);

        await fetchPriceLists();
      }

      setIsLoading(false);
    })();
  }, []);

  useTidioChat(user);

  if (isLoading) {
    return (
      <div className="app-loader">
        <Spin size="large" />
      </div>
    );
  }

  return <ApplicationRoutes />;
}

export default App;

function initSentry() {
  Sentry.init({
    dsn: "https://841bb4523e4c48c8a1392b9d3ab5e2c5@o1276515.ingest.sentry.io/6471928",
    environment: process.env.REACT_APP_ENV,
    beforeSend(event) {
      if (event.environment === "local") return null;

      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.click") {
        const { target } = hint.event;

        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel;
        } else {
          breadcrumb.message += ` (${target.innerText})`;
        }
      } else if (
        breadcrumb.category === "fetch" &&
        breadcrumb?.data?.url?.match(/^data:/)
      ) {
        breadcrumb.data.url = "b64_file";
      }

      return breadcrumb;
    },
    ignoreErrors: ["Non-Error promise rejection captured"],
  });
}

function initGTM() {
  if (!process.env.REACT_APP_GTM_ID) return;

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

function useTidioChat(user: any) {
  useEffect(() => {
    try {
      if (user?._id && window.tidioChatApi) {
        window.tidioChatApi.setVisitorData({
          distinct_id: user._id,
          email: user.contact_email || user.email,
          name: user.name,
          phone: user.phone,
        });
        window.tidioChatApi.setContactProperties({
          company_name: user.organization_name,
          internal_id: user._id,
          position: user.position,
        });
      }
    } catch (error) {
      console.error(error);

      Sentry.captureException(error);
    }
  }, [user?._id]);
}
