import { useEffect, useState } from "react";
import {
  groupTechnologiesByService2,
  groupTechnologiesByServiceRetMap,
} from "@services/technology.helpers";
import { calcAvgRating } from "@services/review.helpers";
import { calculateCompatibility } from "./CompatibilityService";
import { getOrderApi, searchOrdersApi } from "./order.api";

export function useSearchOrders() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const orders = await searchOrdersApi();

      // TODO: prepareOrder ???
      setOrders(
        orders
          .map((order: any) => {
            order.elements = order.elements.map((el: any) => ({
              ...el,
              // TODO: Two of the same - decide to one form
              services: groupTechnologiesByService2(el.technology_ids),
              technologies: groupTechnologiesByServiceRetMap(el.technology_ids),
            }));

            // INFO: Owner can be obscured when data showed to user without a licence
            if (order.owner_company) {
              order.owner_company = {
                ...order.owner_company,
                avgRating: calcAvgRating(order.owner_company.reviews),
              };
            }

            return order;
          })
          .map(calculateCompatibility)
      );
    })().finally(() => {
      setIsLoading(false);
    });
  }, []);

  return [orders, isLoading];
}

export function useOrder(orderId: string) {
  const [order, setOrder] = useState();

  useEffect(() => {
    if (!orderId) return;

    (async () => {
      const order = await getOrderApi(orderId);

      setOrder(order);
    })();
  }, [orderId]);

  return [order];
}
