import { ChangeEvent } from "react";
import { Card } from "@components/Card";
import { Button } from "@components/Button";
import fileImg from "./img/file.svg";
import cs from "./AdditionalFiles.module.scss";

type Props = {
  files: File[];
  onChange: (changedField: string, newValue: File[]) => void;
};

export const AdditionalFiles = ({ files = [], onChange }: Props) => {
  return (
    <section className="AdditionalFiles">
      <Card>
        <Card.Header>
          <h2 className="h5">
            <span>Dodatkowe pliki projektowe </span>
            <span>({files?.length || 0})</span>
          </h2>
          <p className="caption mt8">
            Możesz dodać do 15 plików w dowolnym formacie, maks. 15MB
          </p>
        </Card.Header>

        <Card.Body>
          <div>
            {files?.length > 0 && (
              <ul className={cs.files}>
                {files?.map((file: File) => (
                  <li key={file.name} className="mb8">
                    <img src={fileImg} />
                    <span className="body bold ml8">{file.name}</span>

                    <Button
                      className={cs.removeBtn}
                      type="link"
                      onClick={() => handleFileRemoved(file.name)}
                    >
                      Usuń
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <Button
            size="medium"
            type="secondary"
            style={{ position: "relative" }}
          >
            <label
              htmlFor="project_additional_files"
              style={{
                bottom: "0",
                cursor: "pointer",
                left: "0",
                position: "absolute",
                right: "0",
                top: "0",
              }}
            />
            Wybierz pliki z dysku
          </Button>
        </Card.Body>
      </Card>

      <input
        type="file"
        id="project_additional_files"
        hidden
        multiple
        onChange={handleFilesUploaded}
      />
    </section>
  );

  function handleFileRemoved(fileName: string) {
    const updatedFiles = files.filter(({ name }) => name !== fileName);

    onChange("additionalFiles", updatedFiles);
  }

  function handleFilesUploaded(e: ChangeEvent<HTMLInputElement>) {
    const uploadedFiles = Array.from(e.target.files || []);

    onChange("additionalFiles", [...files, ...uploadedFiles]);

    e.target.value = "";
  }
};
