import { useEffect, useState } from "react";
import { Card } from "@components/Card";
import { InputNumber } from "@components/InputNumber";
import { Button } from "@components/Button";

export function AdditionalInfo({ margin, onMarginSave }) {
  const [marginDraft, setMarginDraft] = useState(margin);

  useEffect(() => {
    setMarginDraft(margin);
  }, [margin]);

  return (
    <section className="AdditionalInfo">
      <Card>
        <Card.Header className="h5">Dodatkowe informacje</Card.Header>
        <Card.Body>
          <div>
            <div className="caption bold color-black mb4">Marża (%)</div>
            <InputNumber
              value={marginDraft}
              min={0}
              style={{ width: "100%" }}
              onChange={setMarginDraft}
            />
          </div>

          <div className="mt16 text-right">
            <Button type="primary" size="medium" onClick={handleSave}>
              Zapisz zmiany
            </Button>
          </div>
        </Card.Body>
      </Card>
    </section>
  );

  function handleSave() {
    onMarginSave(marginDraft);
  }
}
