import { InputNumber } from "@components/InputNumber";

export const Field = ({ name, value, unit, label, isEdited, onChange }) => (
  <div className="PriceList_field">
    <span className="caption color-dark-gray mr4">{label}</span>

    {isEdited ? (
      <InputNumber
        step={0.1}
        size="small"
        value={value}
        onChange={(value) => {
          onChange(name, value);
        }}
      />
    ) : (
      <strong className="caption bold color-black">{value || "-"}</strong>
    )}

    <span className="caption color-dark-gray ml4">{unit}</span>
  </div>
);
