import { useEffect, useState } from "react";
import produce from "immer";
import { store } from "@store";
import { Modal } from "@components/Modal";
import { Select } from "@components/Select";
import { Chip } from "@components/Chip";
import arrowRightImg from "../img/arrow_right.svg";
import cs from "./MachinesModal.module.scss";

export const MachinesModal = ({ isOpen, element, onClose, onConfirm }) => {
  const [machineSelection, setMachineSelection] = useState(new Map());

  const availableServices = store.hooks.useServices();

  useEffect(() => {
    if (isOpen) {
      setMachineSelection(element.selectedMachinesPerService);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Przypisz maszyny"
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okText="Zapisz zmiany"
      width={480}
      onOk={() => {
        onConfirm(machineSelection);
        onClose();
      }}
      onClose={onClose}
    >
      <div className="caption mb24">
        Przyporządkuj odpowiednie maszyny do wybranych usług:
      </div>

      <table className={cs.table}>
        <thead>
          <tr>
            <th align="left" className="caption">
              Usługa:
            </th>
            <th />
            <th align="left" className="caption" style={{ width: 201 }}>
              Maszyna:
            </th>
          </tr>
        </thead>
        <tbody>
          {element.services.map(({ _id }) => {
            const service = availableServices.find((service) => {
              return service._id === _id;
            });
            const selectedMachine = machineSelection.get(_id);

            const options = (element.machinesPerService.get(_id) || []).map(
              (machine) => {
                let name = "";

                if (machine.isDefault) {
                  name = "Cennik domyślny";
                } else {
                  name = machine.model_name;
                }
                return {
                  label: name,
                  value: machine._id,
                };
              }
            );

            return (
              <tr key={_id}>
                <td>
                  <Chip color="gray" size="caption">
                    {service.name}
                  </Chip>
                </td>
                <td style={{ padding: "0 6px" }}>
                  <img src={arrowRightImg} alt="" />
                </td>
                <td>
                  <Select
                    value={selectedMachine?._id}
                    options={options}
                    readOnly={options.length === 0}
                    onChange={(selectedMachineId) => {
                      handleMachineSelected(_id, selectedMachineId);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Modal>
  );

  function handleMachineSelected(serviceId: number, selectedMachineId: string) {
    const selectedMachine = element.machinesPerService
      .get(serviceId)
      .find(({ _id }) => _id === +selectedMachineId);

    const updatedSelection = produce(machineSelection, (draft) => {
      draft.set(serviceId, selectedMachine);
    });

    setMachineSelection(updatedSelection);
  }
};
