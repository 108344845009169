import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { Input } from "@components/Input";
import { Modal } from "@components/Modal";
import { Select } from "@components/Select";
import { TechnologiesSelect } from "@components/TechnologiesSelect";
import cs from "./SelectMachineModal.module.scss";

export const SelectMachineModal = ({
  types,
  brands,
  models,
  onConfirm,
  onClose,
}) => {
  const [machine, setMachine] = useImmer({});
  const [mode, setMode] = useState("standard");
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);

  let availableModels = [];

  if (machine.type && machine.brand) {
    availableModels = models.filter((model) => {
      return (
        model.type_id === machine.type._id &&
        model.brand_id === machine.brand._id
      );
    });
  } else if (machine.brand) {
    availableModels = models.filter((model) => {
      return model.brand_id === machine.brand._id;
    });
  } else if (machine.type) {
    availableModels = models.filter((model) => {
      return model.type_id === machine.type._id;
    });
  } else {
    availableModels = models;
  }

  useEffect(() => {
    if (machine.type) {
      handleTechnologiesChange(machine.type.default_technology_ids);
    }
  }, [machine.type]);

  const typeWithoutSpecificModels = checkIfTypeWithoutSpecificModels(
    machine.type?._id
  );
  const isModelProvided = machine.model?._id || machine.model?.name;
  const isConfirmDisabled =
    !machine.type?._id ||
    selectedTechnologies.length === 0 ||
    (!typeWithoutSpecificModels && !isModelProvided);

  return (
    <Modal
      title="Dodaj maszynę"
      className={cs.SelectMachineModal}
      visible
      width={720}
      okText="Dodaj maszynę"
      onOk={handleConfirm}
      okButtonProps={{
        disabled: isConfirmDisabled,
      }}
      onClose={handleClose}
    >
      <div className={cs.main}>
        <div>
          <Select
            value={machine.type?._id}
            options={types.sort(sortByName).map((a) => ({
              value: a._id,
              label: a.name,
            }))}
            label="Typ"
            placeholder="Wybierz"
            showSearch
            onChange={handleTypeChange}
          />

          {!typeWithoutSpecificModels && (
            <div className="mb16 mt16">
              {mode === "standard" ? (
                <Select
                  value={machine.brand?._id}
                  options={brands.sort(sortByName).map((a) => ({
                    value: a._id,
                    label: a.name,
                  }))}
                  label="Marka"
                  placeholder="Wybierz"
                  showSearch
                  onChange={handleBrandChange}
                />
              ) : (
                <Input
                  label="Marka"
                  value={machine.brand.name}
                  placeholder="Wpisz markę..."
                  onChange={handleCustomBrandChange}
                />
              )}
            </div>
          )}

          {!typeWithoutSpecificModels && (
            <div>
              {mode === "standard" ? (
                <Select
                  value={machine.model?._id}
                  options={availableModels.sort(sortByName).map((a) => ({
                    value: a._id,
                    label: a.name,
                  }))}
                  label="Model"
                  placeholder="Wybierz"
                  showSearch
                  onChange={handleModelChange}
                />
              ) : (
                <Input
                  label="Model"
                  value={machine.model.name}
                  placeholder="Wpisz model..."
                  onChange={handleCustomModelChange}
                />
              )}
            </div>
          )}
        </div>

        <div className="ml-auto">
          {machine.model?._id && (
            <img src={machine.model.img} alt="" style={{ maxWidth: "100%" }} />
          )}
        </div>
      </div>

      {mode === "standard" && !typeWithoutSpecificModels && (
        <div
          className="caption color-primary mt16"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={handleChangedTypeToCustom}
        >
          Nie znalazłem mojej maszyny na liście
        </div>
      )}

      <div className="caption color-black bold mb16 mt35">
        Obsługiwane usługi:
      </div>

      <div>
        <TechnologiesSelect
          value={selectedTechnologies}
          size="small"
          onChange={handleTechnologiesChange}
        />
      </div>
    </Modal>
  );

  function handleClose() {
    onClose();

    setMachine({});
    setMode("standard");
  }

  function handleConfirm() {
    if (checkIfTypeWithoutSpecificModels(machine.type._id)) {
      // NOTE: For machine types without models defined we want
      //       to create custom dummy machine (model) before save.
      onConfirm({
        ...machine,
        brand: {
          name: "",
        },
        model: {
          name: "",
        },
      });
    } else {
      onConfirm(machine);
    }
  }

  function handleTypeChange(typeId) {
    setMachine((prev) => {
      prev.type = types.find(({ _id }) => _id === typeId);

      if (mode === "standard") {
        prev.brand = undefined;
        prev.model = undefined;
      }
    });
  }

  function handleBrandChange(brandId) {
    setMachine((prev) => {
      prev.brand = brands.find(({ _id }) => _id === brandId);
      prev.model = undefined;
    });
  }

  function handleModelChange(modelId) {
    setMachine((prev) => {
      if (modelId) {
        prev.model = models.find(({ _id }) => _id === modelId);
        prev.type = types.find(({ _id }) => _id === prev.model.type_id);
        prev.brand = brands.find(({ _id }) => _id === prev.model.brand_id);
      } else {
        prev.model = undefined;
      }
    });
  }

  function handleCustomBrandChange(newValue) {
    setMachine((prev) => {
      prev.brand.name = newValue;
    });
  }

  function handleCustomModelChange(newValue) {
    setMachine((prev) => {
      prev.model.name = newValue;
    });
  }

  function handleTechnologiesChange(selectedTechnologies) {
    setSelectedTechnologies(selectedTechnologies);

    setMachine((prev) => {
      prev.technologies = selectedTechnologies;
    });
  }

  function handleChangedTypeToCustom() {
    setMode("custom");

    setMachine((prev) => {
      prev.brand = {
        name: "",
      };
      prev.model = {
        name: "",
      };
    });
  }
};

function sortByName(a, b) {
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

  return -1;
}

/**
 * For some machine types we don't specify a model
 */
function checkIfTypeWithoutSpecificModels(typeId: number) {
  if ([11, 12, 13, 14, 15, 16].includes(typeId)) return true;

  return false;
}
