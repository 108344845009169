import { useEffect, useState } from "react";
import { calcAvgRating } from "@services/review.helpers";
import { getOfferApi, getOffersApi, getUrgentOffersApi } from "./offer.api";

export function useOffer(offerId: string) {
  const [offer, setOffer] = useState();
  const [order, setOrder] = useState();

  useEffect(() => {
    if (!offerId) return;

    (async () => {
      const { offer, order } = await getOfferApi(offerId);

      setOffer(offer);
      setOrder(order);
    })();
  }, [offerId]);

  return { offer, order };
}

export function useOffers(sort: Set<string>) {
  const [offers, setOffers] = useState();

  useEffect(() => {
    (async () => {
      const offers = await getOffersApi(sort);

      setOffers(offers);
    })();
  }, [sort]);

  return offers;
}

export function useUrgentOffers() {
  const [offers, setOffers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const offers = await getUrgentOffersApi();

      setOffers(
        offers.map((offer) => {
          return {
            ...offer,
            owner_company: {
              ...offer.owner_company,
              avgRating: calcAvgRating(offer.owner_company.reviews),
            },
          };
        })
      );
      setIsLoading(false);
    })();
  }, []);

  return [offers, isLoading];
}
