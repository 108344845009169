import axios from "axios";
import { handleApiError } from "@helpers/utils";

const apiUrl = "/api/admin/settings";

export async function fetchOfferedServices(companyName: string) {
  try {
    const response = await axios.get(apiUrl + "/service/" + companyName);

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (error) {
    return handleApiError(error);
  }
}

export async function saveOfferedServices(
  companyName: string,
  serviceIds: number[]
) {
  try {
    const response = await axios.put(apiUrl + "/service/" + companyName, {
      serviceIds,
    });

    return {
      isSuccess: response.status === 204,
    };
  } catch (error) {
    return handleApiError(error);
  }
}
