import { logOutApi } from "@services/auth.api";
import { Button } from "@components/Button";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import avatarLargeImg from "./img/avatar_large.svg";
import buildingGrayImg from "./img/building_gray.svg";
import powerImg from "./img/power.svg";
import cs from "./UserDetails.module.scss";

export const UserDetails = ({ user }) => (
  <div className={cs.UserDetails}>
    <div className={cs.main}>
      <img src={avatarLargeImg} alt="" className="mb16" />

      <div className="body bold mb2">{user.name}</div>
      <div className="caption mb16">{user.email}</div>

      <div className="caption d-flex align-center">
        <img src={buildingGrayImg} alt="" className="mr8" />
        <span>{user.organization_name}</span>
      </div>
    </div>
    <div className={cs.footer}>
      <Button
        type="primary"
        size="medium"
        className={cs.logOutBtn}
        onClick={logOutApi}
      >
        <img src={powerImg} alt="" />
        <span>Wyloguj się</span>
      </Button>

      <PrivacyPolicy className="small text-center mt16" />

      <div className="small text-center mt8" style={{ color: "#636e72" }}>
        Kontakt: <a href="tel:+48 81 448 77 88">+48 81 448 77 88</a> |{" "}
        <a href="mailto:support@4dustry.com">support@4dustry.com</a>
      </div>
    </div>
  </div>
);
