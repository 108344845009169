import { useStore } from "@store";

export function getTechnologiesByIds(technologyIds: number[]) {
  const availableTechnologies = useStore.getState().technologies2;

  return availableTechnologies.filter(({ _id }) => technologyIds.includes(_id));
}

export function getTechnologyNames(technologyIds: number[]) {
  const services = getTechnologiesByIds(technologyIds);

  return sortTechnologiesByName(services).map(({ name }) => name);
}

///

function sortTechnologiesByName(technologies: any[]) {
  return technologies.sort((a, b) => {
    if (a.name > b.name) return 1;

    return -1;
  });
}
