import { Navigate } from "react-router-dom";
import { logOutApi } from "@services/auth.api";
import { store } from "@store";
import { Card } from "@components/Card";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Button } from "@components/Button";
import { Baner } from "../../components/Baner";
import cs from "./NoCompanyPage.module.scss";

export const NoCompanyPage = () => {
  const userHasCompany = store.hooks.useUserHasCompany();

  if (userHasCompany) {
    return <Navigate to="/" replace />;
  }

  return (
    <main className={`NoCompanyPage ${cs.NoCompanyPage}`}>
      <Baner type="invited" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <Card>
          <Card.Header className="h5">
            To konto nie jest powiązane z żadną organizacją
          </Card.Header>

          <Card.Body>
            <div className="body">
              Poproś administratora o ponowne wysłanie zaproszenia na powiązany
              adres email.
            </div>
          </Card.Body>

          <Card.Footer className="text-center">
            <Button
              type="primary"
              size="medium"
              className={cs.logOutBtn}
              onClick={logOutApi}
            >
              <span>Wyloguj się</span>
            </Button>
          </Card.Footer>
        </Card>

        <PrivacyPolicy />
      </section>
    </main>
  );
};
