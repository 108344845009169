import React from "react";
import { Link } from "react-router-dom";
import cs from "classnames";
import "./Button.scss";

export type BtnSize = "small" | "medium" | "large";

type Props = {
  type?: "primary" | "secondary" | "outline" | "link";
  href?: string;
  id?: string;
  btnType?: "submit" | "button" | "reset";
  className?: string;
  disabled?: boolean;
  size?: BtnSize;
  style?: React.CSSProperties;
  title?: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
};

export const Button = ({
  type,
  href,
  id,
  btnType = "button",
  children,
  className,
  disabled,
  size,
  style,
  title = "",
  onClick,
}: Props) => {
  const csRoot = cs("Button", className, {
    Button_primary: type === "primary",
    Button_secondary: type === "secondary",
    Button_outline: type === "outline",
    Button_small: size === "small",
    Button_medium: size === "medium",
    Button_large: size === "large",
    Button_link: type === "link",
  });

  if (href) {
    return (
      <Link to={href || ""} className={csRoot} id={id} style={style}>
        {children}
      </Link>
    );
  }

  return (
    <button
      type={btnType}
      className={csRoot}
      style={style}
      disabled={disabled}
      id={id}
      title={title}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
