import axios from "axios";

/**
 * @param nestedArrays
 *
 * [[1,[5,6]]], [3,4]] >> [1, 5, 6, 3, 4]
 */
export function flattenDeepUnique(nestedArrays: any[] = []) {
  const result = new Set();

  nestedArrays.forEach((el) => {
    if (Array.isArray(el)) {
      const nestedResult = flattenDeepUnique(el);

      nestedResult.forEach((x) => {
        result.add(x);
      });
    } else {
      result.add(el);
    }
  });

  return Array.from(result);
}

export function genTempId() {
  return `temp_${Date.now()}${Math.random().toFixed(6)}`;
}

export function getLower(first, second) {
  if (first > second) {
    return second;
  } else return first;
}

export function getGreater(first, second) {
  if (first < second) {
    return second;
  } else return first;
}

export function trim(text: string, count: number) {
  text = text ?? "";

  if (text.length > count) {
    return `${text.substr(0, count)}...`;
  }

  return text;
}

export function repeat(original, noOfTimes) {
  return Array(noOfTimes).fill(original);
}

export function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export async function downloadFile(fileUrl: string) {
  const response = await axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  const fileName = response.headers["content-disposition"].split('"')[1];
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function toFixedNumber(num, digits) {
  const pow = Math.pow(10, digits);

  return Math.round(num * pow) / pow;
}

export function sqMilimetersToSqMeters(sqMilimiters: number) {
  return sqMilimiters / (1000 * 1000);
}

export function qs(...params) {
  const queryString = params.join("&");

  return queryString.length > 0 ? "?" + queryString : "";
}

export function sortToQs(sort: Set<string>) {
  if (!sort || sort.size === 0) return "";

  const params = new URLSearchParams();

  sort.forEach((field) => params.append("sort[]", field));

  return params.toString();
}

export function filterToQs(filter?: Record<string, any>) {
  if (!filter) return "";

  const params = new URLSearchParams();

  params.append("filter", JSON.stringify(filter));

  return params.toString();
}

export function strToB64UrlSafe(str: string) {
  const b64str = btoa(unescape(encodeURIComponent(str)));

  return b64str.replace(/\+/g, "-").replace(/\//g, "_").replace(/\=+$/, "");
}

export async function dataUrlToFile(
  fileUrl: string,
  fileName: string,
  type = "image/png"
): Promise<File> {
  const res: Response = await fetch(fileUrl);

  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type });
}

export function byId(collection, id) {
  return collection.find(({ _id }) => _id === id);
}

export function replaceItem(collection, item) {
  return collection.map((currItem) => {
    if (item._id === currItem._id) {
      return item;
    }

    return currItem;
  });
}

export function validateEmail(email: string) {
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

export function containsAll(text: string, className: string): HTMLElement[] {
  let xpath;

  if (className) {
    xpath = `//*[contains(concat(" ", normalize-space(@class), " "), " ${className} ") and contains(.,'${text}')]`;
  } else {
    xpath = `//*[contains(text(),'${text}')]`;
  }

  const xPathResult = document.evaluate(
    xpath,
    document,
    null,
    XPathResult.ORDERED_NODE_ITERATOR_TYPE,
    null
  );

  const results = [];

  let el = null;
  while ((el = xPathResult.iterateNext())) {
    results.push(el);
  }

  return results as HTMLElement[];
}

export function getShortUsername(name: string) {
  const [firstName = "", lastName = ""] = name.split(" ");

  return firstName[0] + ". " + lastName;
}

export function checkIfAtLeastOneCommonElement(
  array1: any[] = [],
  array2: any[] = []
) {
  return array1.some((el) => array2.includes(el));
}

export function compact(array: any[]) {
  return array.filter((el) => el !== null && el !== undefined);
}

export function isStepFile(file: File) {
  const fileExt = file.name.split(".").pop();

  return fileExt === "step";
}

export function handleApiError(error: any) {
  console.error(error);

  if (axios.isAxiosError(error)) {
    error = error.response?.data?.error?.message;
  }

  return {
    isSuccess: false,
    error: error,
  };
}
