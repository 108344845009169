export function getMaterialName(material: any) {
  switch (material.categoryName) {
    case "metal":
      return [
        material.pnen_100271,
        material.pnen_100272,
        material.din,
        material.usa,
      ]
        .filter((m) => m)
        .join(" | ");
    case "wood":
      return material.name;
    case "plastic":
      return `${material.name} (${material.symbol})`;
    default:
      return "";
  }
}
