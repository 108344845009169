import classNames from "classnames";
import { Modal as AntdModal } from "antd";
import { Button } from "@components/Button";
import cs from "./Modal.module.scss";

export const Modal = ({
  visible = false,
  maskClosable = true,
  title,
  okText,
  cancelText,
  width,
  className,
  centered = false,
  children,
  footer,
  cancelButtonProps = {},
  okButtonProps = {},
  onClose,
  onOk,
}) => {
  return (
    <AntdModal
      wrapClassName={classNames(cs.wrapper, className)}
      title={title}
      visible={visible}
      width={width}
      maskClosable={maskClosable}
      centered={centered}
      footer={
        footer || (
          <Footer
            okText={okText}
            cancelText={cancelText}
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}
            onOk={onOk}
            onClose={onClose}
          />
        )
      }
      cancelText={cancelText}
      onCancel={onClose}
    >
      {children}
    </AntdModal>
  );
};

const Footer = ({
  okText,
  cancelText,
  okButtonProps,
  cancelButtonProps,
  onOk = () => {},
  onClose = () => {},
}) => {
  const cancelCs = classNames(cs.cancelBtn, cancelButtonProps.className);
  const okCs = classNames(
    "Button_primary Button_medium",
    okButtonProps.className
  );

  return (
    <div>
      {cancelText && (
        <Button {...cancelButtonProps} className={cancelCs} onClick={onClose}>
          {cancelText}
        </Button>
      )}

      {okText && (
        <Button {...okButtonProps} className={okCs} onClick={onOk}>
          {okText}
        </Button>
      )}
    </div>
  );
};
