import { useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Spin } from "antd";
import { useOfferedServices } from "../../services/settings.hooks";
import { ServicesSelect } from "@components/ServicesSelect";
import { saveOfferedServices } from "../../services/settings.api";
import cs from "./SettingsAdmin.module.scss";

export const SettingsAdmin = () => {
  const [selectedServices, setSelectedServices] = useState([]);

  const updateServicesTimer = useRef<any>(null);

  const params = useParams();
  const companyName = params.companyName as string;

  const [offeredServices, error, isLoading] = useOfferedServices(companyName);

  useEffect(() => {
    if (!offeredServices) return;

    setSelectedServices(offeredServices);
  }, [offeredServices]);

  if (isLoading) {
    return <Spin spinning size="large" className="flex-center" />;
  }

  if (error === "NOT_ADMIN") {
    return <Navigate to="/" replace />;
  }

  return (
    <main className={cs.OrderPage}>
      <header>
        <h1 className="h1 mb40">Firma: {companyName}</h1>
      </header>
      <section>
        <div className="h4">Oferowane usługi</div>

        <div>
          <ServicesSelect
            value={selectedServices}
            size="small"
            onChange={handleServicesChange}
          />
        </div>
      </section>
    </main>
  );

  async function handleServicesChange(serviceIds: number[]) {
    setSelectedServices(serviceIds);

    if (updateServicesTimer.current) {
      clearTimeout(updateServicesTimer.current);
    }

    updateServicesTimer.current = setTimeout(async () => {
      await saveOfferedServices(companyName, serviceIds);
    }, 1000);
  }
};
