import axios from "axios";
import { handleApiError } from "@helpers/utils";
import { ApiResponse } from "@customTypes/api";
import { _enrichElementInformation } from "../../Customer/services/cad/cad.api";

const apiUrl = "/api/public-order";

export async function createPublicOrderApi(order: any): Promise<ApiResponse> {
  try {
    const response = await axios.post(apiUrl, order, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      isSuccess: response.status === 201,
    };
  } catch (errorWrapper: any) {
    return handleApiError(errorWrapper);
  }
}

export async function analyzeCADFilePublicApi(cadFile: File) {
  const data = new FormData();
  data.append("cadFile", cadFile);

  try {
    const response = await axios.post(apiUrl + "/cad", data);

    return {
      isSuccess: true,
      data: _enrichElementInformation(response.data),
    };
  } catch (error: any) {
    // INFO: Proxy "Payload Too Large"
    if (error.response.status === 413) {
      return {
        isSuccess: false,
        error: "LIMIT_FILE_SIZE",
      };
    }

    return handleApiError(error);
  }
}
