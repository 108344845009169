import { ReactNode } from "react";
import classNames from "classnames";
import { Checkbox as Checkbox_antd } from "antd";
import cs from "./Checkbox.module.scss";

type Props = {
  checked: boolean;
  label?: ReactNode;
  style?: any;
  disabled?: boolean;
  required?: boolean;
  size?: "default" | "small";
  onChange?: (e: any) => void; // TODO: Should just return boolean
};

export const Checkbox = ({
  checked,
  label,
  style,
  disabled = false,
  required = false,
  size = "default",
  onChange,
}: Props) => {
  const rootCs = classNames("Checkbox", cs.Checkbox, {
    [cs.default]: size === "default",
    [cs.small]: size === "small",
  });

  return (
    <div className={rootCs}>
      <Checkbox_antd
        style={style}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      >
        {label}
      </Checkbox_antd>

      <input
        type="checkbox"
        checked={checked}
        required={required}
        className={cs.validator}
      />
    </div>
  );
};
