import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getMaterialName } from "@services/material.helpers";
import { store } from "@store";

export const MaterialName = ({ materialId, typeId }) => {
  const material = store.hooks.useMaterial(materialId);
  const type = store.hooks.useMaterialType(typeId);

  if (material) {
    const materialName = getMaterialName(material);

    return (
      <div>
        <span>{materialName}</span>

        <Tooltip title={material.type_name} placement="top">
          <InfoCircleOutlined
            style={{ cursor: "help", marginLeft: 5 }}
            width={10}
          />
        </Tooltip>
      </div>
    );
  }

  if (type) {
    return (
      <div>
        <span>{type.name}</span>
      </div>
    );
  }

  return null;
};
