import { getDaysFromToday, toLocaleDate } from "@helpers/dateUtils";
import { getOrderStatusNameById } from "@helpers/getOrderStatusNameById";
import { getOrderStatusColorById } from "@helpers/getOrderStatusColorById";
import cs from "./StatusInfoPanel.module.scss";

export const StatusInfoPanel = ({ order }) => {
  const offersRequringApproval = order.offers.filter((offer) => {
    return offer.status === 6;
  });

  const activeOffers = order.offers.filter((offer) => {
    return offer.status !== 6;
  });

  return (
    <section
      className={`StatusInfoPanel ${cs.StatusInfoPanel}`}
      style={{ backgroundColor: getOrderStatusColorById(order.status) }}
    >
      <div className={cs.col}>
        <div className="caption mb4">Status</div>
        <div className="h5">{getOrderStatusNameById(order.status)}</div>
      </div>

      <div className={cs.col}>
        <div className="caption mb4">Ilość ofert:</div>
        <div className="h5">
          <span>{activeOffers.length}</span>

          {offersRequringApproval.length > 0 && (
            <span> ({offersRequringApproval.length})</span>
          )}
        </div>
      </div>

      <div className={cs.col}>
        <div className="caption mb4">Najniższa oferta:</div>
        <div className="h5">
          {order.lowest_offer ? `${order.lowest_offer} PLN` : "-"}
        </div>
      </div>

      <div className={cs.col}>
        <div className="caption mb4">Termin składania ofert:</div>
        <div className="h5">{toLocaleDate(order.bid_deadline)}</div>
      </div>

      <div className={cs.col}>
        <div className="caption mb4">Wymagany termin realizacji:</div>
        <div className="h5">{toLocaleDate(order.done_deadline)}</div>
      </div>

      <div className={cs.col}>
        {order.status === 1 && (
          <>
            <div className="caption mb4">Do końca</div>
            <div className="h5">
              {getDaysFromToday(order.done_deadline)} dni
            </div>
          </>
        )}
      </div>
    </section>
  );
};
