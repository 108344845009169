import { useEffect, useState } from "react";
import { store } from "@store";
import { addChatMessage, ChatMessage } from "@services/chat.api";
import { useChatMessagesForOrder } from "@services/chat.hooks";
import { formatTime, toLocaleDate } from "@helpers/dateUtils";
import { Card } from "@components/Card";
import { Textarea } from "@components/Textarea";
import { Button } from "@components/Button";
import cs from "./Chat.module.scss";

function groupByProvider(messages: ChatMessage[]) {
  const grouped: any[] = []; // { providerId: '', providerName: '', messages: [] }

  messages.forEach((msg) => {
    const existingGroup = grouped.find(
      (group) => group.providerId === msg.provider_id
    );

    if (!existingGroup) {
      const providerName = messages.find(
        (m) => m.author_id === msg.provider_id
      )?.author_name;

      grouped.push({
        providerId: msg.provider_id,
        providerName,
        messages: [msg],
      });
    } else {
      existingGroup.messages.push(msg);
    }
  });

  return grouped;
}

export const Chat = ({ orderId }: { orderId: string }) => {
  const [activeProviderId, setActiveProviderId] = useState<string>("");
  const [message, setMessage] = useState("");

  const [messages, setMessages] = useChatMessagesForOrder(orderId);
  const currentUser = store.hooks.useUser();

  useEffect(() => {
    if (messages.length > 0 && !activeProviderId) {
      setActiveProviderId(messages[0].provider_id);
    }
  }, [messages]);

  const groupedMessages = groupByProvider(messages);
  const messagesByActiveProvider =
    groupedMessages.find(({ providerId }) => providerId === activeProviderId)
      ?.messages || [];

  return (
    <Card className={cs.Chat}>
      <Card.Header className="h5">Wiadomości od usługodawców</Card.Header>
      <Card.Body className="body">
        {messages.length === 0 && (
          <div className="caption">Brak wiadomości.</div>
        )}

        <div className={cs.layout}>
          <section
            style={{ display: "flex", flexDirection: "column", gap: "4px" }}
          >
            {groupedMessages.map((g) => (
              <div
                key={g.providerId}
                className={`${cs.Provider} ${
                  g.providerId === activeProviderId ? cs.Provider__active : ""
                }`}
                onClick={() => handleChangeProvider(g.providerId)}
              >
                {g.providerName}
              </div>
            ))}
          </section>

          {activeProviderId && (
            <section>
              <div>
                {messagesByActiveProvider.map((message) => (
                  <Message key={message._id} message={message} />
                ))}

                <div className="text-right">
                  <Textarea
                    value={message}
                    placeholder="Odpisz na wiadomość"
                    onChange={setMessage}
                  />

                  <Button
                    type="secondary"
                    size="small"
                    className="mt12"
                    onClick={handleAddChatMessage}
                  >
                    Wyślij wiadomość
                  </Button>
                </div>
              </div>
            </section>
          )}
        </div>
      </Card.Body>
    </Card>
  );

  async function handleAddChatMessage() {
    if (!message || !activeProviderId) return;

    const { isSuccess, newMessageId } = await addChatMessage({
      order_id: orderId,
      message,
      provider_id: activeProviderId,
    });

    if (isSuccess && newMessageId) {
      setMessage("");

      setMessages([
        ...messages,
        {
          _id: newMessageId,
          message,
          author_id: currentUser._id,
          author_name: currentUser.name,
          provider_id: activeProviderId,
          created_at: new Date(),
          order_id: orderId,
        },
      ]);
    }
  }

  function handleChangeProvider(providerId: string) {
    setActiveProviderId(providerId);
  }
};

const Message = ({ message }: { message: ChatMessage }) => (
  <div className={cs.Message}>
    <div>
      <span className="body semi-bold">{message.author_name}</span>
      <span>
        , {formatTime(message.created_at)}, {toLocaleDate(message.created_at)}
      </span>
    </div>
    <div>{message.message}</div>
  </div>
);
