import { getOfferStatusColor } from "@helpers/getOfferStatusColor";
import { getOfferMainStatusText } from "@helpers/getOfferMainStatusText";
import { OFFER_STATUSES } from "../constants/OFFER_STATUSES";
import { Chip } from "@components/Chip";
import { ChipSize } from "@components/Chip/Chip";

export function getOfferMainStatusTag(status: OFFER_STATUSES, size: ChipSize) {
  return (
    <Chip color={getOfferStatusColor(status)} size={size}>
      {getOfferMainStatusText(status)}
    </Chip>
  );
}
