import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { getTechnologyIdsByService } from "@services/technology.helpers";
import { checkIfAtLeastOneCommonElement } from "@helpers/utils";
import { useStore } from "@store";
import { Modal } from "@components/Modal";
import { Select } from "@components/Select";

type Resolver = (selectedMachineIds: number[] | null) => void;

export const SelectMachinesModal = forwardRef(({ serviceId }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alreadySelectedMachines, setAlreadySelectedMachines] = useState([]);
  const [selectedMachineIds, setSelectedMachineIds] = useState<number[]>([]);

  const resolver = useRef<Resolver>();

  const machines = useStore((state) => state.machinePark);
  const serviceRelatedTechnologies = getTechnologyIdsByService(serviceId);

  const availableMachines = machines.filter((machine) =>
    checkIfAtLeastOneCommonElement(
      machine.technology_ids,
      serviceRelatedTechnologies
    )
  );

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  return (
    <div className="SelectServiceModal">
      <Modal
        title="Wybierz maszyny"
        visible={isOpen}
        width={440}
        onOk={handleConfirm}
        okButtonProps={{
          disabled: selectedMachineIds.length === 0,
        }}
        maskClosable={false}
        okText="Zapisz"
        onClose={handleCancel}
      >
        <Select
          size="small"
          value={selectedMachineIds}
          mode="multiple"
          options={availableMachines.map(({ _id, model_name }) => ({
            label: model_name,
            value: _id,
            disabled: alreadySelectedMachines.some((id) => id === _id),
          }))}
          showSearch
          style={{ width: 310 }}
          onChange={setSelectedMachineIds}
        />
      </Modal>
    </div>
  );

  function openModal(alreadySelectedMachines) {
    setAlreadySelectedMachines([...alreadySelectedMachines]);
    setIsOpen(true);

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  }

  function handleConfirm() {
    resolver.current?.(selectedMachineIds);

    handleClose();
  }

  function handleCancel() {
    resolver.current?.(null);

    handleClose();
  }

  function handleClose() {
    setIsOpen(false);
    clear();
  }

  function clear() {
    setSelectedMachineIds([]);
  }
});
