import axios from "axios";
import { sortToQs } from "@helpers/utils";
import { prepareOrder } from "./order.helpers";

const apiUrl = "";

export async function cancelOfferApi(offerId: string) {
  try {
    const response = await axios.put(apiUrl + `/api/offers/${offerId}/cancel`);

    return {
      isSuccess: response.status === 200,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function changeNotificationApi(offerId, notifications) {
  try {
    const response = await axios.patch(apiUrl + `/api/offers/${offerId}`, {
      notifications,
    });

    return {
      isSuccess: response.status === 200,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function createOfferApi(offer) {
  try {
    const response = await axios.post(apiUrl + "/api/offers", offer);

    return {
      isSuccess: response.status === 200,
      newOfferId: response.data.newOfferId,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data?.error?.message,
    };
  }
}

export async function confirmOfferApi(offerId) {
  try {
    const response = await axios.put(apiUrl + `/api/offers/${offerId}/confirm`);

    return {
      isSuccess: response.status === 200,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function updateOfferApi(offerId, saveObj) {
  try {
    const response = await axios.put(
      apiUrl + `/api/offers/${offerId}`,
      saveObj
    );

    return {
      isSuccess: response.status === 200,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function getOfferApi(offerId: string) {
  const response = await axios.get(apiUrl + "/api/offers/" + offerId);

  const { offer, order } = response.data;

  return {
    offer,
    order: prepareOrder(order),
  };
}

export async function getUrgentOffersApi() {
  const response = await axios.get(apiUrl + "/api/offers/urgent");

  return response.data;
}

export async function getOffersApi(sort: Set<string>) {
  const response = await axios.get(apiUrl + "/api/offers/" + sortToQs(sort));

  return response.data;
}
