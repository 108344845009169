import { useEffect, useState } from "react";
import { getMaterialName } from "@services/material.helpers";
import { store } from "@store";
import cs from "@components/MaterialSelect/MaterialSelect.module.scss";

export const useOptions = () => {
  const [options, setOptions] = useState([]);
  const [keys, setKeys] = useState(new Set<string>());

  const materials = store.hooks.useMaterials();
  const types = store.hooks.useMaterialTypes();

  useEffect(() => {
    const _keys = new Set<string>();

    const metals = types
      .filter((m) => m.categoryName === "metal")
      .map((type) => {
        _keys.add(`metal;;${type.name}`);

        return {
          typeId: type._id,
          title: (
            <span className={cs.selectable}>
              <span>{type.name}</span>{" "}
              <span style={{ fontSize: "11px", color: "gray" }}>
                ({type.parent_name})
              </span>
            </span>
          ),
          value: type.name,
          children: materials
            .filter((m) => m.type === type._id)
            .map((m) => {
              const materialName = getMaterialName(m);

              _keys.add(`metal;;${type.name};;${materialName}`);

              return {
                materialId: m._id,
                title: (
                  <div className={cs.selectable}>
                    <div style={{ fontWeight: "600" }}>{materialName}</div>

                    <div style={{ fontSize: "10px", color: "gray" }}>
                      (
                      {[
                        m.pnen_100271 ? "PN-EN 10027-1" : null,
                        m.pnen_100272 ? "PN-EN 10027-2" : null,
                        m.din ? "DIN" : null,
                        m.usa ? "USA" : null,
                      ]
                        .filter((m) => m)
                        .join(" | ")}
                      )
                    </div>
                  </div>
                ),
                value: materialName,
              };
            }),
        };
      });

    const woods = types
      .filter((m) => m.categoryName === "wood")
      .map((type) => {
        _keys.add(`wood;;${type.name}`);

        return {
          typeId: type._id,
          title: (
            <span className={cs.selectable}>
              <span>{type.name}</span>{" "}
              <span style={{ fontSize: "11px", color: "gray" }}>
                ({type.parent_name})
              </span>
            </span>
          ),
          value: type.name,
          children: materials
            .filter((m) => m.type === type._id && type.name !== m.name)
            .map((m) => {
              const materialName = getMaterialName(m);

              _keys.add(`wood;;${type.name};;${materialName}`);

              return {
                materialId: m._id,
                title: (
                  <div className={cs.selectable}>
                    <div style={{ fontWeight: "600" }}>{materialName}</div>
                  </div>
                ),
                value: materialName,
              };
            }),
        };
      });

    const plastics = types
      .filter((m) => m.categoryName === "plastic")
      .map((type) => {
        _keys.add(`plastic;;${type.name}`);

        return {
          typeId: type._id,
          title: (
            <span className={cs.selectable}>
              <span>{type.name}</span>{" "}
              <span style={{ fontSize: "11px", color: "gray" }}>
                ({type.parent_name})
              </span>
            </span>
          ),
          value: type.name,
          children: materials
            .filter((m) => m.type === type._id && type.name !== m.name)
            .map((m) => {
              const materialName = getMaterialName(m);

              _keys.add(`plastic;;${type.name};;${materialName}`);

              return {
                materialId: m._id,
                title: (
                  <div className={cs.selectable}>
                    <div style={{ fontWeight: "600" }}>{materialName}</div>
                  </div>
                ),
                value: materialName,
              };
            }),
        };
      });

    const opts = [
      {
        title: "Metale",
        value: "metal",
        selectable: false,
        children: metals,
      },
      {
        title: "Drewna",
        value: "wood",
        selectable: false,
        children: woods,
      },
      {
        title: "Plastik",
        value: "plastic",
        selectable: false,
        children: plastics,
      },
    ];

    setOptions(opts);
    setKeys(_keys);
  }, [materials, types]);

  return [options, keys];
};
