import { useEffect, useState } from "react";
import { Counter } from "@components/Counter";
import { Modal } from "@components/Modal";

export const TotalNoOfCopiesModal = ({
  isOpen,
  totalNumber,
  onClose,
  onConfirm,
}) => {
  const [totalNoCopy, setTotalNoCopy] = useState();

  useEffect(() => {
    if (isOpen) {
      setTotalNoCopy(totalNumber);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Zmień łączną ilość sztuk"
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okText="Zapisz zmiany"
      width={480}
      onOk={() => {
        onConfirm("total_amount", totalNoCopy);
        onClose();
      }}
      onClose={onClose}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="body mr12">Ilość sztuk:</span>
        <Counter value={totalNoCopy} onChange={setTotalNoCopy} />
      </div>
    </Modal>
  );
};
