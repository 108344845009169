import { OFFER_STATUSES } from "../constants/OFFER_STATUSES";

export function getOfferStatusColor(status: OFFER_STATUSES) {
  if (
    status === OFFER_STATUSES.WON ||
    status === OFFER_STATUSES.LOST ||
    status === OFFER_STATUSES.CANCELLED
  ) {
    return "#6C5CE7";
  }

  if (status === OFFER_STATUSES.ONGOING) {
    return "#00B894";
  }

  if (status === OFFER_STATUSES.ORDER_EDITED) {
    return "#636E72";
  }

  if (status === OFFER_STATUSES.ORDER_CANCELLED) {
    return "#D63031";
  }

  throw new Error(`Offer status '${status}' unrecognized.`);
}
