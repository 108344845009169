import { OFFER_STATUSES } from "../constants/OFFER_STATUSES";
import { Chip } from "@components/Chip";
import { ChipSize } from "@components/Chip/Chip";

export function getOfferAddStatusTag(
  status: OFFER_STATUSES,
  size: ChipSize,
  className?: string
) {
  if (status === OFFER_STATUSES.ORDER_EDITED) {
    return (
      <Chip color="red" size={size} className={className}>
        Wymaga potwierdzenia
      </Chip>
    );
  }

  if (status === OFFER_STATUSES.WON) {
    return (
      <Chip color="green" size={size} className={className}>
        Twoja oferta wygrała
      </Chip>
    );
  }

  if (status === OFFER_STATUSES.CANCELLED) {
    return (
      <Chip color="dark-gray" size={size} className={className}>
        Oferta wycofana
      </Chip>
    );
  }

  return null;
}
