import { ReactNode } from "react";
import { notification } from "antd";
import { downloadFile } from "@helpers/utils";
import { WithDownloadLogic } from "@components/DownloadOrderDocs/WithDownloadLogic";

type Props = {
  order: any;
  file: any;
  className?: string;
  children?: ReactNode;
};

export const OrderLink = ({ order, file, className = "", children }: Props) => {
  return (
    <WithDownloadLogic
      order={order}
      download={() => {
        handleFileDownload(order._id, file);
      }}
    >
      {(handleClick: any) => (
        <a className={className} onClick={handleClick}>
          {children || file.name}
        </a>
      )}
    </WithDownloadLogic>
  );

  async function handleFileDownload(orderId: string, file: any) {
    try {
      await downloadFile(`/api/orders/${orderId}/file/${file._id}/download`);

      return true;
    } catch (e) {
      notification.error({
        message: `Coś poszło nie tak. Spróbuj ponownie później.`,
        placement: "topRight",
      });

      return false;
    }
  }
};
