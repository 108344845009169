import axios from "axios";
import { handleApiError } from "@helpers/utils";

const apiUrl = "/api/admin/order";

export async function getOrderApi(orderId: string) {
  try {
    const response = await axios.get(apiUrl + `/${orderId}`);

    return {
      isSuccess: true,
      data: response.data,
    };
  } catch (error) {
    return handleApiError(error);
  }
}

export async function approveOrderApi(orderId: string) {
  try {
    await axios.post(apiUrl + `/${orderId}/approve`);

    return {
      isSuccess: true,
    };
  } catch (error) {
    return handleApiError(error);
  }
}
