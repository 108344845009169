import axios, { AxiosError } from "axios";

const apiUrl = "/api/chat/";

export async function getChatMessagesForOrder(orderId: string) {
  try {
    const response = await axios.get(apiUrl, { params: { orderId } });

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
      error: (errorWrapper as AxiosError)?.response?.data,
    };
  }
}

export interface ChatMessage {
  _id: string;
  order_id: string;
  provider_id: string;
  message: string;
  author_id: string;
  author_name: string;
  created_at: Date;
}

export async function addChatMessage(
  chatMessage: Pick<ChatMessage, "message" | "order_id" | "provider_id">
) {
  try {
    const response = await axios.post(apiUrl, chatMessage);

    return {
      isSuccess: response.status === 201,
      newMessageId: response.data,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data?.error?.message,
    };
  }
}
