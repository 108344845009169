import { Drilling } from "./Drilling";
import { CountersinkHoles } from "./CountersinkHoles";
import { CounterboreHoles } from "./CounterboreHoles";
import cs from "./AdditionalProcessing.module.scss";

export const AdditionalWoodProcessing = ({
  services,
  element,
  readOnly,
  onChange,
}) => {
  const params = element.parameters;

  return (
    <div className="AdditionalProcessing">
      <div className="h4 mb24">Dodatkowa obróbka drewna</div>

      <div className={cs.services}>
        {services.map((service) => {
          switch (service._id) {
            case 133:
              if (
                readOnly &&
                (!params.holes?.drilledHoles ||
                  params.holes?.drilledHoles.length === 0)
              ) {
                return null;
              }

              return (
                <Drilling
                  holes={params.holes?.drilledHoles}
                  readOnly={readOnly}
                  onChange={onChange}
                />
              );
            case 135:
              return (
                <CountersinkHoles
                  holes={params.holes?.counter_sink_holes}
                  readOnly={readOnly}
                  onChange={onChange}
                />
              );
            case 136:
              return (
                <CounterboreHoles
                  holes={params.holes?.counter_bore_holes}
                  readOnly={readOnly}
                  onChange={onChange}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};
