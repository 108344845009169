import { CSSProperties, HTMLInputTypeAttribute } from "react";
import classNames from "classnames";
import cs from "./Input.module.scss";

type Props = {
  value?: string | number;
  label?: string;
  id?: string;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  placeholder?: string;
  name?: string;
  pattern?: string;
  maxLength?: number;
  style?: CSSProperties;
  type?: HTMLInputTypeAttribute;
  size?: "small" | "default" | "large";
  autoComplete?: string;
  onChange: (
    value: string | number | undefined,
    name: string | undefined,
    e?: any | undefined
  ) => void;
};

export const Input = ({
  value,
  label,
  id,
  className,
  type = "text",
  readOnly = false,
  required = false,
  placeholder,
  style,
  size = "default",
  autoComplete = "",
  name,
  pattern,
  maxLength,
  onChange,
}: Props) => {
  const rootCs = classNames("Input", cs.Input, className, {
    [cs.default]: size === "default",
    [cs.large]: size === "large",
    [cs.small]: size === "small",
  });

  return (
    <div className={rootCs}>
      {label && <label className={cs.label}>{label}</label>}

      <input
        id={id}
        value={value}
        type={type}
        readOnly={readOnly}
        placeholder={placeholder}
        style={style}
        required={required}
        autoComplete={autoComplete}
        pattern={pattern}
        maxLength={maxLength}
        onChange={(e) => onChange(e.target.value, name, e)}
      />
    </div>
  );
};
