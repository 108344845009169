import { getShortUsername } from "@helpers/utils";
import { OrderStatus } from "@components/OrderStatus";
import { Chip } from "@components/Chip";

export const columns = (isAdmin) => {
  const cols = [
    {
      title: "Nr. zlecenia",
      field: "seq_no",
      width: 122,
    },
    {
      title: "Opis",
      field: "desc",
    },
    {
      title: "Status",
      field: "status",
      width: 126,
      sortable: true,
      render: (value: any) => <OrderStatus status={value} />,
    },
    {
      title: "Termin ofertowania",
      field: "bid_deadline",
      type: "date",
      width: 126,
      sortable: true,
    },
    {
      title: "Termin realizacji",
      field: "done_deadline",
      type: "date",
      width: 120,
      sortable: true,
    },
    {
      title: "Ilość ofert",
      field: "bids_amount",
      render: (bidsAmount: any) => {
        if (bidsAmount === 0) {
          return <span>brak</span>;
        }

        if (bidsAmount === 1) {
          return (
            <Chip color="orange" size="caption">
              1 oferta
            </Chip>
          );
        }

        if (bidsAmount < 5) {
          return (
            <Chip color="orange" size="caption">
              {bidsAmount} oferty
            </Chip>
          );
        }

        return (
          <Chip color="orange" size="caption">
            {bidsAmount} ofert
          </Chip>
        );
      },
      width: 100,
    },
    {
      title: "Najniższa oferta",
      field: "lowest_offer",
      type: "currency",
      width: 145,
    },
  ];

  if (isAdmin) {
    cols.splice(3, 0, {
      title: "Zlecający",
      field: "owner_name",
      render(value) {
        return getShortUsername(value);
      },
    });
  }

  return cols;
};
