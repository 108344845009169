import { Link } from "react-router-dom";
import { store } from "@store";
import { WarningAlert } from "@components/Alert/WarningAlert";

type Props = {
  className?: string;
};

export const ActivateLicenseWarning = ({ className }: Props) => {
  const user = store.hooks.useUser();

  // NOTE: Show only to users without active license
  if (user.subscription) return null;

  return (
    <WarningAlert
      title="Aktywuj subskrypcję"
      text={
        <div>
          <p>
            Aby zobaczyć dane kontaktowe zleceniodawcy oraz uzyskać możliwość
            pobrania dokumentacji{" "}
            <Link to="/account/users" className="color-primary semi-bold">
              kliknij tutaj!
            </Link>
          </p>
          <p className="mt2"></p>
        </div>
      }
      className={className}
    />
  );
};
