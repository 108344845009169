import { useEffect } from "react";
import produce from "immer";
import { getServiceNameById } from "@services/technology.helpers";
import { InputNumber } from "@components/InputNumber";
import solidImg from "../img/solid.png";
import cs from "./Coating.module.scss";

export const Coating = ({ service, params, readOnly, onChange }) => {
  const coatings = params.coatings || [];
  const coatingName = getCoatingNameByServiceId(service._id);
  const serviceCoating = coatings.find(({ name }) =>
    name?.includes(coatingName)
  );

  const surfaceInputLabel = getSurfaceInputLabelByServiceId(service._id);

  useEffect(() => {
    if (!serviceCoating || serviceCoating.length === 0) {
      setTimeout(() => {
        onChange("coatings", [
          ...coatings,
          {
            name: coatingName,
            area: params.surface_area,
          },
        ]);
      });
    }
  }, [serviceCoating]);

  return (
    <div>
      <div className="h4 mb24">{getServiceNameById(service._id)}</div>

      <div className={cs.main}>
        <div className={cs.imgContainer}>
          <img src={solidImg} alt="" />
        </div>
        <div className={cs.inputs}>
          <div>
            <span>Długość</span>
            <InputNumber
              size="small"
              value={params.overall_dimension_x}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("overall_dimension_x", value)}
            />
            <span>mm</span>
          </div>
          <div>
            <span>Szerokość</span>
            <InputNumber
              size="small"
              value={params.overall_dimension_y}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("overall_dimension_y", value)}
            />
            <span>mm</span>
          </div>
          <div>
            <span>Wysokość</span>
            <InputNumber
              size="small"
              value={params.overall_dimension_z}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("overall_dimension_z", value)}
            />
            <span>mm</span>
          </div>
          <div>
            <span>{surfaceInputLabel}:</span>
            <InputNumber
              size="small"
              value={serviceCoating?.area}
              min={0.001}
              step={0.001}
              readOnly={readOnly}
              required
              onChange={handleAreaChanged}
            />
            <span>
              m<sup>2</sup>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  function handleAreaChanged(value: number) {
    const updatedCoatings = produce(coatings, (draft) => {
      const coating = draft.find((coating) => coating.name === coatingName);

      coating.area = value;
    });

    onChange("coatings", updatedCoatings);
  }
};

function getCoatingNameByServiceId(serviceId: number) {
  switch (serviceId) {
    case 44:
      return "Cynkowanie";
    case 45:
      return "Złocone";
    case 46:
      return "Anodowane";
    case 47:
      return "Czernione";
    case 48:
      return "Niklowanie";
    case 49:
      return "Miedziowane";
  }
}

function getSurfaceInputLabelByServiceId(serviceId: number) {
  switch (serviceId) {
    case 44:
      return "Pole powierzchni cynkowanej";
    case 45:
      return "Pole powierzchni złoconej";
    case 46:
      return "Pole powierzchni anodowanej";
    case 47:
      return "Pole powierzchni czernionej";
    case 48:
      return "Pole powierzchni niklowanej";
    case 49:
      return "Pole powierzchni miedziowanej";
  }
}
