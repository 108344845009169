import { CSSProperties, useEffect, useRef, useState } from "react";
import { InputNumber as InputNumber_antd } from "antd";
import classNames from "classnames";
import { toFixedNumber } from "@helpers/utils";
import cs from "./InputNumber.module.scss";

type Props = {
  min?: number;
  max?: number;
  value: number;
  className?: string;
  style?: CSSProperties;
  readOnly?: boolean;
  size?: "small" | "default" | "large";
  step?: number;
  required?: boolean;
  placeholder?: string;
  onChange?: (newValue: number) => void;
};

export const InputNumber = ({
  min,
  max,
  value,
  className,
  style,
  readOnly = false,
  size = "default",
  step = 0.01,
  required = false,
  placeholder,
  onChange,
}: Props) => {
  const [touched, setTouched] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useListenToSyntheticEvents(ref, setTouched);

  const rootCs = classNames("InputNumber", cs.InputNumber, className, {
    [cs.default]: size === "default",
    [cs.large]: size === "large",
    [cs.small]: size === "small",
    "control-invalid": touched && !readOnly && !checkValidity(),
  });

  return (
    <InputNumber_antd
      ref={ref}
      className={rootCs}
      min={min}
      max={max}
      value={value}
      style={style}
      readOnly={readOnly}
      step={step}
      required={required}
      parser={(stringVal?: string) =>
        stringVal && Number(stringVal.replaceAll(",", "."))
      }
      placeholder={placeholder}
      formatter={(value) => {
        if (value && step === 0.01) {
          return toFixedNumber(value, 2);
        } else if (value && step === 0.001) {
          return toFixedNumber(value, 3);
        }

        return value;
      }}
      onChange={onChange}
      onBlur={() => setTouched(true)}
      onBlurCapture={() => setTouched(true)}
    />
  );

  function checkValidity() {
    if (required && (value === null || value === undefined)) {
      return false;
    }

    return true;
  }
};

const useListenToSyntheticEvents = (ref, setTouched) => {
  useEffect(() => {
    const input = ref.current;
    const handler = () => {
      setTouched(true);
    };

    input.addEventListener("touched", handler);

    return () => {
      input.removeEventListener("touched", handler);
    };
  }, []);
};
