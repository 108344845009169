import { useState } from "react";
import { getNoOfUnitsText } from "@helpers/getNoOfUnitsText";
import { Btn } from "../../Customer/views/NewOrderPage/Elements/Element/Element";
import { TotalNoOfCopiesModal } from "../../Customer/views/NewOrderPage/Elements/Element/TotalNoOfCopiesModal";
import { Chip } from "@components/Chip";

export const TotalAmount = ({
  noOfSets,
  amount,
  total_amount,
  isEditable,
  onChange,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const editable = (
    <>
      <div className="caption">
        <span>
          {noOfSets} x {amount} ={" "}
        </span>
        <strong>{total_amount} szt. </strong>
        <span className="color-primary">
          <Btn onClick={() => setIsModalOpened(true)}>Zmień</Btn>
        </span>
      </div>

      <TotalNoOfCopiesModal
        isOpen={isModalOpened}
        totalNumber={total_amount}
        onConfirm={onChange}
        onClose={() => setIsModalOpened(false)}
      />
    </>
  );

  const readOnly = (
    <Chip color="orange" size="caption">
      {getNoOfUnitsText(total_amount)}
    </Chip>
  );

  return (
    <>
      <div className="caption mb4">Łączna ilość sztuk:</div>

      {isEditable ? editable : readOnly}
    </>
  );
};
