import { store, useStore } from "@store";

export function getParamDetails(machine) {
  const parameters = store.selectors.getParameters(useStore.getState());

  return machine.parameters.map((pp) => {
    const param = parameters.find(({ _id }) => _id === pp.parameter_id);

    return {
      ...param,
      ...pp,
    };
  });
}
