import { Button } from "@components/Button";
import cs from "./RegisterPage.module.scss";

export const CompanyTypeSelection = ({ type, onChange }) => (
  <div>
    <label
      style={{
        color: "#101212",
        fontSize: "13px",
        fontWeight: "700",
        lineHeight: "16px",
        marginBottom: "4px",
      }}
    >
      Jaki jest Twój cel
    </label>

    <div
      style={{
        display: "flex",
        position: "relative",
      }}
    >
      <Button
        type={type === 0 ? "primary" : "secondary"}
        size="medium"
        style={{
          fontWeight: 500,
          border: "1px solid rgba(0, 0, 0, 0.1)",
          flex: 1,
        }}
        onClick={() => {
          onChange(0, "type");
        }}
      >
        Chcę zlecić
        <br /> produkcję
      </Button>

      <Button
        type={type === 1 ? "primary" : "secondary"}
        size="medium"
        style={{
          fontWeight: 500,
          border: "1px solid rgba(0, 0, 0, 0.1)",
          flex: 1,
        }}
        onClick={() => {
          onChange(1, "type");
        }}
      >
        Oferuję usługi produkcyjne
      </Button>

      <input
        className={cs.hiddenValidator}
        required
        value={type !== undefined ? " " : ""}
      />
    </div>
  </div>
);
