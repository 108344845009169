import { getDaysFromToday } from "@helpers/dateUtils";
import { Chip, ChipSize } from "@components/Chip";
import alarmImg from "./img/alarm.svg";

type Props = {
  date: Date | string | number;
  size: ChipSize;
  className?: string;
  text?: string;
};

export const TillEndChips = ({
  date,
  size,
  text = "Do końca",
  className,
}: Props) => {
  let iconSize = 12;

  if (size === "caption") {
    iconSize = 16;
  }

  const noOfDays = getDaysFromToday(date);
  const daysText = noOfDays !== 1 ? "dni" : "dzień";

  return (
    <Chip color="gray" size={size} className={className}>
      <img src={alarmImg} width={iconSize} height={iconSize} />
      <span className="caption color-black">
        <span>{text}:&nbsp;</span>
        <strong>
          {getDaysFromToday(date)}
          {daysText}
        </strong>
      </span>
    </Chip>
  );
};
