import { RadioGroup } from "@components/Radio";
import { InputNumber } from "@components/InputNumber";
import deleteImg from "../../img/delete.svg";
import countersinkImg from "./img/countersink.png";
import countersinkThroughImg from "./img/countersink_through.png";
import cs from "./CountersinkHoles.module.scss";

export const Type = ({
  hole,
  index,
  readOnly,
  onTypeRemoved,
  onTypeChange,
}) => {
  return (
    <div className={cs.Type}>
      <div className="d-flex align-center justify-between mb16">
        <span className="bold">Typ stożka {index + 1}:</span>
        {!readOnly && (
          <img
            src={deleteImg}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => onTypeRemoved(hole.tempId)}
          />
        )}
      </div>

      <div className="d-flex">
        <div className="mr-auto">
          <div className="d-flex align-center mb16">
            <span className="mr24">Ilość</span>
            <InputNumber
              value={hole.count}
              style={{ width: "63px" }}
              size="small"
              min={1}
              step={1}
              readOnly={readOnly}
              required
              onChange={(value) => handleChange("count", value)}
            />
          </div>

          <div>
            <RadioGroup
              value={hole.through_hole}
              readOnly={readOnly}
              required
              onChange={(value) => {
                handleChange("through_hole", value);
              }}
            >
              <RadioGroup.Radio
                value={true}
                className="caption color-dark-gray"
              >
                Otwór przelotowy
              </RadioGroup.Radio>
              <RadioGroup.Radio
                value={false}
                className="caption color-dark-gray mt4"
              >
                Otwór o okreslonej glebokosci
              </RadioGroup.Radio>
            </RadioGroup>
          </div>
        </div>

        <div className="ml-auto mr75">
          {hole.through_hole ? (
            <HoleThroughImg
              hole={hole}
              readOnly={readOnly}
              onChange={handleChange}
            />
          ) : (
            <HoleImg hole={hole} readOnly={readOnly} onChange={handleChange} />
          )}
        </div>
      </div>
    </div>
  );

  function handleChange(property, value) {
    onTypeChange(hole.tempId, property, value);
  }
};

const HoleImg = ({ hole, readOnly, onChange }) => (
  <div className={cs.imgContainer}>
    <InputNumber
      value={hole.sink_depth}
      style={{
        top: "68px",
        left: "-50px",
      }}
      size="small"
      min={0.01}
      readOnly={readOnly}
      required
      onChange={(value) => onChange("sink_depth", value)}
    />

    <InputNumber
      value={hole.sink_outer_diameter}
      style={{
        top: "11px",
        left: "-1px",
      }}
      size="small"
      min={0.01}
      readOnly={readOnly}
      required
      onChange={(value) => onChange("sink_outer_diameter", value)}
    />

    <div className="d-flex align-center">
      <img src={countersinkImg} alt="" />
      <InputNumber
        value={hole.hole_depth}
        style={{
          top: "-2px",
          right: "10px",
        }}
        size="small"
        min={0.01}
        readOnly={readOnly}
        required
        onChange={(value) => onChange("hole_depth", value)}
      />
    </div>

    <InputNumber
      value={hole.hole_diameter}
      style={{
        bottom: "0px",
        left: "47px",
      }}
      size="small"
      min={0.01}
      readOnly={readOnly}
      required
      onChange={(value) => onChange("hole_diameter", value)}
    />
  </div>
);

const HoleThroughImg = ({ hole, readOnly, onChange }) => (
  <div className={cs.imgContainer}>
    <InputNumber
      value={hole.sink_depth}
      style={{
        top: "65px",
        left: "-43px",
      }}
      size="small"
      min={0.01}
      readOnly={readOnly}
      required
      onChange={(value) => onChange("sink_depth", value)}
    />

    <InputNumber
      value={hole.sink_outer_diameter}
      style={{
        top: "11px",
        left: "4px",
      }}
      size="small"
      min={0.01}
      readOnly={readOnly}
      required
      onChange={(value) => onChange("sink_outer_diameter", value)}
    />

    <div className="d-flex align-center">
      <img src={countersinkThroughImg} alt="" width={164} />
      <InputNumber
        value={hole.hole_depth}
        style={{
          top: "-4px",
          right: "7px",
        }}
        size="small"
        min={0.01}
        readOnly={readOnly}
        required
        onChange={(value) => onChange("hole_depth", value)}
      />
    </div>

    <InputNumber
      value={hole.hole_diameter}
      style={{
        bottom: "0px",
        left: "52px",
      }}
      size="small"
      min={0.01}
      readOnly={readOnly}
      required
      onChange={(value) => onChange("hole_diameter", value)}
    />
  </div>
);
