import { useState } from "react";
import { Card } from "@components/Card";
import { Select } from "@components/Select";
import { Button } from "@components/Button";
import { Chip } from "@components/Chip";
import cs from "./SubscriptionPage.module.scss";

const TEAM_SIZES = [
  {
    value: "1",
    label: "1 użytkownik",
  },
  {
    value: "2",
    label: "2 stanowiska",
  },
  {
    value: "3",
    label: "3 stanowiska",
  },
  {
    value: "4",
    label: "4 stanowiska",
  },
  {
    value: "5",
    label: "5 stanowisk",
  },
  {
    value: "999",
    label: "indywidualnie",
  },
];

const monthPricePerUnit = 300;
const yearPricePerUnit = 250;

export const SubscriptionPage = () => {
  const [teamSize, setTeamSize] = useState("1");

  const monthPrice = monthPricePerUnit * teamSize;
  const yearPrice = yearPricePerUnit * teamSize;
  const saving = (monthPrice - yearPrice) * 12;

  return (
    <main className={cs.SubscriptionPage}>
      <Card>
        <Card.Header className="h5">Plany:</Card.Header>
        <Card.Body className={cs.main_layout}>
          <section>
            <div>
              <Select
                label="Wybierz wielkość zespołu"
                value={teamSize}
                options={TEAM_SIZES}
                style={{ width: 190 }}
                onChange={setTeamSize}
              />
            </div>

            <div className={cs.plans}>
              {teamSize !== "999" && (
                <>
                  <div className="mt24">
                    <div className={cs.plan}>
                      <div className="h5">Plan miesięczny</div>
                      <div className="caption mt4">bez umowy na rok</div>
                      <div className="h3 mt16 color-black">
                        {monthPrice} zł / mies.
                      </div>
                      <div className="caption mt4">
                        {monthPrice * 12} zł rocznie
                      </div>
                      <div className="mt16">
                        <form action="/api/subscription/checkout" method="POST">
                          <input
                            type="hidden"
                            name="lookup_key"
                            value={`plan_${teamSize}_m`}
                          />

                          <Button type="primary" size="medium" btnType="submit">
                            Wykup plan
                          </Button>
                        </form>
                      </div>

                      <ul className="color-dark-gray mt32">
                        <li className="caption color-dark-gray">
                          Dedykowany opiekun klienta
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="mt24">
                    <div className={cs.plan}>
                      <div className="h5">Plan roczny</div>
                      <div className="caption mt4">płatność z góry za rok</div>
                      <div className="h3 mt16 color-black">
                        {yearPrice} zł / mies.
                      </div>
                      <div className="caption mt4">
                        <span>{yearPrice * 12} zł rocznie</span>

                        <Chip color="orange" size="small" className="ml4">
                          Oszczędzasz {saving} zł
                        </Chip>
                      </div>
                      <div className="mt16">
                        <form action="/api/subscription/checkout" method="POST">
                          <input
                            type="hidden"
                            name="lookup_key"
                            value={`plan_${teamSize}_y`}
                          />

                          <Button type="primary" size="medium" btnType="submit">
                            Wykup plan
                          </Button>
                        </form>
                      </div>

                      <ul className="color-dark-gray mt32">
                        <li className="caption color-dark-gray">
                          Dedykowany opiekun klienta
                        </li>
                        <li className="caption color-dark-gray">
                          Bezpłatna pomoc techniczna i wdrożeniowa do 2h w
                          miesiącu
                        </li>
                        <li className="caption color-dark-gray">
                          Bezpłatne dedykowane szkolenia i dostęp do webinarów
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}

              {teamSize === "999" && (
                <div className="mt24">
                  <div className={cs.plan}>
                    <div className="h5" style={{ fontSize: 22 }}>
                      Plan indywidualny
                    </div>
                    <div className="caption mt8">
                      najlepsze rozwiązanie dla dużych firm
                    </div>

                    <div className="mt30">
                      <a
                        href="https://www.4dustry.com/kontakt"
                        className="Button_secondary Button_medium d-inline-block"
                      >
                        Skontaktuj się z nami
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </Card.Body>
      </Card>
    </main>
  );
};
