import classNames from "classnames";
import { trim } from "@helpers/utils";
import { Checkbox } from "@components/Checkbox";
import { Counter } from "@components/Counter";
import { Button } from "@components/Button";
import { OrderElementFiles } from "@components/OrderElementFiles";
import { TechnologyNames } from "./TechnologyNames";
import { MaterialName } from "./MaterialName";
import { Btn } from "./Element";
import chevronDownImg from "./chevron_down.svg";
import cs from "./Element.module.scss";

export const ElementFull = ({
  element,
  noOfSets,
  isSelected,
  order,
  onShowDetails,
  onShowMaterial,
  onShowServices,
  onShowRemarks,
  onShowNoOfElementCopies,
  onSelectionChange,
  onChange,
}) => {
  const {
    material_id: materialId,
    material_type_id: materialTypeId,
    amount,
    total_amount,
    services,
    thumbnail,
    name,
    desc,
    remarks,
    file,
    relatedFiles = [],
  } = element;

  const files = [file, ...relatedFiles];

  const rootCs = classNames("Element", cs.Element);

  return (
    <div className={rootCs}>
      <div className={cs.main}>
        <div style={{ alignSelf: "center" }}>
          <Checkbox
            checked={isSelected}
            onChange={(e) =>
              onSelectionChange({
                selected: e.target.checked,
                _id: element._id,
              })
            }
          />
        </div>

        <div style={{ alignSelf: "center" }}>
          <img
            src={
              thumbnail ||
              (file instanceof File && URL.createObjectURL(file)) ||
              process.env.PUBLIC_URL + "/no_thumbnail_96x96.png"
            }
            alt={file.name}
            width={96}
            height={96}
            onError={(e) =>
              (e.target.src =
                process.env.PUBLIC_URL + "/no_thumbnail_96x96.png")
            }
          />
        </div>

        <div className="pr12">
          <div className="body bold mb4">{name}</div>
          <div className="caption">{desc}</div>
        </div>

        <div className="pr12">
          <div className="mb12">
            <div className="caption mb2">Materiał:</div>
            <div className="caption bold color-black">
              {materialId || materialTypeId ? (
                <MaterialName materialId={materialId} typeId={materialTypeId} />
              ) : (
                <>
                  <div className={`${cs.error} Element_error mb2`}>
                    Brak materiału
                  </div>
                  <Btn onClick={onShowMaterial}>Dodaj</Btn>
                </>
              )}
            </div>
          </div>

          <div>
            <div className="caption mb2">Usługi:</div>
            <div className="caption bold color-black">
              {services.length > 0 ? (
                <TechnologyNames serviceIds={services.map(({ _id }) => _id)} />
              ) : (
                <>
                  <div className={`${cs.error} Element_error mb2`}>
                    Brak usług
                  </div>
                  <Btn onClick={onShowServices}>Dodaj</Btn>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="pr12">
          <div className="mb12">
            <div className="caption mb2">Pliki dokumentacji:</div>
            <div className="caption bold color-black">
              <OrderElementFiles order={order} files={files} />
            </div>
          </div>

          <div>
            <div className="caption mb2">Uwagi do pliku:</div>
            <div>
              {remarks ? (
                <>
                  <p className="caption color-black mb2">{trim(remarks, 60)}</p>
                  <Btn onClick={onShowRemarks}>Edytuj</Btn>
                </>
              ) : (
                <Btn onClick={onShowRemarks}>Dodaj uwagi</Btn>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="mb24">
            <div className="caption mb4">Ilość szt. w komplecie:</div>
            <Counter
              value={amount}
              onChange={(newValue: number) => {
                onChange("amount", newValue);
              }}
            />
          </div>

          <div>
            <div className="caption mb4">Łączna ilość sztuk:</div>
            <div className="caption">
              <span>
                {noOfSets} x {amount} ={" "}
              </span>
              <strong>{total_amount} szt. </strong>
              <span className="color-primary">
                <Btn onClick={onShowNoOfElementCopies}>Zmień</Btn>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={cs.footer}>
        <Button type="link" onClick={onShowDetails}>
          <span className="mr8">Szczegóły elementu</span>
          <img src={chevronDownImg} />
        </Button>
      </div>
    </div>
  );
};
