import { useLocation, useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { useMyOrders, useMyUrgentOrders } from "../../services/order.hooks";
import { store } from "@store";
import { Button } from "@components/Button";
import { Table } from "@components/Table";
import { OrderCard } from "./OrderCard";
import { Actions } from "./Actions";
import { columns } from "./tableColumns";
import xSquareImg from "./img/x_square.svg";
import checkCircleImg from "./img/check_circle.svg";
import cs from "./MyOrdersPage.module.scss";

export const MyOrdersPage = () => {
  const [sort, sortByField] = Table.useSort();
  const [filters, setFilters] = useImmer({});

  const user = store.hooks.useUser();
  const [orders] = useMyOrders(sort, filters);
  const [urgentOrders] = useMyUrgentOrders();

  const location = useLocation();

  return (
    <main className="MyOrdersPage">
      <header className={cs.header}>
        <h1 className="h3">Moje zapytania</h1>

        <Button
          href="/order"
          size="medium"
          type="outline"
          className="hide-phone"
        >
          Utwórz zapytanie
        </Button>
      </header>

      {location.state?.wasCreated && <OrderCreatedAlert />}

      <section>
        <div className={cs.urgent}>
          {urgentOrders.map((order) => (
            <OrderCard key={order._id} order={order} />
          ))}
        </div>

        <Table
          data={orders}
          columns={columns(user.is_admin)}
          empty={{
            title: "Ta lista jest pusta",
            sub: "Utwórz nowe zapytanie lub spróbuj ponownie przefiltrować",
          }}
          location={(order) =>
            order.status !== 5 ? `/order/${order._id}` : `/draft/${order._id}`
          }
          sort={sort}
          actions={
            <Actions
              filters={filters}
              onChange={(field, value) => {
                setFilters((prev) => {
                  if (!value) {
                    delete prev[field];
                  } else {
                    prev[field] = value;
                  }
                });
              }}
            />
          }
          onSort={sortByField}
        />
      </section>
    </main>
  );
};

const OrderCreatedAlert = () => {
  const navigate = useNavigate();

  return (
    <div className={cs.OrderCreatedAlert}>
      <img src={checkCircleImg} alt="" />

      <div className="body bold ml8 mr-auto">
        Zapytanie ofertowe zostało utworzone
      </div>

      <img
        src={xSquareImg}
        alt=""
        onClick={() => {
          navigate("", { state: { wasCreated: null } });
        }}
      />
    </div>
  );
};
