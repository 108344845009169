import { getDaysFromToday, toLocaleDate } from "@helpers/dateUtils";
import { getOrderStatusNameById } from "@helpers/getOrderStatusNameById";
import { getOrderStatusColorById } from "@helpers/getOrderStatusColorById";
import cs from "./StatusInfoPanel.module.scss";

export const StatusInfoPanel = ({ order }) => {
  // TODO: kupa

  return (
    <section
      className={`StatusInfoPanel ${cs.StatusInfoPanel}`}
      style={{ backgroundColor: getOrderStatusColorById(order.status) }}
    >
      <div className={cs.col}>
        <div className="caption mb4">Status</div>
        <div className="h5">{getOrderStatusNameById(order.status)}</div>
      </div>

      {/*<div className={cs.col}>*/}
      {/*  <div className="caption mb4">Ilość ofert:</div>*/}
      {/*  <div className="h5">{order.offers_count}</div>*/}
      {/*</div>*/}

      <div className={cs.col}>
        <div className="caption mb4">Termin składania ofert:</div>
        <div className="h5">{toLocaleDate(order.bid_deadline)}</div>
      </div>

      <div className={cs.col}>
        <div className="caption mb4">Wymagany termin realizacji:</div>
        <div className="h5">{toLocaleDate(order.done_deadline)}</div>
      </div>

      <div className={cs.col}>
        {order.status === 1 && (
          <>
            <div className="caption mb4">Do końca</div>
            <div className="h5">
              {getDaysFromToday(order.done_deadline)} dni
            </div>
          </>
        )}
      </div>
    </section>
  );
};
