import { Navigate, useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { isAuthenticated } from "@services/auth.api";
import { RegisterPagePure } from "./RegisterPagePure";

export const RegisterPage = () => {
  const [form, setForm] = useImmer({});

  const navigate = useNavigate();
  const isUserAuthenticated = isAuthenticated();

  if (isUserAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <RegisterPagePure
      form={form}
      onChange={handleFormChanged}
      onSubmit={handleFormSubmited}
    />
  );

  function handleFormChanged(value: string, name: "email" | "password") {
    setForm((prev) => void (prev[name] = value));
  }

  async function handleFormSubmited(e) {
    e.preventDefault();

    navigate("/verification-phone", {
      state: form,
    });
  }
};
