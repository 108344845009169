import { ReactNode } from "react";
import cs from "./MyOffersPage.module.scss";

export const Page = ({ children }: { children: ReactNode }) => (
  <main className={cs.MyOffersPage}>
    <header className="mb32">
      <h1 className="h3">Moje oferty</h1>
    </header>

    {children}
  </main>
);
