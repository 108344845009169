import axios from "axios";

const apiUrl = "/api/subscription";

export async function getSubscriptionInfoApi() {
  try {
    const response = await axios.get(apiUrl);

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (e) {
    return {
      isSuccess: false,
    };
  }
}

export async function getInvoicesApi() {
  try {
    const response = await axios.get(apiUrl + "/invoice");

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (e) {
    return {
      isSuccess: false,
    };
  }
}

export async function cancelSubscriptionApi() {
  try {
    const response = await axios.delete(apiUrl);

    return {
      isSuccess: response.status === 204,
    };
  } catch (e) {
    return {
      isSuccess: false,
    };
  }
}

export async function changeLicenseStateApi(userId: string, state: boolean) {
  try {
    const response = await axios.put(apiUrl + "/user/" + userId, {
      state,
    });

    return {
      isSuccess: response.status === 204,
    };
  } catch (e: any) {
    console.error(e);

    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}
