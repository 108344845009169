import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { store } from "@store";
import { Modal } from "@components/Modal";
import { Select } from "@components/Select";

type Resolver = (selectedService: number | null) => void;

const supportedServices = [
  1, 3, 4, 19, 43, 44, 45, 46, 47, 48, 49, 53, 59, 123, 124, 125, 126, 127, 128,
  129, 131, 132, 133, 135, 136,
];

export const SelectServiceModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<number | null>(null);
  const [alreadySelectedServices, setAlreadySelectedServices] = useState<
    number[]
  >([]);

  const resolver = useRef<Resolver>();

  let services = store.hooks.useServices();

  services = services.filter((s) => supportedServices.includes(s._id));

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  return (
    <div className="SelectServiceModal">
      <Modal
        title="Dodaj usługę"
        visible={isOpen}
        width={420}
        okText="Zapisz zmiany"
        onOk={handleConfirm}
        okButtonProps={{
          disabled: !selectedService,
        }}
        onClose={handleCancel}
      >
        <Select
          size="small"
          value={selectedService}
          options={services.map(({ _id, name }) => ({
            label: name,
            value: _id,
            disabled: alreadySelectedServices.includes(_id),
          }))}
          showSearch
          onChange={setSelectedService}
        />
      </Modal>
    </div>
  );

  function openModal(alreadySelected: number[]) {
    setIsOpen(true);
    setAlreadySelectedServices(alreadySelected || []);

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  }

  function handleConfirm() {
    resolver.current?.(selectedService);

    handleClose();
  }

  function handleCancel() {
    resolver.current?.(null);

    handleClose();
  }

  function handleClose() {
    clear();
    setIsOpen(false);
  }

  function clear() {
    setSelectedService(undefined);
  }
});
