import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import classNames from "classnames";
import { toLocaleDate } from "@helpers/dateUtils";
import { Card } from "@components/Card";
import emptyImg from "./img/empty.png";
import sortUpImg from "./img/sort_up.svg";
import sortDownImg from "./img/sort_down.svg";
import "./Table.scss";

export const Table = ({
  columns,
  data,
  location,
  sort = new Set(),
  title = "Wszystkie",
  empty = {
    title: "Ta lista jest pusta",
  },
  className = "",
  actions = null,
  onSort,
}) => {
  const navigate = useNavigate();

  const rootCs = classNames("Table", className);

  const rows = data ?? [];

  return (
    <Card className={rootCs}>
      <Card.Header className="Table_header">
        <div className="Table_count">
          {title} ({data?.length})
        </div>

        <div className="Table_actions">{actions}</div>
      </Card.Header>

      <Card.Body className="Table_content">
        {data?.length > 0 ? (
          <table>
            <thead>
              <tr className="Table_row--head">
                {columns.map((col) => {
                  const style = {};

                  if (col.width) {
                    style.width = col.width;
                  }

                  const thCs = classNames("Table_th", {
                    "Table_th--sortable": col.sortable,
                  });

                  return (
                    <th
                      key={col.title}
                      className={thCs}
                      style={style}
                      onClick={() => {
                        handleTableHeaderClicked(col);
                      }}
                    >
                      <div className="Table_th-container">
                        <span>{col.title}</span>

                        {col.sortable && (
                          <div className="Table_th-sort">
                            {isSortedUp(col.field) && (
                              <img src={sortUpImg} alt="" />
                            )}
                            {isSortedDown(col.field) && (
                              <img src={sortDownImg} alt="" />
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => {
                const rowCs = classNames("Table_row--body", {
                  "Table_row--clickable": Boolean(location),
                });

                return (
                  <tr
                    key={row._id}
                    className={rowCs}
                    onClick={() => handleRowClicked(row)}
                  >
                    {columns.map((col) => {
                      const cellValue = row[col.field];

                      if (typeof col.render === "function") {
                        return (
                          <td className="Table_td">
                            {col.render(cellValue, col, row)}
                          </td>
                        );
                      }

                      if (col.type === "date") {
                        return (
                          <td className="Table_td">
                            {toLocaleDate(cellValue)}
                          </td>
                        );
                      } else if (col.type === "currency") {
                        if (!cellValue) {
                          return <td className="Table_td">brak</td>;
                        }

                        return <td className="Table_td">{cellValue} PLN</td>;
                      }

                      return (
                        <td
                          className="Table_td"
                          onClick={(e) => {
                            const target = e.target as HTMLElement;

                            const { whiteSpace } = getComputedStyle(
                              target as HTMLElement
                            );

                            if (whiteSpace === "nowrap") {
                              target.style.whiteSpace = "normal";
                            } else {
                              target.style.whiteSpace = "nowrap";
                            }
                          }}
                        >
                          {cellValue}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="Table--empty">
            <img src={emptyImg} alt="List is empty" />
            <p className="h5">{empty.title}</p>
            <p className="caption">{empty.sub}</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );

  function handleRowClicked(row) {
    if (typeof location === "string") {
      navigate(location + "/" + row._id);

      window.scroll({
        top: 0,
        left: 0,
      });
    } else if (typeof location === "function") {
      navigate(location(row));

      window.scroll({
        top: 0,
        left: 0,
      });
    }
  }

  function handleTableHeaderClicked(col) {
    if (!col.sortable) return;

    onSort?.(col.field);
  }

  function isSortedUp(field: string) {
    if (!sort.has(field) && !sort.has(`-${field}`)) {
      return true;
    }

    return sort.has(field);
  }

  function isSortedDown(field: string) {
    if (!sort.has(field) && !sort.has(`-${field}`)) {
      return true;
    }

    return sort.has(`-${field}`);
  }
};

Table.useSort = () => {
  const [sort, setSort] = useImmer(new Set());

  return [sort, sortByField];

  function sortByField(field: string) {
    setSort((prev) => prev.clear());

    // NOTE: Sorted up -> no sorting
    if (sort.has(field)) {
      setSort((prev) => void prev.delete(field));

      // NOTE: Sorted down -> sort up
    } else if (sort.has(`-${field}`)) {
      setSort((prev) => void prev.delete(`-${field}`));
      setSort((prev) => void prev.add(field));
    } else {
      // NOTE: Not sorted -> sort down
      setSort((prev) => void prev.add(`-${field}`));
    }
  }
};
