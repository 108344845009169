import { useOffers, useUrgentOffers } from "../../services/offer.hooks";
import { store } from "@store";
import { Table } from "@components/Table";
import { SubscriptionDisabled } from "../../components/SubscriptionDisabled";
import { Page } from "./Page";
import { MyOffersPagePure } from "./MyOffersPagePure";

export const MyOffersPage = () => {
  const [sort, sortByField] = Table.useSort();

  const user = store.hooks.useUser();
  const offers = useOffers(sort);
  const [urgentOffers] = useUrgentOffers();

  if (!user.subscription) {
    return (
      <Page>
        <SubscriptionDisabled />
      </Page>
    );
  }

  return (
    <MyOffersPagePure
      offers={offers}
      sort={sort}
      urgentOffers={urgentOffers}
      user={user}
      sortByField={sortByField}
    />
  );
};
