import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Counter } from "@components/Counter";

export const NoOfSetsModal = ({ isOpen, noOfSets, onClose, onConfirm }) => {
  const [noOfSetsCopy, setNoOfSetsCopy] = useState<number>();

  useEffect(() => {
    if (isOpen) {
      setNoOfSetsCopy(noOfSets);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Zmień ilość kompletów"
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okText="Zapisz zmiany"
      width={480}
      onOk={() => {
        onConfirm(noOfSetsCopy);
        onClose();
      }}
      onCancel={onClose}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="body mr12">Ilość kompletów:</span>
        <Counter value={noOfSetsCopy} onChange={setNoOfSetsCopy} />
      </div>
    </Modal>
  );
};
