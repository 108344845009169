import { useState } from "react";
import { getNoOfSetsText } from "@helpers/getNoOfSetsText";
import { Card } from "@components/Card";
import { Chip } from "@components/Chip";
import { Button } from "@components/Button";
import { ElementDetailsModal } from "@components/ElementDetailsModal";
import { Element } from "./Element";
import chevronLeftImg from "./img/chevron_left.svg";
import chevronRightImg from "./img/chevron_right.svg";
import cs from "./Elements.module.scss";

export const Elements = ({
  order,
  elements,
}: {
  order: any;
  elements: Map<any, any>;
}) => {
  const [viewedElement, setViewedElement] = useState(null);

  const elementsAsArray = Array.from(elements.values());

  return (
    <>
      <Card>
        <Card.Header className={cs.header}>
          <div>
            <span className="h5">Elementy do wykonania ({elements.size})</span>

            {order.isCompatible && (
              <Chip color="green" size="caption" className="ml24">
                Zgodne z PM
              </Chip>
            )}
          </div>
        </Card.Header>

        <Card.Body>
          <div className="body mb4">
            <span style={{ color: "#636E72" }}>Ilość:</span>
            <Chip color="orange" size="body" className="ml8">
              <span>{getNoOfSetsText(order.total_amount)}</span>
            </Chip>
          </div>

          <div>
            {elementsAsArray.map((wrapper, idx) => (
              <Element
                key={wrapper.element._id}
                order={order}
                element={wrapper.element}
                offer={wrapper.offer}
                selectedMachines={wrapper.selectedMachines}
                isSelected={wrapper.selected}
                onShowDetails={() => {
                  setViewedElement({
                    element: wrapper.element,
                    index: idx + 1,
                  });
                }}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <ElementDetailsModal
        element={viewedElement?.element}
        index={viewedElement?.index}
        noOfSets={order.total_amount}
        visible={Boolean(viewedElement)}
        mode="readonly"
        footer={
          <div>
            <Button
              type="secondary"
              size="medium"
              disabled={viewedElement?.index === 1}
              onClick={prev}
            >
              <img src={chevronLeftImg} />
            </Button>

            <span className="body ml12 mr12">
              <span className="bold">{viewedElement?.index}</span>
              <span> / {elements.size}</span>
            </span>

            <Button
              type="secondary"
              size="medium"
              disabled={viewedElement?.index === elements.size}
              onClick={next}
            >
              <img src={chevronRightImg} />
            </Button>
          </div>
        }
        onClose={() => setViewedElement(null)}
      />
    </>
  );

  function next() {
    setViewedElement((prev) => {
      const nextElement = elementsAsArray[prev.index];
      const nextIndex = prev.index + 1;

      return { element: nextElement.element, index: nextIndex };
    });
  }

  function prev() {
    setViewedElement((prev) => {
      const prevElement = elementsAsArray[prev.index - 2];
      const prevIndex = prev.index - 1;

      return { element: prevElement.element, index: prevIndex };
    });
  }
};
