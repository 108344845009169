import { DateTime, Settings } from "luxon";
import moment from "moment";

Settings.defaultLocale = "pl";

export function formatTime(date?: Date) {
  if (!date) return "";

  const _date = DateTime.fromJSDate(new Date(date));

  return _date.toFormat("HH:mm");
}

export function toLocaleDate(date) {
  if (!date) return "";

  return new Intl.DateTimeFormat("pl-PL").format(new Date(date));
}

export function getDaysFromToday(date) {
  const _date = DateTime.fromJSDate(new Date(date));
  const diff = _date.diff(DateTime.now(), ["days"]).toObject();

  return Math.floor(Math.abs(diff.days));
}

export function addDays(date: any, days: number) {
  return DateTime.fromJSDate(new Date(date)).plus({ days }).toJSDate();
}

export function isSameOrBefore(mainDate, comparedDate) {
  return moment(comparedDate).isSameOrBefore(mainDate, "day");
}

export function toRelative(date) {
  return DateTime.fromJSDate(new Date(date)).toRelative();
}
