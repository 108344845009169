import { useEffect, useState } from "react";
import classNames from "classnames";
import {
  descsAsSelectOptions,
  outerDiametersAsSelectOptions,
  typesAsSelectOptions,
} from "@helpers/thread";
import { Select } from "@components/Select";
import { AddNextBtn } from "../../AddNextBtn";
import { Depth } from "./Depth";
import deleteImg from "../../img/delete.svg";
import cs from "./Threads.module.scss";

export const Diameter = ({
  group,
  index,
  readOnly,
  onDiameterChange,
  onDiameterRemoved,
  onDepthAdded,
  onDepthChange,
  onDepthRemoved,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const availableOuterDiameters = outerDiametersAsSelectOptions(group.standard);
  const availableThreadDescriptions = descsAsSelectOptions(group.standard);

  useEffect(() => {
    if (group.depths.length === 0) {
      onDepthAdded(group.tempId);
    }
  }, [group]);

  const rootCs = classNames(cs.Diameter, {
    [cs.hovered]: isHovered,
  });

  return (
    <div className={rootCs}>
      <div className={cs.diameter}>
        <div className="d-flex align-center justify-between mb24">
          <span className="bold">Gwint {index + 1}:</span>

          {!readOnly && (
            <img
              src={deleteImg}
              alt=""
              style={{ cursor: "pointer" }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => onDiameterRemoved(group.tempId)}
            />
          )}
        </div>

        <div className="mb8">
          <div className="mb4 small bold">Typ</div>
          <Select
            value={group.standard}
            style={{ width: 175, pointerEvents: readOnly ? "none" : "auto" }}
            size="small"
            options={typesAsSelectOptions}
            required
            onChange={(value) => {
              onDiameterChange(group.tempId, "standard", value);
            }}
          />
        </div>

        <div className="mb8">
          <div className="mb4 small bold">Rozmiar</div>
          <Select
            value={group.outer_diameter}
            style={{ width: 175, pointerEvents: readOnly ? "none" : "auto" }}
            size="small"
            options={availableOuterDiameters}
            required
            onChange={(outerDiameter) => {
              onDiameterChange(group.tempId, "outer_diameter", outerDiameter);
            }}
          />
        </div>

        <div className="mb24">
          <div className="mb4 small bold">Oznaczenie</div>
          <Select
            value={group.description}
            style={{ width: 175, pointerEvents: readOnly ? "none" : "auto" }}
            size="small"
            options={availableThreadDescriptions}
            required
            onChange={(description) => {
              onDiameterChange(group.tempId, "description", description);
            }}
          />
        </div>
      </div>

      <div className={cs.deepenings}>
        {group.depths.map((depth, depthIndex) => (
          <Depth
            diameterId={group.tempId}
            depth={depth}
            index={depthIndex}
            readOnly={readOnly}
            onDepthChange={onDepthChange}
            onDepthRemoved={onDepthRemoved}
          />
        ))}

        {!readOnly && (
          <AddNextBtn
            className="mt4"
            onClick={() => onDepthAdded(group.tempId)}
          />
        )}
      </div>
    </div>
  );
};
