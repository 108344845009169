import { useEffect } from "react";
import produce from "immer";
import { getServiceNameById } from "@services/technology.helpers";
import { genTempId } from "@helpers/utils";
import { InputNumber } from "@components/InputNumber";
import { Technologies } from "../Technologies";
import { AddNextBtn } from "../AddNextBtn";
import { Color } from "./Color";
import solidImg from "../img/solid.png";
import cs from "./Painting.module.scss";

export const Painting = ({
  params,
  service,
  readOnly,
  onTechnologiesChange,
  onChange,
}) => {
  const coatings = params.coatings || [];
  const colors = coatings.filter(
    ({ name, tempId }) => name?.includes("RAL") || tempId?.includes("painting_")
  );

  useEffect(() => {
    if (colors.length === 0) {
      setTimeout(() => {
        handleSurfaceAdded();
      });
    }
  }, [colors]);

  return (
    <div>
      <div className="h4 mb24">{getServiceNameById(service._id)}</div>

      <div className={cs.main}>
        <div className={cs.imgContainer}>
          <img src={solidImg} alt="" />
        </div>

        <div>
          <div className={cs.input}>
            <span>Pole powierzchni elementu</span>
            <InputNumber
              size="small"
              value={params.surface_area}
              min={0.001}
              step={0.001}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("surface_area", value)}
            />
            <span>
              m<sup>2</sup>
            </span>
          </div>

          <div className={cs.colors}>
            {colors.map((surface, index) => (
              <Color
                key={surface.name || index}
                surface={surface}
                index={index}
                readOnly={readOnly}
                onChange={handleSurfacesChanged}
                onColorRemove={handleSurfaceRemoved}
              />
            ))}
          </div>

          <div style={{ borderTop: "1px solid #DFE6E9", marginTop: 12 }}>
            {!readOnly && (
              <AddNextBtn onClick={handleSurfaceAdded} className="m4" />
            )}
          </div>
        </div>
      </div>

      <Technologies
        service={service}
        readOnly={readOnly}
        onChange={onTechnologiesChange}
      />
    </div>
  );

  function handleSurfaceAdded() {
    onChange("coatings", [
      ...coatings,
      {
        tempId: "painting_" + genTempId(),
        area: null,
      },
    ]);
  }

  function handleSurfaceRemoved(nameOrTempId) {
    const filtered = coatings.filter((surface) => {
      return surface.name !== nameOrTempId && surface.tempId !== nameOrTempId;
    });

    onChange("coatings", filtered);
  }

  function handleSurfacesChanged(nameOrTempId, property, value) {
    const updatedSurfaces = produce(coatings, (draft) => {
      const surface = draft.find((surface) => {
        return surface.tempId === nameOrTempId || surface.name === nameOrTempId;
      });

      surface[property] = value;
    });

    onChange("coatings", updatedSurfaces);
  }
};
