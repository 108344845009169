import { toLocaleDate } from "@helpers/dateUtils";
import { Button } from "@components/Button";

export const columns = ({ onPermissionChoice }) => [
  {
    title: "Użytkownik",
    field: "name",
  },
  {
    title: "Kontakt",
    render: (_: any, __: any, row: any) => {
      return [row.email, row.phone].filter((val) => val).join(" | ");
    },
  },
  {
    title: "Firma",
    field: "company_name",
  },
  {
    title: "Kiedy wysłano",
    field: "requested_at",
    render: (value: string) => {
      return toLocaleDate(value) + ", " + new Date(value).toLocaleTimeString();
    },
  },
  {
    title: "Decyzja",
    field: "requested_by_company",
    render: (value: string) => {
      return (
        <div>
          <Button
            type="primary"
            size="small"
            className="mr8"
            onClick={() => {
              onPermissionChoice(value, "grant");
            }}
          >
            Akceptuj
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => {
              onPermissionChoice(value, "deny");
            }}
          >
            Odrzuć
          </Button>
        </div>
      );
    },
  },
];
