import produce from "immer";
import { useStore } from "@store";
import { getServiceNameById } from "@services/technology.helpers";
import { getGreater, getLower } from "@helpers/utils";

type MachineParameter = {
  value: string;
  user_id: string;
  machine_id: number;
  parameter_id: number;
};

/**
 * @public
 */
export function calculateCompatibility(order: any) {
  return produce<any>(order, (orderDraft) => {
    try {
      const compatibilityService = new CompatibilityService(orderDraft);

      compatibilityService.setCompatibleMachinesForElements();
      compatibilityService.checkOrderCompatibility();
    } catch (e) {
      console.error(e);
    }
  });
}

///

/**
 * @private
 */
class CompatibilityService {
  readonly machinePark;
  readonly machinelessServices;

  private order;
  private elements;

  constructor(order: any) {
    this.machinePark = useStore.getState().machinePark;
    this.machinelessServices = useStore.getState().machinelessServices;

    this.order = order;
    this.elements = order.elements;
  }

  checkOrderCompatibility() {
    console.group(
      "%cSprawdzanie zgodności z parkiem maszyn: %s (%s)",
      "font-size: 15px",
      this.order.seq_no,
      this.order.desc
    );

    let allElementsCompatible = true;

    this.elements.forEach((element) => {
      element.technologies.forEach((technologies, serviceId) => {
        if (this.machinelessServices.includes(serviceId)) {
          console.log(
            "Serwis '%s' jest oznaczony w parku maszyn jako kompatybilny serwis nieposiadający maszyn.",
            getServiceNameById(serviceId)
          );

          return;
        }

        const noOfCompatibileMachines =
          element.machinesPerService.get(serviceId).length;

        if (noOfCompatibileMachines === 0) {
          console.log(
            "(Element: %s) Brak maszyn dla serwisu:",
            element.name,
            getServiceNameById(serviceId)
          );
          allElementsCompatible = false;
        }
      });
    });

    if (allElementsCompatible) {
      console.log("Zlecenie zgodne z parkiem maszyn");
    } else {
      console.log("%cZlecenie niezgodne z parkiem maszyn", "color: red;");
    }

    this.order.isCompatible = allElementsCompatible;

    console.groupEnd();
  }

  /**
   * For each element's service find machines
   * that are compatible with it.
   */
  setCompatibleMachinesForElements() {
    console.group(
      "%cSzukanie kompatybilnych maszyn: %s (%s)",
      "font-size: 15px",
      this.order.seq_no,
      this.order.desc
    );

    this.elements.forEach((element) => {
      console.group("Element:", element.name);

      element.machinesPerService = new Map();

      const servicesRequiringMachine = new Map(
        Array.from(element.technologies).filter(([serviceId]) => {
          return !this.machinelessServices.includes(serviceId);
        })
      );

      servicesRequiringMachine.forEach((technologies, serviceId: number) => {
        console.group("Serwis:", getServiceNameById(serviceId));

        // NOTE: 1) Find machines that work with AT LEAST
        //          ONE technology selected by Customer;
        let compatibleMachines = this.machinePark.filter((machine) => {
          return technologies.some(({ _id }) =>
            machine.technology_ids.includes(_id)
          );
        });

        // NOTE: 2) Find at least one machine with compatible parameters
        compatibleMachines = compatibleMachines.filter((machine) => {
          console.group("Maszyna:", machine.model_name);

          const isMachineCompatible = new ElementMachineRelation(
            element,
            machine
          ).checkService(serviceId);

          if (isMachineCompatible) {
            console.log(
              "%cMaszyna kompatybilna",
              "color: green; text-decoration: underline;"
            );
          } else {
            console.log("Maszyna niekompatybilna");
          }

          console.groupEnd();

          return isMachineCompatible;
        });

        element.machinesPerService.set(serviceId, compatibleMachines);

        console.groupEnd();
      });

      console.groupEnd();
    });

    console.groupEnd();
  }
}

/**
 * @private
 */
class ElementMachineRelation {
  readonly element;
  readonly params;
  readonly machine;

  constructor(element: any, machine: any) {
    this.element = element;
    this.params = element.parameters;
    this.machine = new Machine(machine);
  }

  checkService(serviceId: number) {
    switch (serviceId) {
      case 1:
        return this.sheetMetalCutting();
      case 19:
        return this.sheetMetalBending();
      //NOTE: Surfaces like painting and anodizing
      case 43:
      case 44:
      case 45:
      case 46:
      case 47:
      case 48:
      case 49:
      case 53:
      case 59:
        return this.check3DimensionsCompatiblity();
      default:
        return false;
    }
  }

  private sheetMetalCutting() {
    const elementNotToLarge = this.checkDimensions();
    const isThicknessCompatible = this.checkThickness();

    return elementNotToLarge && isThicknessCompatible;
  }

  private sheetMetalBending() {
    const isThicknessCompatible = this.checkThickness();

    if (!isThicknessCompatible) {
      return false;
    }

    const { thickness } = this.params;

    const bendParamForThisThickness = this.machine.bendRadiusToThickness.find(
      ({ scope }) => {
        return thickness >= scope[0] && thickness < scope[1];
      }
    );

    if (!bendParamForThisThickness) {
      console.log(
        "%cMaszyna nie ma zdefiniowanej specyfikacji dla zadanej grubości: %s mm",
        "color: red;",
        thickness
      );

      return false;
    }

    let areAllBendsCompatible = true;

    const allBends: any[] = [];
    this.params.mountings.forEach((radiusGroup: any) => {
      radiusGroup.lineLengths.forEach((lineLength: any) => {
        allBends.push({
          radius: radiusGroup.radius,
          length: lineLength.length,
        });
      });
    });

    for (const bend of allBends) {
      console.group(
        "Gięcie (promień: %s, długość: %s):",
        bend.radius,
        bend.length
      );
      let bendCompatible = true;

      if (bend.length > this.machine.maxBendLength) {
        console.log(
          "%cDługość gięcia (%s) niekompatybilna. Maksymalna długość: %s",
          "color: red;",
          bend.length,
          this.machine.maxBendLength
        );

        bendCompatible = false;
      }

      // if (bend.height < this.machine.minShelfHeight + thickness) {
      //   console.log(
      //     "Wysokość półki (%s) niekompatybilna. Maksymalna wysokość: %s",
      //     bend.height,
      //     this.machine.minShelfHeight + thickness
      //   );
      //
      //   areAllBendsCompatible= false;
      // }

      const minBendRadius = bendParamForThisThickness.values[0];
      if (bend.radius < minBendRadius) {
        console.log(
          "%cPromień (%s) jest mniejszy niż minimalny promień gięcia dla wybranej grubości: %s",
          "color: red;",
          bend.radius,
          minBendRadius
        );

        bendCompatible = false;
      }

      const availableRadiusesForThisThickness =
        bendParamForThisThickness.values;

      if (!availableRadiusesForThisThickness.includes(+bend.radius)) {
        console.log(
          "%cPromień (%s) nie występuje w dostępnych promieniach dla tej grubości: %s",
          "color: red;",
          bend.radius,
          availableRadiusesForThisThickness
        );

        bendCompatible = false;
      }

      if (bendCompatible) {
        console.log("ok");
      }

      areAllBendsCompatible = areAllBendsCompatible && bendCompatible;

      console.groupEnd();
    }

    return areAllBendsCompatible;
  }

  private check3DimensionsCompatiblity() {
    const elDimensionX = +this.params.overall_dimension_x;
    const elDimensionY = +this.params.overall_dimension_y;
    const elDimensionZ = +this.params.overall_dimension_z;

    const [x, y, z] = this.machine.workingArea3d;

    let compatible: boolean = false;

    if (x >= elDimensionX) {
      if (
        (y >= elDimensionY && z >= elDimensionZ) ||
        (z >= elDimensionY && y >= elDimensionZ)
      ) {
        compatible = true;
      }
    }

    if (y >= elDimensionX) {
      if (
        (x >= elDimensionY && z >= elDimensionZ) ||
        (z >= elDimensionY && x >= elDimensionZ)
      ) {
        compatible = true;
      }
    }

    if (z >= elDimensionX) {
      if (
        (x >= elDimensionY && y >= elDimensionZ) ||
        (y >= elDimensionY && x >= elDimensionZ)
      ) {
        compatible = true;
      }
    }

    if (!compatible) {
      console.log(
        "%cNiezgodność obszaru roboczego. Wymiary maszyny: %sx%sx%s, wymiary elementu: %sx%sx%s",
        "color: red;",
        x,
        y,
        z,
        elDimensionX,
        elDimensionY,
        elDimensionZ
      );
    }

    return compatible;
  }

  private checkDimensions() {
    const { sheet_metal_width, sheet_metal_length } = this.params;

    const lowerElDimension = getLower(sheet_metal_width, sheet_metal_length);
    const lowerMachineDimension = getLower(...this.machine.workingArea);

    const greaterElDimension = getGreater(
      sheet_metal_width,
      sheet_metal_length
    );
    const greaterMachineDimension = getGreater(...this.machine.workingArea);

    const willFit =
      lowerMachineDimension >= lowerElDimension &&
      greaterMachineDimension >= greaterElDimension;

    if (!willFit) {
      console.log(
        "Niezgodność powierzchni roboczej. Wymiary maszyny: %sx%s. Wymiary elementu: %sx%s",
        greaterMachineDimension,
        lowerMachineDimension,
        greaterElDimension,
        lowerElDimension
      );
    }

    return willFit;
  }

  private checkThickness() {
    const { thickness } = this.params;

    const [minThickness, maxThickness] = this.machine.thicknessRange;

    const willFit = thickness >= minThickness && thickness <= maxThickness;

    if (!willFit) {
      console.log(
        "Niezgodność grubości. Grubość materiału: %s. Zakres grubości dopuszczalnych na maszynie: <%s, %s>",
        thickness,
        minThickness,
        maxThickness
      );
    }

    return thickness >= minThickness && thickness <= maxThickness;
  }
}

/**
 * @private
 */
class Machine {
  private machine;
  private params: MachineParameter[];

  constructor(machine: any) {
    this.machine = machine;
    this.params = machine.parameters;
  }

  get workingArea(): [number, number] {
    const param = this.params.find(({ parameter_id }) => parameter_id === 1);

    if (!param?.value) return [0, 0];

    return param?.value.split(",").map((val: string) => +val) as [
      number,
      number
    ];
  }

  get workingArea3d(): [number, number, number] {
    const param = this.params.find(({ parameter_id }) => parameter_id === 7);

    if (!param?.value) return [0, 0, 0];

    return param.value.split(",").map((val: string) => +val) as [
      number,
      number,
      number
    ];
  }

  get thicknessRange() {
    const minThicknessParam = this.params.find(
      ({ parameter_id }) => parameter_id === 3
    ) as MachineParameter;
    const maxThicknessParam = this.params.find(
      ({ parameter_id }) => parameter_id === 2
    ) as MachineParameter;

    const minThickness = minThicknessParam?.value || 0;
    const maxThickness = maxThicknessParam?.value || 0;

    return [+minThickness, +maxThickness];
  }

  get maxBendLength() {
    const maxBendLengthParam = this.params.find(
      ({ parameter_id }) => parameter_id === 4
    );

    return Number(maxBendLengthParam?.value || 0);
  }

  get minShelfHeight() {
    return Number(
      this.params.find(({ parameter_id }) => parameter_id === 5) || 0
    );
  }

  get bendRadiusToThickness() {
    const radiusThicknessParam = this.params.find(
      ({ parameter_id }) => parameter_id === 6
    );

    return radiusThicknessParam?.value || [];
  }
}
