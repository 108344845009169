import { useState } from "react";
import { Modal } from "@components/Modal";
import { MaterialSelect } from "@components/MaterialSelect";

export const MaterialModal = ({ isOpen, onClose, onConfirm }) => {
  const [selectedOption, setSelectedOption] = useState();

  return (
    <Modal
      title="Dodaj materiał"
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ disabled: !selectedOption }}
      okText="Zapisz zmiany"
      width={480}
      onOk={() => {
        onConfirm(selectedOption);
        handleClose();
      }}
      onClose={handleClose}
    >
      <MaterialSelect
        materialId={selectedOption?.materialId}
        typeId={selectedOption?.typeId}
        width="100%"
        onChange={setSelectedOption}
      />
    </Modal>
  );

  function handleClose() {
    onClose();
    setSelectedOption(null);
  }
};
