import axios from "axios";

const apiUrl = "/api/machine";

export async function getMachineParkApi() {
  try {
    const response = await axios.get(apiUrl);

    return {
      machines: response.data.map((machine) => {
        return {
          ...machine,
          parameters: machine.parameters
            .filter((param) => param)
            .sort((a, b) => {
              return a.parameter_id - b.parameter_id;
            })
            .map((param) => {
              if (param.parameter_id === 6) {
                return {
                  ...param,
                  value: JSON.parse(param.value),
                };
              }

              return param;
            }),
        };
      }),
      isSuccess: response.status === 200,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function getMachinelessServicesApi() {
  try {
    const response = await axios.get(apiUrl + "/machineless");

    return {
      data: response.data.map(({ service_id }) => service_id),
      isSuccess: response.status === 200,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}
