import { calculateMassForMaterial } from "../../services/offer.helpers";
import { toFixedNumber } from "@helpers/utils";
import { Surface } from "@customTypes/elementParameters";

type DrilledHole = {
  hole_diameter: number;
  hole_depth: number;
  count: number;
  through_hole: boolean;
};

export class PriceCalculator {
  private readonly element;
  private readonly offer;
  private readonly serviceId;
  private readonly machine;
  private readonly prices;
  private readonly params;
  private readonly noOfElementCopies;

  constructor(element, offer, service, machine) {
    this.element = element;
    this.offer = offer;
    this.serviceId = service._id;
    this.params = element.parameters;
    this.machine = machine;
    this.prices = machine.compatiblePricing.prices;
    this.noOfElementCopies = element.total_amount;
  }

  public calculatePrice() {
    let pricePerElement: number = 0;

    switch (this.serviceId) {
      case 1:
        return this.cutting();
      case 19:
        return this.bending();
      case 43:
        return this.painting();
      case 44:
        return this.coating("Cynkowanie");
      case 45:
        return this.coating("Złocone");
      case 46:
        return this.coating("Anodowane");
      case 47:
        return this.coating("Czernione");
      case 48:
        return this.coating("Niklowane");
      case 49:
        return this.coating("Miedziowane");
      case 123:
        return this.grinding();
      case 125:
        return this.drilling();
      case 126:
        return this.threading();
      case 127:
        return this.deepening("countersink");
      case 128:
        return this.deepening("counterbore");
    }

    return pricePerElement;
  }

  private cutting() {
    const { prices, params, machine, noOfElementCopies } = this;

    console.group(
      (machine.model_name || "Domyślny cennik") +
        " - robocizna i koszt nastaw (cięcie)"
    );

    const pricesByThickness = prices.table.find(({ scope }) => {
      return params.thickness > scope[0] && params.thickness <= scope[1];
    });

    if (!pricesByThickness) {
      console.error(
        "Grubość materiału (%s mm) nie zdefiniowana w cenniku",
        params.thickness
      );

      return 0;
    }

    const kosztJednegoWpalenia = pricesByThickness.values[0];
    const kosztWpalen = kosztJednegoWpalenia * params.envelopes_count;

    console.log("Koszt jednego wpalenia: %s zł", kosztJednegoWpalenia);
    console.log("Ilość wpaleń: %s szt", params.envelopes_count);
    console.log(
      "Koszt wszystkich wpaleń (%s x %s): %s zł",
      kosztJednegoWpalenia,
      params.envelopes_count,
      kosztWpalen
    );

    const koszt1mCiecia = pricesByThickness.values[1];
    const iloscMetrowCiecia = params.sheet_metal_lenght_cut_line / 1000;
    const kosztCiecia = koszt1mCiecia * iloscMetrowCiecia;

    console.log("Grubość materiału: %s mm", params.thickness);
    console.log(
      "Koszt cięcia 1m dla zakresu grubości <%s, %s>: %s zł",
      pricesByThickness.scope[0],
      pricesByThickness.scope[1],
      koszt1mCiecia
    );
    console.log("Ilość metrów cięcia: %s m", iloscMetrowCiecia);
    console.log(
      "Koszt całego cięcia (%s x %s): %s zł",
      koszt1mCiecia,
      iloscMetrowCiecia,
      kosztCiecia
    );

    const robociznaPerElement = kosztWpalen + kosztCiecia;
    const robocizna = robociznaPerElement * noOfElementCopies;

    console.log(
      "Robocizna dla jednego detalu (%s zł + %s zł): %s zł",
      kosztWpalen,
      kosztCiecia,
      robociznaPerElement
    );
    console.log("Ilość zamówionych detali: %s szt", noOfElementCopies);
    console.log(
      "Robocizna dla wszystkich detali (%s * %s zł): %s zł",
      noOfElementCopies,
      robociznaPerElement,
      robocizna
    );

    const amountPerSheet = this.calculateAmountPerSheet(
      machine,
      params.sheet_metal_length,
      params.sheet_metal_width
    );
    console.log(
      "Ilość detali które zmieszczą się na jednym arkuszu: %s szt",
      amountPerSheet
    );
    console.log("Ilość zamówionych detali: %s szt", noOfElementCopies);

    const iloscArkuszy = Math.ceil(noOfElementCopies / amountPerSheet);

    console.log(
      "Ilość arkuszy (%s / %s): %s szt",
      noOfElementCopies,
      amountPerSheet,
      iloscArkuszy
    );

    const kosztNastawyArkusza = pricesByThickness.values[2];

    console.log("Koszt nastawy jednego arkuszu: %s zł", kosztNastawyArkusza);

    const sumarycznyKosztNastaw = kosztNastawyArkusza * iloscArkuszy;

    console.log(
      "Koszt dla wszystkich nastaw (%s x %s zł): %s zł",
      iloscArkuszy,
      kosztNastawyArkusza,
      sumarycznyKosztNastaw
    );

    console.log(
      "Robocizna + koszt nastaw (%s zł + %s zł): %s zł",
      sumarycznyKosztNastaw,
      robocizna,
      sumarycznyKosztNastaw + robocizna
    );

    console.groupEnd();

    return sumarycznyKosztNastaw + robocizna;
  }

  private bending() {
    const { prices, params, machine, noOfElementCopies, offer, element } = this;

    console.group(
      (machine.model_name || "Domyślny cennik") +
        " - robocizna i koszt nastaw (Gięcie blach)"
    );

    const pricesByMass = prices.table.find(({ scope }) => {
      return params.thickness > scope[0] && params.thickness <= scope[1];
    });

    if (!pricesByMass) {
      console.error(
        "Grubość materiału (%s mm) nie została zdefiniowana w cenniku",
        params.thickness
      );

      return 0;
    }

    // NOTE: For each unique combination of bend line
    //       length and radius one setup is needed
    let noOfSetups = 0;
    this.params.mountings.forEach((radiusGroup: any) => {
      radiusGroup.lineLengths.forEach(() => {
        noOfSetups += 1;
      });
    });

    const setupCost = noOfSetups * prices.setup;

    console.log(
      "Grupuję po dł. linii gięcia i promieniu. Ilość mocowań: %s",
      noOfSetups
    );
    console.log("Koszt pojedynczego mocowania: %s zł", prices.setup);
    console.log(
      "Koszt mocowań dla detalu (%s x %s zł): %s zł",
      noOfSetups,
      prices.setup,
      setupCost
    );
    console.log("\n");

    const mass = calculateMassForMaterial(
      params,
      offer.material_id || element.material_id
    );
    let priceOfBendForThisMass;
    if (mass < 10) {
      priceOfBendForThisMass = pricesByMass.values[0];
    } else if (mass < 50) {
      priceOfBendForThisMass = pricesByMass.values[1];
    } else {
      priceOfBendForThisMass = pricesByMass.values[2];
    }

    let allBendsCount = 0;
    this.params.mountings.forEach((radiusGroup: any) => {
      radiusGroup.lineLengths.forEach((lineLength) => {
        lineLength.angles.forEach((angle) => {
          allBendsCount += angle.count;
        });
      });
    });

    const priceForAllBends =
      allBendsCount * noOfElementCopies * priceOfBendForThisMass;

    console.log("Ilość wszystkich gięć: %s", allBendsCount);
    console.log("Ilość sztuk detalu: %s szt", noOfElementCopies);
    console.log(
      "Cena za jedno gięcie dla masy %s kg: %s zł",
      mass,
      priceOfBendForThisMass
    );
    console.log(
      "Cena za wszystkie gięcia dla tej masy (%s x %s x %s zł): %s zł",
      allBendsCount,
      noOfElementCopies,
      priceOfBendForThisMass,
      priceForAllBends
    );
    console.log("\n");

    const totalPrice = priceForAllBends + setupCost;

    console.log(
      "Cena gięć + koszt nastaw (%s zł + %s zł): %s zł",
      priceForAllBends,
      setupCost,
      totalPrice
    );
    console.log(
      "Cena za pojedynczy detal (%s zł / %s): %s zł",
      totalPrice,
      noOfElementCopies,
      toFixedNumber(totalPrice / noOfElementCopies, 2)
    );

    console.groupEnd();

    return totalPrice;
  }

  private drilling() {
    console.group("Wiercenie otworów w metalu");

    const { prices, params, element, noOfElementCopies } = this;

    const uniqueDiameters = new Set();
    const holes: Hole[] = [];

    const isSheetMetalCutting = element.services.some(({ _id }) => _id === 1);

    console.log(
      "Czy występuje usługa cięcia? -",
      isSheetMetalCutting ? "TAK" : "NIE"
    );

    const cutThreadedHoles = !this.offer.noCutThreadedHoles;

    if (this.offer.noCutThreadedHoles) {
      console.log("Przelotowe otwory gwintowane nie będą wycinane.");
    }

    // NOTE: When there is also the service of sheet metal
    //       cutting then THROUGH holes are cut (so not drilled)
    params.holes?.drilledHoles?.forEach((diameterGroup) => {
      uniqueDiameters.add(diameterGroup.diameter);

      diameterGroup.depths.forEach((depth) => {
        // NOTE: Case when through holes are cut
        if (isSheetMetalCutting && depth.through_hole) return;

        holes.push({
          depth: depth.hole_depth,
          diameter: diameterGroup.diameter,
          count: depth.count,
        });
      });
    });

    if (element.services.some(({ _id }) => _id === 126)) {
      params.holes?.threadHoles?.forEach((diameterGroup) => {
        uniqueDiameters.add(diameterGroup.hole_diameter);

        diameterGroup.depths.forEach((depth) => {
          // NOTE: Case when through holes are cut
          if (isSheetMetalCutting && depth.through_hole && cutThreadedHoles) {
            return;
          }

          holes.push({
            depth: depth.hole_depth,
            diameter: diameterGroup.hole_diameter,
            count: depth.count,
          });
        });
      });
    }

    if (element.services.some(({ _id }) => _id === 127)) {
      params.holes?.counter_sink_holes?.forEach(countHole(isSheetMetalCutting));
    }

    if (element.services.some(({ _id }) => _id === 128)) {
      params.holes?.counter_bore_holes?.forEach(countHole(isSheetMetalCutting));
    }

    if (holes.length === 0) {
      console.log("Wszystkie otwory będą wycięte. Cena za wiercenie to 0 zł.");

      return 0;
    }

    console.log("Ilość unikatowych średnic: %s", uniqueDiameters.size);
    console.log("Koszt pojedynczej nastawy maszyny: %s zł", prices.setup);

    const setupPrice = uniqueDiameters.size * prices.setup;

    console.log(
      "Cena za nastawy (%s * %s zł): %s zł",
      uniqueDiameters.size,
      prices.setup,
      setupPrice
    );
    console.log("\n");

    console.group("Sprawdzanie otworów (%s)", holes.length);

    let priceForAllHoles = 0;

    holes.forEach((hole, index) => {
      console.group("Otwór nr.", index);

      console.log("Średnica otworu: %s mm", hole.diameter);

      const priceRow = prices.table.find((row) => {
        return hole.diameter >= row.scope[0] && hole.diameter < row.scope[1];
      });

      if (!priceRow) {
        console.log(
          "%cBrak cennika dla średnicy %s mm",
          "color: red; font-weight: bold;",
          hole.diameter
        );
        console.groupEnd();
        return;
      }

      const priceForThisDiameter = priceRow.values[0];
      console.log(
        "Cena za 1 cm wiercenia dla tej średnicy: %s zł",
        priceForThisDiameter
      );

      const holeDepthCm = hole.depth / 10;
      console.log("Głebokość otworu: %s cm", holeDepthCm);

      const priceForDepth = priceForThisDiameter * holeDepthCm;
      console.log("Cena za %s cm wiercenia: %s zł", holeDepthCm, priceForDepth);

      console.log("Ilość tego typu otworu: %s", hole.count);

      const priceForThisHole = hole.count * priceForDepth;

      console.log(
        "Cena dla tego typu otworu (%s * %s zł): %s zł",
        hole.count,
        priceForDepth,
        priceForThisHole
      );

      priceForAllHoles += priceForThisHole;

      console.groupEnd();
    });

    console.groupEnd();

    console.log("Cena za wszystkie otwory: %s zł", priceForAllHoles);
    console.log("\n");

    const price = setupPrice + priceForAllHoles;
    const totalPrice = toFixedNumber(price * noOfElementCopies, 2);

    console.log(
      "Cena za pojedynczy detal (%s zł + %s zł): %s zł",
      setupPrice,
      priceForAllHoles,
      price
    );
    console.log("Ilość sztuk:", noOfElementCopies);
    console.log(
      "Cena za całość (%s x %s zł): %s zł",
      noOfElementCopies,
      price,
      totalPrice
    );

    console.groupEnd();

    return totalPrice;

    ///
    type Hole = {
      depth: number;
      diameter: number;
      count: number;
    };

    function countHole(withoutThrough: boolean) {
      return (hole: DrilledHole) => {
        // NOTE: Case when through holes are cut
        if (withoutThrough && hole.through_hole) return;

        uniqueDiameters.add(hole.hole_diameter);

        holes.push({
          depth: hole.hole_depth,
          diameter: hole.hole_diameter,
          count: hole.count,
        });
      };
    }
  }

  private threading() {
    console.group("Gwintowanie");

    const { prices, params, noOfElementCopies } = this;
    const threadTypes = params.holes.threadHoles;
    const holes: any[] = [];

    params.holes?.threadHoles?.forEach((diameterGroup) => {
      diameterGroup.depths.forEach((depth) => {
        holes.push({
          outer_diameter: diameterGroup.outer_diameter,
          thread_depth: depth.thread_depth,
          count: depth.count,
        });
      });
    });

    console.log("Ilość unikatowych typów gwintów: %s", threadTypes.length);
    console.log("Koszt pojedynczej nastawy maszyny: %s zł", prices.setup);

    const setupPrice = threadTypes.length * prices.setup;

    console.log(
      "%cCena za nastawy (%s * %s zł): %s zł",
      "text-decoration: underline;",
      threadTypes.length,
      prices.setup,
      setupPrice
    );

    console.group("Sprawdzanie gwintów (%s)", holes.length);

    let priceForAllThreads = 0;

    holes.forEach((thread, index) => {
      console.group("Gwint nr.", index);

      console.log("Średnica zewnętrzna gwintu: %s mm", thread.outer_diameter);

      const priceRow = prices.table.find((row) => {
        return (
          thread.outer_diameter >= row.scope[0] &&
          thread.outer_diameter < row.scope[1]
        );
      });

      if (!priceRow) {
        console.log(
          "%cBrak cennika dla średnicy %s mm",
          "color: red; font-weight: bold;",
          thread.outer_diameter
        );
        console.groupEnd();
        return;
      }

      const priceForThisDiameter = priceRow.values[0];
      console.log(
        "Cena za 1 cm gwintu dla tej średnicy: %s zł",
        priceForThisDiameter
      );

      const threadDepthCm = thread.thread_depth / 10;
      console.log("Głebokość gwintu: %s cm", threadDepthCm);

      const priceForDepth = priceForThisDiameter * threadDepthCm;
      console.log("Cena za %s cm gwintu: %s zł", threadDepthCm, priceForDepth);

      console.log("Ilość tego typu gwintu: %s", thread.count);

      const priceForThisThread = thread.count * priceForDepth;

      console.log("Cena dla tego typu gwintu: %s zł", priceForThisThread);

      priceForAllThreads += priceForThisThread;

      console.groupEnd();
    });

    console.groupEnd();

    console.log(
      "%cCena za wszystkie gwintowania: %s zł",
      "text-decoration: underline;",
      priceForAllThreads
    );

    const totalPrice = toFixedNumber(
      setupPrice + priceForAllThreads * noOfElementCopies,
      2
    );

    console.log("Ilość sztuk:", noOfElementCopies);
    console.log(
      "Cena za całość %s zł + (%s x %s zł): %s zł",
      setupPrice,
      noOfElementCopies,
      priceForAllThreads,
      totalPrice
    );

    console.groupEnd();

    return totalPrice;
  }

  private deepening(type: "countersink" | "counterbore") {
    const isCountersink = type === "countersink";

    const serviceName = isCountersink
      ? "Pogłebianie stożkowe"
      : "Pogłebianie walcowe";

    console.group(serviceName);

    const { prices, params, noOfElementCopies } = this;
    const holes = isCountersink
      ? params.holes.counter_sink_holes
      : params.holes.counter_bore_holes;

    console.log("Ilość unikatowych pogłębień: %s", holes.length);

    console.log("Koszt pojedynczej nastawy maszyny: %s zł", prices.setup);

    const setupPrice = holes.length * prices.setup;

    console.log(
      "Cena za nastawy (%s * %s zł): %s zł",
      holes.length,
      prices.setup,
      setupPrice
    );
    console.log("\n");

    console.group("Sprawdzanie pogłębień (%s)", holes.length);

    let priceForAllHoles = 0;

    holes.forEach((hole, index) => {
      console.group("Pogłębienie nr.", index);

      console.log("Średnica otworu: %s mm", hole.hole_diameter);

      const priceRow = prices.table?.find((row) => {
        return (
          hole.hole_diameter >= row.scope[0] &&
          hole.hole_diameter < row.scope[1]
        );
      });

      if (!priceRow) {
        console.log(
          "%cBrak cennika dla średnicy %s mm",
          "color: red; font-weight: bold;",
          hole.hole_diameter
        );
        console.groupEnd();
        return;
      }

      const priceForThisDiameter = priceRow.values[0];
      console.log(
        "Cena za 1 cm pogłębienia dla tej średnicy: %s zł",
        priceForThisDiameter
      );

      const deepeningDepth = isCountersink ? hole.sink_depth : hole.bore_depth;
      const depthCm = deepeningDepth / 10;
      console.log("Głebokość pogłębienia: %s cm", depthCm);

      const priceForDepth = priceForThisDiameter * depthCm;
      console.log("Cena za %s cm pogłębienia: %s zł", depthCm, priceForDepth);

      console.log("Ilość tego typu pogłębień: %s", hole.count);

      const priceForThisDeepening = hole.count * priceForDepth;

      console.log(
        "Cena dla tego typu pogłębienia: %s zł",
        priceForThisDeepening
      );

      priceForAllHoles += priceForThisDeepening;

      console.groupEnd();
    });

    console.groupEnd();

    console.log("Cena za wszystkie pogłębienia: %s zł", priceForAllHoles);
    console.log("\n");

    const price = setupPrice + priceForAllHoles;
    const totalPrice = toFixedNumber(price * noOfElementCopies, 2);

    console.log(
      "Cena za pojedynczy detal (%s zł + %s zł): %s zł",
      setupPrice,
      priceForAllHoles,
      price
    );
    console.log("Ilość sztuk:", noOfElementCopies);
    console.log(
      "Cena za całość (%s x %s zł): %s zł",
      priceForAllHoles,
      price,
      totalPrice
    );

    console.groupEnd();

    return totalPrice;
  }

  private painting() {
    const { prices, params, noOfElementCopies } = this;

    console.group("Malowanie");

    const coatings = (params.coatings || []).filter(({ name }) =>
      name.includes("RAL")
    );

    let areaSummary = 0;

    coatings.forEach(({ area }: Surface) => {
      areaSummary += area;
    });

    console.log("Suma powierzchni do malowania: %s m2", areaSummary);
    console.log("Koszt malowania za m2: %s zł", prices.perSquare);

    const priceForPainting = areaSummary * prices.perSquare;

    console.log("Cena za malowanie detalu: %s zł", priceForPainting);
    console.log("\n");

    let tapingCost;

    console.log("Ilość styli: ", coatings.length);

    if (areaSummary === params.surface_area) {
      if (coatings.length === 1) {
        console.log("Tylko jeden styl i malowany cały element - nie oklejamy");

        tapingCost = 0;
      } else {
        const sortedSurfaces = coatings.sort((a, b) => {
          return b.area - a.area;
        });

        const [largest, ...withoutLargest] = sortedSurfaces;
        let areaSummary = 0;

        withoutLargest.forEach(({ area }) => {
          areaSummary += area;
        });

        tapingCost = areaSummary * prices.taping;

        console.log(
          "Style sumują się do powierzchni detalu - pomijamy styl o największej powierzchni (%s mm2)",
          largest.area
        );
        console.log("Cena za oklejanie 1m2: %s zł", prices.taping);
        console.log(
          "Cena za oklejanie detalu (%s m2 x %s zł): %s zł",
          areaSummary,
          prices.taping,
          tapingCost
        );
        console.log("\n");
      }
    } else {
      tapingCost = areaSummary * prices.taping;

      console.log("Style NIE sumują się do powierzchni detalu");
      console.log("Cena za oklejanie 1m2: %s zł", prices.taping);
      console.log(
        "Cena za oklejanie detalu (%s m2 x %s zł): %s zł",
        areaSummary,
        prices.taping,
        tapingCost
      );
      console.log("\n");
    }

    const price = priceForPainting + tapingCost;
    const totalPrice = toFixedNumber(price * noOfElementCopies, 2);

    console.log(
      "Cena za malowanie i oklejanie pojedynczego detalu (%s zł + %s zł): %s zł",
      priceForPainting,
      tapingCost,
      price
    );
    console.log(
      "Cena za malowanie wszystkich sztuk (%s zł * %s): %s zł",
      price,
      noOfElementCopies,
      totalPrice
    );

    console.groupEnd();

    return totalPrice;
  }

  private coating(type: string) {
    const { prices, params, noOfElementCopies } = this;

    console.group(type);

    const coatings = (params.coatings || []).filter(({ name }) =>
      name.includes(type)
    );

    let areaSummary = 0;

    coatings.forEach(({ area }: Surface) => {
      areaSummary += area;
    });

    console.log("Suma powierzchni: %s m2", areaSummary);
    console.log("Koszt za m2: %s zł", prices.perSquare);

    const price = areaSummary * prices.perSquare;
    const totalPrice = toFixedNumber(price * noOfElementCopies, 2);

    console.log(
      "Cena za detal (%s x %s): %s zł",
      areaSummary,
      prices.perSquare,
      price
    );
    console.log("Ilość sztuk:", noOfElementCopies);
    console.log(
      "Cena za całość (%s x %s zł): %s zł",
      noOfElementCopies,
      price,
      totalPrice
    );

    console.groupEnd();

    return totalPrice;
  }

  private grinding() {
    const { prices, params, noOfElementCopies } = this;

    console.group("Szlifowanie");

    console.log("Powierzchnia szlifowania: %s m2", params.grinding_area);
    console.log("Koszt za m2: %s zł", prices.perSquare);

    const price = params.grinding_area * prices.perSquare;
    const totalPrice = toFixedNumber(price * noOfElementCopies, 2);

    console.log(
      "Cena za detal (%s x %s): %s zł",
      params.grinding_area,
      prices.perSquare,
      price
    );
    console.log("Ilość sztuk:", noOfElementCopies);
    console.log(
      "Cena za całość (%s x %s zł): %s zł",
      noOfElementCopies,
      price,
      totalPrice
    );

    console.groupEnd();

    return totalPrice;
  }

  private calculateAmountPerSheet(machine, detailLength, detailWidth) {
    console.groupCollapsed("Obliczanie ilości detali per arkusz");

    const bokiObszaruRoboczego = machine.parameters
      .find(({ parameter_id }) => {
        return parameter_id === 1;
      })
      .value.split(",")
      .map((val) => +val);

    console.log("Pierwszy bok maszyny: %s mm", bokiObszaruRoboczego[0]);
    console.log("Drugi bok maszyny: %s mm", bokiObszaruRoboczego[1]);

    console.log("Długość detalu: %s mm", detailLength);
    console.log("Szerokość detalu: %s mm", detailWidth);

    const a = Math.floor(bokiObszaruRoboczego[0] / detailLength);
    const b = Math.floor(bokiObszaruRoboczego[1] / detailWidth);

    const c = Math.floor(bokiObszaruRoboczego[0] / detailWidth);
    const d = Math.floor(bokiObszaruRoboczego[1] / detailLength);

    const optionA = a * b;
    const optionB = c * d;

    const greater = optionA > optionB ? optionA : optionB;
    const lower = optionA < optionB ? optionA : optionB;

    console.log("Ilość detali per arkusz - wartość mniejsza: %s szt", lower);
    console.log("Ilość detali per arkusz - wartość większa: %s szt", greater);

    console.groupEnd();

    return greater;
  }
}
