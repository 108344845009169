import { Card } from "@components/Card";
import { Grinding } from "./Grinding";
import { Drilling } from "./Drilling";
import { Threading } from "./Threading";
import { CounterDeepening } from "./CounterDeepening";
import cs from "./AdditionalProcessing.module.scss";

export const AdditionalMetalProcessing = ({
  additionalMetalServices,
  onChange,
  onRemove,
}) => {
  return (
    <Card className="AdditionalMetalProcessing">
      <Card.Header className="h4">Dodatkowa obróbka metali</Card.Header>
      <Card.Body className={cs.services}>
        {additionalMetalServices.map((priceList) => {
          switch (priceList.service._id) {
            case 123:
              return (
                <Grinding
                  key={priceList.service._id}
                  priceList={priceList}
                  onChange={(updatedPriceList) => {
                    onChange(priceList.service._id, updatedPriceList);
                  }}
                  onRemove={onRemove}
                />
              );
            case 125:
              return (
                <Drilling
                  key={priceList.service._id}
                  priceList={priceList}
                  onChange={(updatedPriceList) => {
                    onChange(priceList.service._id, updatedPriceList);
                  }}
                  onRemove={onRemove}
                />
              );
            case 126:
              return (
                <Threading
                  key={priceList.service._id}
                  priceList={priceList}
                  onChange={(updatedPriceList) => {
                    onChange(priceList.service._id, updatedPriceList);
                  }}
                  onRemove={onRemove}
                />
              );
            case 127:
            case 128:
              return (
                <CounterDeepening
                  key={priceList.service._id}
                  priceList={priceList}
                  onChange={(updatedPriceList) => {
                    onChange(priceList.service._id, updatedPriceList);
                  }}
                  onRemove={onRemove}
                />
              );
          }
        })}
      </Card.Body>
    </Card>
  );
};
