import axios from "axios";
import { handleApiError } from "@helpers/utils";

const apiUrl = "/api/orders";

export async function hasUserDownloadedOrderFileBeforeApi(orderId: string) {
  try {
    const response = await axios.get(
      apiUrl + `/${orderId}/file/download/history`
    );

    return {
      isSuccess: response.status === 200,
      haveUserDownloadedOrderFileBefore: response.data,
    };
  } catch (errorWrapper: any) {
    return handleApiError(errorWrapper);
  }
}

export async function isUserPermittedToDownloadOrderFile(orderId: string) {
  try {
    const response = await axios.get(
      apiUrl + `/${orderId}/file/download/permisson`
    );

    return {
      isSuccess: response.status === 200,
      isPermissionGranted: response.data,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function requestPermissionToDownloadOrderFile(orderId: string) {
  try {
    const response = await axios.post(
      apiUrl + `/${orderId}/file/download/permisson-request`
    );

    return {
      isSuccess: response.status === 200,
      requestStatus: response.data.requestStatus,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}
