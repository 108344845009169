import { useEffect, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import * as Sentry from "@sentry/react";
import { Select } from "antd";
import converter from "ral-hex-converter";
import cs from "./ColorSelect.module.scss";

const { Option } = Select;

const rals = converter.rals;
const hexes = converter.hex;

export const ColorSelect = ({ value, readOnly, required, onChange }) => {
  const [touched, setTouched] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [color, setColor] = useState("black");

  const ref = useRef<HTMLDivElement>(null);

  useListenToSyntheticEvents(ref, setTouched);

  useLayoutEffect(() => {
    if (value) {
      try {
        const bgColor = converter.toHex(value);

        setBackgroundColor(bgColor);
        setColor(getContrast(bgColor));
      } catch (error) {
        Sentry.captureException(error);

        onChange(null);
      }
    }
  }, [onChange, value]);

  const rootCs = classNames("ColorSelect", cs.ColorSelect, {
    "control-invalid": touched && !readOnly && !checkValidity(),
  });

  return (
    <div className={rootCs} title={value} ref={ref}>
      <Select
        value={value}
        showSearch
        optionLabelProp="label"
        showArrow={false}
        style={{
          backgroundColor,
          color,
          border: value ? "none" : "1px solid #b2bec3",
          pointerEvents: readOnly ? "none" : "auto",
        }}
        dropdownMatchSelectWidth={false}
        onDropdownVisibleChange={(isDropdownOpened) => {
          if (!isDropdownOpened) {
            setTouched(true);
          }
        }}
        onChange={onChange}
      >
        {rals.map((ral, index) => (
          <Option key={ral} value={ral} label={ral}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>{ral}</span>

              <div
                style={{ width: 60, height: 20, backgroundColor: hexes[index] }}
              />
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );

  function checkValidity() {
    if (required && (value === null || value === undefined)) {
      return false;
    }

    return true;
  }
};

const useListenToSyntheticEvents = (ref, setTouched) => {
  useEffect(() => {
    const element = ref.current;
    const handler = () => {
      setTouched(true);
    };

    element.addEventListener("touched", handler);

    return () => {
      element.removeEventListener("touched", handler);
    };
  }, []);
};

function getContrast(hexcolor: string) {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map(function (hex) {
        return hex + hex;
      })
      .join("");
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "black" : "white";
}
