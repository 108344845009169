import { SheetCutting } from "./services/SheetCutting";
import { Bending } from "./services/AdditionalProcessing/Bending";
import { Painting } from "./services/Painting";
import { Coating } from "./services/Coating";

export function getSectionForService(
  service: any,
  element: any,
  readOnly: boolean,
  onTechnologiesChange,
  onChange
) {
  switch (service?._id) {
    case 1:
    case 3:
    case 4:
      return (
        <SheetCutting
          service={service}
          params={element.parameters}
          readOnly={readOnly}
          onTechnologiesChange={onTechnologiesChange}
          onChange={onChange}
        />
      );
    case 19:
      return (
        <Bending
          bends={element.parameters.mountings}
          readOnly={readOnly}
          onChange={onChange}
        />
      );
    case 43:
    case 53:
    case 59:
      return (
        <Painting
          service={service}
          params={element.parameters}
          readOnly={readOnly}
          onTechnologiesChange={onTechnologiesChange}
          onChange={onChange}
        />
      );
    case 44:
    case 45:
    case 46:
    case 47:
    case 48:
    case 49:
      return (
        <Coating
          service={service}
          params={element.parameters}
          readOnly={readOnly}
          onChange={onChange}
        />
      );
    default:
      return <Other service={service} />;
  }
}

const Other = ({ service }: { service: any }) => (
  <div
    style={{
      padding: "10px 20px",
      border: "1px solid #d7d7d7",
    }}
  >
    <div style={{ fontSize: "16px", fontWeight: "500" }}>{service.name}:</div>

    <div
      style={{
        paddingTop: "10px",
        paddingLeft: "12px",
        color: "#ff5f5f",
      }}
    >
      Ta usługa nie została jeszcze przez nas zdefiniowana.
    </div>
  </div>
);
