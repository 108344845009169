import { getDaysFromToday, toLocaleDate } from "@helpers/dateUtils";
import { trim } from "@helpers/utils";
import { getDayAgoString } from "@helpers/getDayAgoString";
import { getDaysRemaningText } from "@helpers/getDaysRemaningText";
import { getNoOfSetsText } from "@helpers/getNoOfSetsText";
import { getAddress } from "@helpers/getAddress";
import { getNoOfUnitsText } from "@helpers/getNoOfUnitsText";
import { getFileURL } from "@helpers/fileUtils";
import { OFFER_STATUSES } from "../../../../../constants/OFFER_STATUSES";
import { Modal } from "@components/Modal";
import { Chip } from "@components/Chip";
import { WithCompanyModal } from "@components/CompanyModal";
import { MaterialName } from "../../../NewOrderPage/Elements/Element/MaterialName";
import { TechnologyNames } from "../../../NewOrderPage/Elements/Element/TechnologyNames";
import fileImg from "./img/file.svg";
import cs from "./OfferDetailsModal.module.scss";

export const OfferDetailsModal = ({ order, offer, footer, onClose }) => {
  if (!offer) return null;

  const { owner_company } = offer;

  return (
    <Modal
      className={cs.OfferDetailsModal}
      title="Szczegóły oferty"
      visible={Boolean(offer)}
      width={1000}
      centered
      footer={footer}
      onClose={onClose}
    >
      {offer && (
        <>
          <section className={cs.alerts}>
            {offer.status === OFFER_STATUSES.ORDER_EDITED && (
              <div className={cs.approvalRequiredAlert}>
                <div className="body bold mb6">
                  Oferta oczekuje na zatwierdzenie
                </div>
                <div className="body">
                  Zapytanie ofertowe zostało zmodyfikowane po opublikowaniu tej
                  oferty. Zleceniobiorca musi zatwierdzić dotychczasową ofretę
                  lub złożyć nową.
                </div>
              </div>
            )}
          </section>

          <section className={cs.main}>
            <div>
              <div className="caption color-dark-gray mb4">Oferta od:</div>

              <div className="mb12">
                <div className="bold mb2">{offer.owner.name}</div>
                <div>{offer.owner.position}</div>

                {(offer.owner.phone || offer.owner.contact_email) && (
                  <div>
                    <span>Kontakt: </span>
                    <a href={`tel:${offer.owner.phone}`} className="link">
                      {offer.owner.phone}
                    </a>

                    {offer.owner.phone && offer.owner.contact_email && (
                      <span> | </span>
                    )}

                    {offer.owner.contact_email && (
                      <a
                        href={`mailto:${offer.owner.contact_email}`}
                        className="link"
                      >
                        {offer.owner.contact_email}
                      </a>
                    )}
                  </div>
                )}
              </div>

              <WithCompanyModal>
                {(openModal) => (
                  <>
                    <div
                      className="semi-bold mb2 clickable"
                      onClick={() => openModal(owner_company._id)}
                    >
                      {owner_company.name}
                    </div>
                  </>
                )}
              </WithCompanyModal>

              <div>
                <span>{getAddress(owner_company)}</span>
              </div>

              <div>NIP: {owner_company.tax_number}</div>

              <div>
                <span>Kontakt: </span>
                <a href={`tel:${owner_company.phone}`} className="link">
                  {owner_company.phone}
                </a>

                {owner_company.phone && owner_company.email && <span> | </span>}

                <a href={`mailto:${owner_company.email}`} className="link">
                  {owner_company.email}
                </a>
              </div>
            </div>

            <div>
              {offer.status === OFFER_STATUSES.WON && (
                <Chip color="green" size="caption" className="mb8">
                  Ta oferta wygrała
                </Chip>
              )}

              {offer.offer_period && (
                <div>
                  <span>Ważność oferty: </span>
                  <span className="bold">
                    <span>{toLocaleDate(offer.offer_period)} </span>
                    <span>
                      (
                      {getDaysRemaningText(
                        getDaysFromToday(offer.offer_period)
                      )}
                      )
                    </span>
                  </span>
                </div>
              )}

              <div>
                <span>Ofertę dodano: </span>
                <span className="bold">
                  <span>{toLocaleDate(offer.created_at)} </span>
                  <span>
                    (
                    {getDayAgoString(
                      Math.abs(getDaysFromToday(offer.created_at))
                    )}
                    )
                  </span>
                </span>
              </div>
            </div>
          </section>

          <section>
            <h2 className="h5 mb16">
              Elementy do wykonania ({order.elements.length})
            </h2>

            <div className="body">
              <span className="color-gray mr8">Ilość:</span>
              <span className="bold">
                {getNoOfSetsText(order.total_amount)}
              </span>
            </div>

            <div>
              {order.elements.map((element) => {
                const offerForElement = offer.elements.find(
                  ({ element_id }) => element_id === element._id
                );

                return (
                  <Element
                    element={element}
                    offerForElement={offerForElement?.offer}
                  />
                );
              })}
            </div>
          </section>

          <section className="mt50">
            <div className="h5 mb16">Podsumowanie</div>

            <div className={cs.summary}>
              <div className="body">
                <div className="color-gray mb4">Opis oferty:</div>
                <div>{offer.desc ? offer.desc : "-"}</div>
              </div>
              <div className="body">
                <div className="color-gray mb4">Załączone pliki:</div>
                <div>
                  {offer.offer_files?.length > 0 ? (
                    <ul>
                      {offer.offer_files?.map((file: any) => (
                        <li key={file.name} className="mb8">
                          <img src={fileImg} />
                          <span className="body bold ml8">{file.name}</span>

                          <a href={getFileURL(file)} className={cs.fileLink}>
                            Pobierz
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="body">
                <div className="color-gray mb4">
                  Kwota całościowa za zrealizowanie zamówienia:
                </div>
                <div className="h2 mt4 mb4">
                  {offer.offer_sum} PLN
                  <span style={{ fontSize: 20 }}> + VAT</span>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Modal>
  );
};

const Element = ({ element, offerForElement }) => {
  const files = [element.file, ...element.relatedFiles];

  return (
    <div className={cs.Element}>
      <div>
        <div className="mb4 body bold">{element.name}</div>
        <div className="caption">{element.desc}</div>
      </div>

      <div>
        <div className="mb12">
          <div className="caption mb2">Materiał:</div>
          <div className="caption color-black">
            <MaterialName
              materialId={element.material_id}
              typeId={element.material_type_id}
            />
          </div>
        </div>

        <div>
          <div className="caption mb2">Usługi:</div>
          <div className="caption color-black">
            <TechnologyNames
              serviceIds={element.services.map(({ _id }) => _id)}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="mb12">
          <div className="caption mb2">Pliki dokumentacji:</div>
          <div className="caption color-black">
            {files.map(({ name }) => name).join(", ")}
          </div>
        </div>

        <div>
          <div className="caption mb2">Uwagi zlecającego:</div>
          <div className="caption color-black">
            {trim(element.remarks || "-", 60)}
          </div>
        </div>
      </div>

      <div>
        <div className="mb12">
          <div className="caption mb4">Łączna ilość:</div>
          <div className="caption color-black bold">
            {getNoOfUnitsText(element.total_amount)}
          </div>
        </div>

        <div className="caption">Ilość szt. w komplecie: {element.amount}</div>
      </div>

      {offerForElement ? (
        <div>
          <div className="mb12">
            <div className="caption color-black bold mb2">Cena detal</div>

            <Chip color="blue" size="caption">
              {offerForElement.price} zł
            </Chip>
          </div>

          <div>
            <div> Cena sumaryczna:</div>

            <div>
              <span>
                {element.total_amount} x {offerForElement.price} zł ={" "}
              </span>
              <strong>{element.total_amount * offerForElement.price} zł</strong>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Chip color="red" size="caption">
            Brak oferty
          </Chip>
        </div>
      )}
    </div>
  );
};
