import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Radio as RadioAntd } from "antd";
import cs from "./Radio.module.scss";

export const RadioGroup = ({
  value,
  required,
  readOnly = false,
  children,
  onChange,
}) => {
  const [touched, setTouched] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useListenToSyntheticEvents(ref, setTouched);

  const rootCs = classNames("RadioGroup", cs.RadioGroup, {
    "control-invalid": touched && !readOnly && !checkValidity(),
  });

  return (
    <div className={rootCs} ref={ref}>
      <RadioAntd.Group
        value={value}
        style={{
          display: "flex",
          flexDirection: "column",
          pointerEvents: readOnly ? "none" : "auto",
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {children}
      </RadioAntd.Group>
    </div>
  );

  function checkValidity() {
    if (required && (value === null || value === undefined)) {
      return false;
    }

    return true;
  }
};

RadioGroup.Radio = RadioAntd;

const useListenToSyntheticEvents = (ref, setTouched) => {
  useEffect(() => {
    const input = ref.current;
    const handler = () => {
      setTouched(true);
    };

    input.addEventListener("touched", handler);

    return () => {
      input.removeEventListener("touched", handler);
    };
  }, []);
};
