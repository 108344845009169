import { useState } from "react";
import { notification, Spin } from "antd";
import { downloadFile } from "@helpers/utils";
import { BtnSize, Button } from "@components/Button";
import { WithDownloadLogic } from "@components/DownloadOrderDocs/WithDownloadLogic";

type Props = {
  order: any;
  size: BtnSize;
  disabled?: boolean;
  title?: string;
};

export const DownloadOrderDocsBtn = ({
  order,
  size,
  disabled = false,
  title = "",
}: Props) => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  return (
    <WithDownloadLogic order={order} download={handleDocumentationDownload}>
      {(handleClick: any) => (
        <Spin spinning={isDownloadLoading}>
          <Button
            type="outline"
            size={size}
            disabled={disabled}
            title={title}
            onClick={handleClick}
          >
            Pobierz dokumentację zapytania
          </Button>
        </Spin>
      )}
    </WithDownloadLogic>
  );

  async function handleDocumentationDownload() {
    setIsDownloadLoading(true);

    try {
      await downloadFile(`/api/orders/${order._id}/file/download`);

      return true;
    } catch (e) {
      notification.error({
        message: `Coś poszło nie tak. Spróbuj ponownie później.`,
        placement: "topRight",
      });

      return false;
    } finally {
      setIsDownloadLoading(false);
    }
  }
};
