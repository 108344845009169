export const MinusSvg = ({ isDisabled = false }) => {
  const color = isDisabled ? "#DFE6E9" : "#4C83C3";

  return (
    <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill={color}>
        <path d="M8.66663 15c1.85647 0 3.63697-.7375 4.94977-2.0503C14.9291 11.637 15.6666 9.85652 15.6666 8c0-1.85652-.7375-3.63699-2.0502-4.94975C12.3036 1.7375 10.5231 1 8.66663 1c-1.85652 0-3.637.7375-4.94975 2.05025C2.40412 4.36301 1.66663 6.14348 1.66663 8c0 1.85652.73749 3.637 2.05025 4.9497C5.02963 14.2625 6.81011 15 8.66663 15Zm0 1c2.12177 0 4.15657-.8429 5.65687-2.3431 1.5003-1.5003 2.3431-3.5352 2.3431-5.6569 0-2.12173-.8428-4.15656-2.3431-5.65685C12.8232.842855 10.7884 0 8.66663 0 6.54489 0 4.51006.842855 3.00977 2.34315 1.50948 3.84344.666626 5.87827.666626 8c0 2.1217.842854 4.1566 2.343144 5.6569C4.51006 15.1571 6.54489 16 8.66663 16Z" />
        <path d="M4.16663 8c0-.13261.05267-.25979.14644-.35355.09377-.09377.22095-.14645.35356-.14645h7.99997c.1326 0 .2598.05268.3536.14645.0937.09376.1464.22094.1464.35355 0 .13261-.0527.25979-.1464.35355-.0938.09377-.221.14645-.3536.14645H4.66663c-.13261 0-.25979-.05268-.35356-.14645C4.2193 8.25979 4.16663 8.13261 4.16663 8Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.666626)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
