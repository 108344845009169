import { Link } from "react-router-dom";
import { Card } from "@components/Card";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Baner } from "../../components/Baner";
import { CompanyTypeSelection } from "./CompanyTypeSelection";
import sharedCs from "../../styles.module.scss";
import cs from "./RegisterPage.module.scss";

export const RegisterPagePure = ({ form, onChange, onSubmit }) => {
  return (
    <main className={`RegisterPage ${cs.RegisterPage}`}>
      <Baner type="register" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <form onSubmit={onSubmit}>
          <Card>
            <Card.Header className="h5">Zarejestruj się</Card.Header>

            <Card.Body>
              <div className={sharedCs.formInputs}>
                <CompanyTypeSelection type={form.type} onChange={onChange} />

                <Input
                  name="first_name"
                  label="Imię"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={onChange}
                />

                <Input
                  name="last_name"
                  label="Nazwisko"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={onChange}
                />

                <Input
                  name="email"
                  type="email"
                  label="Email"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={onChange}
                />

                <Input
                  name="name"
                  label="Nazwa firmy"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={onChange}
                />

                <Input
                  name="tax_number"
                  label="NIP"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={onChange}
                />

                <Input
                  name="password"
                  type="password"
                  label="Hasło"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={onChange}
                />

                <Input
                  name="password_2"
                  type="password"
                  label="Powtórz hasło"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={onChange}
                />
              </div>
            </Card.Body>

            <Card.Footer>
              <Button
                type="primary"
                size="large"
                btnType="submit"
                style={{ width: "100%" }}
              >
                Dalej
              </Button>

              <div className="caption mt24 text-center">
                <span>Posiadasz już konto? </span>
                <Link to="/login" className={sharedCs.link}>
                  Zaloguj się
                </Link>
              </div>
            </Card.Footer>
          </Card>
        </form>

        <PrivacyPolicy />
      </section>
    </main>
  );
};
