import { useEffect, useState } from "react";
import classNames from "classnames";
import { InputNumber } from "@components/InputNumber";
import { AddNextBtn } from "../../AddNextBtn";
import { Angle } from "./Angle";
import deleteImg from "../../img/delete.svg";
import cs from "./Bending.module.scss";

export const LineLength = ({
  lineLengthGroup,
  readOnly,
  onChange,
  onRemove,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (lineLengthGroup.angles.length === 0) {
      setTimeout(() => {
        handleAngleGroupAdded();
      });
    }
  }, [lineLengthGroup.angles]);

  const rootCs = classNames(cs.LineLength, {
    [cs.hovered]: isHovered,
  });

  return (
    <div className={rootCs}>
      <div className={cs.withBtn}>
        <div className={cs.input}>
          <span>Długość linii gięcia</span>
          <InputNumber
            size="small"
            value={lineLengthGroup.length}
            min={0.01}
            readOnly={readOnly}
            required
            onChange={handleLineLengthChanged}
          />
          <span>mm</span>
        </div>

        {!readOnly && (
          <img
            src={deleteImg}
            alt=""
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => onRemove(lineLengthGroup.tempId)}
          />
        )}
      </div>

      <div>
        {lineLengthGroup.angles.map((angleGroup) => (
          <Angle
            angleGroup={angleGroup}
            readOnly={readOnly}
            onChange={handleAngleGroupChanged}
            onRemove={handleAngleGroupRemoved}
          />
        ))}

        {!readOnly && (
          <AddNextBtn className="mt4" onClick={handleAngleGroupAdded} />
        )}
      </div>
    </div>
  );

  function handleLineLengthChanged(newValue) {
    onChange({ ...lineLengthGroup, length: newValue });
  }

  function handleAngleGroupAdded() {
    onChange({
      ...lineLengthGroup,
      angles: [
        ...lineLengthGroup.angles,
        {
          angle: null,
          count: null,
        },
      ],
    });
  }

  function handleAngleGroupRemoved(removedTempId) {
    onChange({
      ...lineLengthGroup,
      angles: lineLengthGroup.angles.filter(
        (angle) => angle.tempId !== removedTempId
      ),
    });
  }

  function handleAngleGroupChanged(updatedAngleGroup) {
    onChange({
      ...lineLengthGroup,
      angles: lineLengthGroup.angles.map((angle) => {
        if (angle.tempId === updatedAngleGroup.tempId) {
          return updatedAngleGroup;
        }

        return angle;
      }),
    });
  }
};
