import { Link } from "react-router-dom";
import { Card } from "@components/Card";
import machineImg from "./img/machine.svg";
import cs from "./SubscriptionDisabled.module.scss";

export const SubscriptionDisabled = () => (
  <Card className={"SubscriptionDisabled " + cs.SubscriptionDisabled}>
    <Card.Body className="body">
      <div>
        <img src={machineImg} alt="" width={150} />
      </div>
      <div>
        <p>
          <span>
            W chwili obecnej nie masz włączonego dostępu do strefy usługodawcy.
            Możesz uzyskać do niego dostęp po zaznaczeniu tej opcji w panel
            usługodawcy przy swoim koncie przechodząc do Ustawienia &#8594;
            Użytkownicy. Aby przejść do tych ustawień
          </span>
          &nbsp;
          <Link to="/account/users" className="color-primary semi-bold">
            kliknij tutaj.
          </Link>
        </p>
      </div>
    </Card.Body>
  </Card>
);
