import axios from "axios";

const apiUrl = "/api/review";

export async function getReviewTargetApi(offerId: string) {
  try {
    const response = await axios.get(`${apiUrl}/${offerId}/target`);

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function addReviewApi(offerId: string, review: any) {
  try {
    const response = await axios.post(`${apiUrl}/${offerId}`, review);

    return {
      isSuccess: response.status === 201,
    };
  } catch (error: any) {
    console.error(error);

    return {
      isSuccess: false,
      error: error.response?.data?.error?.message,
    };
  }
}

export async function editReviewApi(review: any) {
  try {
    const response = await axios.put(`${apiUrl}/${review._id}`, review);

    return {
      isSuccess: response.status === 200,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function getReviewApi(reviewId: string) {
  try {
    const response = await axios.get(`${apiUrl}/${reviewId}`);

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}
