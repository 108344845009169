import { useEffect, useRef, useState } from "react";
import { notification } from "antd";
import {
  cancelSubscriptionApi,
  getInvoicesApi,
} from "../../services/subscription.api";
import { store, useStore } from "@store";
import { addDays, toRelative } from "@helpers/dateUtils";
import { Card } from "@components/Card";
import { Button } from "@components/Button";
import { Table } from "@components/Table";
import { Confirmation } from "@components/Confirmation";
import { columns } from "./tableColumns";
import cs from "./BillingsPage.module.scss";

export const BillingsPage = () => {
  const cancelConfirmation = useRef<any>(null);

  const subscription = store.hooks.useSubscription();
  const invoices = useInvoices();

  const isTrial = invoices.length === 1;
  const isCancellation = subscription.status === "CANCELLATION";

  return (
    <main className={cs.BillingsPage}>
      <section>
        <Card className={cs.currentPackage}>
          <Card.Header className="h5">Aktualny pakiet</Card.Header>
          <Card.Body>
            {isCancellation && (
              <div className="mb12 caption" style={{ color: "#d63031" }}>
                Subskrypcja została anulowana. Dostęp kończy się wraz z końcem
                ostatniego opłaconego okresu rozliczeniowego.
              </div>
            )}

            <div>
              <span className="caption">Cykl płatności: </span>
              <span className="semi-bold body">
                {subscription.interval === "month" ? "miesięczny" : "roczny"}
              </span>
            </div>
            <div>
              <span className="caption">Ilość licencji: </span>
              <span className="semi-bold body">
                {subscription.no_of_licenses}
              </span>
            </div>

            {isTrial && (
              <div>
                <span className="caption">Koniec okresu próbnego: </span>
                <span className="semi-bold body">
                  {toRelative(addDays(invoices[0].period_end, 1))}
                </span>
              </div>
            )}
          </Card.Body>

          {subscription.status === "ONGOING" && (
            <Card.Footer className="text-right">
              <form
                method="POST"
                action="/api/subscription/portal"
                className="d-inline-block"
              >
                <Button type="primary" size="medium" btnType="submit">
                  Zarządzaj
                </Button>
              </form>

              <Button
                type="primary"
                size="medium"
                className="ml8"
                style={{ backgroundColor: "#D63031" }}
                onClick={handleCancel}
              >
                Zrezygnuj
              </Button>
            </Card.Footer>
          )}
        </Card>
      </section>

      <section className="mt75">
        <Table title="Historia płatności" data={invoices} columns={columns} />
      </section>

      <Confirmation
        ref={cancelConfirmation}
        title="Czy chcesz zrezygnować z subskrypcji?"
        text="Panel Usługodawcy nie będzie dłużej dostępny dla pracowników Twojej firmy."
        cancelText="Cofnij"
        okText="Potwierdź"
      />
    </main>
  );

  async function handleCancel() {
    const isConfirmed = await cancelConfirmation.current.open();

    if (isConfirmed) {
      const response = await cancelSubscriptionApi();

      if (response.isSuccess) {
        await useStore.getState().fetchSubscriptionInfo();

        notification.success({
          message:
            "Subskrypcja zostania anulowana na koniec obecnego okresu rozliczeniowego. Żadne nowe opłaty nie zostaną naliczone.",
          placement: "topRight",
        });
      }
    }
  }
};

function useInvoices() {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getInvoicesApi();

      if (response.isSuccess) {
        setInvoices(response.data);
      }
    })();
  }, []);

  return invoices;
}
