import { OFFER_STATUSES } from "../constants/OFFER_STATUSES";

export function getOfferMainStatusText(status: OFFER_STATUSES) {
  if (status === OFFER_STATUSES.ONGOING) {
    return "W trakcie";
  }
  if (
    status === OFFER_STATUSES.WON ||
    status === OFFER_STATUSES.LOST ||
    status === OFFER_STATUSES.CANCELLED
  ) {
    return "Zakończone";
  }
  if (status === OFFER_STATUSES.ORDER_EDITED) {
    return "Zapytanie zmienione";
  }
  if (status === OFFER_STATUSES.ORDER_CANCELLED) {
    return "Zapytanie anulowane";
  }

  throw new Error(`Offer status '${status}' unrecognized.`);
}
