import React from "react";
import classNames from "classnames";
import cs from "./Card.module.scss";

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const Card = ({ children, className, onClick = () => {} }: Props) => {
  const childrenArr = React.Children.toArray(children);

  const headerEl = childrenArr.find((child) => child.type === Header);
  const bodyEl = childrenArr.find((child) => child.type === Body);
  const footerEl = childrenArr.find((child) => child.type === Footer);

  const rootCs = classNames("Card", cs.Card, className);

  return (
    <div className={rootCs} onClick={onClick}>
      {headerEl}
      {bodyEl}
      {footerEl}
    </div>
  );
};

const Header = ({ children, className = "" }) => {
  const rootCs = classNames("Card_Header", cs.header, className);

  return <div className={rootCs}>{children}</div>;
};

const Body = ({ children, className = "" }) => {
  const rootCs = classNames("Card_Body", cs.body, className);

  return <div className={rootCs}>{children}</div>;
};

const Footer = ({ children, className = "" }) => {
  const rootCs = classNames("Card_Footer", cs.footer, className);

  return <div className={rootCs}>{children}</div>;
};

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;
