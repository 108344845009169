import { useState } from "react";
import { store } from "@store";
import { addChatMessage, ChatMessage } from "@services/chat.api";
import { useChatMessagesForOrder } from "@services/chat.hooks";
import { formatTime, toLocaleDate } from "@helpers/dateUtils";
import { Card } from "@components/Card";
import { Textarea } from "@components/Textarea";
import { Button } from "@components/Button";
import cs from "./Chat.module.scss";

export const Chat = ({ orderId }: { orderId: string }) => {
  const [message, setMessage] = useState("");

  const [messages, setMessages] = useChatMessagesForOrder(orderId);
  const currentUser = store.hooks.useUser();

  return (
    <Card className={cs.Chat}>
      <Card.Header className="h5">
        Wyślij wiadomość do zleceniodawcy
      </Card.Header>
      <Card.Body className="body">
        <section>
          {messages.map((message) => (
            <Message key={message._id} message={message} />
          ))}
        </section>

        <section className="text-right">
          <Textarea
            value={message}
            placeholder="Napisz wiadomość"
            onChange={setMessage}
          />

          <Button
            type="secondary"
            size="small"
            className="mt12"
            onClick={handleAddChatMessage}
          >
            Wyślij wiadomość
          </Button>
        </section>
      </Card.Body>
    </Card>
  );

  async function handleAddChatMessage() {
    if (!message) return;

    const { isSuccess, newMessageId } = await addChatMessage({
      order_id: orderId,
      message,
      provider_id: currentUser._id,
    });

    if (isSuccess && newMessageId) {
      setMessage("");

      setMessages([
        ...messages,
        {
          _id: newMessageId,
          message,
          author_name: currentUser.name,
          author_id: currentUser._id,
          provider_id: currentUser._id,
          created_at: new Date(),
          order_id: orderId,
        },
      ]);
    }
  }
};

const Message = ({ message }: { message: ChatMessage }) => (
  <div className={cs.Message}>
    <div>
      <span className="body semi-bold">{message.author_name}</span>
      <span>
        , {formatTime(message.created_at)}, {toLocaleDate(message.created_at)}
      </span>
    </div>
    <div>{message.message}</div>
  </div>
);
