import { useState } from "react";
import classNames from "classnames";
import { InputNumber } from "@components/InputNumber";
import { RadioGroup } from "@components/Radio";
import deleteImg from "../../img/delete.svg";
import threadImg from "./img/thread.png";
import threadThroughImg from "./img/thread_through.png";
import cs from "./Threads.module.scss";

export const Depth = ({
  diameterId,
  depth,
  index,
  readOnly,
  onDepthChange,
  onDepthRemoved,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const rootCs = classNames(cs.Deepening, {
    [cs.hovered]: isHovered,
  });

  return (
    <div className={rootCs}>
      <div className="d-flex align-center justify-between mb16">
        <span className="bold">Głębokość {index + 1}:</span>

        {!readOnly && (
          <img
            src={deleteImg}
            alt=""
            style={{ cursor: "pointer" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
              onDepthRemoved(diameterId, depth.tempId);
            }}
          />
        )}
      </div>

      <div className="d-flex">
        <div>
          <div className="d-flex align-center justify-between mb16">
            <span className="mr32">Ilość</span>
            <InputNumber
              value={depth.count}
              style={{ width: "63px" }}
              size="small"
              min={1}
              step={1}
              readOnly={readOnly}
              required
              onChange={(value) => {
                handleChange("count", value);
              }}
            />
          </div>

          <div>
            <RadioGroup
              value={depth.through_hole}
              readOnly={readOnly}
              required
              onChange={(value) => {
                handleChange("through_hole", value);
              }}
            >
              <RadioGroup.Radio
                value={true}
                className="caption color-dark-gray"
              >
                Otwór przelotowy
              </RadioGroup.Radio>
              <RadioGroup.Radio
                value={false}
                className="caption color-dark-gray mt4"
              >
                Otwór o okreslonej glebokosci
              </RadioGroup.Radio>
            </RadioGroup>
          </div>
        </div>

        {depth.through_hole ? (
          <HoleThroughImg
            depth={depth}
            readOnly={readOnly}
            onChange={handleChange}
          />
        ) : (
          <HoleImg depth={depth} readOnly={readOnly} onChange={handleChange} />
        )}
      </div>
    </div>
  );

  function handleChange(property, value) {
    onDepthChange(diameterId, depth.tempId, property, value);
  }
};

const HoleImg = ({ depth, readOnly, onChange }) => (
  <div className={cs.drillImgContainer}>
    <div style={{ position: "relative" }}>
      <InputNumber
        value={depth.thread_depth}
        style={{
          width: "48px",
          position: "absolute",
          top: "14px",
          left: "-30px",
        }}
        size="small"
        min={0.01}
        readOnly={readOnly}
        required
        onChange={(value) => onChange("thread_depth", value)}
      />
      <img src={threadImg} alt="" width={219} />
      <InputNumber
        value={depth.hole_depth}
        style={{
          width: "48px",
          position: "absolute",
          top: "24px",
          right: "0",
        }}
        size="small"
        min={0.01}
        readOnly={readOnly}
        required
        onChange={(value) => onChange("hole_depth", value)}
      />
    </div>
  </div>
);

const HoleThroughImg = ({ depth, readOnly, onChange }) => (
  <div className={cs.drillImgContainer}>
    <div className="d-flex align-center">
      <img src={threadThroughImg} alt="" />
      <InputNumber
        value={depth.thread_depth}
        style={{
          width: "48px",
          position: "relative",
          top: "-2px",
          right: "49px",
        }}
        size="small"
        min={0.01}
        readOnly={readOnly}
        required
        onChange={(value) => onChange("thread_depth", value)}
      />
    </div>
  </div>
);
