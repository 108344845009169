import { useEffect, useState } from "react";
import {
  _groupTechnologiesByService,
  connectFilesWithElement,
} from "@services/orders";
import { calcAvgRating } from "@services/review.helpers";
import { _enrichElementInformation } from "../../Customer/services/cad/cad.api";
import { servicesToTechnologies } from "../../Customer/services/technology.helpers";
import { getOrderApi } from "./order.api";
import { ORDER_STATUSES } from "../../constants/ORDER_STATUSES";

export function useOrder(orderId: string) {
  const [order, setOrder] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (!orderId) return;

    (async () => {
      const { isSuccess, data, error } = await getOrderApi(orderId);

      if (!isSuccess) {
        return setError(error);
      }

      const { order, offers, downloadHistory } = data;

      if (order.status === ORDER_STATUSES.DRAFT) {
        order.elements = order.elements.map((element) => {
          element = _enrichElementInformation(element);

          return {
            ...element,
            technology_ids: servicesToTechnologies(element.services),
          };
        });
      }

      setOrder({
        ..._groupTechnologiesByService(connectFilesWithElement(order)),
        offers: offers.map((offer) => {
          const avgRating = calcAvgRating(offer.owner_company.reviews);

          return {
            ...offer,
            owner_company: { ...offer.owner_company, avgRating },
            isPartial: order.elements.length !== offer.offer_elements_count,
            anyMaterialsChanged: offer.elements.some(
              (el) => el.offer.material_id !== undefined
            ),
          };
        }),
        downloadHistory,
      });
    })();
  }, [orderId]);

  return [order, error];
}
