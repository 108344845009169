import { TechnologyAnalyser } from "./cad/CadAnalyser";

export function handleNewMaterialSet(element, selectedMaterial) {
  const updatedElement = {
    ...element,
    material_id: selectedMaterial.materialId,
    material_type_id: selectedMaterial.typeId,
  };

  new TechnologyAnalyser(updatedElement).setServices();

  return updatedElement;
}
