import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { toRelative } from "@helpers/dateUtils";
import cs from "./Notification.module.scss";

export const Base = ({ text, url, seen, created_at }) => {
  const navigate = useNavigate();

  const rootCs = classNames("Notification", cs.Notification, {
    [cs.seen]: seen,
  });

  return (
    <div className={rootCs} onClick={() => navigate(url)}>
      <div style={{ lineHeight: "20px" }}>
        <div className={cs.bulb} />
      </div>

      <div>
        <div className="body bold">{text}</div>

        <div className="caption">{toRelative(created_at)}</div>
      </div>
    </div>
  );
};
