import { useEffect, useState } from "react";
import { PROVINCES } from "../../../../constants/PROVINCES";
import { Modal } from "@components/Modal";
import { Button } from "@components/Button";
import { DatePicker } from "@components/DatePicker";
import { Select } from "@components/Select";
import cs from "./AdditionalFilterModal.module.scss";

export const AdditionalFilterModal = ({
  isOpen,
  filters,
  onClose,
  onFilterChange,
}) => {
  const [region, setRegion] = useState();
  const [bidDeadline, setBidDeadline] = useState();
  const [doneDeadline, setDoneDeadline] = useState();

  const anyFilteSet = region || bidDeadline || doneDeadline;

  useEffect(() => {
    setRegion(filters.region);
    setBidDeadline(filters.bidDeadline);
    setDoneDeadline(filters.doneDeadline);
  }, [filters]);

  useEffect(() => {
    if (!isOpen) {
      onFilterChange("region", region);
      onFilterChange("bidDeadline", bidDeadline);
      onFilterChange("doneDeadline", doneDeadline);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Filtry"
      visible={isOpen}
      width={480}
      footer={
        <div>
          <Button type="outline" size="medium" onClick={handleClear}>
            Wyczyść wszystko
          </Button>
          <Button
            type="primary"
            size="medium"
            className="ml16"
            disabled={!anyFilteSet}
            onClick={handleConfirm}
          >
            Zastosuj
          </Button>
        </div>
      }
      onClose={handleClear}
    >
      <div className={cs.AdditionalFilterModal}>
        <div>
          <div className="body bold mb12">Lokalizacja</div>
          <Select
            name="region"
            value={region}
            options={PROVINCES}
            placeholder="Wybierz województwo"
            onChange={setRegion}
          />
        </div>
        <div>
          <div className="body bold mb12">Termin realizacji</div>
          <DatePicker
            value={doneDeadline}
            placeholder="Wybierz termin"
            minDate={DatePicker.onlyFromToday}
            onChange={setDoneDeadline}
          />
        </div>

        <div>
          <div className="body bold mb12">Termin składania ofert</div>
          <DatePicker
            value={bidDeadline}
            placeholder="Wybierz termin"
            minDate={DatePicker.onlyFromToday}
            onChange={setBidDeadline}
          />
        </div>
      </div>
    </Modal>
  );

  function clear() {
    setRegion(null);
    setBidDeadline(null);
    setDoneDeadline(null);
  }

  function handleClear() {
    clear();
    onClose();
  }

  function handleConfirm() {
    onClose();
  }
};
