import { store } from "@store";
import {
  AdditionalMetalProcessing,
  AdditionalPlasticProcessing,
  AdditionalWoodProcessing,
} from "./services/AdditionalProcessing";
import { getSectionForService } from "./getSectionForService";
import cs from "./ServiceDetails.module.scss";

const ADD_METAL_PROCESSING = [123, 125, 126, 127, 128];
const ADD_PLASTIC_PROCESSING = [129, 131, 132];
const ADD_WOOD_PROCESSING = [133, 135, 136];

export const ServiceDetails = ({
  element,
  isEditable,
  onChange,
  onTechnologiesChange,
}) => {
  const allServices = store.hooks.useServices();

  const { services } = element;

  return (
    <div className={`ServiceDetails ${cs.ServiceDetails}`}>
      {services.map((service) => {
        if (
          ADD_METAL_PROCESSING.concat(ADD_PLASTIC_PROCESSING)
            .concat(ADD_WOOD_PROCESSING)
            .includes(service._id)
        ) {
          return null;
        }

        const serviceDetails = allServices.find(
          ({ _id }) => _id === service._id
        );

        return getSectionForService(
          { ...serviceDetails, ...service },
          element,
          !isEditable,
          onTechnologiesChange,
          onChange
        );
      })}

      {services.some(({ _id }) => ADD_METAL_PROCESSING.includes(_id)) && (
        <AdditionalMetalProcessing
          services={services}
          element={element}
          readOnly={!isEditable}
          onChange={onChange}
        />
      )}

      {services.some(({ _id }) => ADD_PLASTIC_PROCESSING.includes(_id)) && (
        <AdditionalPlasticProcessing
          services={services}
          element={element}
          readOnly={!isEditable}
          onChange={onChange}
        />
      )}

      {services.some(({ _id }) => ADD_WOOD_PROCESSING.includes(_id)) && (
        <AdditionalWoodProcessing
          services={services}
          element={element}
          readOnly={!isEditable}
          onChange={onChange}
        />
      )}
    </div>
  );
};
