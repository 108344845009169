import { CSSProperties, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Select as AntdSelect } from "antd";
import cs from "./Select.module.scss";

const { Option } = AntdSelect;

type Props = {
  value?: string | number | string[] | number[] | null;
  label?: string;
  className?: string;
  placeholder?: string;
  style?: CSSProperties;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  options: any[];
  size?: "default" | "small" | "large";
  mode?: "multiple" | "tags";
  onChange: (
    value: string | number | undefined | string[] | number[] | null
  ) => void;
};

export const Select = ({
  value,
  label,
  className,
  placeholder,
  style,
  options = [],
  readOnly = false,
  required = false,
  allowClear = false,
  showSearch = false,
  size = "default",
  mode,
  onChange,
}: Props) => {
  const [touched, setTouched] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useListenToSyntheticEvents(ref, setTouched);

  // NOTE: Don't accept values not included in options
  if (options.length > 0 && value && !Array.isArray(value)) {
    const selectedOption = options.find((option) => option.value === value);

    if (!selectedOption) {
      value = null;
      onChange(null);
    }
  }

  const csRoot = classNames("Select", cs.root, className, {
    [cs.default]: size === "default",
    [cs.small]: size === "small",
    [cs.large]: size === "large",
    "control-invalid": touched && !readOnly && !checkValidity(),
  });

  return (
    <div ref={ref} className={csRoot}>
      {label && <label className="caption bold color-black mb4">{label}</label>}

      <AntdSelect
        value={value}
        disabled={readOnly}
        style={style}
        placeholder={placeholder}
        className={cs.select}
        allowClear={allowClear}
        showSearch={showSearch}
        notFoundContent="Brak wyników"
        optionFilterProp="label"
        mode={mode}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onDropdownVisibleChange={(isDropdownOpened) => {
          if (!isDropdownOpened) {
            setTouched(true);
          }
        }}
        onChange={onChange}
      >
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </Option>
        ))}
      </AntdSelect>
    </div>
  );

  function checkValidity() {
    if (required && (value === null || value === undefined)) {
      return false;
    }

    return true;
  }
};

const useListenToSyntheticEvents = (ref, setTouched) => {
  useEffect(() => {
    const element = ref.current;
    const handler = () => {
      setTouched(true);
    };

    element.addEventListener("touched", handler);

    return () => {
      element.removeEventListener("touched", handler);
    };
  }, []);
};
