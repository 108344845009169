export function getDayAgoString(noOfDays: number) {
  if (noOfDays === 0) {
    return "dzisiaj";
  }

  if (noOfDays === 1) {
    return "1 dzień temu";
  }

  return `${noOfDays} dni temu`;
}
