import { store } from "@store";
import { Chip } from "@components/Chip";

export const Machines = ({ element }) => {
  const availableServices = store.hooks.useServices();

  return element.services.map(({ _id }) => {
    const service = availableServices.find((service) => {
      return service._id === _id;
    });
    const selectedMachine = element.selectedMachinesPerService.get(_id);

    if (!selectedMachine) {
      return (
        <Machines.NotFound key={service.name} serviceName={service.name} />
      );
    }

    let name = "";

    if (selectedMachine.isDefault) {
      name = "Cennik domyślny";
    } else {
      name = selectedMachine.model_name;
    }

    return (
      <Chip
        key={selectedMachine._id}
        color="gray"
        size="caption"
        className="mb4"
      >
        <span>
          <strong>{name} </strong>
          <span style={{ fontWeight: 400 }}>({service.name})</span>
        </span>
      </Chip>
    );
  });
};

Machines.NotFound = ({ serviceName }: { serviceName: string }) => (
  <Chip color="light-red" size="caption" className="mb4">
    <div style={{ fontWeight: 400 }}>
      <div>Brak odpowiedniego cennika </div>
      <div className="color-dark-gray">({serviceName})</div>
    </div>
  </Chip>
);
