import {
  FormEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { notification, Spin } from "antd";
import { createPublicOrderApi } from "../../../public/services/public_order.api";
import { Modal } from "@components/Modal";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import cs from "./ContactInfoModal.module.scss";

type Resolver = () => void;

export const ContactInfoModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useImmer<any>({});
  const [isSaving, setIsSaving] = useState(false);
  const [termsOfUseAccepted, setTermsOfUseAccepted] = useState(false);

  const resolver = useRef<Resolver>();
  const saveObj = useRef<any>(null);
  const contactFormSubmitter = useRef<HTMLButtonElement>(null);
  const permissionsFormSubmitter = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  const allPermsChecked = form.email_contact_permission && termsOfUseAccepted;

  return (
    <Modal
      title="Podaj dane kontaktowe"
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okText="Utwórz zapytanie"
      width={640}
      onClose={handleClose}
      footer={
        <form className="text-left" onSubmit={handlePermissionsFormSubmit}>
          <div className={cs.permissions}>
            <Checkbox
              checked={allPermsChecked || false}
              label="Akceptuję wszystkie zgody"
              onChange={(e) => {
                const isChecked = e.target.checked;

                setTermsOfUseAccepted(isChecked);
                setForm((prev: any) => {
                  prev.email_contact_permission = isChecked;
                });
              }}
            />

            <Checkbox
              checked={termsOfUseAccepted}
              required
              label={
                <span>
                  Akceptuję Regulamin i Politykę prywatności
                  <span style={{ color: "#c91013" }}> *</span>
                </span>
              }
              onChange={(e) => setTermsOfUseAccepted(e.target.checked)}
            />

            <Checkbox
              checked={form.email_contact_permission || false}
              label="Akceptuję zgody marketingowe"
              onChange={(e) =>
                setForm((prev: any) => {
                  prev.email_contact_permission = e.target.checked;
                })
              }
            />

            <p className={cs.explanation}>
              Zgadzam się na kontakt marketingowy ze strony 4Dustry na podany
              adres e-mail. Jeśli wyrazisz zgodę, będziemy kontaktować się z
              Tobą w wybrany przez Ciebie sposób, aby zaprezentować naszą
              ofertę. Możesz zawsze wycofać zgodę na kontakt marketingowy, np.
              pisząc na adres e-mail{" "}
              <a href="mailto:support@4dustry.com" className="link">
                support@4dustry.com
              </a>{" "}
              lub skorzystać z linku w stopce wiadomości od nas. Administratorem
              Twoich danych jest 4Dustry Sp. z o.o. Więcej o tym, jak chronimy
              Twoje dane dowiesz się w{" "}
              <a
                href="https://www.4dustry.com/polityka-prywatnosci"
                className="link"
                target="_blank"
              >
                polityce prywatności.
              </a>
            </p>

            <p className="mt8 pl6">
              <span style={{ color: "#c91013" }}>* </span>
              <span style={{ fontSize: 12 }}>Zgody oznaczone są wymagane</span>
            </p>
          </div>

          <button ref={permissionsFormSubmitter} style={{ display: "none" }} />

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Spin spinning={isSaving}>
              <Button
                type="primary"
                btnType="button"
                size="medium"
                onClick={handleConfirm}
              >
                Utwórz zapytanie
              </Button>
            </Spin>
          </div>
        </form>
      }
    >
      <form className={cs.form} onSubmit={handleContactFormSubmit}>
        <Input
          value={form.firstName}
          name="first_name"
          label="Imię"
          size="default"
          required
          autoComplete="new-password"
          onChange={handleFormFieldChanged}
        />

        <Input
          value={form.lastName}
          name="last_name"
          label="Nazwisko"
          size="default"
          required
          autoComplete="new-password"
          onChange={handleFormFieldChanged}
        />

        <Input
          value={form.email}
          name="email"
          type="email"
          label="Email"
          size="default"
          required
          autoComplete="new-password"
          onChange={handleFormFieldChanged}
        />

        <Input
          value={form.phone}
          name="phone"
          type="tel"
          label="Telefon"
          size="default"
          required
          placeholder="+48 231-321-342"
          pattern="(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)"
          autoComplete="new-password"
          onChange={handleFormFieldChanged}
        />

        <Input
          value={form.companyName}
          name="name"
          label="Nazwa firmy"
          size="default"
          required
          autoComplete="new-password"
          onChange={handleFormFieldChanged}
        />

        <Input
          value={form.taxNumber}
          name="tax_number"
          label="NIP"
          size="default"
          required
          autoComplete="new-password"
          onChange={handleFormFieldChanged}
        />

        {/* NOTE: Hidden submitter */}
        <button
          type="submit"
          style={{ display: "none" }}
          ref={contactFormSubmitter}
        />
      </form>
    </Modal>
  );

  function handleFormFieldChanged(value, field) {
    setForm((prev) => {
      prev[field] = value;
    });
  }

  function handleClose() {
    resolver.current?.();
    setIsOpen(false);
  }

  function handleConfirm() {
    if (!saveObj.current) return;

    // NOTE: Check if all required contact fields have been provided
    contactFormSubmitter.current?.click();
  }

  function handleContactFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // NOTE: Check if all required permissions have been checked
    permissionsFormSubmitter.current?.click();
  }

  async function handlePermissionsFormSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setIsSaving(true);

    try {
      saveObj.current.set("publicUser", JSON.stringify(form));

      const response = await createPublicOrderApi(saveObj.current);

      if (response.isSuccess) {
        handleClose();

        navigate("/public/order-created", { replace: true });
      } else if (response.error === "USER_ALREADY_EXISTS") {
        notification.error({
          message: `Już istnieje użytkownik o tym adresie email. Zaloguj się aby utworzyć zapytanie.`,
          placement: "topRight",
        });
      } else if (response.error === "ACCOUNT_ALREADY_EXISTS") {
        notification.error({
          message: `Już istnieje firma o tej nazwie.`,
          placement: "topRight",
        });
      } else if (response.error === "LIMIT_FILE_SIZE") {
        notification.error({
          message: `Maksymalny rozmiar pliku to 15mb.`,
          placement: "topRight",
        });
      } else if (response.error === "LIMIT_FILE_COUNT") {
        notification.error({
          message: `Całkowita liczba plików związanych z zapytaniem nie może przekroczyć 55.`,
          placement: "topRight",
        });
      } else if (response.error === "LIMIT_ADDITIONAL_FILE_COUNT") {
        notification.error({
          message: `Maksymalna liczba dodatkowych plików projektowych wynosi 15.`,
          placement: "topRight",
        });
      } else if (response.error === "ORDERS_LIMIT_REACHED") {
        notification.error({
          message: `Wykorzystałeś dzienny limit liczby utworzonych zapytań.`,
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Wystąpił błąd przy tworzeniu zapytania.",
          description: "Spróbuj ponownie za jakiś czas.",
          placement: "topRight",
        });
      }
    } finally {
      setIsSaving(false);
    }
  }

  function openModal(saveObject: any) {
    saveObj.current = saveObject;

    setIsOpen(true);

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  }
});
