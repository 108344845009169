import { BendingMachinesLevelFields } from "./BendingMachinesLevelFields";

export function getMachinesLevelFields(serviceId: number) {
  switch (serviceId) {
    case 19:
      return BendingMachinesLevelFields;
    default:
      return null;
  }
}
