import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { Card } from "@components/Card";
import { Button } from "@components/Button";
import { Btn } from "../../../../Customer/views/NewOrderPage/Elements/Element/Element";
import { Field } from "./Field";
import cs from "../PricesPage.module.scss";

export const Painting = ({ priceList, onChange, onRemove }) => {
  const [draft, setDraft] = useImmer({});
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setDraft(priceList);
  }, [priceList]);

  const pricing = draft.pricings?.[0];

  if (!pricing) return null;

  const { prices = {} } = pricing;

  return (
    <Card className={`PriceList ${cs.PriceListWithMachinesAndMaterials}`}>
      <Card.Header className="PriceList_header">
        <div className="h4">{priceList.service.name}</div>

        <Button
          type="secondary"
          size="small"
          onClick={() => onRemove(pricing._id)}
        >
          Usuń usługę
        </Button>
      </Card.Header>

      <Card.Body>
        <Field
          label="Koszt malowania:"
          name="perSquare"
          value={prices.perSquare}
          isEdited={isEdited}
          unit={
            <>
              zł/m<sup>2</sup>
            </>
          }
          onChange={handleChange}
        />

        <Field
          label="Zabezpieczenie przed malowaniem (Oklejanie):"
          name="taping"
          value={prices.taping}
          isEdited={isEdited}
          unit={
            <>
              zł/m<sup>2</sup>
            </>
          }
          onChange={handleChange}
        />

        <div className="mt4">
          {isEdited ? (
            <Btn onClick={handleSave}>Zapisz zmiany</Btn>
          ) : (
            <Btn onClick={() => setIsEdited(true)}>Edytuj</Btn>
          )}
        </div>
      </Card.Body>
    </Card>
  );

  function handleChange(field, value) {
    setDraft((prev) => {
      if (!prev.pricings[0].prices) {
        prev.pricings[0].prices = {};
      }

      prev.pricings[0].prices[field] = value;
    });
  }

  function handleSave() {
    setIsEdited(false);

    onChange(draft);
  }
};
