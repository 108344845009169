import { InputNumber } from "@components/InputNumber";
import cs from "./Parameter.module.scss";

type Props = {
  value: string;
  label: string;
  unit?: string;
  isEdited: boolean;
  dimensions: 1 | 2 | 3;
  onChange: (value: string) => void;
};

export const Parameter = ({
  value,
  label,
  unit = "mm",
  isEdited,
  dimensions,
  onChange,
}: Props) => {
  value = value ?? "";

  return isEdited ? (
    <Edited
      label={label}
      value={value}
      unit={unit}
      dimensions={dimensions}
      onChange={onChange}
    />
  ) : (
    <ReadOnly label={label} value={value} unit={unit} />
  );
};

const ReadOnly = ({
  label,
  value,
  unit,
}: Pick<Props, "label" | "value" | "unit">) => {
  const values = value.split(",");

  return (
    <div className="caption">
      <span>{label}: </span>
      <span className="color-dark-gray">
        {values.join(" x ")} {unit}
      </span>
    </div>
  );
};

const Edited = ({
  label,
  value,
  unit,
  dimensions,
  onChange,
}: Pick<Props, "label" | "value" | "unit" | "dimensions" | "onChange">) => {
  const values = value.length > 0 ? value.split(",").map((val) => +val) : [];

  return (
    <div className={`Parameter__edited ${cs.edited}`}>
      <span className="caption mr4">{label}:</span>

      <InputNumber
        value={values[0]}
        size="small"
        min={0}
        onChange={(val) => handleChange(val, 1)}
      />

      {(dimensions === 2 || dimensions === 3) && (
        <>
          <span className="caption color-dark-gray"> x </span>
          <InputNumber
            value={values[1]}
            size="small"
            min={0}
            onChange={(val) => handleChange(val, 2)}
          />
        </>
      )}

      {dimensions === 3 && (
        <>
          <span className="caption color-dark-gray"> x </span>
          <InputNumber
            value={values[2]}
            size="small"
            min={0}
            onChange={(val) => handleChange(val, 3)}
          />
        </>
      )}

      <span className="ml4">{unit}</span>
    </div>
  );

  function handleChange(newValue: number, dimension: 1 | 2 | 3) {
    const values = value.split(",").map((val) => +val);

    values[dimension - 1] = newValue;

    onChange(values.join(","));
  }
};
