import { Skeleton } from "antd";
import cs from "./Elements.module.scss";

export const LoadingElement = ({ listType }: { listType: "min" | "full" }) =>
  listType === "full" ? <Full /> : <Min />;

const Full = () => (
  <div
    className={cs.LoadingElement}
    style={{
      borderBottom: "1px solid rgb(223, 230, 233)",
      marginBottom: "24px",
      marginTop: "24px",
    }}
  >
    <div className={cs.main}>
      <div />

      <Skeleton.Image />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 4,
          paddingRight: 12,
        }}
      >
        <Skeleton.Input size="small" active style={{ width: "70%" }} />
        <Skeleton.Input size="small" active style={{ width: "90%" }} />
        <Skeleton.Input size="small" active style={{ width: "45%" }} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 12,
          paddingRight: 12,
        }}
      >
        <Skeleton.Input size="default" active style={{ width: "70%" }} />
        <Skeleton.Input size="default" active style={{ width: "50%" }} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 12,
          paddingRight: 12,
        }}
      >
        <Skeleton.Input size="default" active style={{ width: "50%" }} />
        <Skeleton.Input size="default" active style={{ width: "55%" }} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 24,
          paddingRight: 12,
        }}
      >
        <Skeleton.Input size="large" active style={{ width: "90%" }} />
        <Skeleton.Input size="default" active style={{ width: "85%" }} />
      </div>
    </div>
    <div
      style={{
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Skeleton.Input size="small" active style={{ width: 180 }} />
    </div>
  </div>
);

const Min = () => (
  <div
    style={{
      display: "flex",
      height: "77px",
      paddingTop: 12,
      paddingBlock: 12,
      borderBottom: "1px solid rgb(223, 230, 233)",
    }}
  >
    <div style={{ width: 32 }} />

    <div
      style={{ display: "flex", flexDirection: "column", rowGap: 4, flex: 1 }}
    >
      <Skeleton.Input size="small" active style={{ width: "80%" }} />
      <Skeleton.Input size="small" active style={{ width: "70%" }} />
    </div>

    <div
      style={{ display: "flex", flexDirection: "column", rowGap: 4, flex: 1 }}
    >
      <Skeleton.Input size="small" active style={{ width: "80%" }} />
      <Skeleton.Input size="small" active style={{ width: "40%" }} />
    </div>

    <div
      style={{ display: "flex", flexDirection: "column", rowGap: 4, flex: 1 }}
    >
      <Skeleton.Input size="large" active style={{ width: "70%" }} />
    </div>

    <div
      style={{ display: "flex", flexDirection: "column", rowGap: 4, flex: 1 }}
    >
      <Skeleton.Input size="small" active style={{ width: "80%" }} />
      <Skeleton.Input size="small" active style={{ width: "50%" }} />
    </div>

    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Skeleton.Input size="small" active style={{ width: "80%" }} />
    </div>
  </div>
);
