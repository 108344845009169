import { Card } from "@components/Card";

export const OrderDescription = ({ desc }) => (
  <Card>
    <Card.Header className="h5">Opis zapytania</Card.Header>
    <Card.Body className="body">
      {desc ? desc : <span style={{ color: "#636E72" }}>Brak opisu</span>}
    </Card.Body>
  </Card>
);
