import classNames from "classnames";
import cs from "./PrivacyPolicy.module.scss";

export const PrivacyPolicy = ({ className }: { className?: string }) => {
  const rootCs = classNames("PrivacyPolicy", cs.PrivacyPolicy, className);

  return (
    <div className={rootCs}>
      <span>4Dustry | </span>

      <a href="https://www.4dustry.com/polityka-prywatnosci/" target="_blank">
        Polityka prywatności
      </a>
    </div>
  );
};
