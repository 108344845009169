import { useState } from "react";
import { Spin } from "antd";
import { Modal } from "@components/Modal";
import emptyImg from "./img/empty.png";
import cs from "./ViewerModal.module.scss";

export const ViewerModal = ({ isOpen, file, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);

  const isReady = Boolean(file.viewer_3d_url);

  const notReady = (
    <div className={cs.empty}>
      <img src={emptyImg} alt="Model not ready yet" />

      <div className={cs.empty_text}>
        <p>Model nie jest jeszcze gotowy.</p>
        <p>Spróbuj ponownie za 15-30 minut.</p>
      </div>
    </div>
  );

  const viewer = (
    <Spin tip="Ładowanie modelu..." spinning={isLoading} size="large">
      <iframe
        id="3dviewerplayer"
        width="100%"
        height="480"
        src={file.viewer_3d_url}
        allow="fullscreen"
        style={{ border: "none" }}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </Spin>
  );

  return (
    <Modal
      title="Model 3d"
      visible={isOpen}
      width={isReady ? 700 : 500}
      maskClosable={false}
      className={cs.ViewerModal}
      onClose={onClose}
    >
      {isReady ? viewer : notReady}
    </Modal>
  );
};
