import {
  getMachinelessServicesApi,
  getMachineParkApi,
} from "@services/machine.api";
import { fetchOfferedServices } from "@services/account.api";

export type MachineParkSlice = {
  machinePark: any[];
  machinelessServices: any[];
  services: any[];
  fetchMachinePark: () => Promise<void>;
  fetchMachinelessServices: () => Promise<void>;
  fetchOfferedServices: () => Promise<void>;
};

export const machineParkSlice = (set: any): MachineParkSlice => ({
  machinePark: [],
  machinelessServices: [],
  services: [],
  fetchMachinePark: async () => {
    const { machines, isSuccess } = await getMachineParkApi();

    if (isSuccess) {
      set({ machinePark: machines }, false, "machinePark/fetchMachines");
    }
  },
  fetchMachinelessServices: async () => {
    const { data, isSuccess } = await getMachinelessServicesApi();

    if (isSuccess) {
      set(
        { machinelessServices: data },
        false,
        "machinePark/fetchMachinelessServices"
      );
    }
  },
  fetchOfferedServices: async () => {
    const { data, isSuccess } = await fetchOfferedServices();

    if (isSuccess) {
      set({ services: data }, false, "machinePark/fetchOfferedServices");
    }
  },
});

export const selectors = {};
