import { useImmer } from "use-immer";
import { Modal } from "@components/Modal";
import { MaterialSelect } from "@components/MaterialSelect";

export const MaterialModal = ({ isOpen, onClose, onConfirm }) => {
  const [selectedOption, setSelectedOption] = useImmer({});

  return (
    <Modal
      title="Materiał"
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okText="Zapisz zmiany"
      width={480}
      onOk={() => {
        handleConfirm();
        onClose();
      }}
      onClose={onClose}
    >
      <MaterialSelect
        materialId={selectedOption?.materialId}
        typeId={selectedOption?.typeId}
        width="100%"
        placeholder="Wybierz"
        onChange={setSelectedOption}
      />
    </Modal>
  );

  function handleConfirm() {
    if (selectedOption.materialId || selectedOption.typeId) {
      onConfirm(selectedOption.materialId, selectedOption.typeId);
    }
  }
};
