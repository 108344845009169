import { Upload } from "antd";
import { Button } from "@components/Button";
import cs from "./Uploader.module.scss";

const { Dragger } = Upload;

export const Uploader = ({ compact, onFileUpload }) => {
  const props = {
    multiple: true,
    showUploadList: false,
    beforeUpload(file: File, fileList: File[]) {
      const index = fileList.indexOf(file);

      if (index === 0) {
        onFileUpload(fileList);
      }
      return false;
    },
  };

  return (
    <div className={cs.Uploader}>
      <Dragger {...props}>
        <div className={cs.box}>
          {!compact && (
            <>
              <div className={`${cs.dragInfo} hide-mobile`}>
                Przeciągnij i upuść pliki tutaj
              </div>
              <div className="hide-mobile">lub</div>
            </>
          )}

          <Button type="primary" size="medium">
            Wybierz pliki z dysku
          </Button>
        </div>
      </Dragger>
    </div>
  );
};
