import classNames from "classnames";
import plusImg from "../img/plus.svg";
import cs from "./AddNextBtn.module.scss";

export const AddNextBtn = ({ className, onClick }) => {
  const rootCs = classNames(cs.AddNextBtn, className);

  return (
    <div className={rootCs} onClick={onClick}>
      <img src={plusImg} alt="" className="mr4" />
      <span>Dodaj następny</span>
    </div>
  );
};
