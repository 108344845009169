import { Table } from "@components/Table";
import { columns } from "./tableColumns";

export const DownloadHistory = ({ downloadHistory }) => (
  <section className="mt24">
    <Table
      data={downloadHistory}
      columns={columns}
      title="Historia pobierania dokumentacji"
      empty={{ title: "Brak pobrań" }}
    />
  </section>
);
