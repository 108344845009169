import axios from "axios";

const apiUrl = "/api/account/";

export async function getAccountByIdApi(accountId: string) {
  const response = await axios.get(apiUrl + accountId);

  return response.data;
}

export async function getAccountDetailsForCurrentUser() {
  const response = await axios.get(apiUrl);

  return response.data;
}

export async function saveCompanyDetailsApi(companyData) {
  try {
    const response = await axios.put(apiUrl, companyData);

    return {
      isSuccess: response.status === 200,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function getUsersOfCurrentCompany() {
  const response = await axios.get(apiUrl + "users");

  return response.data;
}

export async function sendInvitationApi(inviteeDetails: any) {
  try {
    const response = await axios.post(apiUrl + "invite", inviteeDetails);

    return {
      isSuccess: response.status === 200,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}

export async function deleteUserFromCompanyApi(userId: string) {
  try {
    const response = await axios.delete(apiUrl + "users/" + userId);

    return {
      isSuccess: response.status === 204,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function fetchOfferedServices() {
  try {
    const response = await axios.get(apiUrl + "service");

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function saveOfferedServices(serviceIds: number[]) {
  try {
    const response = await axios.put(apiUrl + "service", { serviceIds });

    return {
      isSuccess: response.status === 204,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}
