import { ReactPortal, useState } from "react";
import ReactDOM from "react-dom";
import { getAvailableMachinesApi } from "../../../services/machine.api";
import { SelectMachineModal } from "./SelectMachineModal";

type ReturnType = [ReactPortal | null, () => Promise<any>];

export function useSelectMachineModal(): ReturnType {
  const [modal, setModal] = useState<ReactPortal | null>(null);

  async function openModal() {
    const modalContainer = document.querySelector(".modal-container");
    const { data, isSuccess } = await getAvailableMachinesApi();

    if (!isSuccess) return null;

    return new Promise((resolve) => {
      const _modal = ReactDOM.createPortal(
        <SelectMachineModal
          models={data.models}
          brands={data.brands}
          types={data.types}
          onConfirm={handleConfirm}
          onClose={handleClose}
        />,
        modalContainer as Element
      );

      setModal(_modal);

      function handleConfirm(selectedService: any) {
        resolve(selectedService);
        setModal(null);
      }

      function handleClose() {
        resolve(null);
        setModal(null);
      }
    });
  }

  return [modal, openModal];
}
