import axios from "axios";
import { filterToQs, handleApiError, qs, sortToQs } from "@helpers/utils";

const apiUrl = "/api/orders";

export async function getMyOrdersApi(
  sort: Set<string>,
  filter?: Record<string, string | number>
) {
  const response = await axios.get(
    apiUrl + qs(sortToQs(sort), filterToQs(filter))
  );

  return response.data;
}

export async function getMyUrgentOrdersApi() {
  const response = await axios.get(apiUrl + "/urgent");

  return response.data;
}

export async function cancelOrderApi(orderId: string) {
  try {
    const response = await axios.put(apiUrl + `/${orderId}/cancel`);

    return {
      isSuccess: response.status === 200,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function createOrderApi(order) {
  try {
    const response = await axios.post(apiUrl, order, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      isSuccess: response.status === 200,
      newOrderId: response.data.newOrderId,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function selectOfferApi(orderId, offerId) {
  try {
    const response = await axios.put(apiUrl + `/${orderId}/offer/${offerId}`);

    return {
      isSuccess: response.status === 200,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function updateOrderApi(orderId, saveObj) {
  try {
    const response = await axios.put(apiUrl + `/${orderId}`, saveObj);

    return {
      isSuccess: response.status === 200,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function getOrderApi(orderId: string) {
  try {
    const response = await axios.get(apiUrl + `/${orderId}`);

    return {
      isSuccess: true,
      data: response.data,
    };
  } catch (error) {
    return handleApiError(error);
  }
}

export async function docsPermissionChoiceApi(
  orderId: string,
  companyId: string,
  choice: "grant" | "deny"
) {
  try {
    const response = await axios.post(
      apiUrl + `/${orderId}/file/download/permisson`,
      { companyId, decision: choice }
    );

    return {
      isSuccess: response.status === 200,
    };
  } catch (errorWrapper: any) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}
