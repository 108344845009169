import { useStore } from "@store";
import { getSubscriptionInfoApi } from "../Organization/services/subscription.api";

export type CompanySlice = {
  subscription: any;
  fetchSubscriptionInfo: () => Promise<void>;
};

export const companySlice = (set: any): CompanySlice => ({
  subscription: {},
  fetchSubscriptionInfo: async () => {
    const { data, isSuccess } = await getSubscriptionInfoApi();

    if (isSuccess) {
      set({ subscription: data }, false, "company/fetchSubscriptionInfo");
    }
  },
});

export const hooks = {
  useSubscription() {
    const subscription = useStore((state) => state.subscription);

    return subscription;
  },
};
