import {
  groupTechnologiesByService2,
  groupTechnologiesByServiceRetMap,
} from "@services/technology.helpers";

export function connectFilesWithElement(order) {
  return {
    ...order,
    order_files: order.order_files.filter((file) => file),
    elements: order.elements.map((element) => {
      const mainFile = order.files.find((file) => {
        return file.is_main && element.file_ids.includes(file._id);
      });

      const relatedFiles = order.files.filter((file) => {
        return !file.is_main && element.file_ids.includes(file._id);
      });

      return {
        ...element,
        file: mainFile,
        relatedFiles,
      };
    }),
  };
}

export function _groupTechnologiesByService(order) {
  return {
    ...order,
    elements: order.elements.map((element) => {
      return {
        ...element,
        // TODO: Two of the same - decide to one form
        services: groupTechnologiesByService2(element.technology_ids),
        technologies: groupTechnologiesByServiceRetMap(element.technology_ids),
      };
    }),
  };
}
