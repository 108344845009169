import { Table } from "@components/Table";
import { Page } from "./Page";
import { columns } from "./tableColumns";
import { UrgentOffer } from "./UrgentOffer";
import cs from "./MyOffersPage.module.scss";

export const MyOffersPagePure = ({
  offers,
  sort,
  urgentOffers,
  user,
  sortByField,
}) => (
  <Page>
    {urgentOffers?.length > 0 && (
      <section className={cs.urgentList}>
        {urgentOffers.map((offer) => (
          <UrgentOffer offer={offer} />
        ))}
      </section>
    )}

    <section>
      <Table
        data={offers}
        columns={columns(user.is_admin)}
        location="/offer"
        sort={sort}
        empty={{
          title: "Ta lista jest pusta",
          sub: "Poszukaj zleceń, na które będziesz mógł złożyć swoje oferty",
        }}
        onSort={sortByField}
      />
    </section>
  </Page>
);
