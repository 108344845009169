import { useEffect } from "react";
import produce from "immer";
import { genTempId } from "@helpers/utils";
import { AddNextBtn } from "../../AddNextBtn";
import { Radius } from "./Radius";
import bendingImg from "./img/bending.png";
import cs from "./Bending.module.scss";

export const Bending = ({ bends = [], readOnly, onChange }) => {
  bends = addTempId(bends);

  useEffect(() => {
    if (bends.length === 0) {
      setTimeout(() => {
        handleRadiusGroupAdded();
      });
    }
  }, [bends]);

  return (
    <div className={cs.Bending}>
      <div className="h4 mb24">Gięcie blach</div>

      <div className={cs.main}>
        <div>
          <img src={bendingImg} alt="" />
        </div>
        <div>
          {bends.map((radiusGroup) => (
            <Radius
              radiusGroup={radiusGroup}
              readOnly={readOnly}
              onChange={handleRadiusGroupChanged}
              onRemove={handleRadiusGroupRemoved}
            />
          ))}

          {!readOnly && (
            <AddNextBtn className="mt4" onClick={handleRadiusGroupAdded} />
          )}
        </div>
      </div>
    </div>
  );

  function handleRadiusGroupChanged(updatedRadius) {
    onChange(
      "mountings",
      bends.map((bend) => {
        if (bend.tempId === updatedRadius.tempId) {
          return updatedRadius;
        }

        return bend;
      })
    );
  }

  function handleRadiusGroupAdded() {
    onChange("mountings", [
      ...bends,
      {
        radius: null,
        lineLengths: [
          {
            length: null,
            angles: [
              {
                angle: null,
                count: null,
              },
            ],
          },
        ],
      },
    ]);
  }

  function handleRadiusGroupRemoved(removedTempId) {
    onChange(
      "mountings",
      bends.filter((bend) => bend.tempId !== removedTempId)
    );
  }
};

function addTempId(bends: any): any {
  return produce(bends, (draft) => {
    draft.forEach((radiusGroup: any) => {
      radiusGroup.tempId = genTempId();

      radiusGroup.lineLengths.forEach((lineLengthGroup) => {
        lineLengthGroup.tempId = genTempId();

        lineLengthGroup.angles.forEach((angleGroup) => {
          angleGroup.tempId = genTempId();
        });
      });
    });
  });
}
