import { useNavigate } from "react-router-dom";
import { toLocaleDate } from "@helpers/dateUtils";
import { getAddress } from "@helpers/getAddress";
import { Chip } from "@components/Chip";
import { TillEndChips } from "@components/TillEndChips";
import cs from "./ListItem.module.scss";

export const ListItem = ({ order }) => {
  const navigate = useNavigate();

  const { owner_company } = order;

  let shortDesc = order.desc;

  if (shortDesc?.length > 400) {
    shortDesc = shortDesc.slice(0, 397) + "...";
  }

  return (
    <div
      className={`ListItem ${cs.ListItem}`}
      onClick={() => {
        navigate(`/offer/order/${order._id}`);
      }}
    >
      <Thumbnails elements={order.elements} />

      <div className="caption mr48">
        <div className="h6 mb16">
          {shortDesc ? shortDesc : `Zapytanie ofertowe ${order.seq_no}`}
        </div>
        <div>
          <span>Ilość kompletów: </span>
          <span className="color-black">{order.total_amount}</span>
        </div>
        <div>
          <span>Unikalne elementy do wykonania: </span>
          <span className="color-black">{order.elements.length}</span>
        </div>
        <div>
          <span>Termin realizacji: </span>
          <span className="color-black">
            {toLocaleDate(order.done_deadline)}
          </span>
        </div>
        <div className="mb24">
          <span>Termin składania ofert: </span>
          <span className="color-black">
            {toLocaleDate(order.bid_deadline)}
          </span>
        </div>

        <div className="d-flex">
          {order.isCompatible && (
            <Chip color="green" size="caption" className="mr8">
              Zgodne z PM
            </Chip>
          )}

          <TillEndChips
            date={order.bid_deadline}
            size="caption"
            text="Do końca ofertowania"
          />
        </div>
      </div>

      <div className={cs.client}>
        {owner_company && (
          <>
            <div className="mb8">Zlecenie od:</div>

            <div className="mb8">
              <span className="body bold color-dark-gray">
                {owner_company.name}
              </span>
            </div>

            <div className="color-dark-gray">
              <span>{getAddress(owner_company)}</span>
            </div>

            <div className="color-dark-gray mb24">
              NIP: {owner_company.tax_number}
            </div>
          </>
        )}

        <div className="mb2">
          <span>Numer zapytania: </span>
          <span className="color-black">{order.seq_no}</span>
        </div>

        <div className="mb2">
          <span>Opublikowano: </span>
          <span className="color-black">{toLocaleDate(order.created_at)}</span>
        </div>

        {/*<div>*/}
        {/*  <span>Ilość ofert: </span>*/}
        {/*  <span className="color-black">{order.offers_count}</span>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

const Thumbnails = ({ elements }) => {
  let mainElements;
  let lastFourElements = [];

  elements = elements.filter((el) => el.thumbnail_storage_key);

  if (elements.length > 4) {
    mainElements = elements.slice(0, 3);
    lastFourElements = elements.slice(3, 7);
  } else {
    mainElements = elements.slice(0, 4);
  }

  return (
    <div className={cs.imgContainer}>
      {mainElements.map(({ _id, thumbnail_storage_key }) => (
        <img
          key={_id}
          src={`/api/file/public/${thumbnail_storage_key}`}
          alt=""
        />
      ))}

      {lastFourElements.length > 0 && (
        <div className={cs.extraImgs}>
          {lastFourElements.map(({ _id, thumbnail_storage_key }) => (
            <img
              key={_id}
              src={`/api/file/public/${thumbnail_storage_key}`}
              alt=""
            />
          ))}
        </div>
      )}
    </div>
  );
};
