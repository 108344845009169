import exclamationImg from "./img/exclamation.svg";
import cs from "./EmailNotConfirmedWarning.module.scss";

export const EmailNotConfirmedWarning = () => {
  return (
    <div className={cs.EmailNotConfirmedWarning}>
      <img src={exclamationImg} alt="" />
      <span>Adres email nie został potwierdzony.</span>
    </div>
  );
};
