import { useEffect, useRef } from "react";
import { useImmer } from "use-immer";
import nestedProperty from "nested-property";
import { getTechnologyIdsByService } from "@services/technology.helpers";
import { Modal } from "@components/Modal";
import { Viewer3D } from "@components/Viewer3D";
import { handleNewMaterialSet } from "../../Customer/services/element.helpers";
import { ServiceDetails } from "./ServiceDetails";
import { Material } from "./Material";
import { Files } from "./Files";
import { Services } from "./Services";
import { TotalAmount } from "./TotalAmount";
import { Amount } from "./Amount";
import { Remarks } from "./Remarks";
import cs from "./ElementDetailsModal.module.scss";

export const ElementDetailsModal = ({
  visible,
  element,
  noOfSets,
  mode,
  footer,
  onClose,
  onConfirm,
}) => {
  const [elementDraft, setElementDraft] = useImmer(element);

  const serviceSectionEl = useRef(null);

  const isEditable = mode !== "readonly";

  useEffect(() => {
    if (visible) {
      setElementDraft(element);
    }
  }, [visible, element]);

  return (
    <Modal
      className={cs.modal}
      title="Szczegóły elementu"
      okText="Pokaż następny detal"
      visible={visible}
      width={1000}
      footer={footer}
      cancelButtonProps={{ hidden: true }}
      onClose={handleClose}
      onOk={handleConfirm}
    >
      {elementDraft && element && (
        <>
          <section className={cs.main}>
            <div className="d-flex-col-center">
              <div className={cs.imgContainer}>
                <img
                  src={
                    element.thumbnail ||
                    process.env.PUBLIC_URL + "/no_thumbnail_200x200.png"
                  }
                  alt=""
                />
              </div>

              {!isEditable && <Viewer3D file={element.file} />}
            </div>

            <div style={{ flex: 1 }}>
              <div className="h5 mb4">{element.name}</div>

              <div className="caption mb16">{element.desc}</div>

              <div className="mb16">
                <div className="caption color-black mb4">
                  Pliki dokumentacji:
                </div>

                <div>
                  <Files
                    main={element.file}
                    additional={elementDraft.relatedFiles}
                    isEditable={isEditable}
                    onChange={handlePropertyChanged}
                  />
                </div>
              </div>

              <div className="mb16">
                <div className="caption color-black mb4">Materiał:</div>

                <div>
                  <Material
                    materialId={elementDraft.material_id}
                    typeId={elementDraft.material_type_id}
                    isEditable={isEditable}
                    onChange={handleMaterialChanged}
                  />
                </div>
              </div>

              <div>
                <div className="caption color-black mb4">Usługi:</div>

                <div>
                  <Services
                    services={elementDraft.services}
                    isEditable={isEditable}
                    isMaterialSelected={
                      elementDraft.material_id || elementDraft.material_type_id
                    }
                    onChange={handleServicesChanged}
                  />
                </div>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <div className="mb24">
                {isEditable ? (
                  <Amount
                    amount={elementDraft.amount}
                    isEditable={isEditable}
                    onChange={handlePropertyChanged}
                  />
                ) : (
                  <TotalAmount
                    amount={elementDraft.amount}
                    total_amount={elementDraft.total_amount}
                    noOfSets={noOfSets}
                    isEditable={isEditable}
                    onChange={handlePropertyChanged}
                  />
                )}
              </div>

              <div className="mb40">
                {isEditable ? (
                  <TotalAmount
                    amount={elementDraft.amount}
                    total_amount={elementDraft.total_amount}
                    noOfSets={noOfSets}
                    isEditable={isEditable}
                    onChange={handlePropertyChanged}
                  />
                ) : (
                  <Amount
                    amount={elementDraft.amount}
                    isEditable={isEditable}
                    onChange={handlePropertyChanged}
                  />
                )}
              </div>

              <div>
                <div className="caption mb4">Uwagi do pliku:</div>
                <div>
                  <Remarks
                    remarks={elementDraft.remarks}
                    isEditable={isEditable}
                    onChange={handlePropertyChanged}
                  />
                </div>
              </div>
            </div>
          </section>

          {elementDraft.services.length > 0 && (
            <section className={`${cs.services} hide-mobile`}>
              <div className={cs.services_title}>
                Cykl produkcji ({elementDraft.services.length})
              </div>

              <div ref={serviceSectionEl}>
                <ServiceDetails
                  element={elementDraft}
                  isEditable={isEditable}
                  onChange={handleParameterChange}
                  onTechnologiesChange={handleTechnologiesChanged}
                />
              </div>
            </section>
          )}
        </>
      )}
    </Modal>
  );

  function handlePropertyChanged(property, newValue) {
    setElementDraft((prev) => {
      prev[property] = newValue;

      if (property === "amount") {
        prev.total_amount = noOfSets * newValue;
      }
    });
  }

  function handleMaterialChanged(selectedMaterial: any) {
    setElementDraft((prev) => {
      return handleNewMaterialSet(prev, selectedMaterial);
    });
  }

  function handleServicesChanged(selectedServiceIds: number[]) {
    handlePropertyChanged(
      "services",
      selectedServiceIds.map((serviceId) => ({
        _id: serviceId,
        technologyIds: getTechnologyIdsByService(serviceId),
      }))
    );
  }

  function handleTechnologiesChanged(serviceId, updatedTechnologies) {
    setElementDraft((prev) => {
      prev.services = prev.services.map((service) => {
        if (service._id === serviceId) {
          return {
            ...service,
            technologyIds: updatedTechnologies,
          };
        }

        return service;
      });
    });
  }

  function handleParameterChange(parameter, value) {
    setElementDraft((prev) => {
      nestedProperty.set(prev.parameters, parameter, value);
    });
  }

  function handleClose() {
    onClose(elementDraft);
  }

  function handleConfirm() {
    onConfirm(elementDraft);
  }
};
