import { useState } from "react";
import { Modal } from "@components/Modal";
import { MaterialSelect } from "@components/MaterialSelect";
import { Textarea } from "@components/Textarea";
import { ServicesSelect } from "@components/ServicesSelect";
import cs from "./EditMultipleModal.module.scss";

export const EditMultipleModal = ({
  isOpen,
  noOfSelected,
  onClose,
  onConfirm,
}) => {
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);
  const [remarks, setRemarks] = useState("");

  return (
    <Modal
      title={`Edytuj zaznaczone (${noOfSelected})`}
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okText="Zapisz zmiany"
      width={640}
      onOk={() => {
        onConfirm({
          material: selectedMaterial,
          services: selectedServiceIds,
          remarks,
        });

        handleClose();
      }}
      onClose={handleClose}
    >
      <div className={cs.EditMultipleModal}>
        <div>
          <div className="mb16">
            <div className="caption color-dark-gray mb4">Materiał:</div>

            <MaterialSelect
              materialId={selectedMaterial?.materialId}
              typeId={selectedMaterial?.typeId}
              width="100%"
              placeholder=""
              onChange={setSelectedMaterial}
            />
          </div>

          <div>
            <div className="caption color-dark-gray mb4">Usługi:</div>

            <ServicesSelect
              value={selectedServiceIds}
              size="small"
              onChange={setSelectedServiceIds}
            />
          </div>
        </div>

        <div>
          <div>
            <div className="caption color-dark-gray mb4">Uwagi:</div>

            <Textarea
              value={remarks}
              style={{ width: "100%" }}
              rows={2}
              onChange={setRemarks}
            />
          </div>
        </div>
      </div>
    </Modal>
  );

  function handleClose() {
    setSelectedMaterial(null);
    setSelectedServiceIds([]);

    onClose();
  }
};
