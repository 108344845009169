import { useState } from "react";
import { notification } from "antd";
import {
  isAuthenticated,
  logOutApi,
  resetPasswordApi,
} from "@services/auth.api";
import { Card } from "@components/Card";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Baner } from "../../components/Baner";
import cs from "./ResetPasswordPage.module.scss";

export const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");

  const isUserAuthenticated = isAuthenticated();

  if (isUserAuthenticated) {
    logOutApi().then(() => {
      window.location.reload();
    });

    return null;
  }

  return (
    <main className={`ResetPasswordPage ${cs.ResetPasswordPage}`}>
      <Baner type="register" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <form onSubmit={handleFormSubmited}>
          <Card>
            <Card.Header>
              <div className="h5">Zresetuj hasło</div>
            </Card.Header>

            <Card.Body>
              <div className="caption h5 mb20">
                Na poniższy adres email zostanie wysłany link umożliwiający
                ustawienie nowego hasła.
              </div>

              <div>
                <Input
                  name="email"
                  type="email"
                  label="Adres email"
                  size="large"
                  required
                  value={email}
                  onChange={setEmail}
                />
              </div>
            </Card.Body>

            <Card.Footer>
              <Button
                type="primary"
                size="large"
                btnType="submit"
                style={{ width: "100%" }}
              >
                Wyślij
              </Button>
            </Card.Footer>
          </Card>
        </form>

        <PrivacyPolicy />
      </section>
    </main>
  );

  async function handleFormSubmited(e) {
    e.preventDefault();

    const { isSuccess, error } = await resetPasswordApi(email);

    if (isSuccess) {
      notification.success({
        message:
          "Email z linkiem umożliwiającym ustawienie nowego hasła został wysłany.",
        placement: "topRight",
      });

      setEmail("");
    } else if (error === "USER_NOT_FOUND") {
      notification.error({
        message: "Użytkownik o tym adresie email nie istnieje.",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Wystąpił błąd.",
        placement: "topRight",
      });
    }
  }
};
