import { useState } from "react";
import { isStepFile } from "@helpers/utils";
import { Btn } from "../../Customer/views/NewOrderPage/Elements/Element/Element";
import { ViewerModal } from "./ViewerModal";
import chevronRightBlueImg from "./img/chevron_right_blue.svg";

export const Viewer3D = ({ file }) => {
  const [isViewerOpened, setIsViewerOpened] = useState(false);

  if (!isStepFile(file)) {
    return null;
  }

  return (
    <>
      <Btn onClick={() => setIsViewerOpened(true)}>
        <span className="mr4">Widok 3d</span>
        <img src={chevronRightBlueImg} alt="" />
      </Btn>

      <ViewerModal
        isOpen={isViewerOpened}
        file={file}
        onClose={() => setIsViewerOpened(false)}
      />
    </>
  );
};
