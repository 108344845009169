import { useState } from "react";
import classNames from "classnames";
import { getServiceNameById } from "@services/technology.helpers";
import { toFixedNumber, trim } from "@helpers/utils";
import { getNoOfUnitsText } from "@helpers/getNoOfUnitsText";
import { Chip } from "@components/Chip";
import { InputNumber } from "@components/InputNumber";
import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { Viewer3D } from "@components/Viewer3D";
import { OrderElementFiles } from "@components/OrderElementFiles";
import { Btn } from "../../../../Customer/views/NewOrderPage/Elements/Element/Element";
import { MaterialName } from "../../../../Customer/views/NewOrderPage/Elements/Element/MaterialName";
import { TechnologyNames } from "../../../../Customer/views/NewOrderPage/Elements/Element/TechnologyNames";
import { RemarksModal } from "../../../../Customer/views/NewOrderPage/Elements/Element/RemarksModal";
import { Machines } from "./Machines";
import { TotalPriceModal } from "./TotalPriceModal";
import { MaterialModal } from "./MaterialModal";
import { MachinesModal } from "./MachinesModal";
import chevronDownImg from "../../OrderPage/img/chevron_down.svg";
import cs from "./Elements.module.scss";

export const Element = ({
  order,
  element,
  offer,
  isSelected,
  wasGeneratePricesClicked,
  onShowDetails,
  onOfferChange,
  onSelectionChanged,
  onSelectedMachineChange,
}) => {
  const [isRemarksModalOpened, setIsRemarksModalOpened] = useState(false);
  const [isMaterialModalOpened, setIsMaterialModalOpened] = useState(false);
  const [isTotalPriceModalOpened, setIsTotalPriceModalOpened] = useState(false);
  const [isMachinesModalOpened, setIsMachinesModalOpened] = useState(false);

  const files = [element.file, ...element.relatedFiles];

  let material;

  if (offer.material_id || offer.material_type_id) {
    material = {
      material_id: offer.material_id,
      material_type_id: offer.material_type_id,
    };
  } else {
    material = {
      material_id: element.material_id,
      material_type_id: element.material_type_id,
    };
  }

  const rootCs = classNames(cs.Element, { [cs.Element__checked]: isSelected });

  const hasBothCuttingAndThreading = [1, 126].every((serviceId) => {
    return element.services.some(({ _id }) => _id === serviceId);
  });

  return (
    <div className={rootCs}>
      <div className={cs.Element_main}>
        <div className={cs.checkbox}>
          <Checkbox
            checked={isSelected}
            onChange={(e) => {
              onSelectionChanged(element._id, e.target.checked);
            }}
          />
        </div>

        <div style={{ alignSelf: "center" }}>
          <img
            src={
              element.thumbnail ||
              process.env.PUBLIC_URL + "/no_thumbnail_96x96.png"
            }
            alt={element.name}
            width={96}
            height={96}
            onError={(e) => (e.target.src = chevronDownImg)}
          />
        </div>

        <div>
          <div className="mb4 body bold">{element.name}</div>
          <div className="caption">{element.desc}</div>

          <div className="mt8">
            <Viewer3D file={element.file} />
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb2">Materiał:</div>
            <div className="caption bold color-black mb2">
              <MaterialName
                materialId={material.material_id}
                typeId={material.material_type_id}
              />
            </div>
            <Btn onClick={() => setIsMaterialModalOpened(true)}>
              Zaproponuj inny materiał
            </Btn>
          </div>

          <div className="mb12">
            <div className="caption mb2">Usługi:</div>
            <div className="caption bold color-black">
              <TechnologyNames
                serviceIds={element.services.map(({ _id }) => _id)}
              />
            </div>
          </div>

          <div className="hide-mobile">
            <div className="caption mb2">Maszyny:</div>
            <div>
              <Machines element={element} />

              <div className="mt4">
                <Btn onClick={() => setIsMachinesModalOpened(true)}>Edytuj</Btn>
              </div>
            </div>
          </div>

          {hasBothCuttingAndThreading && (
            <div className="mt12">
              <div className="caption mb4">Wymagane informacje:</div>

              <div>
                <Checkbox
                  checked={offer.noCutThreadedHoles}
                  label="Nie wycinaj otworów gwintowanych"
                  size="small"
                  onChange={(e) => {
                    handleOfferChanged("noCutThreadedHoles", e.target.checked);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb2">Pliki dokumentacji:</div>
            <div className="caption bold color-black">
              <OrderElementFiles order={order} files={files} />
            </div>
          </div>

          <div className="mb12">
            <div className="caption mb2">Uwagi zlecającego:</div>
            <div className="caption color-black">
              {trim(element.remarks || "-", 60)}
            </div>
          </div>

          <div>
            <div className="caption mb2">Twoje uwagi:</div>
            <div className="caption color-black">
              {offer.remarks ? (
                <>
                  <p className="caption color-black mb2">
                    {trim(offer.remarks, 60)}
                  </p>
                  <Btn onClick={() => setIsRemarksModalOpened(true)}>
                    Edytuj
                  </Btn>
                </>
              ) : (
                <Btn onClick={() => setIsRemarksModalOpened(true)}>
                  Dodaj uwagi
                </Btn>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb4">Łączna ilość:</div>
            <div className="caption color-black bold">
              <Chip color="orange" size="caption">
                {getNoOfUnitsText(element.total_amount)}
              </Chip>
            </div>
          </div>

          <div className="caption">
            Ilość szt. w komplecie: {element.amount}
          </div>
        </div>

        <div>
          {wasGeneratePricesClicked && (
            <div className="caption mb12">
              <div className="mb2">Szczegóły wyceny:</div>

              {element.services.map((service) => {
                const selectedMachine = element.selectedMachinesPerService.get(
                  service._id
                );
                const serviceName = getServiceNameById(service._id);

                let priceForSingleEl = 0;

                if (selectedMachine?.priceForService) {
                  priceForSingleEl = toFixedNumber(
                    selectedMachine?.priceForService / element.total_amount,
                    2
                  );
                }

                return (
                  <div className="mb2 color-black" key={service._id}>
                    {serviceName}: {priceForSingleEl}&nbsp;zł
                  </div>
                );
              })}

              {/*<div className="mb2 color-black">*/}
              {/*  Materiał:{" "}*/}
              {/*  {element.priceForMaterial*/}
              {/*    ? toFixedNumber(*/}
              {/*        element.priceForMaterial / element.total_amount,*/}
              {/*        2*/}
              {/*      )*/}
              {/*    : 0}*/}
              {/*  &nbsp;zł*/}
              {/*</div>*/}
            </div>
          )}

          <div className="mb12">
            <div className="caption bold color-black mb4">Cena detal</div>
            <div className={cs.price}>
              <InputNumber
                size="large"
                value={offer.price || ""}
                step={1}
                min={0}
                placeholder="-"
                onChange={(newValue: number) => {
                  handleOfferChanged("price", toFixedNumber(newValue, 2));
                }}
              />
              <span className="ml12 body">zł</span>
            </div>
          </div>

          <div className="caption">
            <div>Cena sumaryczna:</div>
            <div>
              <span>
                {element.total_amount} x {offer.price || "-"} zł ={" "}
              </span>
              <strong>{offer.price_total} zł </strong>

              <Btn onClick={() => setIsTotalPriceModalOpened(true)}>Zmień</Btn>
            </div>
          </div>
        </div>
      </div>

      <div className={cs.Element_footer}>
        <Button type="link" onClick={onShowDetails}>
          <span className="mr8">Szczegóły elementu</span>
          <img src={chevronDownImg} />
        </Button>
      </div>

      <RemarksModal
        isOpen={isRemarksModalOpened}
        remarks={offer.remarks}
        onConfirm={handleOfferChanged}
        onClose={() => setIsRemarksModalOpened(false)}
      />

      <MachinesModal
        isOpen={isMachinesModalOpened}
        element={element}
        onConfirm={(updatedSelection: any) => {
          onSelectedMachineChange(element._id, updatedSelection);
        }}
        onClose={() => setIsMachinesModalOpened(false)}
      />

      <TotalPriceModal
        isOpen={isTotalPriceModalOpened}
        price={offer.price_total}
        onConfirm={(newValue: number) =>
          handleOfferChanged("price_total", newValue)
        }
        onClose={() => setIsTotalPriceModalOpened(false)}
      />

      <MaterialModal
        isOpen={isMaterialModalOpened}
        onConfirm={(materialId, typeId) => {
          handleOfferChanged("material_id", materialId);
          handleOfferChanged("material_type_id", typeId);
        }}
        onClose={() => setIsMaterialModalOpened(false)}
      />
    </div>
  );

  function handleOfferChanged(property, value) {
    onOfferChange(element._id, property, value);
  }
};
