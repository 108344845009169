import { useEffect, useState } from "react";
import { Modal } from "@components/Modal";
import { MaterialSelect } from "@components/MaterialSelect";
import { ServicesSelect } from "@components/ServicesSelect";
import exclamationImg from "../img/exclamation.svg";
import cs from "./ServicesModal.module.scss";

export const ServicesModal = ({
  isOpen,
  isMaterialSelected,
  services,
  onMaterialSelected,
  onServicesSelected,
  onClose,
}) => {
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>([]);
  const [step, setStep] = useState<1 | 2>(1);

  useEffect(() => {
    if (!isOpen) return;

    setStep(isMaterialSelected ? 2 : 1);
  }, [isOpen, isMaterialSelected]);

  useEffect(() => {
    if (!isOpen) return;

    setSelectedServiceIds(services?.map(({ _id }) => _id));
  }, [services]);

  return (
    <Modal
      title={step === 1 ? "Dodaj materiał" : "Dodaj usługi"}
      visible={isOpen}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{
        disabled: !selectedMaterial && selectedServiceIds.length === 0,
      }}
      okText={step === 1 ? "Dalej" : "Zapisz zmiany"}
      width={480}
      onOk={handleConfirm}
      onClose={handleClose}
    >
      {step === 1 ? (
        <StepOne
          selectedMaterial={selectedMaterial}
          onChange={setSelectedMaterial}
        />
      ) : (
        <StepTwo
          selectedServiceIds={selectedServiceIds}
          onChange={setSelectedServiceIds}
        />
      )}
    </Modal>
  );

  function handleClose() {
    onClose();

    setSelectedMaterial(null);
    setSelectedServiceIds([]);
  }

  function handleConfirm() {
    if (step === 1) {
      onMaterialSelected(selectedMaterial);
    } else {
      onServicesSelected(selectedServiceIds);
      handleClose();
    }
  }
};

/**
 * @private
 */
const StepOne = ({ selectedMaterial, onChange }) => (
  <>
    <div className={cs.materialWarning}>
      <img src={exclamationImg} alt="" className="mr8" />
      <span>Aby dodać usługę, najpierw musisz zdefiniować materiał.</span>
    </div>

    <MaterialSelect
      materialId={selectedMaterial?.materialId}
      typeId={selectedMaterial?.typeId}
      width="100%"
      onChange={onChange}
    />
  </>
);

/**
 * @private
 */
const StepTwo = ({ selectedServiceIds, onChange }) => (
  <ServicesSelect value={selectedServiceIds} size="small" onChange={onChange} />
);
