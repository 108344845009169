import { Tag } from "antd";
import { OFFER_STATUSES } from "../../../../constants/OFFER_STATUSES";
import { Button } from "@components/Button";
import { WithCompanyModal } from "@components/CompanyModal";
import { Chip } from "@components/Chip";
import cs from "../OrderPage.module.scss";

export const columns = ({ onShowDetails }) => {
  return [
    {
      title: "Firma",
      field: "name",
      render(value, col, row) {
        return (
          <WithCompanyModal>
            {(openModal) => (
              <>
                <div
                  className={cs.companyName}
                  onClick={() => openModal(row.owner_company._id)}
                >
                  {row.owner_company.name}
                </div>

                {row.status === OFFER_STATUSES.ORDER_EDITED && (
                  <div className="mt1">
                    <Chip color="dark-gray">Oczekuje na zatwierdzenie</Chip>
                  </div>
                )}
              </>
            )}
          </WithCompanyModal>
        );
      },
    },
    {
      title: "Cena",
      field: "offer_sum",
      type: "currency",
      render(value, col, row) {
        return (
          <div className={getCellClassName(row)}>
            <div>{value} PLN</div>
            {row.isPartial && <Tag color="#cd201f">częściowa!</Tag>}
            {row.anyMaterialsChanged && <Tag color="#cd201f">materiały!</Tag>}
            {row.status === OFFER_STATUSES.WON && (
              <Chip color="green" size="small">
                Ta oferta wygrała
              </Chip>
            )}
          </div>
        );
      },
    },
    {
      title: "Kontakt",
      field: "bid_deadline",
      render: (value, col, row) => {
        return (
          <div className={getCellClassName(row)}>
            <div>
              <a
                href={`mailto:${row.owner_company.email}`}
                target="_blank"
                className={cs.link}
              >
                {row.owner_company.email}
              </a>
            </div>
            <div>
              <a
                href={`tel:${row.owner_company.phone}`}
                target="_blank"
                className={cs.link}
              >
                {row.owner_company.phone}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      field: "_id",
      render(value, col, row) {
        return (
          <Button
            type="link"
            className="Table_details-btn"
            onClick={() => onShowDetails(value)}
          >
            Szczegóły
          </Button>
        );
      },
      width: 93,
    },
  ];
};

function getCellClassName(row: any) {
  return row.status === OFFER_STATUSES.ORDER_EDITED ? cs.cellGrayed : "";
}
