import { useEffect, useState } from "react";
import { TreeSelect } from "antd";
import { getMaterialName } from "@services/material.helpers";
import { store } from "@store";
import { useOptions } from "./useOptions";
import cs from "./MaterialSelect.module.scss";

export const MaterialSelect = ({
  materialId,
  typeId,
  width = 200,
  className = "",
  placeholder = "Wprowadź materiał",
  onChange,
}) => {
  const [value, setValue] = useState<string | null>("");
  const [expanded, setExpanded] = useState<string[]>([]);
  const materials = store.hooks.useMaterials();
  const types = store.hooks.useMaterialTypes();

  const [options, keys] = useOptions();

  useEffect(() => {
    if (materials.length === 0) return;
    if (types.length === 0) return;

    if (materialId) {
      const material = materials.find(({ _id }) => _id === materialId);
      const materialName = getMaterialName(material);

      setValue(materialName);
    } else if (typeId) {
      const type = types.find(({ _id }) => _id === typeId);

      setValue(type.name);
    } else {
      setValue(null);
    }
  }, [materialId, typeId, materials, types]);

  return (
    <TreeSelect
      style={{ width }}
      value={value}
      treeData={options}
      placeholder={placeholder}
      showSearch
      dropdownMatchSelectWidth={false}
      className={className}
      treeExpandedKeys={expanded}
      dropdownClassName={cs.treeSelect}
      notFoundContent={
        <div className="p12 caption">Nie znaleziono materiału...</div>
      }
      onSelect={handleOptionSelected}
      onSearch={(val) => {
        if (val.length >= 2) {
          const keysToExpand = new Set();

          Array.from(keys).forEach((level) => {
            const aaa: any[] = level.split(";;");

            const last = aaa.at(-1);

            if (last.toLowerCase().includes(val.toLowerCase())) {
              aaa.forEach((x) => {
                keysToExpand.add(x);
              });
            }
          });

          setExpanded(Array.from(keysToExpand));
        }
      }}
      onTreeExpand={(key) => {
        setExpanded(key);
      }}
    />
  );

  function handleOptionSelected(key, option) {
    onChange({
      materialId: option.materialId || null,
      typeId: option.typeId || null,
    });
  }
};
