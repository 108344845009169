import axios from "axios";

const apiUrl = "/api/machine";

export async function addMachineApi(
  modelId: number,
  technologyIds: number[],
  parameters: { _id: number; value: string }
) {
  try {
    const response = await axios.post(apiUrl, {
      modelId,
      technologyIds,
      parameters,
    });

    return {
      isSuccess: response.status === 201,
      newMachineId: response.data.newMachineId,
    };
  } catch (e) {
    return {
      isSuccess: false,
    };
  }
}

export async function updateMachineApi(
  machineId: number,
  technologyIds: number[],
  parameters: { _id: number; value: string }
) {
  try {
    const response = await axios.put(apiUrl, {
      machineId,
      technologyIds,
      parameters,
    });

    return {
      isSuccess: response.status === 204,
    };
  } catch (e) {
    return {
      isSuccess: false,
    };
  }
}

export async function updateMachinelessServicesApi(selectedServiceIds) {
  try {
    const response = await axios.put(
      apiUrl + "/machineless",
      selectedServiceIds
    );

    return {
      isSuccess: response.status === 200,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function deleteMachineApi(machineId: number) {
  try {
    const response = await axios.delete(`${apiUrl}/${machineId}`);

    return {
      isSuccess: response.status === 204,
    };
  } catch (e) {
    return {
      isSuccess: false,
    };
  }
}

export async function getAvailableMachinesApi(modelIds?: number[]) {
  try {
    const response = await axios.get(apiUrl + "/machines", {
      params: {
        model_ids: modelIds,
      },
    });

    return {
      data: response.data,
      isSuccess: response.status === 200,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function createMachineApi(machine: any) {
  try {
    const response = await axios.post(apiUrl + "/new", machine);

    return {
      isSuccess: response.status === 201,
      newMachineId: response.data._id,
    };
  } catch (e) {
    return {
      isSuccess: false,
    };
  }
}
