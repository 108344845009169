import { BendingTable } from "./BendingTable";
import { CuttingTable } from "./CuttingTable";

export function getTableByService(serviceId: number) {
  switch (serviceId) {
    case 1:
    case 3:
    case 4:
      return CuttingTable;
    case 19:
      return BendingTable;
    default:
      return () => null;
  }
}
