import { useRef } from "react";
import { notification } from "antd";
import { store } from "@store";
import {
  hasUserDownloadedOrderFileBeforeApi,
  isUserPermittedToDownloadOrderFile,
  requestPermissionToDownloadOrderFile,
} from "@services/order.api";
import { RequestPermissionModal } from "@components/DownloadOrderDocs/RequestPermissionModal";

export const WithDownloadLogic = ({ download, order, children }) => {
  const requestPermissionModal = useRef<any>();

  const user = store.hooks.useUser();

  const orderId = order._id;

  return (
    <>
      {children(handleClick)}

      <RequestPermissionModal ref={requestPermissionModal} />
    </>
  );

  async function handleClick() {
    if (order.owner_id === user._id) {
      await download();

      return;
    }

    if (!order.permisson_to_download_file_required) {
      const firstFile = await isThisTheFirstDownloadedFile();
      const downloaded = await download();

      if (firstFile && downloaded) {
        await showWarningForFirstDownloadedFile();
      }

      return;
    }

    const { isPermissionGranted } = await isUserPermittedToDownloadOrderFile(
      orderId
    );

    if (isPermissionGranted) {
      const firstFile = await isThisTheFirstDownloadedFile();
      const downloaded = await download();

      if (firstFile && downloaded) {
        await showWarningForFirstDownloadedFile();
      }
    } else {
      const shouldSendRequest = await requestPermissionModal.current?.open();

      if (!shouldSendRequest) return;

      const { isSuccess, requestStatus } =
        await requestPermissionToDownloadOrderFile(orderId);

      if (isSuccess) {
        if (requestStatus === 1) {
          notification.success({
            message: "Prośba o dostęp do dokumentacji przesłana.",
            description:
              "Dostaniesz wiadomość na skrzynkę email gdy Zleceniodawca zatwierdzi Twoją prośbę.",
            placement: "topRight",
            duration: 10,
          });
        } else if (requestStatus === 3) {
          notification.error({
            message: "Prośba o dostęp do dokumentacji odrzucona.",
            description: "Zleceniodawca odrzucił Twoją prośbę o dostęp.",
            placement: "topRight",
            duration: 10,
          });
        }
      }
    }
  }

  async function isThisTheFirstDownloadedFile() {
    const { haveUserDownloadedOrderFileBefore } =
      await hasUserDownloadedOrderFileBeforeApi(orderId);

    return haveUserDownloadedOrderFileBefore === false;
  }

  async function showWarningForFirstDownloadedFile() {
    notification.warning({
      message: "Uwaga!",
      description:
        "Od tego momentu jesteś odpowiedzialny za ten egzemplarz dokumentacji ponieważ pobrane przez Ciebie pliki są oznaczone Twoim ID użytkownika. Pamiętaj aby nie udostępniać dokumentacji poza firmą bez zgody zleceniodawcy. W przypadku wykorzystania dokumentacji w sposób niezgodny z przeznaczeniem zleceniodawca może rościć sobie prawa do odszkodowania.",
      placement: "topRight",
      duration: null,
    });
  }
};
