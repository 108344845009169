import { Card } from "@components/Card";
import { Drilling } from "./Drilling";
import { CounterDeepening } from "./CounterDeepening";
import cs from "./AdditionalProcessing.module.scss";

export const AdditionalWoodProcessing = ({
  additionalServices,
  onChange,
  onRemove,
}) => {
  return (
    <Card className="AdditionalProcessing">
      <Card.Header className="h4">Dodatkowa obróbka drewna</Card.Header>

      <Card.Body className={cs.services}>
        {additionalServices.map((priceList) => {
          switch (priceList.service._id) {
            case 133:
              return (
                <Drilling
                  key={priceList.service._id}
                  priceList={priceList}
                  onChange={(updatedPriceList) => {
                    onChange(priceList.service._id, updatedPriceList);
                  }}
                  onRemove={onRemove}
                />
              );
            case 135:
            case 136:
              return (
                <CounterDeepening
                  key={priceList.service._id}
                  priceList={priceList}
                  onChange={(updatedPriceList) => {
                    onChange(priceList.service._id, updatedPriceList);
                  }}
                  onRemove={onRemove}
                />
              );
            default:
              return null;
          }
        })}
      </Card.Body>
    </Card>
  );
};
