import { useState } from "react";
import { Collapse, Spin } from "antd";
import { Checkbox } from "@components/Checkbox";
import { Button } from "@components/Button";
import cs from "./AdditionalProcessings.module.scss";

const { Panel } = Collapse;

export const Wood = ({ machinelessServices, onChange, onSave }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Collapse
      defaultActiveKey={["WOOD"]}
      className={`ProcessingType ${cs.panel}`}
      expandIconPosition="right"
    >
      <Panel header="Obróbka dodatkowa drewna" key="WOOD">
        <div className={cs.checkboxContainer}>
          <Checkbox
            label="Wiercenie otworów w drewnie"
            style={{ fontSize: "18px" }}
            checked={machinelessServices.includes(133)}
            onChange={(e) => onChange(133, e.target.checked)}
          />

          <Checkbox
            label="Pogłębianie stożkowe otworów w drewnie"
            style={{ fontSize: "18px" }}
            checked={machinelessServices.includes(135)}
            onChange={(e) => onChange(135, e.target.checked)}
          />

          <Checkbox
            label="Pogłębianie walcowe otworów w drewnie"
            style={{ fontSize: "18px" }}
            checked={machinelessServices.includes(136)}
            onChange={(e) => onChange(136, e.target.checked)}
          />

          <div className="mt4 ml-auto">
            <Spin spinning={isLoading}>
              <Button type="primary" size="small" onClick={handleSave}>
                Zapisz zmiany
              </Button>
            </Spin>
          </div>
        </div>
      </Panel>
    </Collapse>
  );

  function handleSave() {
    setIsLoading(true);

    onSave();

    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }
};
