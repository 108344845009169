import { Card } from "@components/Card";
import { NewOrderPage } from "../../../Customer/views/NewOrderPage";
import { Baner } from "../../../Auth/components/Baner";
import cubeImg from "./img/3d_cube.png";
import computerImg from "./img/computer.png";
import factoryImg from "./img/factory.png";
import dashImg from "./img/dash.png";

import cs from "./PublicOrder.module.scss";

export const PublicOrder = () => {
  return (
    <main className={`PublicOrder ${cs.PublicOrder}`}>
      <Baner type="login" />

      <section className={cs.main}>
        <div className="text-center">
          <a href="https://www.4dustry.com">
            <img
              src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
              alt="4dustry"
              width={136}
            />
          </a>
        </div>

        <section className={cs.info}>
          <div className={cs.infoMain}>
            <img src={cubeImg} alt="" />
            <p className={cs.title}>Utwórz zapytanie</p>
            <p className={cs.text}>
              Dołącz pliki dokumentacji 2D lub 3D. Wybierz materiał i rodzaj
              technologii.
            </p>
          </div>

          <div>
            <img src={dashImg} className={cs.dashOnlyBig} alt="" />
          </div>
          <div>
            <img src={dashImg} className={cs.dash} alt="" />
          </div>
          <div>
            <img src={dashImg} className={cs.dash} alt="" />
          </div>
          <div>
            <img src={dashImg} className={cs.dash} alt="" />
          </div>
          <div className="hide-mobile">
            <img src={dashImg} className={cs.dash} alt="" />
          </div>

          <div className={cs.infoMain}>
            <img src={factoryImg} alt="" />

            <p className={cs.title}>Zapytanie wysłane</p>
            <p className={cs.text}>
              Szukamy dla Ciebie firmy, która wykona zamówienie!
            </p>
          </div>

          <div>
            <img src={dashImg} className={cs.dashOnlyBig} alt="" />
          </div>
          <div>
            <img src={dashImg} className={cs.dash} alt="" />
          </div>
          <div>
            <img src={dashImg} className={cs.dash} alt="" />
          </div>
          <div>
            <img src={dashImg} className={cs.dash} alt="" />
          </div>
          <div className="hide-mobile">
            <img src={dashImg} className={cs.dash} alt="" />
          </div>

          <div className={cs.infoMain}>
            <img src={computerImg} alt="" />

            <p className={cs.title}>Wybierz podwykonawcę</p>
            <p className={cs.text}>
              Wybierz najlepszą ofertę dopasowaną do Ciebie!
            </p>
          </div>
        </section>

        <div className="mt75">
          <NewOrderPage />
        </div>
      </section>
    </main>
  );
};
