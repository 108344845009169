import { useRef, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { notification } from "antd";
import {
  cancelOrderApi,
  docsPermissionChoiceApi,
  selectOfferApi,
} from "../../services/order.api";
import { useOrder } from "../../services/order.hooks";
import { store } from "@store";
import { toLocaleDate } from "@helpers/dateUtils";
import { ORDER_STATUSES } from "../../../constants/ORDER_STATUSES";
import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { Confirmation } from "@components/Confirmation";
import { DownloadOrderDocsBtn } from "@components/DownloadOrderDocs";
import { DocsProtectionChoice } from "../../components/DocsProtectionChoice";
import { Chat } from "../../components/Chat";
import { DownloadHistory } from "./DownloadHistory/DownloadHistory";
import { DownloadRequests } from "./DownloadRequests/DownloadRequests";
import { AdditionalFiles } from "./AdditionalFiles";
import { StatusInfoPanel } from "./StatusInfoPanel";
import { Elements } from "./Elements";
import { Offers } from "./Offers/Offers";
import repeatImg from "./img/repeat.svg";
import xSquareImg from "./img/x_square.svg";
import cs from "./OrderPage.module.scss";

export const OrderPage = () => {
  const [refreshToken, setRefreshToken] = useState(0);

  const confirmOfferSelection = useRef(null);
  const confirmEdit = useRef(null);
  const confirmCancel = useRef(null);

  const { id } = useParams();
  const user = store.hooks.useUser();
  const [order, error] = useOrder(id, refreshToken);

  const navigate = useNavigate();
  const location = useLocation();

  if (error === "NEITHER_OWNER_OR_ADMIN") {
    return <Navigate to={`/offer/order/${id}`} replace />;
  }

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <main className={cs.OrderPage}>
      {location.state?.wasEdited && (
        <div className={cs.Alert}>
          <div>
            <div className="bold mb6">Zapisano zmiany</div>
            <div>Oferty wymagają zatwierdzenia przed ofertodawców.</div>
          </div>

          <img
            src={xSquareImg}
            onClick={() => {
              navigate("", { state: null });
            }}
          />
        </div>
      )}

      <header className={cs.header}>
        <div>
          <h1 className="h3 mb8">Zapytanie ofertowe {order.seq_no}</h1>
          <div>
            <span>Opublikowano: {toLocaleDate(order.created_at)}</span>

            {user.is_admin && <span> przez {order.owner_name}</span>}
          </div>
        </div>

        <div className={cs.headerActions}>
          <DownloadOrderDocsBtn order={order} size="medium" />

          {order.status !== ORDER_STATUSES.UNAPPROVED && (
            <>
              {order.status === ORDER_STATUSES.ONGOING ? (
                <Button
                  type="primary"
                  size="medium"
                  className="hide-phone"
                  onClick={handleEditClicked}
                >
                  Edytuj zapytanie
                </Button>
              ) : (
                <Button
                  type="primary"
                  size="medium"
                  className="hide-phone"
                  onClick={handleRedoClicked}
                >
                  <img src={repeatImg} className="mr10" width={16} />
                  <span>Ponów zapytanie</span>
                </Button>
              )}
            </>
          )}
        </div>
      </header>

      <StatusInfoPanel order={order} />

      {order.status === ORDER_STATUSES.UNAPPROVED && (
        <section className="mb24">
          <Card>
            <Card.Header className="h5">
              Zapytanie oczekuje na akceptację
            </Card.Header>
            <Card.Body className="body">
              Twoje zapytanie oczekuje na akceptację. Poinformujemy Cię gdy
              proces weryfikacji dobiegnie końca. Od tego momentu zapytanie
              stanie się widoczne dla innych użytkowników na naszej platformie.
            </Card.Body>
          </Card>
        </section>
      )}

      <section className={cs.descAndDocsProtectionSection}>
        <Card className={cs.desc}>
          <Card.Header className="h5">Opis zapytania</Card.Header>
          <Card.Body className="body">
            {order.desc ? (
              order.desc
            ) : (
              <span style={{ color: "#636E72" }}>Brak opisu</span>
            )}
          </Card.Body>
        </Card>

        <Card className={cs.docsProtection}>
          <Card.Header className="h5">Zabezpieczenie dokumentacji</Card.Header>
          <Card.Body className="body">
            <DocsProtectionChoice
              docsProtection={order.permisson_to_download_file_required}
            />
          </Card.Body>
        </Card>
      </section>

      <Offers
        order={order}
        offers={order.offers}
        onOfferSelected={handleOfferSelected}
      />

      {order.downloadRequests?.length > 0 && (
        <DownloadRequests
          downloadRequests={order.downloadRequests}
          onPermissionChoice={handleDownloadPermissionChoice}
        />
      )}

      <DownloadHistory downloadHistory={order.downloadHistory} />

      <Elements
        order={order}
        noOfSets={order.total_amount}
        elements={order.elements}
      />

      {order.order_files.length > 0 && (
        <AdditionalFiles order={order} files={order.order_files} />
      )}

      <Chat orderId={order._id} />

      <section className={cs.footerActions}>
        {order.status === ORDER_STATUSES.ONGOING && (
          <Button type="secondary" size="large" onClick={handleCancel}>
            Wycofaj zapytanie
          </Button>
        )}

        <div className={cs.footerActions_right}>
          <DownloadOrderDocsBtn order={order} size="large" />

          {order.status !== ORDER_STATUSES.UNAPPROVED && (
            <>
              {order.status === ORDER_STATUSES.ONGOING ? (
                <Button
                  type="primary"
                  size="large"
                  className="ml16 hide-phone"
                  onClick={handleEditClicked}
                >
                  Edytuj zapytanie
                </Button>
              ) : (
                <Button
                  type="primary"
                  size="large"
                  className="ml16 hide-phone"
                  onClick={handleRedoClicked}
                >
                  <img src={repeatImg} className="mr12" />
                  <span>Ponów zapytanie</span>
                </Button>
              )}
            </>
          )}
        </div>
      </section>

      <Confirmation
        ref={confirmOfferSelection}
        title="Czy na pewno chcesz wybrać tę ofertę?"
        text="Wybór tej oferty będzie oznaczał zakończenie publikacji zapytania ofertowego."
        cancelText="Anuluj"
        okText="Potwierdź i zakończ zapytanie"
      />

      <Confirmation
        ref={confirmEdit}
        title="Czy chcesz edytować to zapytanie?"
        text="Po zapisaniu zmian wszystkie oferty będą musiały być ponownie zatwierdzone przez ofertodawców."
        cancelText="Wróć"
        okText="Edytuj mimo to"
      />

      <Confirmation
        ref={confirmCancel}
        title="Czy chcesz anulować to zapytanie?"
        text="Anulowanie zapytania jest nieodwracalne. Pamiętaj że częste anulowanie zapytań ofertowych może wpłynąć na twoją ocenę wiarygodności jako kontrahenta."
        cancelText="Wróć"
        okText="Potwierdź i anuluj zapytanie"
      />
    </main>
  );

  async function handleOfferSelected(offerId) {
    const isConfirmed = await confirmOfferSelection.current.open();

    if (isConfirmed) {
      const response = await selectOfferApi(order._id, offerId);

      if (response.isSuccess) {
        notification.success({
          message: "Oferta została wybrana.",
          placement: "topRight",
        });

        setTimeout(() => {
          window.location.reload();
        }, 300);
      } else {
        notification.error({
          message: "Wystąpił błąd.",
          description: response.error,
          placement: "topRight",
        });
      }
    }
  }

  async function handleEditClicked() {
    const isConfirmed = await confirmEdit.current.open();

    if (isConfirmed) {
      navigate(`/order/${order._id}/edit`);
    }
  }

  async function handleRedoClicked() {
    navigate(`/order/${order._id}/copy`);
  }

  async function handleCancel() {
    const isConfirmed = await confirmCancel.current.open();

    if (isConfirmed) {
      const response = await cancelOrderApi(id);

      if (response.isSuccess) {
        // TODO:
        notification.success({
          message: "Zapytanie zostało anulowane.",
          placement: "topRight",
        });

        setRefreshToken(Math.random());
      } else {
        notification.error({
          message: "Wystąpił błąd.",
          description: response.error,
          placement: "topRight",
        });
      }
    }
  }

  async function handleDownloadPermissionChoice(
    companyId: string,
    choice: "grant" | "deny"
  ) {
    const response = await docsPermissionChoiceApi(id, companyId, choice);

    if (response.isSuccess) {
      notification.success({
        message:
          choice === "grant"
            ? "Udzielono dostępu do dokumentacji."
            : "Nie udzielono dostępu do dokumentacji.",
        placement: "topRight",
      });

      setRefreshToken(Math.random());
    } else {
      notification.error({
        message: "Wystąpił błąd.",
        description: response.error,
        placement: "topRight",
      });
    }
  }
};
