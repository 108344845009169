import { InputNumber } from "@components/InputNumber";
import { ColorSelect } from "./ColorSelect";
import deleteImg from "../img/delete.svg";
import cs from "./Painting.module.scss";

export const Color = ({ surface, readOnly, onChange, onColorRemove }) => {
  const identifier = surface.tempId || surface.name;

  const handleChange = onChange.bind(null, identifier);

  return (
    <div className={cs.Color}>
      <ColorSelect
        value={surface.name}
        readOnly={readOnly}
        required
        onChange={(newValue) => handleChange("name", newValue)}
      />

      <span>Pole powierzchni</span>

      <InputNumber
        size="small"
        value={surface.area}
        min={0.001}
        step={0.001}
        readOnly={readOnly}
        required
        onChange={(value) => handleChange("area", value)}
      />

      <span>
        m<sup>2</sup>
      </span>

      {!readOnly && (
        <div>
          <img
            src={deleteImg}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => {
              onColorRemove(identifier);
            }}
          />
        </div>
      )}
    </div>
  );
};
