import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { Modal, notification, Switch } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  cancelOfferApi,
  changeNotificationApi,
} from "../../services/offer.api";
import { useOffer } from "../../services/offer.hooks";
import { store } from "@store";
import { toLocaleDate } from "@helpers/dateUtils";
import { getFileURL } from "@helpers/fileUtils";
import { OFFER_STATUSES } from "../../../constants/OFFER_STATUSES";
import { Card } from "@components/Card";
import { Button } from "@components/Button";
import { Chip } from "@components/Chip";
import { OrderDescription } from "../../components/OrderDescription";
import { CustomerDetails } from "../../components/CustomerDetails";
import { AdditionalFiles } from "../OrderPage/AdditionalFiles";
import { StatusInfoPanel } from "./StatusInfoPanel";
import { Actions } from "./Actions";
import { Elements } from "./Elements";
import fileImg from "../NewOfferPage/img/file.svg";
import cs from "./OfferPage.module.scss";

const { confirm } = Modal;

export const OfferPage = () => {
  const [elementWrappers, setElementWrappers] = useImmer<Map<string, object>>(
    new Map()
  );
  const [isUserNotified, setIsUserNotified] = useState<boolean>();

  const { id } = useParams();
  const user = store.hooks.useUser();
  const { offer, order } = useOffer(id);

  useEffect(() => {
    if (!order || !offer) return;

    const elementWrappers = new Map();

    order.elements.forEach((el) => {
      const offerForEl = offer.elements.find(
        (offerForEl) => offerForEl.element_id === el._id
      );

      elementWrappers.set(el._id, {
        element: el,
        selectedMachines: offerForEl?.selected_machines || [], // TODO: Temp for compatability for older offers
        selected: Boolean(offerForEl?.offer),
        offer: offerForEl?.offer || {},
      });
    });

    setElementWrappers(elementWrappers);
  }, [order]);

  useEffect(() => {
    if (!offer) return;

    setIsUserNotified(offer.notifications);
  }, [offer]);

  if (!offer || !order) {
    return <div>Loading...</div>;
  }

  const priceTotal = Array.from(elementWrappers.values())
    .filter((el) => el.selected)
    .reduce((sum, el) => {
      return sum + (el.offer.price_total || 0);
    }, 0);

  return (
    <main className={cs.OfferPage}>
      <header className={cs.header}>
        <div>
          <h1 className="h3 mb8">
            Oferta do zapytania ofertowego {order.seq_no}
          </h1>

          <div>
            {offer.status === OFFER_STATUSES.ONGOING && offer.offer_period && (
              <Chip size="small" color="gray" className="mr16">
                <span>Termin ważności:</span> &nbsp;
                <span>{toLocaleDate(offer.offer_period)}</span>
              </Chip>
            )}

            <span>Opublikowano: {toLocaleDate(offer.created_at)}</span>

            {user.is_admin && <span> przez {offer.owner_name}</span>}
          </div>
        </div>

        <Actions
          offer={offer}
          order={order}
          size="medium"
          onCancel={handleCancel}
        />
      </header>

      <StatusInfoPanel order={order} offer={offer} offerSummary={priceTotal} />

      <section className={cs.notifications}>
        <Switch checked={isUserNotified} onChange={handleNotificationChange} />

        <span className="ml12 caption color-dark-gray">
          Chce dostawać powiadomienia związane z tym zamówieniem
        </span>
      </section>

      <section className={cs.descCustomerSection}>
        <OrderDescription desc={order.desc} />

        <CustomerDetails
          ownerCompany={order.owner_company}
          owner={order.owner}
        />
      </section>

      <section className="mt24">
        <Elements
          order={order}
          elements={elementWrappers}
          noOfSets={order.total_amount}
          isCompatible={order.isCompatible}
        />
      </section>

      {order.order_files.length > 0 && (
        <AdditionalFiles order={order} files={order.order_files} />
      )}

      <section className={cs.summary}>
        <Card>
          <Card.Header className="h5">Podsumowanie</Card.Header>
          <Card.Body className="body d-flex">
            <div className={cs.desc}>
              <div className="mb4 color-gray">Twój opis oferty:</div>
              <div>{offer.desc ? offer.desc : "-"}</div>
            </div>

            <div>
              <div className="mb8 color-gray">Załączone pliki:</div>
              <div>
                {offer.offer_files?.length > 0 ? (
                  <ul>
                    {offer.offer_files?.map((file: any) => (
                      <li key={file.name} className="mb8">
                        <img src={fileImg} />
                        <span className="body bold ml8">{file.name}</span>

                        <a href={getFileURL(file)} className={cs.fileLink}>
                          Pobierz
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : (
                  "-"
                )}
              </div>
            </div>

            <div className={cs.price}>
              <div>Kwota całościowa za zrealizowanie zamówienia:</div>
              <div className="h2 mt4 mb4">
                {priceTotal || "-"} PLN
                <span style={{ fontSize: 20 }}> + VAT</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </section>

      <section className={cs.footerActions}>
        {offer.status === OFFER_STATUSES.ONGOING && (
          <Button type="secondary" size="large" onClick={handleCancel}>
            Wycofaj ofertę
          </Button>
        )}

        <Actions
          offer={offer}
          order={order}
          size="large"
          onCancel={handleCancel}
        />
      </section>
    </main>
  );

  function handleCancel() {
    confirm({
      title: "Czy na pewno chcesz wycofać ofertę?",
      icon: <ExclamationCircleOutlined />,
      content: "Ta akcja jest nieodwracalna.",
      async onOk() {
        const response = await cancelOfferApi(id);

        if (response.isSuccess) {
          notification.success({
            message: "Oferta została anulowana.",
            placement: "topRight",
          });

          setTimeout(() => {
            window.location.reload();
          }, 300);
        } else {
          notification.error({
            message: "Wystąpił błąd.",
            description: response.error,
            placement: "topRight",
          });
        }
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  }

  async function handleNotificationChange(newValue: boolean) {
    setIsUserNotified(newValue);

    await changeNotificationApi(id, newValue);
  }
};
