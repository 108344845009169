import axios from "axios";

const apiUrl = "/api/materials/";

export async function getMaterialsApi() {
  try {
    const response = await axios.get(apiUrl);

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}
