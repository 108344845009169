import { useEffect, useState } from "react";
import { getTechnologyNames } from "@helpers/technology";
import { Chip } from "@components/Chip";
import { TechnologiesSelect } from "@components/TechnologiesSelect";
import { Btn } from "../../../../Customer/views/NewOrderPage/Elements/Element/Element";
import cs from "./Technologies.module.scss";

type Props = {
  technologyIds: number[];
  onSave: (selectedTechnologyIds: number[]) => void;
};

export const Technologies = ({ technologyIds, onSave }: Props) => {
  const [isEdited, setIsEdited] = useState(false);
  const [selectedTechIdCopy, setSelectedTechIdCopy] = useState<number[]>([]);

  useEffect(() => {
    setSelectedTechIdCopy(technologyIds);
  }, [technologyIds]);

  return (
    <div>
      {isEdited ? (
        <>
          <TechnologiesSelect
            value={selectedTechIdCopy}
            size="small"
            className={cs.select}
            onChange={setSelectedTechIdCopy}
          />

          {selectedTechIdCopy.length > 0 && (
            <Btn onClick={handleSave}>
              <span className="bold">Zapisz</span>
            </Btn>
          )}
        </>
      ) : (
        <div className={cs.Services}>
          {getTechnologyNames(technologyIds).map((techName) => (
            <Chip key={techName} color="gray" size="caption">
              {techName}
            </Chip>
          ))}

          <Btn onClick={() => setIsEdited(true)}>
            <span className="bold">Edytuj</span>
          </Btn>
        </div>
      )}
    </div>
  );

  function handleSave() {
    setIsEdited(false);

    onSave(selectedTechIdCopy);
  }
};
