import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "@components/Modal";

export const RequestPermissionModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const resolver = useRef<any>();

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);

      return new Promise((resolve) => {
        resolver.current = resolve;
      });
    },
  }));

  return (
    <Modal
      title="Prośba o dostęp do dokumentacji"
      width={480}
      okText="Wyślij prośbę"
      cancelText="Wróc"
      cancelButtonProps={{ className: "Button_secondary Button_medium" }}
      okButtonProps={{ className: "Button_primary Button_medium" }}
      visible={isOpen}
      onClose={() => {
        setIsOpen(false);
        resolver.current(false);
      }}
      onOk={() => {
        setIsOpen(false);
        resolver.current(true);
      }}
    >
      <p className="body">
        Zleceniodawca zabezpieczył dokumentację dla tego zamówienia. Wyślij
        prośbę o dostęp. Informację zwrotną prześlemy na Twoją skrzynkę email.
      </p>
    </Modal>
  );
});
