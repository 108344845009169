import { toLocaleDate } from "@helpers/dateUtils";

export const columns = [
  {
    title: "Użytkownik",
    field: "name",
  },
  {
    title: "Firma",
    field: "company_name",
  },

  {
    title: "Kiedy",
    field: "downloaded_at",
    render: (value: string) => {
      return toLocaleDate(value) + ", " + new Date(value).toLocaleTimeString();
    },
  },
];
