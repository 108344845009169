import { useEffect } from "react";
import produce from "immer";
import { genTempId } from "@helpers/utils";
import { InputNumber } from "@components/InputNumber";
import { AddNextBtn } from "../../AddNextBtn";
import { Type } from "./Type";
import commonCs from "../AdditionalProcessing.module.scss";
import cs from "./CounterboreHoles.module.scss";

export const CounterboreHoles = ({ holes = [], readOnly, onChange }) => {
  holes = addTempId(holes);

  useEffect(() => {
    if (holes.length === 0) {
      setTimeout(() => {
        handleTypeAdded();
      });
    }
  }, [holes]);

  return (
    <div>
      <div className="h5 mb16">Pogłębianie walcowe otworów:</div>

      <div className={commonCs.body}>
        <div className={commonCs.amount}>
          <div className="color-dark-gray">Ilość typów pogłębień</div>
          <div>
            <InputNumber
              value={holes.length}
              readOnly
              size="small"
              style={{ width: "63px" }}
            />
          </div>
        </div>

        <div className={cs.main}>
          {holes.map((hole, index) => (
            <Type
              hole={hole}
              index={index}
              readOnly={readOnly}
              onTypeRemoved={handleTypeRemoved}
              onTypeChange={handleTypeChanged}
            />
          ))}

          {!readOnly && (
            <AddNextBtn className="mt4" onClick={handleTypeAdded} />
          )}
        </div>
      </div>
    </div>
  );

  function handleTypeAdded() {
    onChange("holes.counter_bore_holes", [
      ...holes,
      {
        count: null,
        bore_depth: null,
        bore_outer_diameter: null,
        hole_depth: null,
        hole_diameter: null,
      },
    ]);
  }

  function handleTypeChanged(tempId, property, value) {
    const updatedHoles = produce(holes, (draft) => {
      const type = draft.find((hole) => hole.tempId === tempId);
      type[property] = value;
    });

    onChange("holes.counter_bore_holes", updatedHoles);
  }

  function handleTypeRemoved(tempId) {
    onChange(
      "holes.counter_bore_holes",
      holes.filter((hole) => hole.tempId !== tempId)
    );
  }
};

function addTempId(holes: any): any {
  return produce(holes, (draft) => {
    draft.forEach((hole: any) => {
      hole.tempId = genTempId();
    });
  });
}
