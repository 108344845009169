import {
  getParameters,
  getTechnologies,
  getTechnologies2,
} from "@services/technology.api";
import { StoreState, useStore } from "@store";

export type TechnologiesSlice = {
  technologies: any[];
  technologies2: any[];
  groupedTechnologies: any[];
  parameters: any[];
  fetchTechnologies: () => Promise<void>;
  fetchTechnologies2: () => Promise<void>;
  fetchParameters: () => Promise<void>;
};

export const technologiesSlice = (set: any): TechnologiesSlice => ({
  technologies: [],
  technologies2: [],
  parameters: [],
  groupedTechnologies: [],
  fetchTechnologies: async () => {
    const { technologies, groupedTechnologies, isSuccess } =
      await getTechnologies();

    if (isSuccess) {
      set(
        { technologies, groupedTechnologies },
        false,
        "technology/fetchTechnologies"
      );
    }
  },
  fetchTechnologies2: async () => {
    const { technologies, isSuccess } = await getTechnologies2();

    if (isSuccess) {
      set(
        { technologies2: technologies },
        false,
        "technology/fetchTechnologies2"
      );
    }
  },
  fetchParameters: async () => {
    const { parameters, isSuccess } = await getParameters();

    if (isSuccess) {
      set({ parameters }, false, "technology/fetchTechnologies2");
    }
  },
});

export const selectors = {
  getTechnologies: (state: StoreState) => state.technologies2,
  getParameters: (state: StoreState) => state.parameters,
};

export const hooks = {
  useServices() {
    const technologies = useStore(selectors.getTechnologies);

    return technologies.filter((tech) => !tech.parent_id);
  },
  useTechnologies() {
    const technologies = useStore(selectors.getTechnologies);

    const services = technologies.filter((tech) => !tech.parent_id);
    const technologiesOnly = technologies.filter((tech) => tech.parent_id);

    const servicesWithoutChildren = services.filter((service) => {
      return !technologiesOnly.some((tech) => tech.parent_id === service._id);
    });

    return [...technologiesOnly, ...servicesWithoutChildren];
  },
  useTechnolgiesByService(serviceId?: number) {
    const technologies = useStore(selectors.getTechnologies);

    if (!serviceId) return [];

    return technologies.filter((tech) => tech.parent_id === serviceId);
  },
};
