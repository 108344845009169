import { useNavigate } from "react-router-dom";
import { store } from "@store";
import { getOfferMainStatusTag } from "@helpers/getOfferMainStatusTag";
import { getOfferAddStatusTag } from "@helpers/getOfferAddStatusTag";
import { toLocaleDate } from "@helpers/dateUtils";
import { getShortUsername } from "@helpers/utils";
import { Card } from "@components/Card";
import { TillEndChips } from "@components/TillEndChips";
import cs from "./UrgentOffer.module.scss";

export const UrgentOffer = ({ offer }) => {
  const navigate = useNavigate();

  const user = store.hooks.useUser();

  const { owner_company } = offer;

  const elements = offer.elements.filter((el) => el.thumbnail);

  const firstFourElements = elements.slice(0, 4);
  const moreElements = elements.slice(4);

  return (
    <Card
      className={cs.UrgentOffer}
      onClick={() => navigate(`/offer/${offer._id}`)}
    >
      <Card.Header>
        <div className="h5">Moja oferta do zapytania {offer.seq_no}</div>
        <div className={cs.desc}>{offer.desc}</div>
      </Card.Header>

      <Card.Body className="caption color-dark-gray">
        <div className="d-flex mb24">
          {getOfferMainStatusTag(offer.status, "caption")}

          {offer.offer_period && (
            <TillEndChips
              date={offer.offer_period}
              size="caption"
              className="ml8"
            />
          )}
        </div>

        <div className={cs.info}>
          <div className={cs.offer}>
            <div className="mb2">Twoja oferta:</div>
            <div className="h5">{offer.offer_sum} PLN</div>

            {getOfferAddStatusTag(offer.status, "small", "mt2")}
          </div>

          <div className={cs.client}>
            <div className="bold color-black mb4">{owner_company.name}</div>

            <div>
              <div className="d-flex justify-between mt4">
                <span>Termin realizacji:</span>
                <span className="bold">
                  {toLocaleDate(offer.done_deadline)}
                </span>
              </div>

              <div className="d-flex justify-between mt4">
                <span>Termin składania ofert:</span>
                <span className="bold">{toLocaleDate(offer.bid_deadline)}</span>
              </div>

              {user.is_admin && (
                <div className="d-flex justify-between mt4">
                  <span>Składający:</span>
                  <span className="bold">
                    {getShortUsername(offer.owner_name)}
                  </span>
                </div>
              )}

              <div className="d-flex justify-between mt4">
                <span>Ilość ofert:</span>
                <span className="bold">{offer.offers_count}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt24">
          <div>Ilość kompletów: {offer.total_amount}</div>

          <div className={cs.elements}>
            {firstFourElements.map((element) => (
              <img src={element.thumbnail} width={72} height={72} />
            ))}

            {moreElements.length > 0 && (
              <span>+ {moreElements.length} elem.</span>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
