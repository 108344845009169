import { Button } from "@components/Button";
import { Technologies } from "./Technologies";
import { MachineParameters } from "./MachineParameters/MachineParameters";
import machineNoImg from "../img/m_no_img.png";
import cs from "./Machine.module.scss";

export const Machine = ({
  machine,
  onRemoveMachine,
  onTechnologiesChange,
  onParameterChange,
}) => {
  return (
    <div className={`Machine ${cs.Machine}`}>
      <div className={cs.content}>
        <div>
          <img
            src={machine.img || machineNoImg}
            alt={machine.model_name}
            width={216}
          />
        </div>

        <div className={cs.main}>
          <div className="d-flex align-center mb12">
            <span className="h5 mr-auto">{machine.type_name}</span>

            <Button
              type="secondary"
              size="small"
              onClick={() => onRemoveMachine(machine._id)}
            >
              Usuń
            </Button>
          </div>

          {machine.brand_name && (
            <div className="caption">
              <span>Producent: </span>
              <span className="bold color-dark-gray">{machine.brand_name}</span>
            </div>
          )}

          {machine.model_name && (
            <div className="caption">
              <span>Model: </span>
              <span className="bold color-dark-gray">{machine.model_name}</span>
            </div>
          )}

          <div className="mt24">
            <div className="h6 mb8">Specifikacja:</div>

            <MachineParameters
              parameters={machine.parameters}
              onSave={(newValue: any) => {
                onParameterChange(machine._id, newValue);
              }}
            />
          </div>

          <div className="mt24">
            <div className="caption mb8">Obsługiwane technologie:</div>

            <Technologies
              technologyIds={machine.technologies}
              onSave={(newValue) => {
                onTechnologiesChange(machine._id, newValue);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
