import { useEffect, useState } from "react";
import classNames from "classnames";
import { InputNumber } from "@components/InputNumber";
import { AddNextBtn } from "../../AddNextBtn";
import { Depth } from "./Depth";
import deleteImg from "../../img/delete.svg";
import cs from "./Drilling.module.scss";

export const Diameter = ({
  group,
  index,
  readOnly,
  onDiameterChange,
  onDiameterRemoved,
  onDepthAdded,
  onDepthChange,
  onDepthRemoved,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (group.depths.length === 0) {
      setTimeout(() => {
        onDepthAdded(group.tempId);
      });
    }
  }, [group.depths]);

  const rootCs = classNames(cs.Diameter, {
    [cs.hovered]: isHovered,
  });

  return (
    <div className={rootCs}>
      <div className={cs.diameter}>
        <div className="d-flex align-center justify-between mb24">
          <span className="bold">Średnica {index + 1}:</span>
          {!readOnly && (
            <img
              src={deleteImg}
              alt=""
              style={{ cursor: "pointer" }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => onDiameterRemoved(group.tempId)}
            />
          )}
        </div>

        <div className="d-flex align-center">
          <span className="mr24">Średnica Ø</span>
          <InputNumber
            value={group.diameter}
            style={{ width: "63px" }}
            size="small"
            min={0.01}
            readOnly={readOnly}
            required
            onChange={(value) => onDiameterChange(group.tempId, value)}
          />
          <span className="ml8">mm</span>
        </div>
      </div>

      <div className={cs.deepenings}>
        {group.depths.map((depth, depthIndex) => (
          <Depth
            diameter={group.diameter}
            diameterId={group.tempId}
            depth={depth}
            index={depthIndex}
            readOnly={readOnly}
            onDepthChange={onDepthChange}
            onDepthRemoved={onDepthRemoved}
          />
        ))}

        {!readOnly && (
          <AddNextBtn
            className="mt4"
            onClick={() => onDepthAdded(group.tempId)}
          />
        )}
      </div>
    </div>
  );
};
