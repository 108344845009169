import { useRef } from "react";
import { Navigate, useParams } from "react-router-dom";
import { notification, Spin } from "antd";
import { approveOrderApi } from "../../services/order.api";
import { useOrder } from "../../services/order.hooks";
import { ORDER_STATUSES } from "../../../constants/ORDER_STATUSES";
import { toLocaleDate } from "@helpers/dateUtils";
import { downloadFile } from "@helpers/utils";
import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { Confirmation } from "@components/Confirmation";
import { DocsProtectionChoice } from "../../../Customer/components/DocsProtectionChoice";
import { DownloadHistory } from "./DownloadHistory/DownloadHistory";
import { AdditionalFiles } from "./AdditionalFiles";
import { StatusInfoPanel } from "./StatusInfoPanel";
import { Elements } from "./Elements";
import { Offers } from "./Offers/Offers";
import cs from "./OrderPage.module.scss";

export const OrderPage = () => {
  const confirmOrderAccept = useRef(null);

  const { id } = useParams();
  const [order, error] = useOrder(id as string);

  if (error === "NOT_ADMIN") {
    return <Navigate to={`/order/${id}`} replace />;
  }

  if (!order) {
    return <Spin spinning size="large" className="flex-center" />;
  }

  return (
    <main className={cs.OrderPage}>
      <header className={cs.header}>
        <div>
          <h1 className="h3 mb8">Zapytanie ofertowe {order.seq_no}</h1>
          <div>
            <span>Opublikowano: {toLocaleDate(order.created_at)}</span>

            <span> przez {order.owner_name}</span>
          </div>
        </div>

        <div className={cs.headerActions}>
          <Button
            type="outline"
            size="medium"
            onClick={handleDocumentationDownload}
          >
            Pobierz dokumentację zapytania
          </Button>

          {order.status === ORDER_STATUSES.UNAPPROVED && (
            <Button
              type="primary"
              size="medium"
              className="hide-phone"
              onClick={handleAcceptClicked}
            >
              Zatwierdź
            </Button>
          )}
        </div>
      </header>

      <StatusInfoPanel order={order} />

      <section className={cs.descAndDocsProtectionSection}>
        <Card className={cs.desc}>
          <Card.Header className="h5">Opis zapytania</Card.Header>
          <Card.Body className="body">
            {order.desc || <span style={{ color: "#636E72" }}>Brak opisu</span>}
          </Card.Body>
        </Card>

        <Card className={cs.docsProtection}>
          <Card.Header className="h5">Zabezpieczenie dokumentacji</Card.Header>
          <Card.Body className="body">
            <DocsProtectionChoice
              docsProtection={order.permisson_to_download_file_required}
            />
          </Card.Body>
        </Card>
      </section>

      <DownloadHistory downloadHistory={order.downloadHistory} />

      <Offers order={order} offers={order.offers} onOfferSelected={() => {}} />

      <Elements
        order={order}
        noOfSets={order.total_amount}
        elements={order.elements}
      />

      {order.order_files.length > 0 && (
        <AdditionalFiles files={order.order_files} />
      )}

      <Confirmation
        ref={confirmOrderAccept}
        title="Potwierdź"
        text="Czy na pewno chcesz aktywować to zapytanie?"
        cancelText="Anuluj"
        okText="Aktywuj"
      />
    </main>
  );

  async function handleAcceptClicked() {
    const isConfirmed = await confirmOrderAccept.current.open();

    if (isConfirmed) {
      const { isSuccess } = await approveOrderApi(id);

      if (isSuccess) {
        setTimeout(() => {
          window.location.reload();
        }, 600);
      } else {
        notification.error({
          message: `Wystąpił błąd.`,
          placement: "topRight",
        });
      }
    }
  }

  async function handleDocumentationDownload() {
    try {
      await downloadFile(`/api/admin/order/${id}/file`);
    } catch (e) {
      notification.error({
        message: `Coś poszło nie tak. Spróbuj ponownie później.`,
        placement: "topRight",
      });
    }
  }
};
