import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { Btn } from "../../../../../Customer/views/NewOrderPage/Elements/Element/Element";
import { BendingRadiusToThicknessTable } from "./BendingRadiusToThicknessTable";
import { Parameter } from "./Parameter";
import cs from "./MachineParameters.module.scss";

export const MachineParameters = ({ parameters, onSave }) => {
  const [parametersCopy, setParametersCopy] = useImmer(parameters);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setParametersCopy(parameters);
  }, [parameters]);

  return (
    <div className={cs.MachineParameters}>
      <div>
        {parametersCopy.map((param) => {
          if (param._id === 6) return null;

          return (
            <Parameter
              key={param._id}
              label={param.label}
              value={param.value}
              dimensions={param.dimensions}
              isEdited={isEdited}
              onChange={(value) => {
                handleValueChanged(param._id, value);
              }}
            />
          );
        })}

        {/* NOTE: Tables */}
        {parametersCopy.map((param) => {
          if (param._id === 6) {
            return (
              <BendingRadiusToThicknessTable
                key={param._id}
                label={param.label}
                rows={param.value || []}
                readOnly={!isEdited}
                onChange={(value) => {
                  handleValueChanged(param._id, value);
                }}
              />
            );
          }

          return null;
        })}
      </div>

      <div className="mt8">
        {isEdited ? (
          <Btn onClick={handleSave}>
            <span className="bold">Zapisz zmiany</span>
          </Btn>
        ) : (
          <Btn onClick={() => setIsEdited(true)}>
            <span className="bold">Edytuj</span>
          </Btn>
        )}
      </div>
    </div>
  );

  function handleValueChanged(paramId: number, value: string) {
    setParametersCopy((prev) => {
      const parameter = prev.find(({ _id }) => _id === paramId);
      parameter.value = value;
    });
  }

  function handleSave() {
    onSave(parametersCopy);
    setIsEdited(false);
  }
};
