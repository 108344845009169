import axios from "axios";

const apiUrl = "/api/auth";

let userAuthenticated = false;

export async function checkIfUserAuthenticated() {
  const url = apiUrl + "/isAuthenticated";

  try {
    const { data } = await axios.get(url);

    userAuthenticated = data.isAuthenticated;
  } catch (e) {
    // TODO: Log error

    userAuthenticated = false;
  }

  return userAuthenticated;
}

export function isAuthenticated() {
  return userAuthenticated;
}

export async function logOutApi() {
  const url = apiUrl + "/logout";

  try {
    await axios.get(url);
  } finally {
    window.location.reload();
  }
}

export async function logInApi(logInData: { email: string; password: string }) {
  const url = apiUrl + "/login";

  try {
    await axios.post(url, logInData);

    userAuthenticated = true;
  } catch (e) {
    // TODO: Log error
    userAuthenticated = false;
  }

  return userAuthenticated;
}

export async function sendAuthCodeApi(phone: string) {
  const url = apiUrl + "/verification/phone";

  try {
    const response = await axios.post(url, { phone });

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (e: any) {
    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}

export async function verifyAuthCodeApi(phone: string, code: string) {
  const url = apiUrl + "/verification/code";

  try {
    const response = await axios.post(url, { phone, code });

    return {
      isSuccess: response.status === 200,
    };
  } catch (e: any) {
    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}

export async function registerApi(registerData: any) {
  const url = apiUrl + "/register";

  try {
    const response = await axios.post(url, registerData);

    return {
      isSuccess: response.status === 201,
    };
  } catch (e: any) {
    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}

export async function getCurrentUserDetailsApi() {
  const url = apiUrl + "/";

  try {
    const response = await axios.get(url);

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (e: any) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function getUserDetailsFromInvitation(userId: string) {
  try {
    const response = await axios.get(apiUrl + "/invitation/" + userId);

    return {
      isSuccess: response.status === 200,
      data: response.data,
    };
  } catch (e) {
    console.error(e);

    return {
      isSuccess: false,
    };
  }
}

export async function registerFromInvitationApi(
  userId: string,
  registerData: any
) {
  const url = apiUrl + "/invitation/" + userId;

  try {
    const response = await axios.put(url, registerData);

    return {
      isSuccess: response.status === 200,
    };
  } catch (e: any) {
    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}

export async function resetPasswordApi(email: string) {
  const url = apiUrl + "/password/reset/" + email;

  try {
    const response = await axios.post(url);

    return {
      isSuccess: response.status === 200,
    };
  } catch (e: any) {
    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}

export async function updateUserDataApi(userData: any) {
  try {
    const response = await axios.put(apiUrl, userData);

    return {
      isSuccess: response.status === 200,
    };
  } catch (e: any) {
    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}

export async function confirmEmailApi(userId: string) {
  const url = apiUrl + "/confirmation/" + userId;

  try {
    const response = await axios.post(url);

    return {
      isSuccess: response.status === 200,
    };
  } catch (e: any) {
    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}

export async function setNewPasswordApi(password: string, token: string) {
  const url = apiUrl + "/password";

  try {
    const response = await axios.put(url, { password, token });

    return {
      isSuccess: response.status === 200,
    };
  } catch (e: any) {
    return {
      isSuccess: false,
      error: e.response.data?.error?.message,
    };
  }
}
