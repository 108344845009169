export function servicesToTechnologies(services) {
  const technologyIds: any[] = [];

  services.forEach((service) => {
    // NOTE: Service without any technologies - e.g. Grinding
    if (service.technologyIds.length === 0) {
      technologyIds.push(service._id);
    } else {
      service.technologyIds.forEach((technologyId) => {
        technologyIds.push(technologyId);
      });
    }
  });

  return technologyIds;
}
