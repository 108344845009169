import { useEffect, useState } from "react";
import { getTechnologyNames } from "@helpers/technology";
import { Chip } from "@components/Chip";
import { ServicesSelect } from "@components/ServicesSelect";
import { ServiceBare } from "../../Customer/types";
import { Btn } from "../../Customer/views/NewOrderPage/Elements/Element/Element";
import cs from "./Services.module.scss";

type Props = {
  services: ServiceBare[];
  isEditable: boolean;
  isMaterialSelected: boolean;
  onChange: (selectedServiceIds: number[]) => void;
};

export const Services = ({
  services,
  isMaterialSelected,
  isEditable,
  onChange,
}: Props) => {
  const [isEdited, setIsEdited] = useState(false);
  const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>([]);

  useEffect(() => {
    setSelectedServiceIds(services?.map(({ _id }) => _id));
  }, [services]);

  const edited = (
    <>
      <ServicesSelect
        value={selectedServiceIds}
        size="small"
        className={cs.select}
        onChange={setSelectedServiceIds}
      />

      {selectedServiceIds.length > 0 && (
        <Btn onClick={handleSave}>
          <span className="bold">Zapisz</span>
        </Btn>
      )}
    </>
  );

  const list = (
    <div className={cs.Services}>
      {getTechnologyNames(services.map(({ _id }) => _id)).map((serviceName) => (
        <Chip color="gray" size="caption">
          {serviceName}
        </Chip>
      ))}

      {isEditable && (
        <Btn onClick={() => setIsEdited(true)}>
          <span className="bold">Edytuj</span>
        </Btn>
      )}
    </div>
  );

  const empty = (
    <div className="caption bold color-black">
      <div className="caption color-red mb2">Brak usług</div>

      {isMaterialSelected ? (
        <Btn onClick={() => setIsEdited(true)}>
          <span className="bold">Dodaj</span>
        </Btn>
      ) : (
        <div className="small">(Wybierz materiał)</div>
      )}
    </div>
  );

  if (isEdited) {
    return edited;
  }

  return services.length > 0 ? list : empty;

  function handleSave() {
    setIsEdited(false);

    onChange(selectedServiceIds);
  }
};
