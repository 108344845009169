import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useImmer } from "use-immer";
import isEqual from "lodash.isequal";
import { notification, Spin } from "antd";
import { createOrderApi, updateOrderApi } from "../../services/order.api";
import { servicesToTechnologies } from "../../services/technology.helpers";
import { useOrder } from "../../services/order.hooks";
import {
  OrderingAnalyser,
  useFirstFileAnalysed,
} from "../../services/OrderingAnalyser";
import { Button } from "@components/Button";
import { DatePickerValue } from "@components/DatePicker";
import { Confirmation } from "@components/Confirmation";
import { NotAvailableOnMobile } from "@components/NotAvailableOnMobile";
import { Elements } from "./Elements";
import { AdditionalFiles } from "./AdditionalFiles";
import { Details } from "./Details";
import { CancelConfirmation } from "./CancelConfirmation";
import { HowToCard } from "./HowToCard";
import { ContactInfoModal } from "./ContactInfoModal";
import cs from "./NewOrderPage.module.scss";

type Form = Partial<{
  noOfSets: number;
  desc: string;
  bid_deadline: DatePickerValue;
  done_deadline: DatePickerValue;
  permisson_to_download_file_required: boolean;
  additionalFiles: File[];
}>;

type Mode = Partial<{
  isEdit: boolean;
  isCopy: boolean;
  isNew: boolean;
  isDraft: boolean;
  isPublic: boolean;
}>;

export const NewOrderPage = () => {
  const [form, setForm] = useImmer<Form>({ noOfSets: 1 });
  const [elements, setElements] = useImmer([]);
  const [selectedElements, setSelectedElements] = useImmer(new Set());
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isHowToClosed, setIsHowToClosed] =
    useState<boolean>(checkIsHowToClosed);

  const cancelConfirmation = useRef(null);
  const confirmOrderCreation = useRef(null);
  const contactInfoModal = useRef(null);

  const navigate = useNavigate();

  const mode = getMode();
  const [originalOrder] = useExistingOrder(mode, setForm, setElements);
  const setFirstAnalysed = useFirstFileAnalysed(mode);

  return (
    <main className={`NewOrderPage ${cs.NewOrderPage}`}>
      {!mode.isPublic && (
        <h1 className={cs.pageTitle}>
          {mode.isEdit
            ? `Zapytanie ofertowe ${form.seq_no}`
            : "Utwórz zapytanie"}
        </h1>
      )}

      <form className="hide-phone" onSubmit={handleSubmit}>
        <div className={cs.elements_howToCard_section}>
          <Elements
            order={form}
            elements={elements}
            selectedElements={selectedElements}
            noOfSets={form.noOfSets}
            isHowToClosed={isHowToClosed}
            mode={mode}
            onNoOfSetsChange={(newTotalAmount: number) => {
              handleFormChanged("noOfSets", newTotalAmount);
            }}
            onElementsChange={(handler) => {
              setIsHowToClosed(true);
              setFirstAnalysed(true);

              setElements((prev) => {
                const updatedElements = handler(prev);

                return updatedElements;
              });
            }}
            onSelectionChange={({ selected, _id }) => {
              setSelectedElements((prev) => {
                if (selected) {
                  prev.add(_id);
                } else {
                  prev.delete(_id);
                }
              });
            }}
            onCloseHowToCard={closeHowToCard}
          />

          {!isHowToClosed && <HowToCard onClose={closeHowToCard} />}
        </div>

        <AdditionalFiles
          files={form.additionalFiles}
          onChange={handleFormChanged}
        />

        <Details
          form={form}
          disabled={elements.length === 0}
          onChange={handleFormChanged}
        />

        <section className={cs.actions}>
          {mode.isEdit ? (
            <>
              <Button
                type="secondary"
                size="large"
                onClick={() => {
                  navigate("/order/" + form._id);
                }}
              >
                Wróć
              </Button>

              <Spin spinning={isSaveLoading}>
                <Button
                  type="primary"
                  size="large"
                  btnType="submit"
                  disabled={!checkValidity()}
                >
                  Zapisz zmiany
                </Button>
              </Spin>
            </>
          ) : (
            <>
              <Button
                type="secondary"
                size="large"
                style={{ visibility: mode.isPublic ? "hidden" : "visible" }}
                onClick={() => {
                  cancelConfirmation.current.open();
                }}
              >
                Anuluj zapytanie
              </Button>

              <Spin spinning={isSaveLoading}>
                <Button
                  id="order_submit_btn"
                  type="primary"
                  size="large"
                  btnType="submit"
                  disabled={!checkValidity()}
                >
                  {mode.isPublic ? "Przejdź dalej" : "Utwórz zapytanie"}
                </Button>
              </Spin>
            </>
          )}
        </section>
      </form>

      <NotAvailableOnMobile />

      <CancelConfirmation ref={cancelConfirmation} />

      {mode.isPublic && <ContactInfoModal ref={contactInfoModal} />}

      <Confirmation
        ref={confirmOrderCreation}
        title="Potwierdzenie"
        text={
          <span>
            <span>
              Czy napewno uzupełniłeś wszystkie informacje dotyczące zapytania,
              takie jak materiał i poszukiwane usługi? Jeśli tak, kliknij w
            </span>
            <strong> Utwórz Zapytanie</strong>.
          </span>
        }
        cancelText="Anuluj"
        okText="Utwórz zapytanie"
        okCs="Button_primary"
        cancelCs="Button_secondary"
      />
    </main>
  );

  function handleFormChanged(changedField: string, newValue: any) {
    setForm((prev) => {
      prev[changedField] = newValue;

      if (changedField === "done_deadline") {
        prev.bid_deadline = null;
      }
      if (changedField === "noOfSets") {
        setElements((prev) => {
          prev.forEach((element) => {
            element.total_amount = element.amount * newValue;
          });
        });
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      !form.bid_deadline ||
      !form.done_deadline ||
      form.permisson_to_download_file_required === undefined
    ) {
      document
        .querySelectorAll(".Details .DatePicker")
        .forEach((datepicker) => {
          datepicker.dispatchEvent(new CustomEvent("touched"));
        });

      document.querySelectorAll(".Details .RadioGroup").forEach((input) => {
        input.dispatchEvent(new CustomEvent("touched"));
      });

      return;
    }

    // NOTE: Check if all elements have material and services selected
    const errorEl = document.querySelector(".Element_error");

    if (errorEl) {
      notification.info({
        message:
          "Każdy detal powinien mieć wybrany materiał i przynajmniej jedną poszukiwaną usługę.",
        placement: "bottomRight",
      });

      errorEl.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });

      return;
    }

    if (mode.isNew || mode.isDraft) {
      const confirmed = await confirmOrderCreation.current.open();

      if (!confirmed) {
        return;
      }
    }

    try {
      if (mode.isEdit || mode.isDraft) {
        setIsSaveLoading(true);

        const response = await handleEditSave(form, elements, originalOrder);

        if (response.isSuccess) {
          navigate("/order/" + form._id, {
            state: {
              wasEdited: mode.isEdit,
            },
          });
        } else {
          notification.error({
            message: "Wystąpił błąd. Spróbuj ponownie później.",
            placement: "topRight",
          });
        }
      } else if (mode.isPublic) {
        const saveObj = createSaveObject(form, elements);
        await contactInfoModal.current?.openModal(saveObj);
      } else {
        setIsSaveLoading(true);

        const saveObj = createSaveObject(form, elements);
        const response = await createOrderApi(saveObj);

        if (response.isSuccess) {
          OrderingAnalyser.orderCreated();

          navigate("/orders", { state: { wasCreated: true } });
        } else {
          notification.error({
            message: "Wystąpił błąd przy tworzeniu zapytania.",
            description: "Spróbuj ponownie za jakiś czas.",
            placement: "topRight",
          });
        }
      }
    } finally {
      setIsSaveLoading(false);
    }
  }

  function checkValidity() {
    const atLeastOneElement = elements.length > 0;

    return atLeastOneElement;
  }

  function closeHowToCard() {
    localStorage.setItem("new_order_how_to_closed", "1");
    setIsHowToClosed(true);
  }
};

function useExistingOrder(mode: Mode, setForm, setElements) {
  const { id } = useParams();

  const [existingOrder] = useOrder(id);

  useEffect(() => {
    if (!existingOrder) return;

    if (mode.isEdit || mode.isCopy || mode.isDraft) {
      setForm(mapExistingOrderToForm(existingOrder, mode));
      setElements(existingOrder.elements);
    }
  }, [existingOrder]);

  return [existingOrder];
}

function createSaveObject(form: Form, elements) {
  const formData = new FormData();

  formData.append("desc", form.desc || "");
  formData.append(
    "permisson_to_download_file_required",
    form.permisson_to_download_file_required
  );
  formData.append("total_amount", form.noOfSets);
  formData.append("done_deadline", form.done_deadline.toISOString());
  formData.append("bid_deadline", form.bid_deadline.toISOString());

  form.additionalFiles?.forEach((file) => {
    // NOTE: Existing file - case when order cloned
    if (file._id) {
      formData.append("order_files_existing", file._id);
    } else {
      formData.append("order_files", file, file.name);
    }
  });

  elements.forEach((element) => {
    if (!element.file._id) {
      formData.append(
        "element_file",
        element.file,
        `${element._id}____${element.file.name}`
      );
    }

    element.relatedFiles.forEach((relatedFile) => {
      if (!relatedFile._id) {
        formData.append(
          "element_related_file",
          relatedFile,
          `${element._id}____${relatedFile.name}`
        );
      }
    });
  });

  const saveObjElements = prepareElementsBeforeSave(elements);

  formData.append("elements", JSON.stringify(saveObjElements));

  return formData;
}

function prepareElementsBeforeSave(elements) {
  return elements.map((element) => ({
    _id: element._id,
    name: element.name,
    material_id: element.material_id,
    material_type_id: element.material_type_id,
    technology_ids: servicesToTechnologies(element.services),
    remarks: element.remarks,
    amount: element.amount,
    total_amount: element.total_amount,
    desc: element.desc,
    thumbnail: element.thumbnail,
    parameters: element.parameters,
    // NOTE: Here only already existing files - case when order cloned
    file: element.file._id || null,
    relatedFiles: element.relatedFiles
      .filter((file) => file._id)
      .map(({ _id }) => _id),
  }));
}

function mapExistingOrderToForm(existingOrder: any, mode: Mode) {
  return {
    ...existingOrder,
    noOfSets: existingOrder.total_amount,
    additionalFiles: existingOrder.order_files,
    permisson_to_download_file_required:
      existingOrder.permisson_to_download_file_required,
    done_deadline: mode.isEdit ? moment(existingOrder.done_deadline) : null,
    bid_deadline: mode.isEdit ? moment(existingOrder.bid_deadline) : null,
  };
}

async function handleEditSave(form, elements, originalOrder) {
  const formData = new FormData();

  // NOTE: 1) Order data

  // NOTE: Without removed and new ones (File type - uploaded separately)
  const selectedOrderFilesWithoutNewlyAdded = form.additionalFiles.filter(
    (file) => file._id
  );
  const addedOrderFiles = form.additionalFiles.filter((file) => !file._id);

  formData.append(
    "order",
    JSON.stringify({
      done_deadline: form.done_deadline.toISOString(),
      bid_deadline: form.bid_deadline.toISOString(),
      desc: form.desc,
      total_amount: form.noOfSets,
      permisson_to_download_file_required:
        form.permisson_to_download_file_required,
      order_files: selectedOrderFilesWithoutNewlyAdded,
    })
  );
  addedOrderFiles.forEach((addedFile) => {
    formData.append("newOrderFiles", addedFile);
  });

  // NOTE: 2) Elements data
  const removedElementIds = originalOrder.elements
    .filter((el) => {
      return !elements.some(({ _id }) => _id === el._id);
    })
    .map(({ _id }) => _id);

  let editedElements = elements.filter((el) => {
    const originalEl = originalOrder.elements.find(({ _id }) => _id === el._id);

    return !isEqual(originalEl, el);
  });

  editedElements = editedElements.map((el) => ({
    ...el,
    technology_ids: servicesToTechnologies(el.services),
  }));

  formData.append("removedElementIds", JSON.stringify(removedElementIds));
  formData.append("editedElements", JSON.stringify(editedElements));

  editedElements.forEach((el) => {
    const addedFiles = el.relatedFiles.filter((file) => !file._id);

    addedFiles.forEach((addedFile) => {
      formData.append(
        "newElementFiles",
        addedFile,
        `${el._id}____${addedFile.name}`
      );
    });
  });

  editedElements.forEach((el) => {
    // NOTE: New elements
    if (typeof el._id === "number") {
      formData.append(
        "newElementFiles",
        el.file,
        `${el._id}____${el.file.name}`
      );
    }
  });

  // NOTE: 3) Save
  return updateOrderApi(form._id, formData);
}

function getMode(): Mode {
  const path = window.location.pathname;

  if (path.includes("copy")) return { isCopy: true };

  if (path.includes("edit")) return { isEdit: true };

  if (path.includes("draft")) return { isDraft: true };

  if (path.includes("public")) return { isPublic: true };

  return { isNew: true };
}

function checkIsHowToClosed() {
  const path = window.location.pathname;

  if (path.includes("edit") || path.includes("copy")) {
    return true;
  }

  return Boolean(localStorage.getItem("new_order_how_to_closed"));
}
