import classNames from "classnames";
import { trim } from "@helpers/utils";
import { getNoOfUnitsText } from "@helpers/getNoOfUnitsText";
import { Chip } from "@components/Chip";
import { Button } from "@components/Button";
import { Viewer3D } from "@components/Viewer3D";
import { Checkbox } from "@components/Checkbox";
import { OrderElementFiles } from "@components/OrderElementFiles";
import { MaterialName } from "../../../Customer/views/NewOrderPage/Elements/Element/MaterialName";
import { TechnologyNames } from "../../../Customer/views/NewOrderPage/Elements/Element/TechnologyNames";
import { Machines } from "./Machines";
import chevronDownImg from "./img/chevron_down.svg";
import cs from "./Elements.module.scss";

export const Element = ({
  order,
  element,
  offer,
  selectedMachines,
  isSelected,
  onShowDetails,
}) => {
  const files = [element.file, ...element.relatedFiles];

  let material;

  if (offer.material_id || offer.material_type_id) {
    material = {
      material_id: offer.material_id,
      material_type_id: offer.material_type_id,
    };
  } else {
    material = {
      material_id: element.material_id,
      material_type_id: element.material_type_id,
    };
  }

  const hasBothCuttingAndThreading = [1, 126].every((serviceId) => {
    return element.services.some(({ _id }) => _id === serviceId);
  });

  const rootCs = classNames(cs.Element, { [cs.Element__checked]: isSelected });

  return (
    <div className={rootCs}>
      <div className={cs.Element_main}>
        <div style={{ alignSelf: "center" }}>
          <img
            src={
              element.thumbnail ||
              process.env.PUBLIC_URL + "/no_thumbnail_96x96.png"
            }
            alt={element.name}
            width={96}
            height={96}
            onError={(e) => (e.target.src = chevronDownImg)}
          />
        </div>

        <div>
          <div className="mb4 body bold">{element.name}</div>
          <div className="caption">{element.desc}</div>

          <div className="mt8">
            <Viewer3D file={element.file} />
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb2">Materiał:</div>
            <div className="caption bold color-black">
              <MaterialName
                materialId={material.material_id}
                typeId={material.material_type_id}
              />
            </div>
          </div>

          <div className="mb12">
            <div className="caption mb2">Usługi:</div>
            <div className="caption bold color-black">
              <TechnologyNames
                serviceIds={element.services.map(({ _id }) => _id)}
              />
            </div>
          </div>

          <div className="hide-mobile">
            <div className="caption mb2">Maszyny:</div>
            <div>
              <Machines selectedMachines={selectedMachines} />
            </div>
          </div>

          {hasBothCuttingAndThreading && (
            <div className="mt12">
              <div className="caption mb4">Wymagane informacje:</div>

              <div style={{ pointerEvents: "none" }}>
                <Checkbox
                  checked={offer.noCutThreadedHoles}
                  label="Nie wycinaj otworów gwintowanych"
                  size="small"
                />
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb2">Pliki dokumentacji:</div>
            <div className="caption bold color-black">
              <OrderElementFiles order={order} files={files} />
            </div>
          </div>

          <div className="mb12">
            <div className="caption mb2">Uwagi zlecającego:</div>
            <div className="caption color-black">
              {trim(element.remarks || "-", 60)}
            </div>
          </div>

          <div>
            <div className="caption mb2">Twoje uwagi:</div>
            <div className="caption color-black">
              {trim(offer.remarks || "-", 60)}
            </div>
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb4">Łączna ilość:</div>
            <div className="caption color-black bold">
              <Chip color="orange" size="caption">
                {getNoOfUnitsText(element.total_amount)}
              </Chip>
            </div>
          </div>

          <div className="caption">
            Ilość szt. w komplecie: {element.amount}
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb4">Cena detal:</div>
            <div className="h5">{offer.price} zł</div>
          </div>

          <div className="caption">
            <div className="mb4">Cena sumaryczna:</div>

            <div>
              <span>
                {element.total_amount} x {offer.price} zł ={" "}
              </span>
              <strong className="color-black">{offer.price_total} zł</strong>
            </div>
          </div>
        </div>
      </div>

      <div className={cs.Element_footer}>
        <Button type="link" onClick={onShowDetails}>
          <span className="mr8">Szczegóły elementu</span>
          <img src={chevronDownImg} />
        </Button>
      </div>
    </div>
  );
};
