import { useEffect } from "react";
import { useImmer } from "use-immer";
import { notification } from "antd";
import { store, useStore } from "@store";
import { useCurrentAccount } from "@services/account.hooks";
import { saveCompanyDetailsApi } from "@services/account.api";
import { updateUserDataApi } from "@services/auth.api";
import { PROVINCES } from "../../../constants/PROVINCES";
import { Card } from "@components/Card";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { Textarea } from "@components/Textarea";
import { Select } from "@components/Select";
import { EmailNotConfirmedWarning } from "./EmailNotConfirmedWarning";
import cs from "./GeneralPage.module.scss";

export const GeneralPage = () => {
  const [personalInfoForm, setPersonalInfoForm] = useImmer<any>({});
  const [companyAddressForm, setCompanyAddressForm] = useImmer<any>({});
  const [companyDataForm, setCompanyDataForm] = useImmer<any>({});

  const user = store.hooks.useUser();
  const account = useCurrentAccount();
  const updateOrganizationName = useStore(
    store.selectors.updateOrganizationName
  );

  useEffect(() => {
    if (user) {
      setPersonalInfoForm({
        first_name: user.first_name,
        last_name: user.last_name,
        contact_email: user.contact_email,
        phone: user.phone,
        position: user.position,
      });
    }
  }, [user]);

  useEffect(() => {
    if (account) {
      setCompanyDataForm({
        name: account.name,
        email: account.email,
        phone: account.phone,
        tax_number: account.tax_number,
        regon_number: account.regon_number,
        website: account.website,
        additional_info: account.additional_info,
      });

      setCompanyAddressForm({
        street: account.street,
        building_no: account.building_no,
        apartment_no: account.apartment_no,
        postal_code: account.postal_code,
        city: account.city,
        region: account.region,
      });
    }
  }, [account]);

  return (
    <main className={`GeneralPage ${cs.GeneralPage}`}>
      {!user.is_confirmed && (
        <section>
          <EmailNotConfirmedWarning />
        </section>
      )}

      <section>
        <h2 className="h4">Moje dane</h2>

        <Card>
          <Card.Header className="h5">Podstawowe dane</Card.Header>
          <Card.Body>
            <form onSubmit={handlePersonalInfoSaved}>
              <Input
                name="first_name"
                label="Imię"
                required
                value={personalInfoForm.first_name}
                onChange={handlePersonalInfoChanged}
              />

              <Input
                name="last_name"
                label="Nazwisko"
                required
                value={personalInfoForm.last_name}
                onChange={handlePersonalInfoChanged}
              />

              <Input
                name="contact_email"
                label="Email"
                value={personalInfoForm.contact_email}
                onChange={handlePersonalInfoChanged}
              />

              <Input
                name="phone"
                label="Telefon"
                value={personalInfoForm.phone}
                onChange={handlePersonalInfoChanged}
              />

              <Input
                name="position"
                label="Stanowisko"
                value={personalInfoForm.position}
                onChange={handlePersonalInfoChanged}
              />

              <div className="text-right">
                <Button type="primary" btnType="submit" size="medium">
                  Zapisz zmiany
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </section>

      <section>
        <h2 className="h4">Dane firmy</h2>

        <Card>
          <Card.Header className="h5">Podstawowe dane firmy</Card.Header>
          <Card.Body>
            <form onSubmit={handleCompanySaved}>
              <Input
                name="name"
                label="Nazwa firmy"
                required
                value={companyDataForm.name}
                onChange={handleCompanyDataChanged}
              />

              <Input
                name="email"
                label="Email"
                value={companyDataForm.email}
                onChange={handleCompanyDataChanged}
              />

              <Input
                name="phone"
                label="Telefon"
                value={companyDataForm.phone}
                onChange={handleCompanyDataChanged}
              />

              <Input
                name="tax_number"
                label="NIP"
                required
                value={companyDataForm.tax_number}
                onChange={handleCompanyDataChanged}
              />

              <Input
                name="regon_number"
                label="REGON"
                value={companyDataForm.regon_number}
                onChange={handleCompanyDataChanged}
              />

              <Input
                name="website"
                label="Strona internetowa"
                value={companyDataForm.website}
                onChange={handleCompanyDataChanged}
              />

              <Textarea
                name="additional_info"
                label="Pozostałe informacje"
                rows={6}
                value={companyDataForm.additional_info}
                onChange={handleCompanyDataChanged}
              />

              <div className="text-right">
                <Button type="primary" btnType="submit" size="medium">
                  Zapisz zmiany
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header className="h5">Adres firmy</Card.Header>

          <Card.Body>
            <form onSubmit={handleCompanyAddressSaved}>
              <Input
                name="street"
                label="Ulica"
                value={companyAddressForm.street}
                onChange={handleCompanyAddressChanged}
              />

              <div className={cs.twoInRow}>
                <Input
                  name="building_no"
                  label="Nr. domu"
                  value={companyAddressForm.building_no}
                  onChange={handleCompanyAddressChanged}
                />

                <Input
                  name="apartment_no"
                  label="Nr. lokalu"
                  value={companyAddressForm.apartment_no}
                  onChange={handleCompanyAddressChanged}
                />
              </div>

              <div className={cs.twoInRow}>
                <Input
                  name="postal_code"
                  label="Kod pocztowy"
                  value={companyAddressForm.postal_code}
                  onChange={handleCompanyAddressChanged}
                />

                <Input
                  name="city"
                  label="Miejscowość"
                  value={companyAddressForm.city}
                  onChange={handleCompanyAddressChanged}
                />
              </div>

              <Select
                name="region"
                label="Województwo"
                value={companyAddressForm.region}
                options={PROVINCES}
                onChange={(value) =>
                  handleCompanyAddressChanged(value, "region")
                }
              />

              <div className="text-right">
                <Button type="primary" btnType="submit" size="medium">
                  Zapisz zmiany
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </section>
    </main>
  );

  function handlePersonalInfoChanged(value, name) {
    setPersonalInfoForm((prev) => {
      prev[name] = value;
    });
  }

  function handleCompanyDataChanged(value, name) {
    setCompanyDataForm((prev) => {
      prev[name] = value;
    });
  }

  function handleCompanyAddressChanged(value, name) {
    setCompanyAddressForm((prev) => {
      prev[name] = value;
    });
  }

  async function handlePersonalInfoSaved(e) {
    e.preventDefault();

    const { isSuccess } = await updateUserDataApi(personalInfoForm);

    if (isSuccess) {
      notification.success({
        message: "Dane zapisane.",
        placement: "topRight",
      });

      await useStore.getState().fetchCurrentUser();
    }
  }

  async function handleCompanySaved(e) {
    e.preventDefault();

    const { isSuccess } = await saveCompanyDetailsApi(companyDataForm);

    if (isSuccess) {
      notification.success({
        message: "Dane zapisane.",
        placement: "topRight",
      });

      updateOrganizationName(companyDataForm.name);
    }
  }

  async function handleCompanyAddressSaved(e) {
    e.preventDefault();

    const { isSuccess } = await saveCompanyDetailsApi(companyAddressForm);

    if (isSuccess) {
      notification.success({
        message: "Dane zapisane.",
        placement: "topRight",
      });

      updateOrganizationName(companyDataForm.name);
    }
  }
};
