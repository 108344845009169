import { createContext, useEffect, useRef } from "react";
import nestedProperty from "nested-property";
import { flattenDeepUnique } from "@helpers/utils";
import { Card } from "@components/Card";
import { Button } from "@components/Button";
import { SelectMachinesModal } from "../../SelectMachinesModal";
import { genGroupId } from "../../PricesPage";
import { MachinesLevel } from "./MachinesLevel";
import cs from "./WithMachinesAndMaterials.module.scss";

export const ServiceContext = createContext(null);

export const WithMachinesAndMaterials = ({ priceList, onChange, onRemove }) => {
  const selectMachinesModal = useRef();

  useEffect(() => {
    // NOTE: Newly added.
    // FIXME: Very ugly hack...
    if (!priceList.pricings[0].group_id) {
      onChange({
        ...priceList,
        pricings: [
          {
            group_id: genGroupId(),
            prices: {},
            machines: [],
            perMaterials: [],
            is_default: true,
          },
        ],
      });
    }
  }, []);

  return (
    <ServiceContext.Provider value={priceList.service._id}>
      <div className="PriceList">
        <Card className={cs.WithMachinesAndMaterials}>
          <Card.Header className={cs.header}>
            <div className="h4">{priceList.service.name}</div>

            <Button type="secondary" size="small" onClick={handleRemoveService}>
              Usuń usługę
            </Button>
          </Card.Header>

          <Card.Body>
            {priceList.pricings.map((pricing) => (
              <MachinesLevel
                key={pricing.group_id}
                pricing={pricing}
                onAssignMachines={() => {
                  handleAssignMachines(pricing.group_id);
                }}
                onDefaultChange={handleDefaultChanged}
                onChange={(updatedPricing) => {
                  handlePricingChanged(pricing.group_id, updatedPricing);
                }}
                onRemove={onRemove}
              />
            ))}
          </Card.Body>

          <Card.Footer className="text-right">
            <Button
              type="primary"
              size="medium"
              onClick={handleAddNewMachineGroup}
            >
              Dodaj cennik dla kolejnych maszyn
            </Button>
          </Card.Footer>
        </Card>

        <SelectMachinesModal
          ref={selectMachinesModal}
          serviceId={priceList.service._id}
        />
      </div>
    </ServiceContext.Provider>
  );

  function handleAddNewMachineGroup() {
    onChange({
      ...priceList,
      pricings: [
        ...priceList.pricings,
        {
          group_id: genGroupId(),
          prices: {},
          machines: [],
          perMaterials: [],
        },
      ],
    });
  }

  function handleDefaultChanged(pricingId) {
    const updatedPricings = priceList.pricings.map((machineGroupLevel: any) => {
      if (pricingId === machineGroupLevel.group_id) {
        return {
          ...machineGroupLevel,
          is_default: true,
        };
      }

      return { ...machineGroupLevel, is_default: false };
    });

    onChange({ ...priceList, pricings: updatedPricings });
  }

  function handlePricingChanged(pricingId, updatedPricing) {
    const updatedPricings = priceList.pricings.map((machineGroupLevel: any) => {
      if (pricingId === machineGroupLevel.group_id) {
        return updatedPricing;
      }

      return machineGroupLevel;
    });

    onChange({ ...priceList, pricings: updatedPricings });
  }

  async function handleAssignMachines(pricingId: number) {
    const alreadySelectedMachines = flattenDeepUnique(
      nestedProperty.get(priceList.pricings, "+.machines")
    );

    const machineIds = await selectMachinesModal.current?.openModal(
      alreadySelectedMachines
    );

    if (machineIds?.length > 0) {
      const updatedPricings = priceList.pricings.map(
        (machineGroupLevel: any) => {
          if (pricingId === machineGroupLevel.group_id) {
            return {
              ...machineGroupLevel,
              machines: [...(machineGroupLevel.machines || []), ...machineIds],
            };
          }

          return machineGroupLevel;
        }
      );

      onChange({ ...priceList, pricings: updatedPricings });
    }
  }

  function handleRemoveService() {
    let idsOfAllPricings = flattenDeepUnique(
      nestedProperty.get(priceList.pricings, "+.perMaterials.+._id")
    );

    if (idsOfAllPricings.length === 0) {
      // NOTE: No materials added yet
      idsOfAllPricings = nestedProperty.get(priceList.pricings, "+._id");
    }

    onRemove(idsOfAllPricings);
  }
};
