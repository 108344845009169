import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { confirmEmailApi } from "@services/auth.api";
import { Card } from "@components/Card";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Button } from "@components/Button";
import { Baner } from "../../components/Baner";
import cs from "./ConfirmationPage.module.scss";

export const ConfirmationPage = () => {
  const { userId } = useParams();

  useEffect(() => {
    confirmEmailApi(userId as string);
  }, []);

  return (
    <main className={`ConfirmationPage ${cs.ConfirmationPage}`}>
      <Baner type="invited" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <Card>
          <Card.Header className="h4 text-center">
            Witamy w aplikacji 4dustry
          </Card.Header>

          <Card.Body>
            <div className="body text-center">
              Twoje konto jest już potwierdzone. Dziękujemy!
            </div>
          </Card.Body>

          <Card.Footer>
            <Button
              type="primary"
              size="large"
              href="/login"
              className={cs.logInBtn}
            >
              Zaloguj się
            </Button>
          </Card.Footer>
        </Card>

        <PrivacyPolicy />
      </section>
    </main>
  );
};
