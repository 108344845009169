export const CAD_FILE_EXTENSIONS = [
  "3dm",
  // "asm",
  "catpart",
  // "catproduct",
  "dwg",
  "dxf",
  "g",
  "iam",
  "ige",
  "iges",
  "igs",
  "ipt",
  "neu",
  "obj",
  "par",
  "prt",
  "psm",
  "sat",
  "sldprt",
  "smt",
  "ste",
  "step",
  "stl",
  "stla",
  "stlb",
  "stp",
  "stpz",
  "wire",
  "x_b",
  "x_t",
];
