import { useState } from "react";
import classNames from "classnames";
import { InputNumber } from "@components/InputNumber";
import deleteImg from "../../img/delete.svg";
import cs from "./Bending.module.scss";

export const Angle = ({ angleGroup, readOnly, onChange, onRemove }) => {
  const [isHovered, setIsHovered] = useState(false);

  const rootCs = classNames(cs.Angle, {
    [cs.hovered]: isHovered,
  });

  return (
    <div className={rootCs}>
      <div className={cs.withBtn}>
        <div>
          <div className={cs.Angle_input}>
            <span>Kąt gięcia</span>
            <InputNumber
              size="small"
              value={angleGroup.angle}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => handleChange("angle", value)}
            />
            <span>deg</span>
          </div>

          <div className={cs.Angle_input}>
            <span>Ilość gięć</span>
            <InputNumber
              size="small"
              value={angleGroup.count}
              min={1}
              step={1}
              readOnly={readOnly}
              required
              onChange={(value) => handleChange("count", value)}
            />
          </div>
        </div>

        {!readOnly && (
          <img
            src={deleteImg}
            alt=""
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => onRemove(angleGroup.tempId)}
          />
        )}
      </div>
    </div>
  );

  function handleChange(property, value) {
    onChange({ ...angleGroup, [property]: value });
  }
};
