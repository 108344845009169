import { notification } from "antd";
import { confirmOfferApi } from "../../services/offer.api";
import { OFFER_STATUSES } from "../../../constants/OFFER_STATUSES";
import { DownloadOrderDocsBtn } from "@components/DownloadOrderDocs";
import { Button } from "@components/Button";
import thumbUpImg from "./img/thumb_up.svg";
import thumbDownImg from "./img/thumb_down.svg";
import cs from "./Actions.module.scss";

export const Actions = ({ offer, order, size, onCancel }) => {
  const isEditable =
    offer.status === OFFER_STATUSES.ONGOING ||
    offer.status === OFFER_STATUSES.ORDER_EDITED;

  if (offer.status === OFFER_STATUSES.ORDER_EDITED) {
    return (
      <div className={cs.actions}>
        <DownloadOrderDocsBtn order={order} size={size} />

        <Button type="secondary" size={size} href={`/offer/${offer._id}/edit`}>
          Edytuj ofertę
        </Button>

        <Button type="secondary" size={size} onClick={onCancel}>
          <img src={thumbDownImg} alt="" />
          <span>Wycofaj ofertę</span>
        </Button>

        <Button type="primary" size={size} onClick={handleConfirm}>
          <img src={thumbUpImg} alt="" />
          <span>Podtrzymaj ofertę</span>
        </Button>
      </div>
    );
  }

  return (
    <div className={cs.actions}>
      <DownloadOrderDocsBtn order={order} size={size} />

      {isEditable && (
        <Button type="primary" size={size} href={`/offer/${offer._id}/edit`}>
          Edytuj ofertę
        </Button>
      )}
    </div>
  );

  async function handleConfirm() {
    const response = await confirmOfferApi(offer._id);

    if (response.isSuccess) {
      notification.success({
        message: "Oferta została potwierdzona.",
        placement: "topRight",
      });

      setTimeout(() => {
        window.location.reload();
      }, 300);
    } else {
      notification.error({
        message: "Wystąpił błąd.",
        description: response.error,
        placement: "topRight",
      });
    }
  }
};
