import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { AppBar } from "@components/AppBar";
import { Menu } from "@components/Menu";
import { DashboardPage } from "../views/DashboardPage";
import { AddReviewPage } from "../views/AddReviewPage";
import { EditReviewPage } from "../views/EditReviewPage";
import { LoginPage } from "../Auth/views/LoginPage";
import { RegisterPage } from "../Auth/views/RegisterPage";
import { InvitedPage } from "../Auth/views/InvitedPage";
import { ConfirmationPage } from "../Auth/views/ConfirmationPage";
import { NoCompanyPage } from "../Auth/views/NoCompanyPage";
import { ResetPasswordPage } from "../Auth/views/ResetPasswordPage";
import { NewPasswordPage } from "../Auth/views/NewPasswordPage";
import { PhonePage } from "../Auth/views/PhonePage";
import { CodeVerificationPage } from "../Auth/views/CodeVerificationPage";
import { PendingVerificationPage } from "../Auth/views/PendingVerificationPage";
import { GeneralPage } from "../Organization/views/GeneralPage";
import { UsersPage } from "../Organization/views/UsersPage";
import { MachinePark } from "../Organization/views/MachinePark";
import { PricesPage } from "../Organization/views/PricesPage";
import { BillingsPage } from "../Organization/views/BillingsPage";
import { SubscriptionPage } from "../Organization/views/SubscriptionPage";
import { Navigation as OrganizationNavigation } from "../Organization/components/Navigation";
import { MyOrdersPage } from "../Customer/views/MyOrdersPage";
import { NewOrderPage } from "../Customer/views/NewOrderPage";
import { OrderPage as CustomerOrderPage } from "../Customer/views/OrderPage";
import { DocsPermissionChoicePage } from "../Customer/views/DocsPermissionChoicePage";
import { SearchOrdersPage } from "../Provider/views/SearchOrdersPage";
import { MyOffersPage } from "../Provider/views/MyOffersPage";
import { OfferPage as ProviderOfferPage } from "../Provider/views/OfferPage";
import { OrderPage as ProviderOrderPage } from "../Provider/views/OrderPage";
import { NewOfferPage } from "../Provider/views/NewOfferPage";
import { RequireAuth } from "./RequireAuth";
import { PublicOrder } from "../public/views/PublicOrder";
import { OrderCreatedInfo } from "../public/views/OrderCreatedInfo";
import { OrderPage as AdminOrderPage } from "../Admin/views/OrderPage";
import { SettingsAdmin } from "../Admin/views/Settings";

export const ApplicationRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/public/order" element={<PublicOrder />} />
        <Route path="/public/order-created" element={<OrderCreatedInfo />} />
        <Route path="/verification-phone" element={<PhonePage />} />
        <Route path="/verification-code" element={<CodeVerificationPage />} />
        <Route path="/invitation/:userId" element={<InvitedPage />} />
        <Route path="/confirmation/:userId" element={<ConfirmationPage />} />
        <Route path="/no-company" element={<NoCompanyPage />} />
        <Route path="/pending-verification" element={<PendingVerificationPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/new-password/:token" element={<NewPasswordPage />} />
        <Route path="*" element={<Navigate replace to="/" />} />

        <Route
          path="/account"
          element={
            <RequireAuth>
              <OrganizationLayout />
            </RequireAuth>
          }
        >
          <Route index element={<GeneralPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="subscription" element={<SubscriptionPage />} />
          <Route path="machines" element={<MachinePark />} />
          <Route path="prices" element={<PricesPage />} />
          <Route path="billings" element={<BillingsPage />} />
        </Route>

        <Route
          path="/order"
          element={
            <RequireAuth>
              <div className="App App--without-menu">
                <AppBar />

                <NewOrderPage />
              </div>
            </RequireAuth>
          }
        />

        <Route
          path="/order/:id/edit"
          element={
            <RequireAuth>
              <div className="App App--without-menu">
                <AppBar />

                <NewOrderPage />
              </div>
            </RequireAuth>
          }
        />

        <Route
          path="/draft/:id"
          element={
            <RequireAuth>
              <div className="App App--without-menu">
                <AppBar />

                <NewOrderPage />
              </div>
            </RequireAuth>
          }
        />

        <Route
          path="/order/:id/copy"
          element={
            <RequireAuth>
              <div className="App App--without-menu">
                <AppBar />

                <NewOrderPage />
              </div>
            </RequireAuth>
          }
        />

        <Route
          path="/admin"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route path="order">
            <Route path=":id" element={<AdminOrderPage />} />
          </Route>
          <Route path="settings">
            <Route path=":companyName" element={<SettingsAdmin />} />
          </Route>
        </Route>

        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route index element={<DashboardPage />} />
          <Route path="review/:offerId" element={<AddReviewPage />} />
          <Route path="review/edit/:reviewId" element={<EditReviewPage />} />
          <Route path="orders" element={<MyOrdersPage />} />
          <Route path="search" element={<SearchOrdersPage />} />
          <Route path="offers" element={<MyOffersPage />} />

          {/* Customer */}
          <Route path="order">
            {/* /order/123 */}
            <Route path=":id" element={<CustomerOrderPage />} />
          </Route>

          {/* Provider */}
          <Route path="offer">
            {/* /offer/456 */}
            <Route path=":id" element={<ProviderOfferPage />} />
            {/* /offer/456/edit */}
            <Route path=":offerId/edit" element={<NewOfferPage />} />
            {/* /offer/order/123 */}
            <Route path="order/:id" element={<ProviderOrderPage />} />
            {/* /offer/order/123/new */}
            <Route path="order/:orderId/new" element={<NewOfferPage />} />
          </Route>

          <Route
            path="docs-permission"
            element={<DocsPermissionChoicePage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const Layout = () => (
  <div className="App">
    <AppBar />

    <aside>
      <Menu />
    </aside>

    <Outlet />

    <div className="modal-container" />
  </div>
);

const OrganizationLayout = () => (
  <div className="App">
    <AppBar />

    <aside>
      <Menu />
    </aside>

    <div>
      <div className="h3 ml32 mt32 mb16">Ustawienia</div>

      <OrganizationNavigation />

      <Outlet />
    </div>

    <div className="modal-container" />
  </div>
);
