import React, { useState } from "react";
import { Button } from "@components/Button";
import maxListImg from "./img/max_list.svg";
import miniListImg from "./img/mini_list.svg";

type ListType = "min" | "full";

export const useListTypeCmp = (
  initial: ListType
): [ListType, React.FC<{ className?: string }>] => {
  const [listType, setListType] = useState<ListType>(initial);

  const Cmp = ({ className = "" }) =>
    listType === "min" ? (
      <Button
        type="link"
        size="small"
        className={className}
        onClick={() => setListType("full")}
      >
        <img src={maxListImg} alt="" className="mr9" />
        <span>Powiększ listę</span>
      </Button>
    ) : (
      <Button
        type="link"
        size="small"
        className={className}
        onClick={() => setListType("min")}
      >
        <img src={miniListImg} alt="" className="mr9" />
        <span>Minimalizuj listę</span>
      </Button>
    );

  return [listType, Cmp];
};
