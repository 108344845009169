import { getDaysFromToday, toLocaleDate } from "@helpers/dateUtils";
import { getOfferMainStatusText } from "@helpers/getOfferMainStatusText";
import { getOfferAddStatusTag } from "@helpers/getOfferAddStatusTag";
import { getOfferStatusColor } from "@helpers/getOfferStatusColor";
import { ORDER_STATUSES } from "../../../constants/ORDER_STATUSES";
import cs from "./StatusInfoPanel.module.scss";

export const StatusInfoPanel = ({ order, offer, offerSummary }) => {
  return (
    <section
      className={`StatusInfoPanel ${cs.StatusInfoPanel}`}
      style={{ backgroundColor: getOfferStatusColor(offer.status) }}
    >
      <div className={cs.col}>
        <div className="caption mb4">Status</div>
        <div className="h5">{getOfferMainStatusText(offer.status)}</div>
      </div>

      {/*<div className={cs.col}>*/}
      {/*  <div className="caption mb4">Ilość ofert:</div>*/}
      {/*  <div className="h5">{order.offers_count}</div>*/}
      {/*</div>*/}

      <div className={cs.col}>
        <div className="caption mb4">Twoja oferta:</div>
        <div className="h5">{offerSummary} PLN</div>

        {getOfferAddStatusTag(offer.status, "small", "mt4")}
      </div>

      <div className={cs.col}>
        <div className="caption mb4">Termin składania ofert:</div>
        <div className="h5">{toLocaleDate(order.bid_deadline)}</div>
      </div>

      <div className={cs.col}>
        <div className="caption mb4">Wymagany termin realizacji:</div>
        <div className="h5">{toLocaleDate(order.done_deadline)}</div>
      </div>

      <div className={cs.col}>
        {order.status === ORDER_STATUSES.ONGOING && (
          <>
            <div className="caption mb4">Do końca:</div>
            <div className="h5">
              {getDaysFromToday(order.done_deadline)} dni
            </div>
          </>
        )}
      </div>
    </section>
  );
};
