import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notification, Spin } from "antd";
import { docsPermissionChoiceApi } from "../../services/order.api";
import cs from "./DocsPermissionChoicePage.module.scss";

export const DocsPermissionChoicePage = () => {
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const orderId = searchParam.get("o");
    const companyId = searchParam.get("a");
    const choice = searchParam.get("c");

    if (!orderId || !companyId || !choice) return;

    docsPermissionChoiceApi(orderId, companyId, choice).then((result) => {
      if (result.isSuccess) {
        if (choice === "grant") {
          notification.success({
            message: "Dostęp został udzielony.",
            placement: "topRight",
            duration: 5,
          });
        } else {
          notification.success({
            message: "Dostęp nie został udzielony.",
            placement: "topRight",
            duration: 5,
          });
        }
      } else {
        notification.error({
          message: "Wystąpił błąd. Spróbuj ponownie później.",
          placement: "topRight",
          duration: 5,
        });
      }

      navigate("/");
    });
  }, [searchParam]);

  return (
    <main className={cs.DocsPermissionChoicePage}>
      <header className={cs.header}>
        <h1 className="h3 mb8">Dostęp do dokumentacji</h1>
      </header>

      <div className={cs.loader}>
        <Spin spinning size="large" />
      </div>
    </main>
  );
};
