import { useState } from "react";
import { notification, Spin } from "antd";
import { sendInvitationApi } from "@services/account.api";
import { validateEmail } from "@helpers/utils";
import { Modal } from "@components/Modal";
import { Input } from "@components/Input";
import { Button } from "@components/Button";

export const InviteModal = ({ isOpen, onClose }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isEmailValid = validateEmail(email);

  return (
    <Modal
      title="Zaproś użytkownika"
      visible={isOpen}
      width={420}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Spin spinning={isLoading}>
            <Button
              type="primary"
              size="medium"
              disabled={!firstName || !lastName || !isEmailValid}
              onClick={handleConfirm}
            >
              Zaproś
            </Button>
          </Spin>
        </div>
      }
      onClose={() => handleClose()}
    >
      <div>
        <Input
          label="Imię"
          value={firstName}
          className="mb12"
          onChange={setFirstName}
        />

        <Input
          label="Nazwisko"
          value={lastName}
          className="mb12"
          onChange={setLastName}
        />

        <Input label="Email" type="email" value={email} onChange={setEmail} />
      </div>
    </Modal>
  );

  async function handleConfirm() {
    setIsLoading(true);

    const { isSuccess, error } = await sendInvitationApi({
      firstName,
      lastName,
      email,
    });

    setIsLoading(false);

    if (isSuccess) {
      notification.success({
        message: "Zaproszenie zostało wysłane",
        placement: "topRight",
      });

      handleClose(true);
    } else if (error === "USER_ALREADY_EXISTS") {
      notification.error({
        message: `Istnieje już użytkownik o tym adresie email.`,
        placement: "topRight",
      });
    } else if (error === "USER_ALREADY_CONNECTED_TO_DIFFERENT_COMPANY") {
      notification.error({
        message: "Konto o tym adresie email jest już powiązane z inną firmą.",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Coś poszło nie tak.",
        description:
          "Zostaliśmy automatycznie poinformowani o wystąpieniu tego błędu",
        placement: "topRight",
      });
    }
  }

  function handleClose(wasInvited = false) {
    onClose(wasInvited);
    clear();
  }

  function clear() {
    setFirstName("");
    setLastName("");
    setEmail("");
  }
};
