import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { notification, Spin } from "antd";
import {
  registerApi,
  sendAuthCodeApi,
  verifyAuthCodeApi,
} from "@services/auth.api";
import { GTMAnalyser } from "@services/analytics.helpers";
import { Card } from "@components/Card";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Baner } from "../../components/Baner";
import cs from "./CodeVerificationPage.module.scss";

export const CodeVerificationPage = () => {
  const [code, setCode] = useState("");
  const [timeLeft, setTimeLeft] = useState(180);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  useFacebookPixel();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <main className={`CodeVerificationPage ${cs.CodeVerificationPage}`}>
      <Baner type="register" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <form onSubmit={handleSubmit}>
          <Card>
            <Card.Header>
              <div className="h5">Weryfikacja użytkownika</div>
            </Card.Header>

            <Card.Body>
              <div className="caption h5 mb20">
                Wprowadź kod zabezpieczeń wysłany pod numer telefonu kończący
                się na {location.state.phone.slice(-4)}.
              </div>

              <div>
                <Input
                  value={code}
                  label="6-cyfrowy kod dostępu"
                  size="large"
                  required
                  placeholder="______"
                  maxLength={6}
                  autoComplete="new-password"
                  style={{ letterSpacing: 15 }}
                  onChange={(value: string, name, e) => {
                    setCode(value);

                    if (value?.length === 6) {
                      e.target.blur();
                    }
                  }}
                />
              </div>

              <div
                className="small color-black mt2 text-right"
                style={{ fontSize: 12 }}
              >
                Wygasa za <strong>{formatSecondsToMinutes(timeLeft)}</strong>
              </div>

              <div className="mt20 color-black">
                Kod dostępu nie dotarł?{" "}
                <strong
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handleSendCodeAgain}
                >
                  Wyślij ponownie
                </strong>
              </div>
            </Card.Body>

            <Card.Footer>
              <Spin spinning={isLoading}>
                <Button
                  type="primary"
                  size="large"
                  btnType="submit"
                  disabled={code?.length !== 6}
                  style={{ width: "100%" }}
                >
                  Zarejestruj się
                </Button>
              </Spin>
            </Card.Footer>
          </Card>
        </form>

        <PrivacyPolicy />
      </section>
    </main>
  );

  async function handleSubmit(e) {
    e.preventDefault();

    const account = location.state;

    setIsLoading(true);

    const { isSuccess, error } = await verifyAuthCodeApi(
      account.verificationPhoneNumber,
      code
    );

    if (isSuccess) {
      await register(account);
    } else if (error === "INVALID_CODE") {
      notification.error({
        message: `Podany kod nie jest prawidłowy.`,
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Wystąpił błąd. Spróbuj ponownie później.",
        placement: "topRight",
      });
    }

    setIsLoading(false);
  }

  async function handleSendCodeAgain() {
    const { isSuccess } = await sendAuthCodeApi(location.state.phone);

    if (isSuccess) {
      notification.success({
        message: "Kod weryfikacyjny został wysłany.",
        placement: "topRight",
      });

      setCode("");
      setTimeLeft(180);
    } else {
      notification.error({
        message: "Wystąpił błąd. Spróbuj ponownie później.",
        placement: "topRight",
      });
    }
  }

  async function register(account: any) {
    const { isSuccess, error } = await registerApi(account);

    if (isSuccess) {
      GTMAnalyser.trigger("user_registered");

      ReactPixel.grantConsent();
      ReactPixel.track("CompleteRegistration");
      ReactPixel.revokeConsent();

      notification.success({
        message: "Konto utworzone.",
        placement: "topRight",
      });

      setTimeout(() => {
        window.location.replace("/account");
      }, 800);
    } else if (error === "USER_ALREADY_EXISTS") {
      notification.error({
        message: `Już istnieje użytkownik o tym adresie email.`,
        placement: "topRight",
      });
    } else if (error === "ACCOUNT_ALREADY_EXISTS") {
      notification.error({
        message: `Już istnieje firma o tej nazwie.`,
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Wystąpił błąd. Spróbuj ponownie później.",
        placement: "topRight",
      });
    }
  }
};

function useFacebookPixel() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      ReactPixel.init("411315800927763");
      ReactPixel.revokeConsent();

      return () => {
        ReactPixel.revokeConsent();
      };
    }
  }, []);
}

function formatSecondsToMinutes(s: number) {
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}
