import { Button } from "@components/Button";
import { ServicesSelect } from "@components/ServicesSelect";
import { AdditionalProcessings } from "./AdditionalProcessings";
import { Machine } from "./Machine/Machine";
import cs from "./MachinePark.module.scss";

export const MachineParkPure = ({
  selectedServices = [],
  machines = [],
  machinelessServices,
  onAddMachine,
  onRemoveMachine,
  onParameterChange,
  onTechnologiesChange,
  onMachinelessSave,
  onServicesChange,
}) => {
  const isEmpty = machines.length === 0;

  return (
    <main className={cs.MachinePark}>
      <div className={cs.main}>
        <div className={cs.machines}>
          <section className={cs.technologies}>
            <div className={cs.header}>
              <div className="h4">Oferowane usługi</div>
            </div>

            <div>
              <ServicesSelect
                value={selectedServices}
                size="small"
                placeholder="Wybierz usługi, które oferujesz"
                onChange={onServicesChange}
              />
            </div>
          </section>

          {isEmpty ? (
            <Empty onAddMachine={onAddMachine} />
          ) : (
            <section>
              <div className={cs.header}>
                <div className="h4">Moje maszyny ({machines.length})</div>
                <div>
                  <Button type="primary" size="small" onClick={onAddMachine}>
                    Dodaj maszynę
                  </Button>
                </div>
              </div>

              <div>
                {machines.map((machine) => (
                  <Machine
                    key={machine._id}
                    machine={machine}
                    onRemoveMachine={onRemoveMachine}
                    onTechnologiesChange={onTechnologiesChange}
                    onParameterChange={onParameterChange}
                  />
                ))}
              </div>
            </section>
          )}
        </div>

        <AdditionalProcessings
          machinelessServices={machinelessServices}
          onSave={onMachinelessSave}
        />
      </div>
    </main>
  );
};

const Empty = ({ onAddMachine }) => (
  <section className={cs.Empty}>
    <div className="h5 mb16">Nie masz żadnych maszyn w Twoim parku</div>

    <Button type="primary" size="medium" onClick={onAddMachine}>
      Dodaj maszynę
    </Button>
  </section>
);
