import { Navigate, useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import { notification } from "antd";
import { isAuthenticated, logInApi } from "@services/auth.api";
import { LoginPagePure } from "./LoginPagePure";

type Form = Partial<{
  email: string;
  password: string;
}>;

export const LoginPage = () => {
  const [form, setForm] = useImmer<Form>({});

  const location = useLocation();
  const isUserAuthenticated = isAuthenticated();

  if (isUserAuthenticated) {
    if (location.state?.from) {
      return <Navigate to={location.state.from} replace />;
    }

    return <Navigate to="/" replace />;
  }

  return (
    <LoginPagePure onChange={handleFormChanged} onSubmit={handleFormSubmited} />
  );

  function handleFormChanged(value: string, name: "email" | "password") {
    setForm((prev) => void (prev[name] = value));
  }

  async function handleFormSubmited(e) {
    e.preventDefault();

    const isAuthenticated = await logInApi({
      email: form.email as string,
      password: form.password as string,
    });

    if (isAuthenticated) {
      window.location.reload();
    } else {
      notification.error({
        message: "Błędny login lub hasło",
        placement: "topRight",
      });
    }
  }
};
