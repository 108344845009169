import { useStore } from "@store";

export function groupByService(data) {
  const availableMaterialTypes = useStore.getState().types;
  const availableServices = useStore.getState().technologies2;

  const grouped: any[] = [];
  data.forEach((dbRow: any) => {
    let existingService = grouped.find(({ service }) => {
      return service._id === dbRow.service_id;
    });

    if (!existingService) {
      const service = availableServices.find(
        ({ _id }) => _id === dbRow.service_id
      );

      existingService = {
        service,
        pricings: [],
      };

      grouped.push(existingService);
    }

    // NOTE: Services with only one pricing - no machines & no materials
    if (!dbRow.group_id) {
      existingService.pricings.push(dbRow);

      return;
    }

    let existingPerMachines = existingService.pricings.find(
      ({ group_id }) => group_id === dbRow.group_id
    );

    if (!existingPerMachines) {
      existingPerMachines = {
        // NOTE: This id comes in handy when there is no material
        //       added yet and we need to identify this pricing
        //       (removing pricing before any materials added)
        _id: dbRow._id,
        group_id: dbRow.group_id,
        machines: dbRow.machine_ids,
        is_default: dbRow.is_default,
        perMaterials: [],
      };

      existingService.pricings.push(existingPerMachines);
    }

    if (dbRow.material_categories) {
      existingPerMachines.perMaterials.push({
        _id: dbRow._id,
        prices: dbRow.prices, // XXX: Prices defined at lower level
        materialTypes: dbRow.material_categories.map((typeId) => {
          return availableMaterialTypes.find(({ _id }) => _id === typeId);
        }),
      });
    }
  });

  return grouped;
}
