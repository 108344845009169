import { useEffect } from "react";
import produce from "immer";
import { genTempId } from "@helpers/utils";
import { InputNumber } from "@components/InputNumber";
import { AddNextBtn } from "../../AddNextBtn";
import { Diameter } from "./Diameter";
import commonCs from "../AdditionalProcessing.module.scss";

export const Drilling = ({ holes = [], readOnly, onChange }) => {
  holes = addTempId(holes);

  useEffect(() => {
    if (holes.length === 0) {
      setTimeout(() => {
        handleDiameterAdded();
      });
    }
  }, [holes]);

  return (
    <div>
      <div className="h5 mb16">Wiercenie</div>

      <div className={commonCs.body}>
        <div className={commonCs.amount}>
          <div className="color-dark-gray">Ilość średnic otworów</div>
          <div>
            <InputNumber
              value={holes.length}
              readOnly
              style={{ width: "63px" }}
              size="small"
            />
          </div>
        </div>

        <div className={commonCs.main}>
          {holes.map((diameterGroup, index) => (
            <Diameter
              group={diameterGroup}
              index={index}
              readOnly={readOnly}
              onDiameterChange={handleDiameterChanged}
              onDiameterRemoved={handleDiameterRemoved}
              onDepthAdded={handleDepthAdded}
              onDepthChange={handleDepthChanged}
              onDepthRemoved={handleDepthRemoved}
            />
          ))}

          {!readOnly && (
            <AddNextBtn className="mt4" onClick={handleDiameterAdded} />
          )}
        </div>
      </div>
    </div>
  );

  function handleDepthAdded(diameterTempId) {
    const updatedHoles = produce(holes, (draft) => {
      const diameterGroup = draft.find(
        (group) => group.tempId === diameterTempId
      );

      diameterGroup.depths.push({
        count: null,
        through_hole: null,
        hole_depth: null,
      });
    });

    onChange("holes.drilledHoles", updatedHoles);
  }

  function handleDiameterAdded() {
    onChange("holes.drilledHoles", [
      ...holes,
      {
        diameter: null,
        depths: [{ count: null, through_hole: null, hole_depth: null }],
      },
    ]);
  }

  function handleDepthChanged(diameterTempId, depthTempId, property, value) {
    const updatedHoles = produce(holes, (draft) => {
      const diameterGroup = draft.find(
        ({ tempId }) => tempId === diameterTempId
      );
      const updatedDepth = diameterGroup.depths.find(
        (depth) => depth.tempId === depthTempId
      );
      updatedDepth[property] = value;
    });

    onChange("holes.drilledHoles", updatedHoles);
  }

  function handleDiameterChanged(diameterTempId, newValue) {
    const updatedHoles = produce(holes, (draft) => {
      const diameterGroup = draft.find(
        ({ tempId }) => tempId === diameterTempId
      );
      diameterGroup.diameter = newValue;
    });

    onChange("holes.drilledHoles", updatedHoles);
  }

  function handleDiameterRemoved(diameterTempId) {
    onChange(
      "holes.drilledHoles",
      holes.filter((hole) => hole.tempId !== diameterTempId)
    );
  }

  function handleDepthRemoved(diameterTempId, depthTempId) {
    const updatedHoles = produce(holes, (draft) => {
      const diameterGroup = draft.find(
        ({ tempId }) => tempId === diameterTempId
      );
      diameterGroup.depths = diameterGroup.depths.filter(
        (depth) => depth.tempId !== depthTempId
      );
    });

    onChange("holes.drilledHoles", updatedHoles);
  }
};

function addTempId(holes) {
  return produce(holes, (draft) => {
    draft.forEach((diameterGroup) => {
      diameterGroup.tempId = genTempId();

      diameterGroup.depths.forEach((depth) => {
        depth.tempId = genTempId();
      });
    });
  });
}
