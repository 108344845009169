import { useEffect, useState } from "react";
import { fetchOfferedServices } from "./settings.api";

export function useOfferedServices(companyName: string) {
  const [offeredServices, setOfferedServices] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!companyName) return;

    (async () => {
      try {
        const { isSuccess, data, error } = await fetchOfferedServices(
          companyName
        );

        if (!isSuccess) {
          return setError(error);
        }

        setOfferedServices(data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [companyName]);

  return [offeredServices, error, isLoading];
}
