import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import moment, { Moment } from "moment";
import { DatePicker as DatePickerBase } from "antd";
import cs from "./DatePicker.module.scss";

export type DatePickerValue = Moment | null | undefined;

type Props = {
  value?: DatePickerValue;
  label?: string;
  placeholder?: string;
  className?: string | string[];
  style?: React.CSSProperties;
  required?: boolean;
  readOnly?: boolean;
  size?: "default" | "small" | "large";
  minDate?: ((date: Moment) => boolean) | undefined;
  onChange: (value: Moment | null) => void;
};

const TODAY = new Date();
const TOMORROW = new Date().setDate(new Date().getDate() + 1);

export const DatePicker = ({
  label,
  value,
  placeholder,
  minDate,
  className,
  style = {},
  size = "default",
  required = false,
  readOnly,
  onChange,
}: Props) => {
  const [touched, setTouched] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useListenToSyntheticEvents(ref, setTouched);

  const isInvalid = touched && !readOnly && !checkValidity();

  value = value ? moment(value) : null;

  const csRoot = classNames("DatePicker", cs.root, className, {
    [cs.default]: size === "default",
    [cs.small]: size === "small",
    [cs.large]: size === "large",
    "control-invalid": isInvalid,
  });

  return (
    <div ref={ref} className={csRoot} style={style}>
      {label && <label className={cs.label}>{label}</label>}

      <DatePickerBase
        value={value}
        disabledDate={minDate}
        placeholder={placeholder}
        className={cs.input}
        onChange={(val) => {
          onChange(val);
          setTouched(true);
        }}
        onBlur={() => setTouched(true)}
      />

      {isInvalid && (
        <div className="mt8 caption color-red">To pole jest wymagane</div>
      )}
    </div>
  );

  function checkValidity() {
    if (required && (value === null || value === undefined)) {
      return false;
    }

    return true;
  }
};

DatePicker.onlyFromToday = (date: Moment) => {
  return date.isBefore(TODAY);
};

DatePicker.onlyFromTomorrow = (date: Moment) => {
  return date.isBefore(TOMORROW);
};

const useListenToSyntheticEvents = (ref, setTouched) => {
  useEffect(() => {
    const input = ref.current;
    const handler = () => {
      setTouched(true);
    };

    input.addEventListener("touched", handler);

    return () => {
      input.removeEventListener("touched", handler);
    };
  }, []);
};
