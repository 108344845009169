import { getTechnologyIdsByService } from "@services/technology.helpers";
import { sqMilimetersToSqMeters } from "@helpers/utils";
import { useStore } from "@store";
import { Surface } from "@customTypes/elementParameters";
import { ServiceBare } from "../../types";

/**
 * @public
 */
export class CadAnalyser {
  private element: any;
  private params: any;

  constructor(element: any) {
    this.element = element;
    this.params = element.parameters;
  }

  analyse() {
    new MaterialAnalyser(this.element).setMaterialDetails();
    new ParametersProcessor(this.element).process();
    new TechnologyAnalyser(this.element).setServices();
  }
}

/**
 * @private
 */
class ParametersProcessor {
  private element: any;
  private params: any;

  constructor(element: any) {
    this.element = element;
    this.params = element.parameters;
  }

  process() {
    this.setEnvelopCount();
    this.transformThumbnail();
    this.groupDrillsByDiameter();
    this.groupThreads();
    this.groupBendsIntoMountings();
    this.squareMilimetersToSquareMetersForSurfaces();
  }

  private setEnvelopCount() {
    if (this.params.envelopes) {
      this.params.envelopes_count = this.params.envelopes.length;
    }
  }

  private transformThumbnail() {
    const { thumbnail } = this.element;

    if (!thumbnail.includes("data:image/png;base64")) {
      this.element.thumbnail = `data:image/png;base64,${thumbnail}`;
    }
  }

  /**
   * Group drills by diameter and then by depth
   *
   * [{
   *     diameter: 12,
   *     depths: [
   *         {
   *             count: 4,
   *             hole_depth: 11.8,
   *             through_hole: false
   *         }
   *     ]
   * }]
   */
  private groupDrillsByDiameter() {
    if (!this.params.holes?.drilled_holes) return;

    const grouped: any[] = [];

    this.params.holes.drilled_holes.forEach(addHoleToGroup);

    this.params.holes.drilledHoles = grouped;

    ///

    function addHoleToGroup(hole: any) {
      const diameter = hole.hole_diameter;

      if (!grouped.find((existing) => existing.diameter === diameter)) {
        grouped.push({ diameter, depths: [] });
      }

      const diameterGroup = grouped.find(
        (existing) => existing.diameter === diameter
      );

      const depthGroup = diameterGroup.depths.find(
        (depth: any) =>
          depth.hole_depth === hole.hole_depth &&
          depth.through_hole === hole.through_hole
      );

      if (depthGroup) {
        depthGroup.count += hole.count;
      } else {
        diameterGroup.depths.push({
          count: hole.count,
          hole_depth: hole.hole_depth,
          through_hole: hole.through_hole,
        });
      }
    }
  }

  /**
   * Group threaded holes by description and then by depth of the thread and depth of the hole
   *
   * [{
   *     description: "M4x0.7"
   *     standard: "ISO Profil metryczny",
   *     depths: [
   *         {
   *             count: 20,
   *             thread_depth: 10,
   *             hole_depth: 12,
   *             nominal_diameter: 12,
   *             through_hole: false
   *         }
   *     ]
   * }]
   */
  private groupThreads() {
    if (!this.params.holes?.thread_holes) return;

    const grouped: any[] = [];

    this.params.holes.thread_holes.forEach(addHoleToGroup);

    this.params.holes.threadHoles = grouped;

    ///

    function addHoleToGroup(hole: any) {
      const standard = hole.standard;
      const description = hole.description;
      const hole_diameter = hole.hole_diameter;
      const outer_diameter = hole.nominal_diameter;

      if (
        !grouped.find(
          (existing) =>
            existing.standard === standard &&
            existing.description === description &&
            existing.hole_diameter === hole_diameter &&
            existing.outer_diameter === outer_diameter
        )
      ) {
        grouped.push({
          standard,
          description,
          hole_diameter,
          outer_diameter,
          depths: [],
        });
      }

      const diameterGroup = grouped.find(
        (existing) =>
          existing.standard === standard &&
          existing.description === description &&
          existing.hole_diameter === hole_diameter &&
          existing.outer_diameter === outer_diameter
      );
      const depthGroup = diameterGroup.depths.find(
        (depth: any) =>
          depth.thread_depth === hole.thread_depth &&
          depth.hole_depth === hole.hole_depth &&
          depth.through_hole === hole.through_hole
      );

      if (depthGroup) {
        depthGroup.count += hole.count;
      } else {
        diameterGroup.depths.push({
          thread_depth: hole.thread_depth,
          hole_depth: hole.hole_depth,
          through_hole: hole.through_hole,
          count: hole.count,
        });
      }
    }
  }

  /**
   * Group bends by: radius ->> bend line length ->> angle
   *
   * [
   *   {
   *     radius: 90,
   *     lineLengths: [
   *       {
   *         length: 30,
   *         angles: [
   *           {
   *             angle: 15,
   *             count: 65,
   *           },
   *         ],
   *       },
   *     ],
   *   },
   * ];
   */
  private groupBendsIntoMountings() {
    if (!this.params.bends) return;

    const grouped: any[] = [];

    this.params.bends.forEach(addBendToGroup);

    this.params.mountings = grouped;

    function addBendToGroup(bend: any) {
      const radius = bend.radius;

      let radiusGroup = grouped.find((existing) => existing.radius === radius);

      if (!radiusGroup) {
        radiusGroup = {
          radius,
          lineLengths: [],
        };

        grouped.push(radiusGroup);
      }

      let lineLength = radiusGroup.lineLengths.find(
        (lineLength: any) => lineLength.length === bend.length
      );

      if (!lineLength) {
        lineLength = {
          length: bend.length,
          angles: [],
        };

        radiusGroup.lineLengths.push(lineLength);
      }

      const angleGroup = lineLength.angles.find(
        (angleGroup: any) => angleGroup.angle === bend.angle
      );

      if (angleGroup) {
        angleGroup.count += bend.count;
      } else {
        lineLength.angles.push({
          angle: bend.angle,
          count: bend.count,
        });
      }
    }
  }

  private squareMilimetersToSquareMetersForSurfaces() {
    if (Array.isArray(this.params.coatings)) {
      this.params.coatings = this.params.coatings.map((coating) => ({
        ...coating,
        area: sqMilimetersToSqMeters(coating.area),
      }));
    }

    if (this.params.surface_area) {
      this.params.surface_area = sqMilimetersToSqMeters(
        this.params.surface_area
      );
    }

    if (this.params.grinding_area) {
      this.params.grinding_area = sqMilimetersToSqMeters(
        this.params.grinding_area
      );
    }
  }
}

/**
 * @private
 */
class MaterialAnalyser {
  private element: any;

  constructor(element: any) {
    this.element = element;
  }

  setMaterialDetails() {
    const cadMaterialName = this.element.parameters.material || "Ogólny";
    const material = MaterialAnalyser.findMaterialByName(cadMaterialName);

    if (material) {
      this.element.material_id = material._id;
    } else {
      this.element.material_id = null;
    }
  }

  private static findMaterialByName(materialName: string) {
    const { materials } = useStore.getState();

    const lcMaterialName = materialName.toLowerCase();

    const material = materials.find((material) => {
      if (material.pnen_100271?.toLowerCase() === lcMaterialName) {
        return true;
      }

      if (material.pnen_100272?.toLowerCase() === lcMaterialName) {
        return true;
      }

      if (material.din?.toLowerCase() === lcMaterialName) {
        return true;
      }

      if (material.usa?.toLowerCase() === lcMaterialName) {
        return true;
      }

      return material.name?.toLowerCase() === lcMaterialName;
    });

    return material;
  }
}

/**
 * @public
 */
export class TechnologyAnalyser {
  private element: any;
  private params: any;
  private readonly materialCategory: string;

  constructor(element: any) {
    this.element = element;
    this.params = element.parameters;
    this.materialCategory = this.checkMaterialCategory(element);
  }

  private get isMetal() {
    return this.materialCategory === "metal";
  }

  private get isPlastic() {
    return this.materialCategory === "plastic";
  }

  private get isWood() {
    return this.materialCategory === "wood";
  }

  setServices() {
    if (!this.element.material_id && !this.element.material_type_id) {
      this.element.services = [];

      return;
    }

    const services: any[] = [];

    const checkService = TechnologyAnalyser.checkService;

    checkService(this.isSheetMetalCutting, 1, services);
    checkService(this.isSheetWoodCutting, 3, services);
    checkService(this.isSheetPlasticCutting, 4, services);
    checkService(this.isSheetMetalBending, 19, services);
    checkService(this.isMetalPainting, 43, services);
    checkService(this.isWoodPainting, 53, services);
    checkService(this.isPlasticPainting, 59, services);
    checkService(this.isAnodizing, 46, services);
    checkService(this.isMetalDrilling, 125, services);
    checkService(this.isWoodDrilling, 133, services);
    checkService(this.isPlasticDrilling, 129, services);
    checkService(this.isMetalThreading, 126, services);
    checkService(this.isMetalCountersinkDeepening, 127, services);
    checkService(this.isWoodCountersinkDeepening, 135, services);
    checkService(this.isPlasticCountersinkDeepening, 131, services);
    checkService(this.isMetalCounterboreDeepening, 128, services);
    checkService(this.isWoodCounterboreDeepening, 136, services);
    checkService(this.isPlasticCounterboreDeepening, 132, services);

    this.element.services = services;
  }

  private static checkService(
    predicate: () => boolean,
    serviceId: number,
    services: ServiceBare[]
  ) {
    if (predicate()) {
      services.push({
        _id: serviceId,
        technologyIds: getTechnologyIdsByService(serviceId),
      });
    }
  }

  private isSheetMetalCutting = () => {
    return this.isMetal && this.params.sheet_metal_component;
  };

  private isSheetWoodCutting = () => {
    return this.isWood && this.params.sheet_metal_component;
  };

  private isSheetPlasticCutting = () => {
    return this.isPlastic && this.params.sheet_metal_component;
  };

  private isSheetMetalBending = () => {
    return this.isMetal && this.params.bends?.length > 0;
  };

  private isMetalPainting = () => {
    if (!this.isMetal) return false;
    if (!this.params.coatings) return false;

    return this.params.coatings.some(({ name }: Surface) =>
      name.includes("RAL")
    );
  };

  private isWoodPainting = () => {
    if (!this.isWood) return false;
    if (!this.params.coatings) return false;

    return this.params.coatings.some(({ name }: Surface) =>
      name.includes("RAL")
    );
  };

  private isPlasticPainting = () => {
    if (!this.isPlastic) return false;
    if (!this.params.coatings) return false;

    return this.params.coatings.some(({ name }: Surface) =>
      name.includes("RAL")
    );
  };

  private isAnodizing = () => {
    if (!this.isMetal) return false;
    if (!this.params.coatings) return false;

    return this.params.coatings.some(({ name }: Surface) =>
      name.includes("Anodowane")
    );
  };

  private isMetalDrilling = () => {
    if (!this.isMetal) return false;

    const holes = this.params.holes;

    return holes?.drilled_holes?.length > 0;
  };

  private isWoodDrilling = () => {
    if (!this.isWood) return false;

    const holes = this.params.holes;

    return holes?.drilled_holes?.length > 0;
  };

  private isPlasticDrilling = () => {
    if (!this.isPlastic) return false;

    const holes = this.params.holes;

    return holes?.drilled_holes?.length > 0;
  };

  private isMetalThreading = () => {
    if (!this.isMetal) return false;

    return this.params.holes?.thread_holes?.length > 0;
  };

  private isMetalCountersinkDeepening = () => {
    if (!this.isMetal) return false;

    return this.params.holes?.counter_sink_holes?.length > 0;
  };

  private isWoodCountersinkDeepening = () => {
    if (!this.isWood) return false;

    return this.params.holes?.counter_sink_holes?.length > 0;
  };

  private isPlasticCountersinkDeepening = () => {
    if (!this.isPlastic) return false;

    return this.params.holes?.counter_sink_holes?.length > 0;
  };

  private isMetalCounterboreDeepening = () => {
    if (!this.isMetal) return false;

    return this.params.holes?.counter_bore_holes?.length > 0;
  };

  private isWoodCounterboreDeepening = () => {
    if (!this.isWood) return false;

    return this.params.holes?.counter_bore_holes?.length > 0;
  };

  private isPlasticCounterboreDeepening = () => {
    if (!this.isPlastic) return false;

    return this.params.holes?.counter_bore_holes?.length > 0;
  };

  private checkMaterialCategory(element: any) {
    if (element.material_id) {
      const materials = useStore.getState().materials;

      const material = materials.find(({ _id }) => _id === element.material_id);

      return material?.categoryName;
    }

    if (element.material_type_id) {
      const types = useStore.getState().types;

      const type = types.find(({ _id }) => _id === element.material_type_id);

      return type?.categoryName;
    }
  }
}
