import { useEffect, useState } from "react";
import produce from "immer";
import { InputNumber } from "@components/InputNumber";
import { Btn } from "../../../../../../Customer/views/NewOrderPage/Elements/Element/Element";
import cs from "./BendingMachinesLevelFields.module.scss";

export const BendingMachinesLevelFields = ({ pricing, onChange }) => {
  const [setupDraft, setSetupDraft] = useState();
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    // NOTE: First is as good as any - these fields
    //       are the same across all materails groups
    const setup = pricing.perMaterials[0]?.prices.setup;

    setSetupDraft(setup);
  }, [pricing]);

  return (
    <div className={cs.BendingMachinesLevelFields}>
      <div className={cs.field}>
        <span className="mr4">
          Koszt przygotowania detalu i nastawy setupu:
        </span>
        {isEdited ? (
          <InputNumber
            step={0.1}
            size="small"
            value={setupDraft}
            onChange={setSetupDraft}
          />
        ) : (
          <strong className="color-black">{setupDraft}</strong>
        )}

        <span className="ml4">zł</span>
      </div>

      <div className="mt4">
        {isEdited ? (
          <Btn onClick={handleSave}>Zapisz zmiany</Btn>
        ) : (
          <Btn onClick={() => setIsEdited(true)}>Edytuj ceny</Btn>
        )}
      </div>
    </div>
  );

  function handleSave() {
    const updatedPricing = produce(pricing, (draft) => {
      draft.perMaterials = draft.perMaterials.map((materialsGroup) => {
        materialsGroup.prices.setup = setupDraft;

        return materialsGroup;
      });
    });

    onChange(updatedPricing);

    setIsEdited(false);
  }
};
