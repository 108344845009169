import { useRef, useState } from "react";
import { Spin } from "antd";
import { useUsersOfCurrentCompany } from "@services/account.hooks";
import { deleteUserFromCompanyApi } from "@services/account.api";
import { changeLicenseStateApi } from "../../services/subscription.api";
import { store, useStore } from "@store";
import { Table } from "@components/Table";
import { Button } from "@components/Button";
import { Confirmation } from "@components/Confirmation";
import { Chip } from "@components/Chip";
import { useHandleSubscribed } from "./useHandleSubscribed";
import { InviteModal } from "./InviteModal";
import { ActivateProviderSection } from "./ActivateProviderSection";
import { columns } from "./tableColumns";
import cs from "./UsersPage.module.scss";

export const UsersPage = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [refreshToken, setRefreshToken] = useState<null | number>(null);
  const [isTableActionOngoing, setIsTableActionOngoing] = useState(false);

  const deleteConfirmation = useRef(null);

  const currentUser = store.hooks.useUser();
  const [users, areUsersLoading] = useUsersOfCurrentCompany(refreshToken);
  const subscription = store.hooks.useSubscription();

  const justSubscribed = useHandleSubscribed();

  const noOfLicensesInUse = users?.reduce(
    (sum, user) => (user.subscription ? sum + 1 : sum),
    0
  );
  const noOfAvailableLicenses =
    subscription?.no_of_licenses - noOfLicensesInUse || 0;

  return (
    <main className={cs.UsersPage}>
      {!subscription && !justSubscribed && <ActivateProviderSection />}

      <section className={cs.actions}>
        {subscription.status === "ONGOING" && (
          <form method="POST" action="/api/subscription/portal">
            <Button type="secondary" size="medium" btnType="submit">
              Wykup dodatkowe stanowiska
            </Button>
          </form>
        )}

        <Button
          type="primary"
          size="medium"
          onClick={() => setIsModalOpened(true)}
        >
          Zaproś użytkownika
        </Button>
      </section>

      <section>
        <Spin spinning={areUsersLoading || isTableActionOngoing}>
          <Table
            title="Liczba użytkowników"
            data={users}
            actions={
              subscription ? (
                <div className="body semi-bold d-flex align-center">
                  Liczba dostępnych licencji:{" "}
                  <Chip size="caption" color="dark-gray" className="ml4">
                    {noOfAvailableLicenses} z {subscription.no_of_licenses}
                  </Chip>
                </div>
              ) : null
            }
            columns={columns({
              currentUserId: currentUser._id,
              noOfAvailableLicenses,
              onSubscriptionChange: handleLicenseChanged,
              onDeleteUser: handleDeleteUser,
            })}
          />
        </Spin>
      </section>

      <InviteModal isOpen={isModalOpened} onClose={handleInvitationSent} />

      <Confirmation
        ref={deleteConfirmation}
        title="Potwierdź"
        text="Chcesz usunąć użytkownika?"
        okText="Usuń"
        cancelText="Wróć"
      />
    </main>
  );

  function handleInvitationSent(invitationSent: boolean) {
    setIsModalOpened(false);

    if (invitationSent) {
      setRefreshToken(Math.random());
    }
  }

  async function handleLicenseChanged(userId: string, state: boolean) {
    setIsTableActionOngoing(true);

    const { isSuccess, error } = await changeLicenseStateApi(userId, state);

    if (isSuccess) {
      setRefreshToken(Date.now());

      if (userId === currentUser._id) {
        await useStore.getState().fetchCurrentUser();
      }
    } else if (error === "NO_LICENSES_AVAILABLE") {
      window.location.reload();
    }

    setIsTableActionOngoing(false);
  }

  async function handleDeleteUser(userId: string) {
    const isConfirmed = await deleteConfirmation.current.open();

    if (isConfirmed) {
      setIsTableActionOngoing(true);

      try {
        const { isSuccess } = await deleteUserFromCompanyApi(userId);

        if (isSuccess) {
          setRefreshToken(Date.now());
        }
      } finally {
        setIsTableActionOngoing(false);
      }
    }
  }
};
