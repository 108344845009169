import { useState } from "react";
import { CompanyModal } from "@components/CompanyModal";

type Props = {
  children: (open: any) => JSX.Element;
};

export const WithCompanyModal = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [companyId, setCompanyId] = useState("");

  return (
    <>
      {children(handleOpen)}

      <CompanyModal
        isOpen={isOpen}
        companyId={companyId}
        onClose={handleClose}
      />
    </>
  );

  function handleClose() {
    setIsOpen(false);
  }

  function handleOpen(companyId: string) {
    setCompanyId(companyId);
    setIsOpen(true);
  }
};
