import { Link } from "react-router-dom";
import { Rate } from "antd";
import { toLocaleDate } from "@helpers/dateUtils";
import { store } from "@store";
import cs from "./CompanyModal.module.scss";

export const Review = ({ review }) => {
  const user = store.hooks.useUser();

  const isOwner = review.reviewer_id === user._id;

  return (
    <div className={cs.Review}>
      <div style={{ paddingRight: 16, flex: "0 0 150px" }}>
        <div className="semi-bold">{toLocaleDate(review.created_at)}</div>
        <div>4dustry sp. z o.o.</div>

        {isOwner && (
          <div className="color-primary">
            <Link to={`/review/edit/${review._id}`}>Edytuj</Link>
          </div>
        )}
      </div>

      <div style={{ paddingRight: 20, flexShrink: 0 }}>
        <Rate
          disabled
          allowHalf
          defaultValue={review.main_rating}
          style={{ fontSize: 16 }}
        />
      </div>

      <div>
        <div>{review.comment}</div>

        {review.updated_at && (
          <div className="caption">
            (zaktualizowano: {toLocaleDate(review.updated_at)})
          </div>
        )}
      </div>
    </div>
  );
};
