import { CSSProperties } from "react";
import classNames from "classnames";
import { store } from "@store";
import { Select } from "@components/Select";
import cs from "./TechnologiesSelect.module.scss";

type Props = {
  value: number[] | null;
  className?: string;
  style?: CSSProperties;
  size?: "default" | "small" | "large";
  placeholder?: string;
  serviceId?: number;
  onChange: (selectedTechnologyIds: number[]) => void;
};

export const TechnologiesSelect = ({
  value,
  className,
  style,
  size = "default",
  placeholder,
  serviceId,
  onChange,
}: Props) => {
  const availableTechnologies = store.hooks.useTechnologies();
  const availableTechnologiesByService =
    store.hooks.useTechnolgiesByService(serviceId);

  let technologies;

  if (serviceId) {
    technologies = availableTechnologiesByService;
  } else {
    technologies = availableTechnologies;
  }

  const rootCs = classNames(
    "TechnologiesSelect",
    cs.TechnologiesSelect,
    className
  );

  return (
    <Select
      value={value}
      mode="multiple"
      showSearch
      style={style}
      className={rootCs}
      size={size}
      placeholder={placeholder}
      options={technologies.map((service) => ({
        value: service._id,
        label: service.name,
      }))}
      onChange={onChange}
    />
  );
};
