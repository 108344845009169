import { OrderLink } from "@components/DownloadOrderDocs";
import { Card } from "@components/Card";
import fileImg from "./img/file.svg";
import cs from "./AdditionalFiles.module.scss";

type Props = {
  order: any;
  files: File[];
};

export const AdditionalFiles = ({ order, files = [] }: Props) => {
  return (
    <section className="mt24">
      <Card>
        <Card.Header>
          <h2 className="h5">
            Dodatkowe pliki projektowe ({files?.length || 0})
          </h2>
        </Card.Header>

        <Card.Body>
          <div>
            {files?.length > 0 && (
              <ul className={cs.files}>
                {files?.map((file: File) => (
                  <li key={file.name} className="mb8">
                    <img src={fileImg} />
                    <span className="body bold ml8">{file.name}</span>

                    {file.aws_key && (
                      <OrderLink order={order} file={file} className={cs.link}>
                        Pobierz
                      </OrderLink>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Card.Body>
      </Card>
    </section>
  );
};
