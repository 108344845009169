import { toLocaleDate } from "@helpers/dateUtils";

export const columns = [
  {
    title: "Użytkownik",
    field: "name",
  },
  {
    title: "Kontakt",
    render: (_: any, __: any, row: any) => {
      return [row.email, row.phone].filter((val) => val).join(" | ");
    },
  },
  {
    title: "Firma",
    field: "company_name",
  },
  {
    title: "Kiedy pobrano",
    field: "downloaded_at",
    render: (value: string) => {
      return toLocaleDate(value) + ", " + new Date(value).toLocaleTimeString();
    },
  },
];
