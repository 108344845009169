import { useContext, useRef } from "react";
import nestedProperty from "nested-property";
import { Switch } from "antd";
import { store, useStore } from "@store";
import { flattenDeepUnique, genTempId, replaceItem } from "@helpers/utils";
import { Button } from "@components/Button";
import { Btn } from "../../../../../Customer/views/NewOrderPage/Elements/Element/Element";
import { SelectMaterialTypesModal } from "../../SelectMaterialCategoriesModal";
import { getMachinesLevelFields } from "./machinesLevelFields/getMachinesLevelFields";
import { MaterialsLevel } from "./MaterialsLevel";
import { ServiceContext } from "./WithMachinesAndMaterials";
import deleteImg from "./img/delete.svg";
import cs from "./MachinesLevel.module.scss";

export const MachinesLevel = ({
  pricing,
  onAssignMachines,
  onDefaultChange,
  onChange,
  onRemove,
}) => {
  const selectMaterialTypesModal = useRef();

  const serviceId = useContext(ServiceContext);

  const machinePark = useStore((state) => state.machinePark);
  const availableMaterialTypes = store.hooks.useMaterialTypes();

  const machines = pricing.machines?.map((machineId) =>
    machinePark.find(({ _id }) => _id === machineId)
  );

  const AdditionalFields = getMachinesLevelFields(serviceId);

  return (
    <div className={cs.MachinesLevel}>
      <div className="mb24">
        <div className={cs.header}>
          <div className="d-flex align-center">
            <Switch
              checked={pricing.is_default}
              onChange={handleSelectedAsDefault}
            />
            <span className="caption color-dark-gray ml12">
              Cennik domyślny dla usługi
            </span>
          </div>

          <Button
            type="secondary"
            size="small"
            className="ml-auto"
            onClick={handleMachineLevelDeleted}
          >
            Usuń grupę
          </Button>
        </div>

        <div className="caption mb24">Przypisane maszyny:</div>

        <div>
          {machines?.length > 0 ? (
            <div>
              {machines.map((machine, index) => (
                <Machine
                  key={machine._id}
                  machine={machine}
                  index={index}
                  onRemove={handleMachineRemoved}
                />
              ))}

              <div className="mt8">
                <Btn onClick={onAssignMachines}>Dodaj maszyny</Btn>
              </div>
            </div>
          ) : (
            <Button type="secondary" size="medium" onClick={onAssignMachines}>
              Przypisz maszyny
            </Button>
          )}
        </div>
      </div>

      {AdditionalFields && pricing.perMaterials?.length > 0 && (
        <div className="mb24">
          <div className="caption mb24">
            Cennik wspólny dla wszystkich materiałów:
          </div>

          <div>
            <AdditionalFields pricing={pricing} onChange={onChange} />
          </div>
        </div>
      )}

      <div>
        <div className="caption mb24">Cennik dla wybranych materiałów:</div>

        <div>
          {pricing.perMaterials?.length > 0 ? (
            <div>
              {pricing.perMaterials.map((materialsGroup) => (
                <MaterialsLevel
                  materialsGroupPricing={materialsGroup}
                  onChange={handleMaterialsGroupChanged}
                  onEdit={() => handleMaterialsEdited(materialsGroup)}
                  onDelete={() => handleMaterialsDeleted(materialsGroup)}
                />
              ))}

              <Button
                type="secondary"
                size="medium"
                onClick={addNewMaterialsGroup}
              >
                Dodaj kolejne materiały
              </Button>
            </div>
          ) : (
            <Button
              type="secondary"
              size="medium"
              onClick={addNewMaterialsGroup}
            >
              Przypisz materiały
            </Button>
          )}
        </div>
      </div>

      <SelectMaterialTypesModal
        ref={selectMaterialTypesModal}
        serviceId={serviceId}
      />
    </div>
  );

  function handleMachineRemoved(machineId) {
    onChange({
      ...pricing,
      machines: pricing.machines.filter((_id) => _id !== machineId),
    });
  }

  function handleSelectedAsDefault(checked: boolean) {
    if (checked) {
      onDefaultChange(pricing.group_id);
    }
  }

  function handleMaterialsGroupChanged(updatedGroup) {
    onChange({
      ...pricing,
      perMaterials: replaceItem(pricing.perMaterials, updatedGroup),
    });
  }

  async function addNewMaterialsGroup() {
    const alreadySelectedMaterials = flattenDeepUnique(
      nestedProperty.get(pricing, "perMaterials.+.materialTypes.+._id")
    );

    const materialTypeIds = await selectMaterialTypesModal.current.openModal(
      alreadySelectedMaterials,
      []
    );

    if (materialTypeIds?.length > 0) {
      const materialTypes = materialTypeIds.map((typeId) => {
        return availableMaterialTypes.find(({ _id }) => _id === typeId);
      });

      const newMaterialsGroup = {
        _id: genTempId(),
        materialTypes,
        prices: {},
      };

      // TODO: specyficzne dla gięcia, jak dojdą inne niż setup to weź o tym pamiętaj
      if (pricing.perMaterials?.[0]?.prices?.setup) {
        newMaterialsGroup.prices.setup = pricing.perMaterials[0]?.prices.setup;
      }

      onChange({
        ...pricing,
        perMaterials: [...(pricing.perMaterials || []), newMaterialsGroup],
      });
    }
  }

  async function handleMaterialsEdited(materialsGroup) {
    const materialsSelectedInThisGroup = materialsGroup.materialTypes.map(
      ({ _id }) => _id
    );
    const materialsAlreadySelectedInOtherMaterialsGroups = flattenDeepUnique(
      nestedProperty.get(pricing, "perMaterials.+.materialTypes.+._id")
    ).filter(
      (materialId) => !materialsSelectedInThisGroup.includes(materialId)
    );

    const materialTypeIds = await selectMaterialTypesModal.current.openModal(
      materialsAlreadySelectedInOtherMaterialsGroups,
      materialsSelectedInThisGroup
    );

    if (materialTypeIds?.length > 0) {
      const materialTypes = materialTypeIds.map((typeId) => {
        return availableMaterialTypes.find(({ _id }) => _id === typeId);
      });

      onChange({
        ...pricing,
        perMaterials: replaceItem(pricing.perMaterials, {
          ...materialsGroup,
          materialTypes,
        }),
      });
    }
  }

  function handleMaterialsDeleted(materialsGroup) {
    onRemove(materialsGroup._id);
  }

  function handleMachineLevelDeleted() {
    let idsOfAllPricings = flattenDeepUnique(
      nestedProperty.get(pricing.perMaterials, "+._id")
    );

    if (idsOfAllPricings.length === 0) {
      // NOTE: No materials added yet
      idsOfAllPricings.push(pricing._id);
    }

    onRemove(idsOfAllPricings);
  }
};

const Machine = ({ machine, index, onRemove }) => {
  return (
    <div className={`Machine ${cs.Machine}`}>
      <div className={cs.header}>
        <span>
          [{index + 1}] {machine.type_name}
        </span>

        <img src={deleteImg} alt="" onClick={() => onRemove(machine._id)} />
      </div>

      <div>
        <span className="small color-gray">Producent: </span>
        <span className="bold">{machine.brand_name}</span>
      </div>
      <div>
        <span className="small color-gray">Model: </span>
        <span className="bold">{machine.model_name}</span>
      </div>
    </div>
  );
};
