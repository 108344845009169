import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notification, Spin } from "antd";
import { sendAuthCodeApi } from "@services/auth.api";
import { Card } from "@components/Card";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Baner } from "../../components/Baner";
import cs from "./PhonePage.module.scss";

export const PhonePage = () => {
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <main className={`PhonePage ${cs.PhonePage}`}>
      <Baner type="register" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <form onSubmit={handleSubmit}>
          <Card>
            <Card.Header>
              <div className="h5">Weryfikacja użytkownika</div>
            </Card.Header>

            <Card.Body>
              <div className="caption h5 mb20">
                Wprowadź numer telefonu, pod który chcesz wysłać kod
                weryfikacyjny.
              </div>

              <div>
                <Input
                  name="phone"
                  value={phone}
                  type="tel"
                  label="Telefon"
                  size="large"
                  required
                  placeholder="+48 231-321-342"
                  pattern="(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)"
                  autoComplete="new-password"
                  onChange={setPhone}
                />
              </div>
            </Card.Body>

            <Card.Footer>
              <Spin spinning={isLoading}>
                <Button
                  type="primary"
                  size="large"
                  btnType="submit"
                  style={{ width: "100%" }}
                >
                  Dalej
                </Button>
              </Spin>
            </Card.Footer>
          </Card>
        </form>

        <PrivacyPolicy />
      </section>
    </main>
  );

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    const { isSuccess, data, error } = await sendAuthCodeApi(phone);

    if (isSuccess) {
      navigate("/verification-code", {
        state: {
          ...location.state,
          phone,
          verificationPhoneNumber: data.phone,
        },
      });
    } else if (error === "INVALID_PHONE") {
      notification.error({
        message: "Niepoprawny numer telefonu.",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Wystąpił błąd. Spróbuj ponownie później.",
        placement: "topRight",
      });
    }

    setIsLoading(false);
  }
};
