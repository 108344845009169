import { useState } from "react";
import classNames from "classnames";
import { RadioGroup } from "@components/Radio";
import { InputNumber } from "@components/InputNumber";
import deleteImg from "../../img/delete.svg";
import drillImg from "./img/drill.png";
import drillThroughImg from "./img/drill_through.png";
import cs from "./Drilling.module.scss";

export const Depth = ({
  diameter,
  diameterId,
  depth,
  index,
  readOnly,
  onDepthChange,
  onDepthRemoved,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const rootCs = classNames(cs.Deepening, {
    [cs.hovered]: isHovered,
  });

  return (
    <div className={rootCs}>
      <div className="d-flex align-center justify-between mb16">
        <span className="bold">Głębokość {index + 1}:</span>
        {!readOnly && (
          <img
            src={deleteImg}
            alt=""
            style={{ cursor: "pointer" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
              onDepthRemoved(diameterId, depth.tempId);
            }}
          />
        )}
      </div>

      <div className="d-flex">
        <div style={{ flex: "0 1 159px" }}>
          <div className="d-flex align-center justify-between mb16">
            <span>Ilość</span>
            <InputNumber
              value={depth.count}
              style={{ width: "63px" }}
              size="small"
              min={1}
              step={1}
              readOnly={readOnly}
              required
              onChange={(value) => {
                handleChange("count", value);
              }}
            />
          </div>

          <div>
            <RadioGroup
              value={depth.through_hole}
              readOnly={readOnly}
              required
              onChange={(value) => {
                handleChange("through_hole", value);
              }}
            >
              <RadioGroup.Radio
                value={true}
                className="caption color-dark-gray"
              >
                Otwór przelotowy
              </RadioGroup.Radio>
              <RadioGroup.Radio
                value={false}
                className="caption color-dark-gray mt4"
              >
                Otwór o okreslonej glebokosci
              </RadioGroup.Radio>
            </RadioGroup>
          </div>
        </div>

        {depth.through_hole ? (
          <HoleThroughImg
            diameter={diameter}
            depth={depth}
            readOnly={readOnly}
            onChange={handleChange}
          />
        ) : (
          <HoleImg
            diameter={diameter}
            depth={depth}
            readOnly={readOnly}
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  );

  function handleChange(property, value) {
    onDepthChange(diameterId, depth.tempId, property, value);
  }
};

const HoleImg = ({ diameter, depth, readOnly, onChange }) => (
  <div className={cs.drillImgContainer}>
    <InputNumber
      value={diameter}
      readOnly
      style={{ width: "48px", marginLeft: "28px" }}
      size="small"
    />

    <div className="d-flex align-center">
      <img src={drillImg} alt="" />
      <InputNumber
        value={depth.hole_depth}
        style={{
          width: "48px",
          position: "relative",
          top: "5px",
          right: "38px",
        }}
        size="small"
        min={0.01}
        readOnly={readOnly}
        required
        onChange={(value) => onChange("hole_depth", value)}
      />
    </div>
  </div>
);

const HoleThroughImg = ({ diameter, depth, readOnly, onChange }) => (
  <div className={cs.drillImgContainer}>
    <InputNumber
      value={diameter}
      readOnly
      style={{ width: "48px", marginLeft: "29px" }}
      size="small"
    />

    <div className="d-flex align-center">
      <img src={drillThroughImg} alt="" />
      <InputNumber
        value={depth.hole_depth}
        style={{
          width: "48px",
          position: "relative",
          top: "8px",
          right: "41px",
        }}
        size="small"
        min={0.01}
        readOnly={readOnly}
        required
        onChange={(value) => onChange("hole_depth", value)}
      />
    </div>
  </div>
);
