import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { Chip } from "@components/Chip";
import { MaterialSelect } from "@components/MaterialSelect";
import { MaterialName } from "../../Customer/views/NewOrderPage/Elements/Element/MaterialName";
import { Btn } from "../../Customer/views/NewOrderPage/Elements/Element/Element";

export const Material = ({ materialId, typeId, isEditable, onChange }) => {
  const [selectedOption, setSelectedOption] = useImmer({});
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setSelectedOption({
      materialId,
      typeId,
    });
  }, [materialId, typeId]);

  const edited = (
    <>
      <MaterialSelect
        materialId={selectedOption?.materialId}
        typeId={selectedOption?.typeId}
        width={280}
        className="mr8"
        onChange={setSelectedOption}
      />

      <Btn onClick={handleSave}>
        <span className="bold">Zapisz</span>
      </Btn>
    </>
  );

  const selected = (
    <>
      <Chip color="gray" size="caption" className="mr8">
        <MaterialName materialId={materialId} typeId={typeId} />
      </Chip>

      {isEditable && (
        <Btn onClick={() => setIsEdited(true)}>
          <span className="bold">Edytuj</span>
        </Btn>
      )}
    </>
  );

  const empty = (
    <div className="caption bold color-black">
      <div className="caption color-red mb2">Brak materiału</div>
      <Btn onClick={() => setIsEdited(true)}>
        <span className="bold">Dodaj</span>
      </Btn>
    </div>
  );

  if (isEdited) {
    return edited;
  }

  return materialId || typeId ? selected : empty;

  function handleSave() {
    onChange(selectedOption);

    setIsEdited(false);
  }
};
