import { Navigate, useLocation } from "react-router-dom";
import { isAuthenticated } from "@services/auth.api";
import { store } from "@store";

// @ts-ignore
export const RequireAuth = ({ children }) => {
  const isUserAuthenticated = isAuthenticated();
  const location = useLocation();
  const userHasCompany = store.hooks.useUserHasCompany();
  const pendingVerification = store.hooks.usePendingVerification();

  if (!isUserAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!userHasCompany) {
    return <Navigate to="/no-company" replace />;
  } else if (pendingVerification) {
    return <Navigate to="/pending-verification" replace />;
  }

  return children;
};
