import { useState } from "react";
import { getNoOfUnitsText } from "@helpers/getNoOfUnitsText";
import { getNoOfSetsText } from "@helpers/getNoOfSetsText";
import { trim } from "@helpers/utils";
import { Card } from "@components/Card";
import { Chip } from "@components/Chip";
import { Button } from "@components/Button";
import { ElementDetailsModal } from "@components/ElementDetailsModal";
import { Viewer3D } from "@components/Viewer3D";
import { OrderElementFiles } from "@components/OrderElementFiles";
// TODO: Wspólne komponenty
import { MaterialName } from "../../../Customer/views/NewOrderPage/Elements/Element/MaterialName";
import { TechnologyNames } from "../../../Customer/views/NewOrderPage/Elements/Element/TechnologyNames";
import chevronLeftImg from "./img/chevron_left.svg";
import chevronRightImg from "./img/chevron_right.svg";
import chevronDownImg from "./img/chevron_down.svg";
import cs from "./Elements.module.scss";

export const Elements = ({ order, noOfSets, isCompatible, elements }) => {
  const [viewedElement, setViewedElement] = useState(null);

  return (
    <section className={"Elements"}>
      <Card>
        <Card.Header className={cs.header}>
          <span className="h5">Elementy do wykonania ({elements.length})</span>

          {isCompatible && (
            <Chip color="green" size="caption" className="ml24">
              Zgodne z PM
            </Chip>
          )}
        </Card.Header>
        <Card.Body>
          <div className="body mb4">
            <span style={{ color: "#636E72" }}>Ilość:</span>
            <Chip color="orange" size="body" className="ml8">
              <span>{getNoOfSetsText(noOfSets)}</span>
            </Chip>
          </div>

          <div>
            {elements.map((element, index) => (
              <Element
                key={element._id}
                order={order}
                element={element}
                onShowDetails={() => {
                  setViewedElement({ element, index: index + 1 });
                }}
              />
            ))}
          </div>
        </Card.Body>
      </Card>

      <ElementDetailsModal
        element={viewedElement?.element}
        index={viewedElement?.index}
        noOfSets={noOfSets}
        visible={Boolean(viewedElement)}
        mode="readonly"
        footer={
          <div>
            <Button
              type="secondary"
              size="medium"
              disabled={viewedElement?.index === 1}
              onClick={prev}
            >
              <img src={chevronLeftImg} />
            </Button>

            <span className="body ml12 mr12">
              <span className="bold">{viewedElement?.index}</span>
              <span> / {elements.length}</span>
            </span>

            <Button
              type="secondary"
              size="medium"
              disabled={viewedElement?.index === elements.length}
              onClick={next}
            >
              <img src={chevronRightImg} />
            </Button>
          </div>
        }
        onClose={() => setViewedElement(null)}
      />
    </section>
  );

  function next() {
    setViewedElement((prev) => {
      const nextElement = elements[prev.index];
      const nextIndex = prev.index + 1;

      return { element: nextElement, index: nextIndex };
    });
  }

  function prev() {
    setViewedElement((prev) => {
      const prevElement = elements[prev.index - 2];
      const prevIndex = prev.index - 1;

      return { element: prevElement, index: prevIndex };
    });
  }
};

const Element = ({ order, element, onShowDetails }) => {
  const files = [element.file, ...element.relatedFiles];

  return (
    <div className={cs.Element}>
      <div className={cs.Element_main}>
        <div style={{ alignSelf: "center" }}>
          <img
            src={
              element.thumbnail ||
              process.env.PUBLIC_URL + "/no_thumbnail_96x96.png"
            }
            alt={element.name}
            width={96}
            height={96}
            onError={(e) => (e.target.src = chevronDownImg)}
          />
        </div>

        <div>
          <div className="mb4 body bold">{element.name}</div>
          <div className="caption mb8">{element.desc}</div>
          <div>
            <Viewer3D file={element.file} />
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb2">Materiał:</div>
            <div className="caption bold color-black">
              <MaterialName
                materialId={element.material_id}
                typeId={element.material_type_id}
              />
            </div>
          </div>

          <div>
            <div className="caption mb2">Usługi:</div>
            <div className="caption bold color-black">
              <TechnologyNames
                serviceIds={element.services.map(({ _id }) => _id)}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb2">Pliki dokumentacji:</div>
            <div className="caption bold color-black">
              <OrderElementFiles order={order} files={files} />
            </div>
          </div>

          <div>
            <div className="caption mb2">Uwagi zlecającego:</div>
            <div className="caption color-black">
              {trim(element.remarks || "-", 60)}
            </div>
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb4">Łączna ilość:</div>
            <div className="caption color-black bold">
              <Chip color="orange" size="caption">
                {getNoOfUnitsText(element.total_amount)}
              </Chip>
            </div>
          </div>

          <div className="caption">
            Ilość szt. w komplecie: {element.amount}
          </div>
        </div>
      </div>

      <div className={cs.Element_footer}>
        <Button type="link" onClick={onShowDetails}>
          <span className="mr8">Szczegóły elementu</span>
          <img src={chevronDownImg} />
        </Button>
      </div>
    </div>
  );
};
