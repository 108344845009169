import { forwardRef, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "@components/Modal";

export const CancelConfirmation = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
  }));

  const navigate = useNavigate();

  return (
    <Modal
      title="Anulować zapytanie?"
      width={480}
      okText="Anuluj zapytanie"
      cancelText="Wróc"
      cancelButtonProps={{ className: "Button_primary Button_medium" }}
      okButtonProps={{ className: "Button_secondary Button_medium" }}
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      onOk={() => {
        setIsOpen(false);
        navigate("/");
      }}
    >
      <p className="body">
        Zmiany nie zostaną zapisane, co w razie powrotu będzie wymagało ich
        ponownego uzupełnienia.
      </p>
    </Modal>
  );
});
