import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { notification, Rate, Spin } from "antd";
import { addReviewApi, getReviewTargetApi } from "@services/review.api";
import { Card } from "@components/Card";
import { Button } from "@components/Button";
import { Textarea } from "@components/Textarea";
import cs from "./AddReviewPage.module.scss";

export const AddReviewPage = () => {
  const [targetCompanyName, setTargetCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [review, setReview] = useImmer({ rating: 0, comment: "" });

  const params = useParams();
  const offerId = params.offerId as string;

  const navigate = useNavigate();

  useEffect(() => {
    if (params.offerId) {
      getReviewTargetApi(offerId)
        .then((response) => {
          if (response.isSuccess) {
            setTargetCompanyName(response.data.companyName);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [params]);

  return (
    <main className="AddReviewPage">
      <header className="mb75">
        <h1 className="h3">Wystaw opinię</h1>
      </header>

      <section>
        <Spin spinning={isLoading}>
          <Card className={cs.card}>
            <Card.Header className="h4 text-center">
              Jak przebiegła współpraca z firmą {targetCompanyName}?
            </Card.Header>

            <Card.Body>
              <div className="mb16">
                <div>
                  <label>Ocena</label>
                </div>
                <Rate
                  value={review.rating}
                  allowHalf
                  onChange={(newVal) => {
                    setReview((review) => {
                      review.rating = newVal;
                    });
                  }}
                />
              </div>

              <Textarea
                value={review.comment}
                label="Komentarz"
                placeholder="Podziel się opinią na temat współpracy z tą firmą"
                rows={4}
                maxLength={1000}
                size="large"
                onChange={(newVal) => {
                  setReview((review) => {
                    review.comment = newVal;
                  });
                }}
              />
            </Card.Body>

            <Card.Footer>
              <Button type="primary" size="large" onClick={handleSubmit}>
                Wystaw opinię
              </Button>
            </Card.Footer>
          </Card>
        </Spin>
      </section>
    </main>
  );

  async function handleSubmit() {
    setIsLoading(true);

    const response = await addReviewApi(offerId, review);

    if (response.isSuccess) {
      notification.success({
        message: "Opinia została wystawiona. Dziękujemy!",
        placement: "topRight",
        duration: 4,
      });

      navigate("/");
    } else if (response.error === "REVIEW_ALREADY_EXISTS") {
      notification.error({
        message: "Możesz dodać tylko jedną opinię.",
        description: `Twoja firma dodała już opinię firmie ${targetCompanyName}.`,
        placement: "topRight",
        duration: 8,
      });

      setIsLoading(false);
    } else {
      notification.error({
        message: "Wystąpił błąd.",
        description: "Spróbuj ponownie za jakiś czas.",
        placement: "topRight",
      });

      setIsLoading(false);
    }
  }
};
