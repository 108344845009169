import { Card } from "@components/Card";
import { Button } from "@components/Button";

export const ActivateProviderSection = () => (
  <section>
    <Card className="mb75">
      <Card.Header className="h5">Moduł Usługodawcy</Card.Header>

      <Card.Body className="body">
        <span>
          Aby wyszukiwać zlecenia i składać na nie oferty Twoja firma musi
          aktywować subskrypcję. Wybierz Plan odpowiedni dla Ciebie.
        </span>
      </Card.Body>

      <Card.Footer className="text-right">
        <Button
          type="primary"
          size="large"
          href="/account/subscription"
          style={{ display: "inline-block" }}
        >
          Wybierz Plan
        </Button>
      </Card.Footer>
    </Card>
  </section>
);
