import axios from "axios";
import { useEffect, useState } from "react";
import { Notification } from "@customTypes/notification";

const apiUrl = "/api/notifications";

export function useNotifications() {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    getNotificationsApi().then((response) => {
      setNotifications(response.notifications || []);
    });
  }, []);

  return [notifications, setNotifications];
}

async function getNotificationsApi() {
  try {
    const response = await axios.get(apiUrl);

    return {
      isSuccess: response.status === 200,
      notifications: response.data,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}

export async function markNotificationsAsSeenApi() {
  try {
    const response = await axios.put(apiUrl);

    return {
      isSuccess: response.status === 200,
    };
  } catch (errorWrapper) {
    return {
      isSuccess: false,
      error: errorWrapper.response.data,
    };
  }
}
