import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";

export const columns = ({
  currentUserId,
  noOfAvailableLicenses,
  onSubscriptionChange,
  onDeleteUser,
}) => [
  {
    title: "Nazwa",
    field: "name",
    render: (value, _, row) => {
      return (
        <div>
          <div>{row.name}</div>
          <div className="caption">{row.email}</div>
        </div>
      );
    },
  },
  {
    title: "Panel usługodawcy",
    field: "subscription",
    width: 160,
    render: (value: any, _: any, row: any) => (
      <Checkbox
        checked={value}
        disabled={!value && noOfAvailableLicenses === 0}
        onChange={(e) => onSubscriptionChange(row.user_id, e.target.checked)}
      />
    ),
  },
  {
    title: "Usuń użytkownika",
    field: "user_id",
    width: 126,
    render: (value: any) =>
      value !== currentUserId ? (
        <Button
          type="secondary"
          size="small"
          onClick={() => onDeleteUser(value)}
        >
          Usuń
        </Button>
      ) : null,
  },
];
