import { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Popover } from "antd";
import { useStore } from "@store";
import { Notifications } from "@components/Notifications";
import { Menu } from "@components/Menu";
import { UserDetails } from "./UserDetails";
import buildingImg from "./img/building.svg";
import avatarImg from "./img/avatar.svg";
import chevronDownImg from "./img/chevron_down.svg";
import mobileMenuImg from "./img/mobile_menu.svg";
import cs from "./AppBar.module.scss";

export const AppBar = () => {
  const [open, setOpen] = useState(false);

  const user = useStore((state) => state.user);

  return (
    <header className={`AppBar ${cs.root}`}>
      <Link to="/">
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-white.svg"}
          alt="4dustry logo"
          width={108}
        />
      </Link>

      <div className={cs.company}>
        <img src={buildingImg} alt="company" width={16} height={16} />
        <span className={cs.company_name}>{user.organization_name}</span>
      </div>

      <Notifications />

      <Popover
        placement="bottomLeft"
        content={<UserDetails user={user} />}
        trigger="click"
        overlayClassName={cs.userPopover}
        arrowContent={null}
      >
        <span className={cs.avatar}>
          <img src={avatarImg} alt="avatar" />
          <img
            src={chevronDownImg}
            alt="arrow down"
            style={{ marginLeft: 4 }}
          />
        </span>
      </Popover>

      <img
        src={mobileMenuImg}
        alt=""
        className={cs.mobileMenu}
        onClick={() => {
          setOpen(true);
        }}
      />

      <Drawer
        title="Basic Drawer"
        placement="left"
        closable={false}
        visible={open}
        className={cs.mobileMenuDrawer}
        onClose={() => setOpen(false)}
      >
        <Menu onLinkClicked={() => setOpen(false)} />
      </Drawer>
    </header>
  );
};
