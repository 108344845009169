import { useEffect, useState } from "react";
import { getTechnologyNames } from "@helpers/technology";
import { Chip } from "@components/Chip";
import { TechnologiesSelect } from "@components/TechnologiesSelect";
import { Btn } from "../../../../../Customer/views/NewOrderPage/Elements/Element/Element";
import cs from "./Technologies.module.scss";

export const Technologies = ({ service, readOnly, onChange }) => {
  const [isEdited, setIsEdited] = useState(false);
  const [selectedTechIdCopy, setSelectedTechIdCopy] = useState<number[]>([]);

  useEffect(() => {
    setSelectedTechIdCopy(service.technologyIds);
  }, [service]);

  return (
    <div className="mt16">
      <div className="caption bold color-black mb8">Technologie:</div>

      {isEdited ? (
        <>
          <TechnologiesSelect
            value={selectedTechIdCopy}
            serviceId={service._id}
            size="small"
            className={cs.select}
            onChange={setSelectedTechIdCopy}
          />

          {selectedTechIdCopy.length > 0 && (
            <Btn onClick={handleSave}>
              <span className="bold">Zapisz</span>
            </Btn>
          )}
        </>
      ) : (
        <div className={cs.Services}>
          {getTechnologyNames(service.technologyIds).map((techName) => (
            <Chip color="gray" size="caption">
              {techName}
            </Chip>
          ))}

          {!readOnly && (
            <Btn onClick={() => setIsEdited(true)}>
              <span className="bold">Edytuj</span>
            </Btn>
          )}
        </div>
      )}
    </div>
  );

  function handleSave() {
    setIsEdited(false);

    onChange(service._id, selectedTechIdCopy);
  }
};
