import { ORDER_STATUSES } from "../../constants/ORDER_STATUSES";
import { getOrderStatusNameById } from "@helpers/getOrderStatusNameById";
import { getOrderStatusColorById } from "@helpers/getOrderStatusColorById";
import { Chip } from "@components/Chip";
import { ChipSize } from "@components/Chip/Chip";

type Props = {
  status: ORDER_STATUSES;
  size: ChipSize;
};

export const OrderStatus = ({ status, size }: Props) => (
  <Chip color={getOrderStatusColorById(status)} size={size}>
    {getOrderStatusNameById(status)}
  </Chip>
);
