import { getServiceNameById } from "@services/technology.helpers";
import { Chip } from "@components/Chip";

export const Machines = ({ selectedMachines }) => {
  return selectedMachines.map(({ service_id, machine_id, machine_name }) => {
    const serviceName = getServiceNameById(service_id);

    if (!machine_id) {
      return <Machines.NotFound key={serviceName} serviceName={serviceName} />;
    }

    return (
      <Chip key={service_id} color="gray" size="caption" className="mb4">
        <span>
          <strong>{machine_name || "Cennik domyślny"} </strong>
          <span style={{ fontWeight: 400 }}>({serviceName})</span>
        </span>
      </Chip>
    );
  });
};

Machines.NotFound = ({ serviceName }: { serviceName: string }) => (
  <Chip color="light-red" size="caption" className="mb4">
    <div style={{ fontWeight: 400 }}>
      <div>Brak odpowiedniego cennika </div>
      <div className="color-dark-gray">({serviceName})</div>
    </div>
  </Chip>
);
