import { CSSProperties } from "react";
import classNames from "classnames";
import { store } from "@store";
import { Select } from "@components/Select";
import cs from "./ServicesSelect.module.scss";

type Props = {
  value: number[] | null;
  className?: string;
  style?: CSSProperties;
  size?: "default" | "small" | "large";
  placeholder?: string;
  onChange: (selectedTechnologyIds: number[]) => void;
};

export const ServicesSelect = ({
  value,
  className,
  style,
  size = "default",
  placeholder,
  onChange,
}: Props) => {
  const availableServices = store.hooks.useServices();

  const rootCs = classNames("ServicesSelect", cs.ServicesSelect, className);

  return (
    <Select
      value={value}
      mode="multiple"
      showSearch
      style={style}
      className={rootCs}
      size={size}
      placeholder={placeholder}
      options={availableServices.map((service) => ({
        value: service._id,
        label: service.name,
      }))}
      onChange={onChange}
    />
  );
};
