import { useState } from "react";
import { getTechnologyIdsByService } from "@services/technology.helpers";
import { handleNewMaterialSet } from "../../../../services/element.helpers";
import { TotalNoOfCopiesModal } from "./TotalNoOfCopiesModal";
import { RemarksModal } from "./RemarksModal";
import { MaterialModal } from "./MaterialModal";
import { ServicesModal } from "./ServicesModal";
import { ElementMin } from "./ElementMin";
import { ElementFull } from "./ElementFull";
import cs from "./Element.module.scss";

export const Element = ({
  order,
  element,
  noOfSets,
  isSelected,
  listType,
  onPropertyChanged,
  onReplaceElement,
  onSelectionChange,
  onShowDetails,
}) => {
  const [
    isTotalNoOfElementCopiesModalOpened,
    setIsTotalNoOfElementCopiesModalOpened,
  ] = useState(false);
  const [isRemarksModalOpened, setIsRemarksModalOpened] = useState(false);
  const [isMaterialModalOpened, setIsMaterialModalOpened] = useState(false);
  const [isServicesModalOpened, setIsServicesModalOpened] = useState(false);

  const { _id, total_amount, services, remarks } = element;

  return (
    <>
      {listType === "full" ? (
        <ElementFull
          order={order}
          element={element}
          noOfSets={noOfSets}
          isSelected={isSelected}
          onChange={handlePropertyChanged}
          onShowDetails={() => onShowDetails(element)}
          onShowMaterial={() => setIsMaterialModalOpened(true)}
          onShowServices={() => setIsServicesModalOpened(true)}
          onShowRemarks={() => setIsRemarksModalOpened(true)}
          onShowNoOfElementCopies={() =>
            setIsTotalNoOfElementCopiesModalOpened(true)
          }
          onSelectionChange={onSelectionChange}
        />
      ) : (
        <ElementMin
          element={element}
          onShowDetails={() => onShowDetails(element)}
          noOfSets={noOfSets}
          isSelected={isSelected}
          onChange={handlePropertyChanged}
          onShowNoOfElementCopies={() =>
            setIsTotalNoOfElementCopiesModalOpened(true)
          }
          onSelectionChange={onSelectionChange}
          onShowMaterial={() => setIsMaterialModalOpened(true)}
        />
      )}

      <TotalNoOfCopiesModal
        isOpen={isTotalNoOfElementCopiesModalOpened}
        totalNumber={total_amount}
        onConfirm={handlePropertyChanged}
        onClose={() => setIsTotalNoOfElementCopiesModalOpened(false)}
      />

      <RemarksModal
        isOpen={isRemarksModalOpened}
        remarks={remarks}
        onConfirm={handlePropertyChanged}
        onClose={() => setIsRemarksModalOpened(false)}
      />

      <MaterialModal
        isOpen={isMaterialModalOpened}
        onConfirm={handleMaterialChanged}
        onClose={() => setIsMaterialModalOpened(false)}
      />

      <ServicesModal
        isOpen={isServicesModalOpened}
        isMaterialSelected={element.material_id || element.material_type_id}
        services={services}
        onMaterialSelected={handleMaterialChanged}
        onServicesSelected={handleServicesChanged}
        onClose={() => setIsServicesModalOpened(false)}
      />
    </>
  );

  function handlePropertyChanged(property: string, newValue: any) {
    onPropertyChanged(_id, property, newValue);
  }

  function handleMaterialChanged(selectedMaterial: any) {
    const updatedElement = handleNewMaterialSet(element, selectedMaterial);

    onReplaceElement(updatedElement);
  }

  function handleServicesChanged(selectedServiceIds: number[]) {
    handlePropertyChanged(
      "services",
      selectedServiceIds.map((serviceId) => ({
        _id: serviceId,
        technologyIds: getTechnologyIdsByService(serviceId),
      }))
    );
  }
};

// TODO:
export const Btn = ({ children, onClick }) => {
  return (
    <button
      type="button"
      className={`${cs.Btn} caption color-primary`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
