import { useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { useCurrentAccount } from "@services/account.hooks";
import { deletePricingApi, savePricingApi } from "@services/pricing.api";
import { saveCompanyDetailsApi } from "@services/account.api";
import { groupByService } from "@services/pricing.helpers";
import { store, useStore } from "@store";
import { Button } from "@components/Button";
import { Painting } from "./services/Painting";
import { Surfaces } from "./services/Surfaces";
import { WithMachinesAndMaterials } from "./services/WithMachinesAndMaterials";
import {
  AdditionalMetalProcessing,
  AdditionalWoodProcessing,
  AdditionalPlasticProcessing,
} from "./services/AdditionalProcessing";
import { SelectServiceModal } from "./SelectServiceModal";
import { AdditionalInfo } from "./AdditionalInfo";
import cs from "./PricesPage.module.scss";

const ADDITIONAL_METAL_SERVICES = [123, 124, 125, 126, 127, 128];
const ADDITIONAL_PLASTIC_SERVICES = [129, 131, 132];
const ADDITIONAL_WOOD_SERVICES = [133, 135, 136];

export const PricesPage = () => {
  const [priceLists, setPriceLists] = useImmer(new Map());
  const [serviceToRefresh, setServiceToRefresh] = useState<number | null>(null);

  const selectServiceModal = useRef(null);

  const account = useCurrentAccount();
  const priceListsStore = store.hooks.usePriceLists();
  const availableServices = store.hooks.useServices();

  useEffect(() => {
    const data = groupByService(priceListsStore);

    setPriceLists((prev) => {
      data.forEach((servicePricing) => {
        // NOTE: Refresh only service that was modified and saved
        if (
          !serviceToRefresh ||
          serviceToRefresh === servicePricing.service._id
        ) {
          prev.set(servicePricing.service._id, servicePricing);
        }
      });

      prev.forEach((_, serviceId) => {
        if (!data.some(({ service }) => service._id === serviceId)) {
          prev.delete(serviceId);
        }
      });
    });
  }, [priceListsStore]);

  const priceListsArray = Array.from(priceLists.values());

  const additionalMetalServices = priceListsArray.filter((priceList) => {
    return ADDITIONAL_METAL_SERVICES.includes(priceList.service._id);
  });

  const additionalPlasticServices = priceListsArray.filter((priceList) => {
    return ADDITIONAL_PLASTIC_SERVICES.includes(priceList.service._id);
  });

  const additionalWoodServices = priceListsArray.filter((priceList) => {
    return ADDITIONAL_WOOD_SERVICES.includes(priceList.service._id);
  });

  const standardServices = priceListsArray.filter((priceList) => {
    return !ADDITIONAL_METAL_SERVICES.concat(ADDITIONAL_PLASTIC_SERVICES)
      .concat(ADDITIONAL_WOOD_SERVICES)
      .includes(priceList.service._id);
  });

  const isEmpty = priceListsArray.length === 0;

  return (
    <>
      <main className={cs.PricesPage}>
        <div className={cs.main}>
          {isEmpty ? (
            <Empty onSelectService={selectService} />
          ) : (
            <section className={cs.priceLists}>
              {standardServices.map((priceList) => {
                if ([43, 59].includes(priceList.service._id)) {
                  return (
                    <Painting
                      key={priceList.service._id}
                      priceList={priceList}
                      onChange={(updatedPricelist) => {
                        handlePriceListChanged(
                          priceList.service._id,
                          updatedPricelist
                        );
                      }}
                      onRemove={handlePriceListRemoved}
                    />
                  );
                }

                if ([44, 45, 46, 47, 48, 49].includes(priceList.service._id)) {
                  return (
                    <Surfaces
                      key={priceList.service._id}
                      priceList={priceList}
                      onChange={(updatedPricelist) => {
                        handlePriceListChanged(
                          priceList.service._id,
                          updatedPricelist
                        );
                      }}
                      onRemove={handlePriceListRemoved}
                    />
                  );
                }

                return (
                  <WithMachinesAndMaterials
                    key={priceList.service._id}
                    priceList={priceList}
                    onChange={(updatedPricelist, save) => {
                      handlePriceListChanged(
                        priceList.service._id,
                        updatedPricelist,
                        save
                      );
                    }}
                    onRemove={handlePriceListRemoved}
                  />
                );
              })}

              {additionalMetalServices.length > 0 && (
                <AdditionalMetalProcessing
                  additionalMetalServices={additionalMetalServices}
                  onChange={handlePriceListChanged}
                  onRemove={handlePriceListRemoved}
                />
              )}

              {additionalPlasticServices.length > 0 && (
                <AdditionalPlasticProcessing
                  additionalServices={additionalPlasticServices}
                  onChange={handlePriceListChanged}
                  onRemove={handlePriceListRemoved}
                />
              )}

              {additionalWoodServices.length > 0 && (
                <AdditionalWoodProcessing
                  additionalServices={additionalWoodServices}
                  onChange={handlePriceListChanged}
                  onRemove={handlePriceListRemoved}
                />
              )}

              <Button
                type="primary"
                size="large"
                style={{ alignSelf: "flex-start" }}
                onClick={selectService}
              >
                Dodaj usługę
              </Button>
            </section>
          )}

          <AdditionalInfo
            margin={account?.price_margin}
            onMarginSave={handleMarginSaved}
          />
        </div>
      </main>

      <SelectServiceModal ref={selectServiceModal} />
    </>
  );

  async function selectService() {
    const selectedServiceIds = priceListsArray.reduce((sum, priceList) => {
      return [...sum, priceList.service._id];
    }, []);

    const serviceId = await selectServiceModal.current.openModal(
      selectedServiceIds
    );

    if (serviceId) {
      const service = availableServices.find(({ _id }) => _id === serviceId);

      setPriceLists((prev) => {
        if (!prev.get(serviceId)) {
          prev.set(serviceId, {
            service,
            pricings: [
              {
                is_default: true,
              },
            ],
          });
        }
      });
    }
  }

  async function handlePriceListChanged(
    serviceId,
    updatedPricelist,
    save = true
  ) {
    setPriceLists((prev) => {
      prev.set(serviceId, updatedPricelist);
    });

    if (save) {
      const { isSuccess } = await savePricingApi(
        createSaveObj(updatedPricelist)
      );

      if (isSuccess) {
        setServiceToRefresh(serviceId);

        // NOTE: Refresh store
        await useStore.getState().fetchPriceLists();
      }
    }
  }

  async function handlePriceListRemoved(priceListIds) {
    const { isSuccess } = await deletePricingApi(priceListIds);

    if (isSuccess) {
      // NOTE: Refresh store
      await useStore.getState().fetchPriceLists();
    }
  }

  async function handleMarginSaved(updatedMargin: number) {
    await saveCompanyDetailsApi({
      ...account,
      price_margin: updatedMargin,
    });
  }

  function createSaveObj(perService) {
    return {
      serviceId: perService.service._id,
      pricings: perService.pricings.map((perMachine) => ({
        group_id: perMachine.group_id,
        machineIds: perMachine.machines,
        is_default: perMachine.is_default,
        prices: perMachine.prices,
        perMaterials:
          perMachine.perMaterials?.map((perMaterial) => ({
            prices: perMaterial.prices,
            materialTypeIds: perMaterial.materialTypes.map(({ _id }) => _id),
          })) || null,
      })),
    };
  }
};

const Empty = ({ onSelectService }) => (
  <section className={cs.Empty}>
    <div className="h5 mb16">Twój cennik jest pusty</div>

    <Button type="primary" size="medium" onClick={onSelectService}>
      Dodaj usługę
    </Button>
  </section>
);

export function genGroupId() {
  return Date.now() + "";
}
