import { useEffect, useState } from "react";
import { GTMAnalyser, SmartlookAnalyser } from "@services/analytics.helpers";

export class OrderingAnalyser {
  public static firstFileUploaded() {
    GTMAnalyser.trigger("ordering_first_upload");
  }

  public static firstFileAnalysed() {
    GTMAnalyser.trigger("ordering_first_analysed");
    SmartlookAnalyser.trigger("ordering_start");
  }

  public static orderCreated() {
    GTMAnalyser.trigger("ordering_created");
  }
}

// NOTE: Trigger this event only once
export function useFirstFileAnalysed(mode) {
  const [firstAnalysed, setFirstAnalysed] = useState(false);

  useEffect(() => {
    if (firstAnalysed && mode.isNew) {
      OrderingAnalyser.firstFileAnalysed();
    }
  }, [firstAnalysed]);

  return setFirstAnalysed;
}

// NOTE: Trigger this event only once
export function useFirstFileUploaded(noOfElements: number) {
  const [firstUploaded, setFirstUploaded] = useState(false);

  useEffect(() => {
    if (firstUploaded && noOfElements === 0) {
      OrderingAnalyser.firstFileUploaded();
    }
  }, [firstUploaded]);

  return setFirstUploaded;
}
