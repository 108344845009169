import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { notification } from "antd";
import {
  getUserDetailsFromInvitation,
  isAuthenticated,
  logOutApi,
  registerFromInvitationApi,
} from "@services/auth.api";
import { Card } from "@components/Card";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Baner } from "../../components/Baner";
import sharedCs from "../../styles.module.scss";
import cs from "./InvitedPage.module.scss";

export const InvitedPage = () => {
  const [form, setForm] = useImmer({});

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getUserDetailsFromInvitation(params.userId).then(({ data, isSuccess }) => {
      if (isSuccess) {
        setForm({ ...data });
      }
    });
  }, [params.userId]);

  const isUserAuthenticated = isAuthenticated();

  if (isUserAuthenticated) {
    logOutApi().then(() => {
      window.location.reload();
    });

    return null;
  }

  return (
    <main className={`InvitedPage ${cs.InvitedPage}`}>
      <Baner type="invited" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <form onSubmit={handleFormSubmited}>
          <Card>
            <Card.Header className="h5">Zarejestruj się</Card.Header>

            <Card.Body>
              <div className={sharedCs.formInputs}>
                <Input
                  value={form.first_name}
                  name="first_name"
                  label="Imię"
                  size="large"
                  required
                  onChange={handleFormChanged}
                />

                <Input
                  value={form.last_name}
                  name="last_name"
                  label="Nazwisko"
                  size="large"
                  required
                  onChange={handleFormChanged}
                />

                <Input
                  value={form.password}
                  name="password"
                  type="password"
                  label="Hasło"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={handleFormChanged}
                />

                <Input
                  value={form.password_2}
                  name="password_2"
                  type="password"
                  label="Powtórz hasło"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={handleFormChanged}
                />
              </div>
            </Card.Body>

            <Card.Footer>
              <Button
                type="primary"
                size="large"
                btnType="submit"
                style={{ width: "100%" }}
              >
                Zarejestruj się
              </Button>
            </Card.Footer>
          </Card>
        </form>

        <PrivacyPolicy />
      </section>
    </main>
  );

  function handleFormChanged(value: string, name: string) {
    setForm((prev) => void (prev[name] = value));
  }

  async function handleFormSubmited(e) {
    e.preventDefault();

    const { isSuccess, error } = await registerFromInvitationApi(
      params.userId,
      form
    );

    if (isSuccess) {
      notification.success({
        message: "Konto utworzone.",
        placement: "topRight",
      });

      setTimeout(() => {
        window.location.href = "/account";
      }, 800);
    } else if (error === "USER_ALREADY_EXISTS") {
      notification.error({
        message: (
          <span>
            Konto zostało już wcześniej zarejestrowane.{" "}
            <span
              style={{ color: "#4c83c3", fontWeight: 500, cursor: "pointer" }}
              onClick={handleLogInClicked}
            >
              Zaloguj się.
            </span>
          </span>
        ),
        placement: "topRight",
        duration: null,
      });
    } else {
      notification.error({
        message: "Wystąpił błąd.",
        placement: "topRight",
      });
    }

    ///

    function handleLogInClicked(e) {
      e.preventDefault();
      navigate("/login");

      document.querySelector(".ant-notification-notice-close")?.click();
    }
  }
};
