import { ReactNode } from "react";
import { Alert } from "./Alert";
import exclamationImg from "./img/exclamation_triangle.svg";

type Props = {
  title: ReactNode;
  text?: ReactNode;
  className?: string;
};

export const WarningAlert = ({ title, text, className }: Props) => (
  <Alert
    title={title}
    text={text}
    background="#FFEAA7"
    icon={<img src={exclamationImg} alt="" />}
    className={className}
  />
);
