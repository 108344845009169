import { toLocaleDate } from "@helpers/dateUtils";
import { getOfferMainStatusTag } from "@helpers/getOfferMainStatusTag";
import { getOfferAddStatusTag } from "@helpers/getOfferAddStatusTag";
import { getShortUsername } from "@helpers/utils";
import { OFFER_STATUSES } from "../../../constants/OFFER_STATUSES";
import { TillEndChips } from "@components/TillEndChips";

export const columns = (isAdmin) => {
  const cols = [
    {
      title: "Firma",
      field: "account",
      width: 175,
      render: (value: any) => value.name,
    },
    {
      title: "Status",
      field: "status",
      sortable: true,
      render: (value: any) => {
        return getOfferMainStatusTag(value, "small");
      },
    },
    {
      title: isAdmin ? "Oferta" : "Twoja oferta",
      field: "offer_sum",
      render: (value: any, _: any, row: any) => (
        <div>
          <div
            style={{
              color:
                row.status === OFFER_STATUSES.CANCELLED ? "#B2BEC3" : "inherit",
            }}
          >
            {value} PLN
          </div>

          {getOfferAddStatusTag(row.status, "small", "mt2")}
        </div>
      ),
    },
    {
      title: "Data złożenia oferty",
      field: "created_at",
      sortable: true,
      render: (value: any, _: any, row: any) => (
        <div>
          <div>{toLocaleDate(value)}</div>

          {(row.status === OFFER_STATUSES.ONGOING ||
            row.status === OFFER_STATUSES.ORDER_EDITED) &&
            row.offer_period && (
              <TillEndChips
                date={row.offer_period}
                size="small"
                className="mt2"
              />
            )}
        </div>
      ),
    },
    {
      title: "Termin realizacji",
      field: "done_deadline",
      sortable: true,
      type: "date",
    },
    {
      title: "Ilość kompletów",
      field: "total_amount",
      type: "text",
    },
  ];

  if (isAdmin) {
    cols.splice(3, 0, {
      title: "Składający",
      field: "owner_name",
      render(value) {
        return getShortUsername(value);
      },
    });
  }

  return cols;
};
