export const NotAvailableOnMobile = () => (
  <section
    className="body p20 pt40 mt40 text-center show-phone"
    style={{ display: "none" }}
  >
    <img
      src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
      alt="4dustry"
      width="180px"
      className="mb30"
    />

    <div className="ml-auto mr-auto">
      <p>
        Funkcjonalność nie jest jeszcze przystosowana do działania na
        urządzeniach mobilnych.
      </p>
    </div>

    <img
      src={process.env.PUBLIC_URL + "/robot.png"}
      alt="4dustry"
      className="mt20"
    />

    <div className="mb40 mt40 ml-auto mr-auto">
      Pozdrawiamy zespół <br />
      4Dustry
    </div>
  </section>
);
