import { getAddress } from "@helpers/getAddress";
import { Card } from "@components/Card";
import { WithCompanyModal } from "@components/CompanyModal";

export const CustomerDetails = ({ ownerCompany, owner }) => {
  return (
    <Card>
      <Card.Header className="h5">Zlecający</Card.Header>
      <Card.Body className="body">
        <div>
          <div className="mb20">
            <div className="bold mb2">{owner.name}</div>
            <div>{owner.position}</div>

            {(owner.phone || owner.contact_email) && (
              <div>
                <span>Kontakt: </span>
                <a href={`tel:${owner.phone}`} className="link">
                  {owner.phone}
                </a>

                {owner.contact_email && (
                  <>
                    <span> | </span>
                    <a href={`mailto:${owner.contact_email}`} className="link">
                      {owner.contact_email}
                    </a>
                  </>
                )}
              </div>
            )}
          </div>

          <WithCompanyModal>
            {(openModal) => (
              <>
                <div
                  className="semi-bold clickable mb2"
                  onClick={() => openModal(ownerCompany._id)}
                >
                  {ownerCompany.name}
                </div>
              </>
            )}
          </WithCompanyModal>

          <div>
            <span>{getAddress(ownerCompany)}</span>
          </div>

          <div>NIP: {ownerCompany.tax_number}</div>

          {(ownerCompany.phone || ownerCompany.email) && (
            <div>
              <span>Kontakt: </span>
              <a href={`tel:${ownerCompany.phone}`} className="link">
                {ownerCompany.phone}
              </a>

              {ownerCompany.phone && ownerCompany.email && <span> | </span>}

              <a href={`mailto:${ownerCompany.email}`} className="link">
                {ownerCompany.email}
              </a>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
