import produce from "immer";
import * as Sentry from "@sentry/react";
import { getCurrentUserDetailsApi } from "@services/auth.api";
import { StoreState, useStore } from "@store";

export type UserSlice = {
  user: any;
  fetchCurrentUser: () => Promise<void>;
  updateOrganizationName: (newName: string) => void;
};

export const userSlice = (set: any): UserSlice => ({
  user: {},
  fetchCurrentUser: async () => {
    const { data, isSuccess } = await getCurrentUserDetailsApi();

    if (isSuccess) {
      // TODO: Not the best place
      Sentry.setUser({ id: data._id });

      set({ user: data }, false, "user/fetchCurrentUser");
    }
  },
  updateOrganizationName: (newName) => {
    const updateFn = produce((state) => {
      state.user.organization_name = newName;
    });

    set(updateFn, false, "user/updateOrganizationName");
  },
});

export const selectors = {
  updateOrganizationName: (state: StoreState) => state.updateOrganizationName,
};

export const hooks = {
  useUser() {
    const user = useStore((state) => state.user);

    return user;
  },

  // NOTE: Check if user's not been deleted from his company
  useUserHasCompany() {
    const { is_deleted } = useStore((state) => state.user);

    return !is_deleted;
  },

  usePendingVerification() {
    const { access_allowed } = useStore((state) => state.user);

    return !access_allowed;
  },
};
