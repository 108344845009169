import TagManager from "react-gtm-module";

export class GTMAnalyser {
  static trigger(eventName: string) {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
      },
    });
  }
}

export class SmartlookAnalyser {
  static trigger(eventName: string) {
    if (window.smartlook) {
      window.smartlook("track", eventName);
    }
  }
}
