import { Card } from "@components/Card";
import fileImg from "./img/file.svg";
import cs from "./AdditionalFiles.module.scss";

type Props = {
  files: File[];
};

export const AdditionalFiles = ({ files = [] }: Props) => (
  <section className="AdditionalFiles">
    <Card>
      <Card.Header>
        <h2 className="h5">
          Dodatkowe pliki projektowe ({files?.length || 0})
        </h2>
      </Card.Header>

      <Card.Body>
        <div>
          {files?.length > 0 && (
            <ul className={cs.files}>
              {files?.map((file: any) => (
                <li key={file.name} className="mb8">
                  <img src={fileImg} />
                  <span className="body bold ml8">{file.name}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Card.Body>
    </Card>
  </section>
);
