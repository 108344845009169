import { Card } from "@components/Card";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Baner } from "../../../Auth/components/Baner";
import cs from "./OrderCreatedInfo.module.scss";

export const OrderCreatedInfo = () => {
  return (
    <main className={cs.root}>
      <Baner type="register" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
        />

        <Card className={cs.card}>
          <Card.Header className="h5">Zapytanie zostało utworzone</Card.Header>

          <Card.Body>
            <div className="body">
              <p>
                Twoje zapytanie czeka na weryfikację. Po akceptacji zostanie
                zamieszczone na platformie. Powiadomienia o nowych ofertach
                zostaną wysłane na podany przez Ciebie adres email.
              </p>
              <br />
              <p>
                Aby zarządzać swoim zapytaniem, zaloguj się do platformy. Dane
                do logowania znajdziesz w wiadomości email.
              </p>
            </div>
          </Card.Body>
        </Card>

        <PrivacyPolicy />
      </section>
    </main>
  );
};
