import closeImg from "./img/x_square.svg";
import cs from "./HowToCard.module.scss";

export const HowToCard = ({ onClose }) => (
  <div className={cs.HowToCard}>
    <div className={cs.header}>
      <span>Prześlij swoje pliki</span>
      <img src={closeImg} alt="" onClick={onClose} />
    </div>

    <ul>
      <li>
        Akceptujemy następujące formaty plików (.ipt; .sldprt; .step; .stp;
        .prt; .dxf; .dwg; .jpg; .png; .pdf)
      </li>
      <li>
        Dodając kilka plików o tej samej nazwie zostaną one automatycznie
        złączone i przydzielone do jednego elementu
      </li>
    </ul>
  </div>
);
