import { Counter } from "@components/Counter";

export const Amount = ({ amount, isEditable, onChange }) => (
  <>
    <div className="caption mb4">Ilość szt. w komplecie:</div>

    {isEditable ? (
      <Counter
        value={amount}
        onChange={(newValue) => {
          onChange("amount", newValue);
        }}
      />
    ) : (
      <span className="caption bold color-black">{amount} szt.</span>
    )}
  </>
);
