import { Modal } from "@components/Modal";
import cs from "./InfoModal.module.scss";

export const InfoModal = ({ title, visible, children, onClose }) => {
  return (
    <Modal
      title={title}
      width={480}
      className={cs.InfoModal}
      visible={visible}
      onClose={onClose}
    >
      {children}
    </Modal>
  );
};
