import { useEffect, useState } from "react";
import {
  getAccountByIdApi,
  getAccountDetailsForCurrentUser,
  getUsersOfCurrentCompany,
} from "./account.api";
import { compact } from "@helpers/utils";
import { Account } from "./account.types";

export function useAccount(accountId: string) {
  const [account, setAccount] = useState<Account | null>(null);

  useEffect(() => {
    if (!accountId) return;

    (async () => {
      const account = await getAccountByIdApi(accountId);

      setAccount(account);
    })();
  }, [accountId]);

  return account;
}

export function useCurrentAccount() {
  const [account, setAccount] = useState<Account | null>(null);

  useEffect(() => {
    (async () => {
      const account = await getAccountDetailsForCurrentUser();

      setAccount(account);
    })();
  }, []);

  return account;
}

export function useUsersOfCurrentCompany(refreshToken: null | number = null) {
  const [users, setUsers] = useState<any[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const users = await getUsersOfCurrentCompany();
        setUsers(users);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [refreshToken]);

  return [users, isLoading, setUsers];
}
