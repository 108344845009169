import { ReactNode } from "react";
import classNames from "classnames";
import cs from "./Chip.module.scss";

export type ChipColor =
  | "green"
  | "purple"
  | "orange"
  | "red"
  | "light-red"
  | "gray"
  | "blue"
  | "ginger"
  | "dark-gray"
  | "#00B894"
  | "#6C5CE7"
  | "#D63031"
  | "#636E72"
  | "#4c83c3"
  | "#e17055";

export type ChipSize = "small" | "caption" | "body";

type Props = {
  color: ChipColor;
  size?: ChipSize;
  className?: string;
  children: ReactNode;
  onClick?: () => void;
};

export const Chip = ({
  color,
  size = "small",
  className,
  children,
  onClick = () => {},
}: Props) => {
  const rootCs = classNames("Chip", cs.Chip, className, {
    [cs.small]: size === "small",
    [cs.caption]: size === "caption",
    [cs.body]: size === "body",
    [cs.green]: color === "green" || color === "#00B894",
    [cs.purple]: color === "purple" || color === "#6C5CE7",
    [cs.orange]: color === "orange",
    [cs.red]: color === "red" || color === "#D63031",
    [cs.lightRed]: color === "light-red",
    [cs.gray]: color === "gray",
    [cs.darkGray]: color === "dark-gray" || color === "#636E72",
    [cs.blue]: color === "blue" || color === "#4c83c3",
    [cs.ginger]: color === "ginger" || color === "#e17055",
  });

  return (
    <div className={rootCs} onClick={onClick}>
      {children}
    </div>
  );
};
