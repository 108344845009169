import { getServiceNameById } from "@services/technology.helpers";
import { InputNumber } from "@components/InputNumber";
import { Technologies } from "../Technologies";
import twoDimensionalImg from "./img/2dView.png";
import cs from "./SheetCutting.module.scss";

export const SheetCutting = ({
  params,
  service,
  readOnly,
  onTechnologiesChange,
  onChange,
}) => {
  return (
    <div>
      <div className="h4 mb24">{getServiceNameById(service._id)}</div>

      <div className={cs.main}>
        <div className={cs.imgContainer}>
          <img src={twoDimensionalImg} alt="" />
        </div>
        <div className={cs.inputs}>
          <div>
            <span>Szerokość</span>
            <InputNumber
              size="small"
              value={params.sheet_metal_width}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("sheet_metal_width", value)}
            />
            <span>mm</span>
          </div>
          <div>
            <span>Długość</span>
            <InputNumber
              size="small"
              value={params.sheet_metal_length}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("sheet_metal_length", value)}
            />
            <span>mm</span>
          </div>
          <div>
            <span>Grubość materiału</span>
            <InputNumber
              size="small"
              value={params.thickness}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("thickness", value)}
            />
            <span>mm</span>
          </div>
          <div>
            <span>Długość linii cięcia</span>
            <InputNumber
              size="small"
              value={params.sheet_metal_lenght_cut_line}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) =>
                onChange("sheet_metal_lenght_cut_line", value)
              }
            />
            <span>mm</span>
          </div>
          <div>
            <span>Ilość wpaleń</span>
            <InputNumber
              size="small"
              value={params.envelopes_count}
              min={1}
              step={1}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("envelopes_count", value)}
            />
          </div>
        </div>
      </div>

      <Technologies
        service={service}
        readOnly={readOnly}
        onChange={onTechnologiesChange}
      />
    </div>
  );
};
