import { useEffect } from "react";
import produce from "immer";
import { useImmer } from "use-immer";
import { SaveOutlined } from "@ant-design/icons";
import { InputNumber } from "@components/InputNumber";
import removeImg from "./img/remove.svg";
import cs from "./BendingRadiusToThicknessTable.module.scss";

export const BendingRadiusToThicknessTable = ({
  label,
  rows,
  readOnly,
  onChange,
}) => {
  const [newRow, setNewRow] = useImmer({
    scope: [0],
    values: [],
  });

  useEffect(() => {
    setNewRow((prev) => {
      const lastRow = rows[rows.length - 1];

      prev.scope = [lastRow?.scope[1] || 0];
    });
  }, [rows]);

  return (
    <div className="BendingRadiusToThicknessTable">
      <div className="caption mb4">{label}:</div>

      <table className={cs.bendingTable}>
        <thead>
          <tr>
            <th>Grubość</th>
            <th>Promień gięcia rmin(mm)</th>
            <th
              colSpan={4}
              style={{
                paddingRight: readOnly ? 12 : 4,
              }}
            >
              Dostępne promienie gięcia
            </th>

            {!readOnly && <th />}
          </tr>
        </thead>

        <tbody>
          {rows.map(({ scope, values }, idx) => (
            <tr key={scope.join(",")}>
              <td>
                {scope[0]} mm - {scope[1]} mm
              </td>
              <td>
                {readOnly ? (
                  values[0] || ""
                ) : (
                  <InputNumber
                    value={values[0] || ""}
                    min={0.1}
                    readOnly={readOnly}
                    size="small"
                    onChange={(value) => handleValueChange(scope[0], 0, value)}
                  />
                )}
              </td>
              <td>
                {readOnly ? (
                  [values.slice(1).join(", ")]
                ) : (
                  <InputNumber
                    value={values[1] || ""}
                    min={0}
                    readOnly={readOnly}
                    size="small"
                    onChange={(value) => handleValueChange(scope[0], 1, value)}
                  />
                )}
              </td>
              <td>
                {!readOnly && (
                  <InputNumber
                    value={values[2] || ""}
                    min={0}
                    readOnly={readOnly}
                    size="small"
                    onChange={(value) => handleValueChange(scope[0], 2, value)}
                  />
                )}
              </td>
              <td>
                {!readOnly && (
                  <InputNumber
                    value={values[3] || ""}
                    min={0}
                    readOnly={readOnly}
                    size="small"
                    onChange={(value) => handleValueChange(scope[0], 3, value)}
                  />
                )}
              </td>
              <td>
                {!readOnly && (
                  <InputNumber
                    value={values[4] || ""}
                    min={0}
                    readOnly={readOnly}
                    size="small"
                    onChange={(value) => handleValueChange(scope[0], 4, value)}
                  />
                )}
              </td>
              {!readOnly && (
                <td style={{ textAlign: "center" }}>
                  {idx === rows.length - 1 && (
                    <img
                      src={removeImg}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={removeLastRow}
                    />
                  )}
                </td>
              )}
            </tr>
          ))}

          {!readOnly && (
            <tr>
              <td style={{ textAlign: "center" }}>
                {newRow.scope[0]} mm -{" "}
                <InputNumber
                  value={newRow.scope[1] || ""}
                  min={+newRow.scope[0] + 1}
                  readOnly={readOnly}
                  size="small"
                  onChange={handleEndScopeChanged}
                />{" "}
                mm
              </td>
              <td>
                <InputNumber
                  value={newRow.values[0] || ""}
                  min={0}
                  readOnly={readOnly}
                  size="small"
                  onChange={(value) => {
                    handleNewScopeValueChanged(0, value);
                  }}
                />
              </td>
              <td>
                <InputNumber
                  value={newRow.values[1] || ""}
                  min={0}
                  readOnly={readOnly}
                  size="small"
                  onChange={(value) => {
                    handleNewScopeValueChanged(1, value);
                  }}
                />
              </td>
              <td>
                <InputNumber
                  value={newRow.values[2] || ""}
                  min={0}
                  readOnly={readOnly}
                  size="small"
                  onChange={(value) => {
                    handleNewScopeValueChanged(2, value);
                  }}
                />
              </td>
              <td>
                <InputNumber
                  value={newRow.values[3] || ""}
                  min={0}
                  readOnly={readOnly}
                  size="small"
                  onChange={(value) => {
                    handleNewScopeValueChanged(3, value);
                  }}
                />
              </td>
              <td>
                <InputNumber
                  value={newRow.values[4] || ""}
                  min={0}
                  readOnly={readOnly}
                  size="small"
                  onChange={(value) => {
                    handleNewScopeValueChanged(4, value);
                  }}
                />
              </td>

              <td style={{ textAlign: "center" }}>
                <div
                  style={{
                    color: "#5994d6",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  onClick={handleAddNewRow}
                >
                  <SaveOutlined />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  function handleAddNewRow() {
    let currentEndScope = newRow.scope[1];

    if (!currentEndScope) {
      currentEndScope = +newRow.scope[0] + 1;
    }

    onChange([
      ...rows,
      {
        ...newRow,
        scope: [newRow.scope[0], currentEndScope],
      },
    ]);

    setNewRow({
      scope: [currentEndScope],
      values: [],
    });
  }

  function removeLastRow() {
    onChange(rows.slice(0, rows.length - 1));

    setNewRow((prev) => {
      prev.scope[0] = rows[rows.length - 2]?.scope[1] || 0;
    });
  }

  function handleEndScopeChanged(newValue) {
    setNewRow({
      ...newRow,
      scope: [newRow.scope[0], +newValue],
    });
  }

  function handleValueChange(scopeStart, valueIndex, newValue) {
    const updatedRows = produce(rows, (draft) => {
      const pricing = draft.find(({ scope }) => scope[0] === scopeStart);

      pricing.values[valueIndex] = +newValue;
    });

    onChange(updatedRows);
  }

  function handleNewScopeValueChanged(valueIndex, newValue) {
    setNewRow((prev) => {
      prev.values[valueIndex] = +newValue;
    });
  }
};
