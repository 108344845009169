import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { store } from "@store";
import cs from "./Navigation.module.scss";

const { TabPane } = Tabs;

export const Navigation = () => {
  const [activeTab, setActiveTab] = useState("1");

  const subscription = store.hooks.useSubscription();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    switch (location.pathname) {
      case "/account":
        setActiveTab("/account");
        break;
      case "/account/users":
        setActiveTab("/account/users");
        break;
      case "/account/billings":
        setActiveTab("/account/billings");
        break;
      case "/account/machines":
        setActiveTab("/account/machines");
        break;
      case "/account/prices":
        setActiveTab("/account/prices");
        break;
    }
  }, [location]);

  const activatedSubscription = subscription && subscription.status !== "TRIAL";

  return (
    <div className={cs.Navigation}>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={(key) => {
          navigate(key);
        }}
      >
        <TabPane tab="Ogólne" key="/account" />
        <TabPane tab="Użytkownicy" key="/account/users" />
        {activatedSubscription && (
          <TabPane tab="Rachunki" key="/account/billings" />
        )}
        <TabPane
          tab="Park maszyn"
          key="/account/machines"
          className="hide-mobile"
        />
        <TabPane tab="Cennik" key="/account/prices" className="hide-mobile" />
      </Tabs>
    </div>
  );
};
