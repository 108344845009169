import { ORDER_STATUSES } from "../constants/ORDER_STATUSES";

export function getOrderStatusColorById(status: ORDER_STATUSES) {
  switch (status) {
    case ORDER_STATUSES.ONGOING:
      return "#00B894";
    case ORDER_STATUSES.CLOSED:
      return "#6C5CE7";
    case ORDER_STATUSES.CANCELLED:
      return "#D63031";
    case ORDER_STATUSES.UNRESOLVED:
      return "#636E72";
    case ORDER_STATUSES.DRAFT:
      return "#4c83c3";
    case ORDER_STATUSES.UNAPPROVED:
      return "#e17055";
    default:
      throw new Error(`Order status '${status}' unrecognized.`);
  }
}
