import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Switch } from "antd";
import { store, useStore } from "@store";
import { Button } from "@components/Button";
import { Select } from "@components/Select";
import { ServicesSelect } from "@components/ServicesSelect";
import { AdditionalFilterModal } from "./AdditionalFilterModal";
import { SlidersSvg } from "./img/SlidersSvg";
import cs from "./Filters.module.scss";

const sortOptions = [
  { label: "Od najnowszych", value: "created_at" },
  { label: "Ilość kompletów - od najwyższej", value: "total_amount" },
  { label: "Ilość kompletów - od najniższej", value: "-total_amount" },
];

export const Filters = ({ filters, sort, onFilterChange, onSortChange }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const user = store.hooks.useUser();
  const machines = useStore((state) => state.machinePark);

  const machineParkEmpty = machines.length === 0;

  useEffect(() => {
    if (filters.compatible === undefined && machines.length > 0) {
      onFilterChange("compatible", true);
    }
  }, [machines, filters.compatible]);

  const modalFiltersCount = countModalFilters(filters);

  return (
    <section className="Filters">
      <div className={cs.main}>
        <div className={cs.technologies}>
          <div className="caption bold color-black mb4">
            Technologia produkcji
          </div>

          <ServicesSelect
            value={filters.services}
            size="large"
            placeholder="Wybierz technologię produkcji"
            onChange={(value) => onFilterChange("services", value)}
          />
        </div>

        <Button
          type={modalFiltersCount === 0 ? "outline" : "primary"}
          size="medium"
          className={cs.openFiltersButton}
          onClick={() => setIsModalOpened(true)}
        >
          <SlidersSvg color={modalFiltersCount === 0 ? "#101212" : "#ffffff"} />
          <span className="ml8">Pokaż filtry</span>

          {modalFiltersCount !== 0 && <span>&nbsp;• {modalFiltersCount}</span>}
        </Button>

        <div className={cs.sort}>
          <div className="caption bold color-black mb4">Sortuj</div>

          <Select
            value={sort}
            options={sortOptions}
            placeholder="Wybierz"
            onChange={onSortChange}
          />
        </div>
      </div>

      {user.subscription && (
        <div className="mt16 d-flex align-center caption bold">
          <span
            className="d-flex align-center"
            style={{ opacity: machineParkEmpty ? 0.5 : 1 }}
          >
            <Switch
              checked={filters.compatible}
              disabled={machineParkEmpty}
              onChange={(value) => onFilterChange("compatible", value)}
            />

            <span className="ml12  color-dark-gray">
              Tylko zlecenia pasujące do Twojego Parku Maszyn (PM).
            </span>
          </span>

          {machineParkEmpty && (
            <Link to="/account/machines" className={`${cs.link} hide-mobile`}>
              Zdefiniuj park maszyn
            </Link>
          )}
        </div>
      )}

      <AdditionalFilterModal
        isOpen={isModalOpened}
        filters={filters}
        onClose={() => setIsModalOpened(false)}
        onFilterChange={onFilterChange}
      />
    </section>
  );

  function countModalFilters(filters) {
    let count = 0;

    if (filters.region) count++;
    if (filters.bidDeadline) count++;
    if (filters.doneDeadline) count++;

    return count;
  }
};
