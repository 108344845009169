import React, { ReactNode } from "react";
import classNames from "classnames";
import cs from "./Alert.module.scss";

type Props = {
  title: ReactNode;
  text?: ReactNode;
  className?: string;
  background: "#FFEAA7" | "#FF7675" | "#55EFC4";
  icon?: React.ReactNode;
};

export const Alert = ({ title, text, className, background, icon }: Props) => {
  const rootCs = classNames(cs.Alert, "Alert", className);

  return (
    <div className={rootCs} style={{ backgroundColor: background }}>
      {icon}

      <div className={cs.info_section}>
        <div className={cs.title}>{title}</div>

        {text && <div className="body">{text}</div>}
      </div>
    </div>
  );
};
