import { Link } from "react-router-dom";
import { Card } from "@components/Card";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Baner } from "../../components/Baner";
import sharedCs from "../../styles.module.scss";
import cs from "./LoginPage.module.scss";

export const LoginPagePure = ({ onChange, onSubmit }) => {
  return (
    <main className={`LoginPage ${cs.LoginPage}`}>
      <Baner />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <form onSubmit={onSubmit}>
          <Card>
            <Card.Header className="h5">Zaloguj się</Card.Header>

            <Card.Body>
              <div className={sharedCs.formInputs}>
                <div>
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    size="large"
                    required
                    onChange={onChange}
                  />
                </div>

                <div>
                  <Input
                    name="password"
                    type="password"
                    label="Hasło"
                    size="large"
                    required
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="text-right mt12">
                <Link to="/reset-password" className={sharedCs.link}>
                  Nie pamiętam hasła
                </Link>
              </div>
            </Card.Body>

            <Card.Footer>
              <Button
                type="primary"
                size="large"
                btnType="submit"
                style={{ width: "100%" }}
              >
                Zaloguj się
              </Button>

              <div className="caption mt24 text-center">
                <span>Nie masz konta? </span>
                <Link to="/register" className={sharedCs.link}>
                  Zarejestruj się
                </Link>
              </div>
            </Card.Footer>
          </Card>
        </form>

        <PrivacyPolicy />
      </section>
    </main>
  );
};
