import {
  _groupTechnologiesByService,
  connectFilesWithElement,
} from "@services/orders";
import { calcAvgRating } from "@services/review.helpers";
import { calculateCompatibility } from "./CompatibilityService";

export function prepareOrder(order: any) {
  // INFO: Owner can be obscured when data showed to user without a licence
  if (order.owner_company) {
    order.owner_company = {
      ...order.owner_company,
      avgRating: calcAvgRating(order.owner_company.reviews),
    };
  }

  return calculateCompatibility({
    ..._groupTechnologiesByService(
      addMetalDrillingIfMissing(connectFilesWithElement(order))
    ),
  });
}

function addMetalDrillingIfMissing(order) {
  return {
    ...order,
    elements: order.elements.map((element) => {
      const anyHoleService =
        element.technology_ids.includes(126) ||
        element.technology_ids.includes(127) ||
        element.technology_ids.includes(128);

      if (anyHoleService && !element.technology_ids.includes(125)) {
        element.technology_ids.push(125);

        // NOTE: If during order creation drilling service was initially present
        //       but disabled by the customer then drilledHoles are still present
        //       but should not take part in the offer just because we're artifically
        //       adding drilling service here.
        if (element.parameters.holes?.drilledHoles) {
          element.parameters.holes.drilledHoles = [];
        }
      }

      return {
        ...element,
      };
    }),
  };
}
