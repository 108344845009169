import classNames from "classnames";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { RadioGroup } from "@components/Radio";
import cs from "./DocsProtectionChoice.module.scss";

export const DocsProtectionChoice = ({ docsProtection, onChange }) => {
  const rootCs = classNames(cs.DocsProtectionChoice, {
    [cs.DocsProtectionChoice__readonly]: !onChange,
  });

  return (
    <div className={rootCs}>
      <RadioGroup value={docsProtection} required onChange={onChange}>
        <RadioGroup.Radio value={false} className="color-dark-gray">
          <span>Udostępniaj bez ograniczeń</span>
          <Tooltip
            title="Usługodawcy mogą pobierać dokumentację bez ograniczeń. Zostaniesz poinformowany o każdej firmie, która pobrała dokumentację."
            placement="top"
            className={cs.tooltip}
          >
            <InfoCircleOutlined
              style={{ cursor: "help", marginLeft: 5 }}
              width={10}
            />
          </Tooltip>
        </RadioGroup.Radio>

        <RadioGroup.Radio value={true} className="color-dark-gray mt4">
          <span>Udzielaj dostępu wybranym</span>
          <Tooltip
            title="Usługodawcy mogą pobierać dokumentację tylko za Twoją zgodą."
            placement="top"
            className={cs.tooltip}
          >
            <InfoCircleOutlined
              style={{ cursor: "help", marginLeft: 5 }}
              width={10}
            />
          </Tooltip>
        </RadioGroup.Radio>
      </RadioGroup>
    </div>
  );
};
