import classNames from "classnames";
import { Card } from "@components/Card";
import { Textarea } from "@components/Textarea";
import { DatePicker, DatePickerValue } from "@components/DatePicker";
import { DocsProtectionChoice } from "../../components/DocsProtectionChoice";
import cs from "./Details.module.scss";

type Props = {
  form: {
    desc: string;
    bid_deadline: DatePickerValue;
    done_deadline: DatePickerValue;
    permisson_to_download_file_required: boolean;
  };
  disabled: boolean;
  onChange: (changedField: string, newValue: any) => void;
};

export const Details = ({ form, disabled, onChange }: Props) => {
  const rootCs = classNames("Details", { [cs.disabled]: disabled });

  return (
    <section className={rootCs}>
      <Card>
        <Card.Header>
          <h2 className="h5">Szczegóły zapytania</h2>
        </Card.Header>

        <Card.Body className={cs.body}>
          <Textarea
            value={form.desc}
            label="Opis zapytania"
            placeholder="Powiedz potencjalnym wykonawcom na co powinni zwrócić uwagę"
            rows={8}
            className={cs.desc}
            maxLength={2000}
            size="large"
            onChange={(newValue) => {
              onChange("desc", newValue);
            }}
          />

          <div>
            <DatePicker
              value={form.done_deadline}
              label="Wymagany termin realizacji"
              placeholder="Wybierz termin"
              className={[cs.date, "mb24"]}
              minDate={DatePicker.onlyFromTomorrow}
              required
              onChange={(newValue) => {
                onChange("done_deadline", newValue);
              }}
            />

            <DatePicker
              value={form.bid_deadline}
              label="Termin składania ofert"
              placeholder="Wybierz termin"
              className={[cs.date, "mb24"]}
              minDate={(date) => {
                if (form.done_deadline) {
                  return (
                    DatePicker.onlyFromTomorrow(date) ||
                    date.isAfter(form.done_deadline, "days")
                  );
                }

                return DatePicker.onlyFromTomorrow(date);
              }}
              required
              onChange={(newValue) => {
                onChange("bid_deadline", newValue);
              }}
            />

            <div>
              <div className="mb4 caption bold color-black">
                Zabezpieczenie dokumetacji
              </div>
              <DocsProtectionChoice
                docsProtection={form.permisson_to_download_file_required}
                onChange={(newValue: boolean) => {
                  onChange("permisson_to_download_file_required", newValue);
                }}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </section>
  );
};
