import { Skeleton } from "antd";
import { Table } from "@components/Table";
import { Button } from "@components/Button";
import { useMyUrgentOrders } from "../../Customer/services/order.hooks";
import { OrderCard } from "../../Customer/views/MyOrdersPage/OrderCard";
import { useUrgentOffers } from "../../Provider/services/offer.hooks";
import { UrgentOffer } from "../../Provider/views/MyOffersPage/UrgentOffer";
import cs from "./DashboardPage.module.scss";

export const DashboardPage = () => {
  const [urgentOrders, areOrdersLoading] = useMyUrgentOrders();
  const [urgentOffers, areOffersLoading] = useUrgentOffers();

  return (
    <main className="DashboardPage">
      <section>
        <div className="h3 mb24">Moje aktywne zapytania</div>

        {urgentOrders.length > 0 ? (
          <div className={cs.urgent}>
            {urgentOrders.map((order) => (
              <OrderCard key={order._id} order={order} />
            ))}
          </div>
        ) : !areOrdersLoading ? (
          <NoOrders />
        ) : (
          <Skeleton active />
        )}
      </section>

      <section className="mt32">
        <div className="h3 mb24">Moje aktywne oferty</div>

        {urgentOffers.length > 0 ? (
          <div className={cs.urgent}>
            {urgentOffers.map((offer) => (
              <UrgentOffer offer={offer} />
            ))}
          </div>
        ) : !areOffersLoading ? (
          <NoOffers />
        ) : (
          <Skeleton active />
        )}
      </section>
    </main>
  );
};

const NoOrders = () => (
  <section className={cs.NoOrders}>
    <div className="h5 mb16">Nie masz żadnych aktywnych zapytań</div>

    <Button href="/order" type="primary" size="medium">
      Utwórz nowe zapytanie
    </Button>
  </section>
);

const NoOffers = () => (
  <Table
    data={[]}
    empty={{
      title: "Ta lista jest pusta",
      sub: "Poszukaj zleceń, na które będziesz mógł złożyć swoje oferty",
    }}
    className={cs.NoOffers}
  />
);
