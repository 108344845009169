import { useEffect, useState } from "react";
import { ChatMessage, getChatMessagesForOrder } from "./chat.api";

export function useChatMessagesForOrder(orderId: string) {
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  useEffect(() => {
    if (!orderId) return;

    (async () => {
      const { data } = await getChatMessagesForOrder(orderId);

      setMessages(data || []);
    })();
  }, [orderId]);

  return [messages, setMessages] as const;
}
