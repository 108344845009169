import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { notification } from "antd";
import {
  isAuthenticated,
  logOutApi,
  setNewPasswordApi,
} from "@services/auth.api";
import { Card } from "@components/Card";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { PrivacyPolicy } from "@components/PrivacyPolicy";
import { Baner } from "../../components/Baner";
import sharedCs from "../../styles.module.scss";
import cs from "./NewPasswordPage.module.scss";

export const NewPasswordPage = () => {
  const [form, setForm] = useImmer({ password: "", password2: "" });

  const params = useParams();

  const isUserAuthenticated = isAuthenticated();

  if (isUserAuthenticated) {
    logOutApi().then(() => {
      window.location.reload();
    });

    return null;
  }

  return (
    <main className={`NewPasswordPage ${cs.NewPasswordPage}`}>
      <Baner type="register" />

      <section className={cs.main}>
        <img
          src={process.env.PUBLIC_URL + "/4dustry-logo-blue.svg"}
          alt="4dustry"
          width={136}
        />

        <form onSubmit={handleFormSubmited}>
          <Card>
            <Card.Header className="h5">Ustaw nowe hasło</Card.Header>

            <Card.Body>
              <div className={sharedCs.formInputs}>
                <Input
                  value={form.password}
                  name="password"
                  type="password"
                  label="Hasło"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={handleFormChanged}
                />

                <Input
                  id="password2"
                  value={form.password2}
                  name="password2"
                  type="password"
                  label="Powtórz hasło"
                  size="large"
                  required
                  autoComplete="new-password"
                  onChange={handleFormChanged}
                />
              </div>
            </Card.Body>

            <Card.Footer>
              <Button
                type="primary"
                size="large"
                btnType="submit"
                style={{ width: "100%" }}
              >
                Zapisz
              </Button>
            </Card.Footer>
          </Card>
        </form>

        <PrivacyPolicy />
      </section>
    </main>
  );

  function handleFormChanged(value: string, name: string) {
    setForm((prev) => void (prev[name] = value));
  }

  async function handleFormSubmited(e) {
    e.preventDefault();

    if (!validatePassword()) return;

    const { isSuccess, error } = await setNewPasswordApi(
      form.password,
      params.token
    );

    if (isSuccess) {
      notification.success({
        message: "Hasło zmienione.",
        placement: "topRight",
      });

      setTimeout(() => {
        window.location.href = "/login";
      }, 800);
    } else if (error === "TOKEN_EXPIRED") {
      notification.error({
        message:
          "Link wygasł. Prosimy ponownie wygenerować wiadomość z linkiem resetującym.",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Wystąpił błąd.",
        placement: "topRight",
      });
    }
  }

  function validatePassword() {
    const passwordConfirm = document.getElementById(
      "password2"
    ) as HTMLInputElement;

    if (form.password !== form.password2) {
      passwordConfirm.setCustomValidity("Hasła nie pasują");
      passwordConfirm.reportValidity();

      return false;
    } else {
      passwordConfirm.setCustomValidity("");

      return true;
    }
  }
};
