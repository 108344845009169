import { useStore } from "@store";
import { getPriceListApi } from "@services/pricing.api";

export type PriceListSlice = {
  priceLists: any[];
  fetchPriceLists: () => Promise<void>;
};

export const priceListSlice = (set: any): PriceListSlice => ({
  priceLists: [],
  fetchPriceLists: async () => {
    const { rawData, isSuccess } = await getPriceListApi();

    if (isSuccess) {
      set({ priceLists: rawData }, false, "priceList/fetchPriceLists");
    }
  },
});

export const hooks = {
  usePriceLists() {
    const priceLists = useStore((state) => state.priceLists);

    return priceLists;
  },
};
