import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { notification, Switch } from "antd";
import { useCurrentAccount } from "@services/account.hooks";
import {
  createOfferApi,
  getOfferApi,
  updateOfferApi,
} from "../../services/offer.api";
import { getOrderApi } from "../../services/order.api";
import { calculateMassForMaterial } from "../../services/offer.helpers";
import { OfferingAnalyser } from "../../services/OfferingAnalyser";
import { store, useStore } from "@store";
import { genTempId, toFixedNumber } from "@helpers/utils";
import { toLocaleDate } from "@helpers/dateUtils";
import { ORDER_STATUSES } from "../../../constants/ORDER_STATUSES";
import { Textarea } from "@components/Textarea";
import { DatePicker } from "@components/DatePicker";
import { Card } from "@components/Card";
import { Button } from "@components/Button";
import { Confirmation, ConfirmationRef } from "@components/Confirmation";
import { DownloadOrderDocsBtn } from "@components/DownloadOrderDocs";
import { CustomerDetails } from "../../components/CustomerDetails";
import { OrderDescription } from "../../components/OrderDescription";
import { AdditionalFiles } from "../OrderPage/AdditionalFiles";
import { Elements } from "./Elements/Elements";
import { PriceCalculator } from "./PriceCalculator";
import { StatusInfoPanel } from "./StatusInfoPanel";
import fileImg from "./img/file.svg";
import cs from "./NewOffer.module.scss";

export const NewOfferPage = () => {
  const [order, setOrder] = useState(null);
  const [offer, setOffer] = useImmer({
    desc: "",
    offer_files: [],
  });
  const [elementWrappers, setElementWrappers] = useImmer<Map<string, object>>(
    new Map()
  );
  const [isPricingSetup, setIsPricingSetup] = useState<boolean>();
  const [refreshPricingTrigger, setRefreshPricingTrigger] = useState(null);
  const [wasGeneratePricesClicked, setWasGeneratePricesClicked] =
    useState(false);

  const regeneratePricesConfirmation = useRef<ConfirmationRef>(null);

  const { orderId, offerId } = useParams();

  const navigate = useNavigate();

  const currUser = useCurrentAccount();
  const priceLists = store.hooks.usePriceLists();
  const services = store.hooks.useServices();

  const isEditMode = Boolean(offerId);

  useEffect(() => {
    (async () => {
      if (isEditMode) {
        const { offer, order } = await getOfferApi(offerId);

        const elementWrappers = new Map();

        order.elements.forEach((el) => {
          const offerForEl = offer.elements.find(
            (offerForEl) => offerForEl.element_id === el._id
          );

          elementWrappers.set(el._id, {
            element: { ...el, selectedMachinesPerService: new Map() },
            selectedMachines: offerForEl?.selected_machines || [], // TODO: Temp for compatability for older offers
            selected: Boolean(offerForEl?.offer),
            offer: offerForEl?.offer || {},
          });
        });

        setOrder(order);
        setOffer(offer);
        setElementWrappers(elementWrappers);
      } else {
        const order = await getOrderApi(orderId);

        const elementWrappers = new Map();

        order.elements.forEach((el) => {
          elementWrappers.set(el._id, {
            element: { ...el, selectedMachinesPerService: new Map() },
            selected: true,
            offer: {},
          });
        });

        setOrder(order);
        setElementWrappers(elementWrappers);
      }
    })();
  }, [orderId, offerId]);

  useEffect(() => {
    if (isPricingSetup) return;
    if (priceLists.length === 0) return;
    if (elementWrappers.size === 0) return;

    setupPricing();
  }, [priceLists, elementWrappers]);

  useEffect(() => {
    if (refreshPricingTrigger) {
      refreshPricing();
    }
  }, [refreshPricingTrigger]);

  if (!order || elementWrappers.size === 0) {
    return <div>Loading...</div>;
  }

  const priceTotal = Array.from(elementWrappers.values())
    .filter((el) => el.selected)
    .reduce((sum, el) => {
      return sum + (el.offer.price_total || 0);
    }, 0);

  return (
    <main className={cs.NewOfferPage}>
      <header className={cs.header}>
        <div>
          <h1 className="h3 mb8">
            Oferta do zapytania ofertowego {order.seq_no}
          </h1>
          <div>Opublikowano: {toLocaleDate(order.created_at)}</div>
        </div>

        <div className={cs.headerActions}>
          <DownloadOrderDocsBtn order={order} size="medium" />

          {order.status === ORDER_STATUSES.ONGOING && (
            <Button type="primary" size="medium" onClick={handleSubmit}>
              {isEditMode ? "Zapisz zmiany" : "Wyslij ofertę"}
            </Button>
          )}
        </div>
      </header>

      <StatusInfoPanel order={order} />

      <section className={cs.notifications}>
        <Switch
          onChange={(checked) => {
            setOffer((prev) => void (prev.notifications = checked));
          }}
        />
        <span className="ml12 caption color-dark-gray">
          Chce dostawać powiadomienia związane z tym zamówieniem
        </span>
      </section>

      <section className={cs.descCustomerSection}>
        <OrderDescription desc={order.desc} />

        <CustomerDetails
          ownerCompany={order.owner_company}
          owner={order.owner}
        />
      </section>

      <section className="mt24">
        <Elements
          order={order}
          elements={elementWrappers}
          wasGeneratePricesClicked={wasGeneratePricesClicked}
          onClick={generatePrices}
          onOfferForElementChange={handleOfferForElementChange}
          onSelectionChanged={handleSelectionChanged}
          onSelectedMachineChange={handleSelectedMachineChanged}
        />
      </section>

      {order.order_files.length > 0 && (
        <AdditionalFiles order={order} files={order.order_files} />
      )}

      <section className={cs.summary}>
        <Card>
          <Card.Header className="h5">Podsumowanie</Card.Header>
          <Card.Body className="body color-gray d-flex">
            <div className={cs.desc}>
              <div className="mb12">
                <div className="mb4">Ogranicz czas ważności oferty:</div>
                <DatePicker
                  value={offer.offer_period}
                  minDate={(date) => {
                    if (order.bid_deadline) {
                      return (
                        DatePicker.onlyFromTomorrow(date) ||
                        date.isAfter(order.bid_deadline, "days")
                      );
                    }

                    return DatePicker.onlyFromTomorrow(date);
                  }}
                  placeholder="Oferta ważna do"
                  onChange={(val) => {
                    setOffer((prev) => void (prev.offer_period = val));
                  }}
                />
              </div>

              <div>
                <div className="mb4">Twój opis oferty:</div>
                <Textarea
                  value={offer.desc}
                  placeholder="Dodaj opis"
                  onChange={(val) => {
                    setOffer((prev) => void (prev.desc = val));
                  }}
                />
              </div>
            </div>

            <div>
              <div className="mb4">Załączone pliki:</div>

              {offer.offer_files.length > 0 && (
                <ul className={cs.offerFiles}>
                  {offer.offer_files.map((file: File) => (
                    <li key={file.name}>
                      <img src={fileImg} />
                      <span className="body bold ml8">{file.name}</span>

                      <Button
                        className={cs.offerFiles_removeBtn}
                        type="link"
                        onClick={() => handleAdditionalFileRemoved(file.name)}
                      >
                        Usuń
                      </Button>
                    </li>
                  ))}
                </ul>
              )}

              <Button
                size="small"
                type="secondary"
                style={{ position: "relative" }}
              >
                <label
                  htmlFor="project_additional_files"
                  style={{
                    bottom: "0",
                    cursor: "pointer",
                    left: "0",
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                />
                Dodaj pliki
              </Button>

              <input
                type="file"
                id="project_additional_files"
                hidden
                multiple
                onChange={(e) => {
                  const newFiles = Array.from(e.target.files || []);

                  setOffer((prev) => {
                    prev.offer_files.push(...newFiles);
                  });
                }}
              />
            </div>

            <div className={cs.price}>
              <div>Kwota całościowa za zrealizowanie zamówienia:</div>
              <div className="h2 mt4 mb4">
                {priceTotal || "-"} PLN
                <span style={{ fontSize: 20 }}> + VAT</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </section>

      <section className={cs.actions}>
        <DownloadOrderDocsBtn order={order} size="large" />

        {order.status === ORDER_STATUSES.ONGOING && (
          <Button
            type="primary"
            size="large"
            className="ml16"
            onClick={handleSubmit}
          >
            {isEditMode ? "Zapisz zmiany" : "Wyslij ofertę"}
          </Button>
        )}
      </section>

      <Confirmation
        ref={regeneratePricesConfirmation}
        title="Chcesz ponownie wygenerować ofertę automatycznie?"
        text="Wszystkie nadpisane zmiany zostaną usunięte"
        okText="Generuj mimo to"
        cancelText="Wróć"
      />
    </main>
  );

  function handleAdditionalFileRemoved(fileName: string) {
    setOffer((prev) => {
      prev.offer_files = prev.offer_files.filter(
        (file: File) => file.name !== fileName
      );
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const selectedEls = Array.from(elementWrappers.values()).filter(
      (wrapper) => wrapper.selected
    );

    // TODO: cała walidacja
    if (selectedEls.length === 0) {
      notification.error({
        message: "Musisz wybrać przynajmniej jeden element.",
        placement: "topRight",
      });

      return;
    } else if (!priceTotal) {
      notification.error({
        message: "Uzupełnij cenę.",
        placement: "topRight",
      });

      return;
    }

    if (isEditMode) {
      await handleOfferUpdated();
    } else {
      await handleOfferCreated();
    }
  }

  function handleSelectionChanged(_id, isSelected) {
    setElementWrappers((els) => {
      const el = els.get(_id);

      el.selected = isSelected;
    });
  }

  function handleSelectedMachineChanged(_id, updatedSelection) {
    setElementWrappers((els) => {
      const el = els.get(_id);

      el.element.selectedMachinesPerService = updatedSelection;
    });
  }

  function handleOfferForElementChange(elementId, property, value) {
    setElementWrappers((els) => {
      const el = els.get(elementId);

      el.offer[property] = value;

      if (property === "price") {
        el.offer.price_total = value * el.element.total_amount;
      }
      if (property === "price_total") {
        el.offer.price = toFixedNumber(value / el.element.total_amount, 2);
      }
      if (property === "noCutThreadedHoles" || property === "material_id") {
        setRefreshPricingTrigger(Date.now());
      }
    });
  }

  async function generatePrices() {
    let isConfirmed = true;

    // NOTE: Show confirmation if any price was already set
    if (priceTotal && regeneratePricesConfirmation.current) {
      isConfirmed = await regeneratePricesConfirmation.current.open();
    }

    if (!isConfirmed) return;

    OfferingAnalyser.generatePrices();

    setWasGeneratePricesClicked(true);

    setElementWrappers((els: Map<string, object>) => {
      els.forEach(({ element }, _id) => {
        let priceForAllElementCopies = 0;

        element.services.forEach((service) => {
          const selectedMachine = element.selectedMachinesPerService.get(
            service._id
          );

          if (selectedMachine?._id) {
            priceForAllElementCopies += selectedMachine.priceForService || 0;
          }
        });

        // priceForAllElementCopies += element.priceForMaterial;

        handleOfferForElementChange(
          _id,
          "price_total",
          Math.ceil(priceForAllElementCopies)
        );
      });
    });
  }

  function createSaveObjectForNewOffer() {
    const formData = new FormData();

    formData.append("order_id", orderId);
    formData.append("desc", offer.desc);
    formData.append("notifications", Boolean(offer.notifications));

    if (offer.offer_period) {
      formData.append(
        "offer_period",
        new Date(offer.offer_period).toISOString()
      );
    }

    offer.offer_files.forEach((file) => {
      formData.append("offer_files", file);
    });

    const elements = Array.from(elementWrappers.values())
      .filter((wrapper) => wrapper.selected)
      .map((wrapper) => ({
        element_id: wrapper.element._id,
        selected_machines: Array.from(
          wrapper.element.selectedMachinesPerService,
          ([serviceId, machine]) => {
            // NOTE: No pricelist for this machine (even default)
            if (!machine) {
              return {
                service_id: serviceId,
                machine_id: null,
              };
            }

            return {
              service_id: serviceId,
              machine_id: machine.isDefault ? "DEFAULT" : machine._id,
              machine_name: machine.model_name,
            };
          }
        ),
        offer: wrapper.offer,
      }));

    formData.append("elements", JSON.stringify(elements));

    return formData;
  }

  async function handleOfferCreated() {
    const saveObj = createSaveObjectForNewOffer();

    const response = await createOfferApi(saveObj);

    if (response.isSuccess) {
      notification.success({
        message: "Oferta została złożona",
        placement: "topRight",
      });

      OfferingAnalyser.offerCreated();

      navigate(`/offer/${response.newOfferId}`);
    } else if (response.error === "OFFER_EXISTS") {
      notification.error({
        message: "Już złożyłeś ofertę na to zapytanie.",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Wystąpił błąd przy tworzeniu zapytania.",
        description: "Spróbuj ponownie później.",
        placement: "topRight",
      });
    }
  }

  async function handleOfferUpdated() {
    const saveObj = createSaveObjectForEdit();

    const response = await updateOfferApi(offerId, saveObj);

    if (response.isSuccess) {
      notification.success({
        message: "Oferta została zaktualizowana.",
        placement: "topRight",
      });

      navigate(`/offer/${offerId}`);
    } else {
      notification.error({
        message: "Wystąpił błąd.",
        description: response.error,
        placement: "topRight",
      });
    }
  }

  function createSaveObjectForEdit() {
    const formData = new FormData();

    formData.append("desc", offer.desc);
    formData.append("notifications", Boolean(offer.notifications));

    if (offer.offer_period) {
      formData.append(
        "offer_period",
        new Date(offer.offer_period).toISOString()
      );
    }

    const elements = Array.from(elementWrappers.values())
      .filter((wrapper) => wrapper.selected)
      .map((wrapper) => ({
        element_id: wrapper.element._id,
        offer: wrapper.offer,
      }));

    formData.append("elements", JSON.stringify(elements));

    const selectedOfferFilesWithoutNewlyAdded = offer.offer_files.filter(
      (file) => file._id
    );
    formData.append(
      "offer_files",
      JSON.stringify(selectedOfferFilesWithoutNewlyAdded)
    );

    const newlyAddedFiles = offer.offer_files.filter((file) => !file._id);

    newlyAddedFiles.forEach((file) => {
      formData.append("new_offer_files", file);
    });

    return formData;
  }

  function setupPricing() {
    setElementWrappers((els: Map<string, object>) => {
      console.group(
        "%cUstawianie cenników i wyliczanie cen",
        "font-size: 15px"
      );

      els.forEach(({ element, offer, selectedMachines }, _id) => {
        console.group("Element:", element.name);

        attachCompatiblePriceListsToMachines(element);
        // console.log(current(element));
        markMachinesWithoutPriceListsAsIncompatible(element);
        // console.log(current(element));
        setDefaultPriceLists(element);
        // console.log(current(element));
        forEveryMachineCalculatePricePerService(element, offer);
        // console.log(current(element));

        //calculatePriceForMaterial(element, offer);

        if (isEditMode) {
          copySavedSelectedMachines(element, selectedMachines);
        } else {
          setCheapestMachinePerServiceAsSelected(element);
        }

        console.groupEnd();
      });

      console.groupEnd();
    });

    setIsPricingSetup(true);
  }

  function refreshPricing() {
    setElementWrappers((els: Map<string, object>) => {
      els.forEach(({ element, offer }, _id) => {
        //calculatePriceForMaterial(element, offer);
        forEveryMachineCalculatePricePerService(element, offer);
        setCheapestMachinePerServiceAsSelected(element);
      });
    });
  }

  // NOTE: Single machine can have multiple pricings - per service
  //       or per material so get appriopriate for the element
  function attachCompatiblePriceListsToMachines(element) {
    console.group("Dla każdej kompatybilnej maszyny szukam cennika");

    element.machinesPerService.forEach((machinesPerService, serviceId) => {
      machinesPerService.forEach((machine) => {
        machine.compatiblePricing = findCompatiblePriceListForMachine(
          machine,
          serviceId,
          element
        );
      });
    });

    console.groupEnd();
  }

  function markMachinesWithoutPriceListsAsIncompatible(element) {
    element.machinesPerService.forEach((machinesPerService, serviceId) => {
      element.machinesPerService.set(
        serviceId,
        machinesPerService.filter((machine) => {
          return machine.compatiblePricing;
        })
      );
    });
  }

  function setDefaultPriceLists(element) {
    console.group(
      "Dla każdego serwisu bez kompatybilnej maszyny ustawiam domyślny cennik dla tego serwisu"
    );

    element.services.forEach((service) => {
      const serviceDetails = services.find(({ _id }) => _id === service._id);

      const compatibleMachinesForService =
        element.machinesPerService.get(service._id) || [];

      if (compatibleMachinesForService.length === 0) {
        // NOTE: No compatible machines - check if there is
        //       a matching default pricing for this service
        const defaultPricingForService = priceLists.find((priceList) => {
          return priceList.service_id === service._id && priceList.is_default;
        });

        if (!defaultPricingForService) {
          console.log(
            "%cBrak domyślnego cennika dla serwisu: %s",
            "color: red;",
            serviceDetails.name
          );

          // TODO: Something is wrong with the flow of this function
          element.machinesPerService.set(service._id, []);

          console.groupEnd();
          return;
        }

        // TODO: Duplicated code - extract as a function
        if (service._id === 1 || service._id === 19) {
          // NOTE: Check if pricing has this thickness defined
          const pricesByThickness =
            defaultPricingForService.prices?.table?.find(({ scope }) => {
              return (
                element.parameters.thickness > scope[0] &&
                element.parameters.thickness <= scope[1]
              );
            });

          if (!pricesByThickness) {
            console.log(
              "%c(%s) Domyślny cennik nie ma zdefiniowanej zadanej grubości. Grubość detalu: %s mm",
              "color: red;",
              serviceDetails.name,
              element.parameters.thickness
            );

            // TODO: Something is wrong with the flow of this function
            element.machinesPerService.set(service._id, []);

            console.groupEnd();
            return;
          }
        }

        const defaultMachine = {
          _id: genTempId(),
          isDefault: true,
          compatiblePricing: defaultPricingForService,
          parameters: [
            {
              value: "3000,2500",
              parameter_id: 1,
            },
          ],
        };

        console.log("(%s) Ustawiam domyślny cennik", serviceDetails.name);

        element.machinesPerService.set(service._id, [defaultMachine]);
      }
    });

    console.groupEnd();
  }

  function setCheapestMachinePerServiceAsSelected(element) {
    element.services.forEach((service) => {
      const compatibleMachinesForService =
        element.machinesPerService.get(service._id) || [];

      const [cheapestMachine] = compatibleMachinesForService.sort((a, b) => {
        return a.priceForService - b.priceForService;
      });

      element.selectedMachinesPerService.set(service._id, cheapestMachine);
    });
  }

  function copySavedSelectedMachines(element: any, savedMachines: any[]) {
    const selectedMachinesPerService = new Map();

    savedMachines.forEach(({ service_id, machine_id }) => {
      const compatibleMachinesForService =
        element.machinesPerService.get(service_id) || [];

      let machine;

      if (machine_id === "DEFAULT") {
        machine = compatibleMachinesForService.find(
          ({ isDefault }) => isDefault
        );
      } else {
        machine = compatibleMachinesForService.find(
          ({ _id }) => _id === machine_id
        );
      }

      selectedMachinesPerService.set(service_id, machine);
    });

    element.selectedMachinesPerService = selectedMachinesPerService;
  }

  function forEveryMachineCalculatePricePerService(element, offer) {
    console.group("Obliczam ceny per maszyna per serwis:");

    // NOTE: Calculate price for every machine for every service
    element.services.forEach((service) => {
      const serviceDetails = services.find(({ _id }) => _id === service._id);

      console.group("Serwis: ", serviceDetails.name);

      const compatibleMachinesForService =
        element.machinesPerService.get(service._id) || [];

      const compatibleAndWithPricing = compatibleMachinesForService.filter(
        (machine) => machine.compatiblePricing
      );

      if (compatibleAndWithPricing?.length > 0) {
        compatibleAndWithPricing.forEach((machine) => {
          const priceCalculator = new PriceCalculator(
            element,
            offer,
            service,
            machine
          );

          const currPrice = priceCalculator.calculatePrice();

          const margin = currUser.price_margin / 100 || 0;
          machine.priceForService = toFixedNumber(
            margin * currPrice + currPrice,
            2
          );

          console.log(
            "Uwzględniam marżę (%s%): %s zł",
            margin,
            machine.priceForService
          );
        });
      } else {
        console.log(
          "%cBrak kompatybilnych maszyn i domyślny cennik nie może zostać użyty",
          "color:red;"
        );
      }

      console.groupEnd();
    });

    console.groupEnd();
  }

  function calculatePriceForMaterial(element, offer) {
    let materialPrice = calculateMaterialPrice(
      element.parameters,
      offer?.material_id || element.material_id
    );

    console.log(
      "Cena za materiał dla pojedynczego elementu: %s zł",
      materialPrice
    );

    materialPrice = materialPrice * element.total_amount;

    console.log("Cena za materiał dla wszystkich detali: %s zł", materialPrice);

    const margin = currUser.price_margin / 100 || 0;
    materialPrice = toFixedNumber(materialPrice * margin + materialPrice, 2);

    console.log("Uwzględniam marżę (%s%): %s zł", margin, materialPrice);

    element.priceForMaterial = materialPrice;
  }

  function findCompatiblePriceListForMachine(machine, serviceId, element) {
    const materials = useStore.getState().materials;

    const elParams = element.parameters;

    let compatiblePriceList = priceLists.find((priceList) => {
      if (priceList.service_id !== serviceId) {
        return false;
      }

      if (priceList.material_categories) {
        if (!priceList.machine_ids?.includes(machine._id)) {
          console.log(
            "%c (%s) Odrzucam jeden z cenników ze względu niezgodność maszyn.",
            "color: #8273d3;",
            machine.model_name
          );

          return false;
        }

        if (element.material_id) {
          const material = materials.find(
            ({ _id }) => _id === element.material_id
          );

          if (!priceList.material_categories.includes(material.type)) {
            console.log(
              "%c (%s) Odrzucam jeden z cenników ze względu na typ materiału.",
              "color: #8273d3;",
              machine.model_name
            );

            return false;
          }
        } else if (element.material_type_id) {
          if (
            !priceList.material_categories.includes(element.material_type_id)
          ) {
            console.log(
              "%c (%s) Odrzucam jeden z cenników ze względu na typ materiału.",
              "color: #8273d3;",
              machine.model_name
            );

            return false;
          }
        }
      }

      return true;
    });

    if (!compatiblePriceList) {
      console.log(
        "%cKompatybilna maszyna nie ma cennika. Maszyna: %s",
        "color: red;",
        machine.model_name
      );

      return null;
    }

    if (serviceId === 1 || serviceId === 19) {
      // NOTE: Check if pricing has this thickness defined
      const pricesByThickness = compatiblePriceList.prices?.table?.find(
        ({ scope }) => {
          return (
            elParams.thickness > scope[0] && elParams.thickness <= scope[1]
          );
        }
      );

      if (!pricesByThickness) {
        console.log(
          "%cKompatybilna maszyna nie ma cennika dla zadanej grubości. Maszyna: %s, Grubość detalu: %s mm",
          "color: red;",
          machine.model_name,
          elParams.thickness
        );

        return null;
      }
    }

    return compatiblePriceList;
  }
};

///
function calculateMaterialPrice(elementParameters, materialId) {
  console.group("Obliczanie ceny za materiał");

  const TEMP_MATERIAL_KG_COST = 16;

  const mass = calculateMassForMaterial(elementParameters, materialId);

  console.log("Masa: %s kg", mass);
  console.log("Cena za kg materiału: %s zł", TEMP_MATERIAL_KG_COST);

  const materialCost = mass * TEMP_MATERIAL_KG_COST;

  console.log("Cena za materiał: %s zł", materialCost);
  console.groupEnd();

  return materialCost;
}
