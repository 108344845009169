import { Notification as NotificationType } from "@type/notification";
import { Base } from "./Base";

type Props = {
  notification: NotificationType;
};

export const Notification = ({ notification }: Props) => {
  return getNotificationCmp(notification);
};

function getNotificationCmp(notification: NotificationType) {
  let text = "";
  let url = "";

  switch (notification.type) {
    case 1:
      text = `Nowa oferta do zapytania ${notification.details.orderSeqNo}.`;
      url = `/order/${notification.details.orderId}`;
      break;

    case 2:
      text = `Zapytanie ${notification.details.orderSeqNo} zostało anulowane.`;
      url = `/offer/order/${notification.details.orderId}`;
      break;

    case 3:
      text = `Zmieniono warunki zapytania ${notification.details.orderSeqNo}. Musisz
        potwierdzić, że twoja oferta jest nadal aktualna.`;
      url = `/offer/order/${notification.details.orderId}`;
      break;

    case 4:
      text = `Oferta do zapytania ${notification.details.orderSeqNo} została wycofana.`;
      url = `/order/${notification.details.orderId}`;
      break;

    case 5:
      text = "Twoja oferta została wybrana.";
      url = `/offer/${notification.details.offerId}`;
      break;

    case 6:
      text = `Nie wygrałeś zapytania ${notification.details.orderSeqNo}.`;
      url = `/offer/${notification.details.offerId}`;
      break;

    default:
      // TODO: Should not throw but return null and report to Sentry
      throw new Error(`Notification type '${notification.type}' unrecognized`);
  }

  return (
    <Base
      text={text}
      url={url}
      seen={notification.seen}
      created_at={notification.created_at}
    />
  );
}
