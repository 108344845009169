import { useState } from "react";
import { getNoOfUnitsText } from "@helpers/getNoOfUnitsText";
import { getNoOfSetsText } from "@helpers/getNoOfSetsText";
import { trim } from "@helpers/utils";
import { Card } from "@components/Card";
import { Chip } from "@components/Chip";
import { Button } from "@components/Button";
import { Pagination } from "@components/Pagination";
import { ElementDetailsModal } from "@components/ElementDetailsModal";
import { Viewer3D } from "@components/Viewer3D";
import { MaterialName } from "../../../Customer/views/NewOrderPage/Elements/Element/MaterialName";
import { TechnologyNames } from "../../../Customer/views/NewOrderPage/Elements/Element/TechnologyNames";
import { useListTypeCmp } from "../../../Customer/components/ListType";
import chrvronLeftImg from "./img/chevron_left.svg";
import chrvronRightImg from "./img/chevron_right.svg";
import chevronDownImg from "./img/chevron_down.svg";
import cs from "./Elements.module.scss";

export const Elements = ({ order, noOfSets, elements }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    perPage: 5,
  });
  const [viewedElement, setViewedElement] = useState(null);
  const [listType, ListTypeBtn] = useListTypeCmp("min");

  const elementsPaginated = elements.slice(
    pagination.perPage * (pagination.current - 1),
    pagination.perPage * pagination.current
  );

  const viewedIndex = elements.findIndex(
    ({ _id }) => _id === viewedElement?._id
  );

  return (
    <section className={"Elements"}>
      <Card>
        <Card.Header className={cs.header}>
          <span className="h5">Elementy do wykonania ({elements.length})</span>

          <ListTypeBtn className="hide-mobile" />
        </Card.Header>
        <Card.Body>
          <div className="body mb4">
            <span style={{ color: "#636E72" }}>Ilość:</span>
            <Chip color="orange" size="body" className="ml8">
              <span>{getNoOfSetsText(noOfSets)}</span>
            </Chip>
          </div>

          <div>
            {elementsPaginated.map((element) =>
              listType === "min" ? (
                <ElementMin
                  key={element._id}
                  element={element}
                  onShowDetails={() => {
                    setViewedElement(element);
                  }}
                />
              ) : (
                <Element
                  key={element._id}
                  order={order}
                  element={element}
                  onShowDetails={() => {
                    setViewedElement(element);
                  }}
                />
              )
            )}
          </div>

          <div className={cs.pagination}>
            <Pagination
              current={pagination.current}
              perPage={pagination.perPage}
              total={elements.length}
              onPageChange={(newPage) => {
                setPagination({ ...pagination, current: newPage });
              }}
              onPerPageChange={(newPerPage) => {
                setPagination({ current: 1, perPage: newPerPage });
              }}
            />
          </div>
        </Card.Body>
      </Card>

      <ElementDetailsModal
        element={viewedElement}
        noOfSets={noOfSets}
        visible={Boolean(viewedElement)}
        mode="readonly"
        footer={
          <div>
            <Button
              type="secondary"
              size="medium"
              disabled={viewedIndex === 0}
              onClick={prev}
            >
              <img src={chrvronLeftImg} />
            </Button>

            <span className="body ml12 mr12">
              <span className="bold">{viewedIndex + 1}</span>
              <span> / {elements.length}</span>
            </span>

            <Button
              type="secondary"
              size="medium"
              disabled={viewedIndex === elements.length - 1}
              onClick={next}
            >
              <img src={chrvronRightImg} />
            </Button>
          </div>
        }
        onClose={() => setViewedElement(null)}
      />
    </section>
  );

  function next() {
    setViewedElement(() => {
      const nextElement = elements[viewedIndex + 1];

      return nextElement;
    });
  }

  function prev() {
    setViewedElement(() => {
      const prevElement = elements[viewedIndex - 1];

      return prevElement;
    });
  }
};

const ElementMin = ({ element, onShowDetails }) => (
  <div className={cs.Element__min}>
    <div className="pr8">
      <div className="body bold mb4">{element.name}</div>
      <div className="caption" style={{ color: "#2D3436" }}>
        {element.desc}
      </div>
    </div>

    <div>
      <div className="caption mb4">Materiał:</div>
      <div className="caption bold color-black">
        <MaterialName
          materialId={element.material_id}
          typeId={element.material_type_id}
        />
      </div>
    </div>

    <div>
      <div className="caption mb4">Ilość szt. w komplecie:</div>
      <div className="caption bold color-black">{element.amount} szt.</div>
    </div>

    <div>
      <div className="caption mb4">Łączna ilość:</div>
      <div className="caption bold color-black">
        <Chip color="orange" size="caption">
          <span>{getNoOfUnitsText(element.total_amount)}</span>
        </Chip>
      </div>
    </div>

    <div className="ml-auto mr-auto">
      <Button type="link" size="medium" onClick={onShowDetails}>
        Szczegóły elementu
      </Button>
    </div>
  </div>
);

const Element = ({ order, element, onShowDetails }) => {
  const files = [element.file, ...element.relatedFiles];

  return (
    <div className={cs.Element}>
      <div className={cs.main}>
        <div style={{ alignSelf: "center" }}>
          <img
            src={
              element.thumbnail ||
              process.env.PUBLIC_URL + "/no_thumbnail_96x96.png"
            }
            alt={element.name}
            width={96}
            height={96}
            onError={(e) => (e.target.src = chevronDownImg)}
          />
        </div>

        <div>
          <div className="mb4 body bold">{element.name}</div>
          <div className="caption mb8">{element.desc}</div>
          <div>
            <Viewer3D file={element.file} />
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb2">Materiał:</div>
            <div className="caption bold color-black">
              <MaterialName
                materialId={element.material_id}
                typeId={element.material_type_id}
              />
            </div>
          </div>

          <div>
            <div className="caption mb2">Usługi:</div>
            <div className="caption bold color-black">
              <TechnologyNames
                serviceIds={element.services.map(({ _id }) => _id)}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb2">Pliki dokumentacji:</div>
            <div className="caption bold color-black">
              <div className="caption bold color-black">
                {files.map((file, idx) => {
                  const isLast = idx === files.length - 1;
                  const comma = isLast ? " " : ", ";

                  return (
                    <div key={file.name}>
                      {file.name}
                      {comma}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div>
            <div className="caption mb2">Uwagi zlecającego:</div>
            <div className="caption color-black">
              {trim(element.remarks || "-", 60)}
            </div>
          </div>
        </div>

        <div>
          <div className="mb12">
            <div className="caption mb4">Łączna ilość:</div>
            <div className="caption color-black bold">
              <Chip color="orange" size="caption">
                {getNoOfUnitsText(element.total_amount)}
              </Chip>
            </div>
          </div>

          <div className="caption">
            Ilość szt. w komplecie: {element.amount}
          </div>
        </div>
      </div>

      <div className={cs.footer}>
        <Button type="link" onClick={onShowDetails}>
          <span className="mr8">Szczegóły elementu</span>
          <img src={chevronDownImg} />
        </Button>
      </div>
    </div>
  );
};
