import { Link } from "react-router-dom";
import manImg from "./img/man.png";
import checkCircleImg from "./img/check_circle.svg";
import cs from "./Baner.module.scss";

export const Baner = ({ type = "login" }) => (
  <section className={`Baner ${cs.Baner}`}>
    <div className={cs.title}>Aplikacja 4Dustry pomaga w:</div>

    <div className="body">
      <ul>
        <li className={cs.listItem}>
          <img src={checkCircleImg} alt="" />
          <span>
            Znalezieniu firmy która wykona dla ciebie detale w wielu
            technologiach produkcji
          </span>
        </li>
        <li className={cs.listItem}>
          <img src={checkCircleImg} alt="" />
          <span>Znalezieniu nowych klientów na Twoje usługi produkcyjne</span>
        </li>
        <li className={cs.listItem}>
          <img src={checkCircleImg} alt="" />
          <span>Przyspiesza proces od zapytania ofertowego do realizacji</span>
        </li>
        <li className={cs.listItem}>
          <img src={checkCircleImg} alt="" />
          <span>Automatyzuje proces tworzenia oferty</span>
        </li>
      </ul>
    </div>

    <div className={cs.actions}>
      {type === "login" ? (
        <Link to="/register">
          <WhiteBtn text="Załóż konto" />
        </Link>
      ) : type === "register" ? (
        <Link to="/login">
          <WhiteBtn text="Zaloguj konto" />
        </Link>
      ) : null}

      <a href="https://www.4dustry.com/" className={cs.detailsBtn}>
        Poznaj szczegóły
      </a>
    </div>

    <img src={manImg} alt="" style={{ marginTop: "auto", marginLeft: 8 }} />
  </section>
);

const WhiteBtn = ({ text }) => {
  return <button className={cs.WhiteBtn}>{text}</button>;
};
