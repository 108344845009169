import { useState } from "react";
import { OfferDetailsModal } from "./OfferDetailsModal/OfferDetailsModal";
import { Table } from "@components/Table";
import { Button } from "@components/Button";
import { columns } from "./tableColumns";
import chrvronLeftImg from "../img/chevron_left.svg";
import chrvronRightImg from "../img/chevron_right.svg";
import trophyImg from "../img/trophy.svg";
import cs from "./Offers.module.scss";

export const Offers = ({ order, offers, onOfferSelected }) => {
  const [viewedOffer, setViewedOffer] = useState(null);

  const currentIndex = offers.findIndex(({ _id }) => _id === viewedOffer?._id);

  const footer = (
    <div className={cs.footer}>
      <div className="h4">
        Suma: {viewedOffer?.offer_sum} PLN
        <span style={{ fontSize: "18px" }}> + VAT </span>
      </div>

      <div className="ml-auto">
        <Button
          type="secondary"
          size="medium"
          disabled={currentIndex === 0}
          onClick={prev}
        >
          <img src={chrvronLeftImg} />
        </Button>

        <span className="body ml12 mr12">
          <span className="bold">{currentIndex + 1}</span>
          <span> / {offers.length}</span>
        </span>

        <Button
          type="secondary"
          size="medium"
          disabled={currentIndex === offers.length - 1}
          onClick={next}
        >
          <img src={chrvronRightImg} />
        </Button>
      </div>
    </div>
  );

  return (
    <section className="mt24">
      <Table
        data={offers}
        columns={columns({ onShowDetails: handleShowDetails })}
        title="Oferty"
        empty={{ title: "Brak ofert" }}
      />

      <OfferDetailsModal
        offer={viewedOffer}
        order={order}
        footer={footer}
        onClose={() => setViewedOffer(null)}
      />
    </section>
  );

  function handleShowDetails(offerId) {
    const offer = offers.find(({ _id }) => _id === offerId);

    setViewedOffer(offer);
  }

  function next() {
    setViewedOffer((prev) => {
      const nextOffer = offers[currentIndex + 1];

      return nextOffer;
    });
  }

  function prev() {
    setViewedOffer((prev) => {
      const prevOffer = offers[currentIndex - 1];

      return prevOffer;
    });
  }
};
