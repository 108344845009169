import { InputNumber } from "@components/InputNumber";
import solidImg from "../../img/solid.png";
import cs from "./Grinding.module.scss";

export const Grinding = ({ params, readOnly, onChange }) => {
  return (
    <div>
      <div className="h5 mb16">Szlifowanie:</div>

      <div className={cs.main}>
        <div className={cs.imgContainer}>
          <img src={solidImg} alt="" />
        </div>
        <div className={cs.inputs}>
          <div>
            <span>Długość</span>
            <InputNumber
              size="small"
              value={params.overall_dimension_x}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("overall_dimension_x", value)}
            />
            <span>mm</span>
          </div>
          <div>
            <span>Szerokość</span>
            <InputNumber
              size="small"
              value={params.overall_dimension_y}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("overall_dimension_y", value)}
            />
            <span>mm</span>
          </div>
          <div>
            <span>Wysokość</span>
            <InputNumber
              size="small"
              value={params.overall_dimension_z}
              min={0.01}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("overall_dimension_z", value)}
            />
            <span>mm</span>
          </div>
          <div>
            <span>Pole powierzchni szlifowanej:</span>
            <InputNumber
              size="small"
              value={params.grinding_area}
              min={0.001}
              step={0.001}
              readOnly={readOnly}
              required
              onChange={(value) => onChange("grinding_area", value)}
            />
            <span>
              m<sup>2</sup>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
