import { ORDER_STATUSES } from "../../../constants/ORDER_STATUSES";
import { getOrderStatusNameById } from "@helpers/getOrderStatusNameById";
import { Select } from "@components/Select";
import { DatePicker } from "@components/DatePicker";
import cs from "./MyOrdersPage.module.scss";

export const Actions = ({ filters, onChange }) => (
  <div className={`${cs.Actions} hide-mobile`}>
    <Select
      value={filters.status}
      style={{ width: 150 }}
      placeholder="Status"
      options={[
        {
          value: ORDER_STATUSES.ONGOING,
          label: getOrderStatusNameById(ORDER_STATUSES.ONGOING),
        },
        {
          value: ORDER_STATUSES.CLOSED,
          label: getOrderStatusNameById(ORDER_STATUSES.CLOSED),
        },
        {
          value: ORDER_STATUSES.UNRESOLVED,
          label: getOrderStatusNameById(ORDER_STATUSES.UNRESOLVED),
        },
        {
          value: ORDER_STATUSES.CANCELLED,
          label: getOrderStatusNameById(ORDER_STATUSES.CANCELLED),
        },
      ]}
      size="small"
      allowClear
      onChange={(value) => {
        onChange("status", value);
      }}
    />

    <DatePicker
      value={filters.bid_deadline}
      placeholder="Termin ofertowania"
      size="small"
      onChange={(value) => {
        onChange("bid_deadline", value || null);
      }}
    />

    <DatePicker
      value={filters.done_deadline}
      placeholder="Termin realizacji"
      size="small"
      onChange={(value) => {
        onChange("done_deadline", value || null);
      }}
    />
  </div>
);
