import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { store } from "@store";
import { Modal } from "@components/Modal";
import { Select } from "@components/Select";

type Resolver = (selectedMachineIds: number[] | null) => void;

export const SelectMaterialTypesModal = forwardRef(({ serviceId }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alreadySelectedMaterials, setAlreadySelectedMaterials] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const resolver = useRef<Resolver>();

  const materialTypes = store.hooks.useMaterialTypesByService(serviceId);

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  return (
    <div className="SelectServiceModal">
      <Modal
        title="Wybierz typy materiału"
        visible={isOpen}
        width={440}
        onOk={handleConfirm}
        maskClosable={false}
        okText="Zapisz"
        okButtonProps={{
          disabled: selectedTypes.length === 0,
        }}
        onClose={handleCancel}
      >
        <Select
          size="small"
          value={selectedTypes}
          mode="multiple"
          options={materialTypes.map(({ _id, name }) => ({
            label: name,
            value: _id,
            disabled: alreadySelectedMaterials.some((id) => id === _id),
          }))}
          showSearch
          style={{ width: 310 }}
          onChange={setSelectedTypes}
        />
      </Modal>
    </div>
  );

  function openModal(alreadySelectedMaterials, currentlySelected) {
    setAlreadySelectedMaterials(alreadySelectedMaterials);
    setSelectedTypes(currentlySelected || []);
    setIsOpen(true);

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  }

  function handleConfirm() {
    resolver.current?.(selectedTypes);

    handleClose();
  }

  function handleCancel() {
    resolver.current?.(null);

    handleClose();
  }

  function handleClose() {
    setIsOpen(false);
    clear();
  }

  function clear() {
    setSelectedTypes([]);
  }
});
