import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "@components/Modal";

type Props = {
  title: string;
  text: string;
  okText: string;
  cancelText: string;
  okCs?: string;
  cancelCs?: string;
};

type Resolver = (value: boolean) => void;

export type ConfirmationRef = {
  open: () => Promise<boolean>;
};

export const Confirmation = forwardRef<{}, Props>(
  (
    {
      title,
      text,
      okText = "Potwierdzam",
      cancelText = "Anuluj",
      okCs = "Button_secondary",
      cancelCs = "Button_primary",
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const resolver = useRef<Resolver>();

    useImperativeHandle<any, ConfirmationRef>(ref, () => ({
      open: () => {
        return new Promise((_resolve) => {
          setIsOpen(true);

          resolver.current = _resolve;
        });
      },
    }));

    let resolve = resolver.current as Resolver;

    return (
      <Modal
        title={title}
        width={480}
        okText={okText}
        cancelText={cancelText}
        cancelButtonProps={{ className: `${cancelCs} Button_medium` }}
        okButtonProps={{ className: `${okCs} Button_medium` }}
        visible={isOpen}
        onClose={() => {
          setIsOpen(false);
          resolve(false);
        }}
        onOk={() => {
          setIsOpen(false);
          resolve(true);
        }}
      >
        <p className="body">{text}</p>
      </Modal>
    );
  }
);
