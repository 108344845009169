import { ORDER_STATUSES } from "../constants/ORDER_STATUSES";

export function getOrderStatusNameById(status: ORDER_STATUSES) {
  switch (status) {
    case ORDER_STATUSES.ONGOING:
      return "W trakcie";
    case ORDER_STATUSES.CLOSED:
      return "Zakończone";
    case ORDER_STATUSES.CANCELLED:
      return "Anulowane";
    case ORDER_STATUSES.UNRESOLVED:
      return "Nierozstrzygnięte";
    case ORDER_STATUSES.DRAFT:
      return "Szkic";
    case ORDER_STATUSES.UNAPPROVED:
      return "Oczekujące";
    default:
      throw new Error(`Order status '${status}' unrecognized.`);
  }
}
